import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-editor',
  template: `
    <div class="wrapper">
      <map-editor *axLazyElement></map-editor>
    </div>
  `,
  styles: [``],
})
export class MapEditorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
