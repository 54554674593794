import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactInfo, License, Subscriptions } from '@app/core/interfaces';
import { PermissionAction, PermissionResource } from '@app/core/constants';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  alreadyWarned$ = new BehaviorSubject(false);
  licensesExpiring$ = new BehaviorSubject(false);
  licensesWarning$ = new BehaviorSubject(false);
  customerId?: number;
  company: any = {};
  licenses$ = new BehaviorSubject([]);
  licenseInfo: License[] = [];
  loading: boolean = false;
  roleName: string = '';
  settings: any = {};
  subscriptions: Subscriptions = {};
  subscription$ = new BehaviorSubject(0);
  systemSetting$ = new BehaviorSubject([]);
  //companyApplicationInfo: any = {};

  userId?: number;
  userSettings: any = {};

  isSuperUser: boolean = false;
  permissions: any[] = [];

  scrHeight: number = window.innerHeight;
  scrWidth: number = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    return this.scrHeight < 750 || this.scrWidth < 500;
  }

  constructor(
    private http: HttpClient,
    private notifier: MatSnackBar,
    private storage: StorageService
  ) {}

  initializeService(ldapReassign: boolean = false) {
    this.loading = true;
    let uvsC = this.storage.get('uvs-credentials');
    let companyData = this.storage.get('company');
    this.customerId = companyData ? companyData.id : undefined;

    if (uvsC) {
      this.roleName = uvsC.userObject.RoleName;
      this.userId = uvsC.userObject.Id;
      this.userSettings = uvsC.userObject.UserSettings
        ? JSON.parse(uvsC.userObject.UserSettings)
        : {};
      this.isSuperUser = uvsC.userObject.IsSuperUser
        ? uvsC.userObject.IsSuperUser
        : false;
      this.permissions = uvsC.userObject.Permissions
        ? uvsC.userObject.Permissions
        : [];
    }

    if (ldapReassign) this.loading = false;

    if (!ldapReassign) {
      this.subscriptions = {};
      if (this.customerId) {
        forkJoin([
          this.getCustomerInfo(),
          this.getLicenseInfo(),
          this.getSubcriptions(),
          this.getSystemSettings(),
        ]).subscribe(
          ([data, licenses, subs, sets]: any) => {
            this.company = data;
            this.licenseInfo = licenses;
            this.settings = sets;
            this.subscriptions = subs;
            this.loading = false;

            if (uvsC.userObject.FromLDAP) {
              this.updateUserSettingsUseCases(uvsC.userObject);
            }

            if (!this.subscriptions.integrations) {
              this.subscriptions.integrations = {
                ALARIS: false,
                ARC: false,
                CMX: false,
                MERAKI: false,
              };
            }
          },
          () => {
            this.loading = false;
            this.notifier.open(
              'There was a problem retrieving the customer subscriptions.',
              '',
              { panelClass: 'error' }
            );
          }
        );
      }
    }
  }

  //MKL changed these to TIERS on 5-9-2024
  getPermission(
    permissionType: PermissionResource,
    permissionAction: PermissionAction
  ): boolean {
    if (this.isSuperUser) return true;
    const p = this.permissions.find(
      (permission: any) => permission.Name == permissionType
    );
    if (p) {
      switch (permissionAction) {
        case 'view': //4
          return p.EnableView || p.EnableAdd || p.EnableEdit || p.EnableDelete;
        case 'add': //3
          return p.EnableAdd || p.EnableEdit || p.EnableDelete;
        case 'edit': //2
          return p.EnableEdit || p.EnableDelete;
        case 'delete': //1
          return p.EnableDelete;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  addIntegration(integrationJSON: string) {
    return this.http.post(
      '/api/Customer/AddIntegration?CustomerId=' +
        this.customerId +
        '&IntegrationInfo=' +
        integrationJSON,
      {}
    );
  }

  addLicense(license: string) {
    const encodedLicense: string = encodeURIComponent(license);
    return this.http.post(
      '/api/Customer/AddLicense?CustomerId=' +
        this.customerId +
        '&LicenseInfo=' +
        encodedLicense,
      {}
    );
  }

  createCustomer(createCustomer: any) {
    return this.http.post(
      '/api/Customer/CreateCustomer?CompanyName=' +
        encodeURIComponent(createCustomer.customer_name) +
        '&Domain=' +
        encodeURIComponent(createCustomer.Domain) +
        '&SubDomain=' +
        encodeURIComponent(createCustomer.SubDomain) +
        '&ContactPerson=' +
        createCustomer.names +
        '&ContactEmail=' +
        encodeURIComponent(createCustomer.Email) +
        '&ContactPhone=' +
        createCustomer.Phone +
        '&CustomerApplications=' +
        createCustomer.subscriptions,
      {}
    );
  }

  deleteIntegration(id: number) {
    return this.http.delete(
      '/api/Customer/DeleteIntegration?CustomerId=' +
        this.customerId +
        '&IntegrationId=' +
        id
    );
  }

  //TODO: will need a getAll based on the tenant id
  getAllCustomers() {
    return this.http
      .get('/api/Customer/GetAll')
      .pipe(map((data: any) => data.List));
  }

  getCustomerInfo() {
    return this.http.get(
      '/api/Customer/GetByCustomerId?CustomerId=' + this.customerId
    );
  }

  getLicenseInfo() {
    return this.http
      .get('/api/Customer/GetLicenseInfo?CustomerId=' + this.customerId)
      .pipe(
        map((data: any) => {
          let licenseInfo = data.LicenseInfo
            ? JSON.parse(data.LicenseInfo)
            : '';
          let licenses: any = [
            {
              name: 'Devices',
              langKey: 'DEVICES',
              amount: licenseInfo?.NumberOfTags,
              used: data.NumberOfTagLicensesUsed || 0,
              expiration: licenseInfo?.TagsExpTime,
              icon: 'wifi',
            },
            {
              name: 'Infrastructures',
              langKey: 'INFRASTRUCTURE',
              amount: licenseInfo?.NumberOfInfrastructure,
              used: data.NumberOfBeaconLicensesUsed || 0,
              expiration: licenseInfo?.InfrasExpTime,
              icon: 'wifi_tethering',
            },
            {
              name: 'AssetTracking',
              langKey: 'ASSET',
              amount: licenseInfo?.AssetTrackingAmount,
              used: data.NumberOfAssetTrackingLicensesUsed || 0,
              expiration: licenseInfo?.AssetTrackingTime,
              icon: 'share_location',
            },
            {
              name: 'HandHygiene',
              langKey: 'HYGIENE',
              amount: licenseInfo?.HandHygieneAmount,
              used: data.NumberOfHandHygieneLicensesUsed || 0,
              expiration: licenseInfo?.HandHygieneTime,
              icon: 'clean_hands',
            },
            {
              name: 'MELT',
              langKey: 'MELT',
              amount: licenseInfo?.MELTAmount,
              used: data.NumberOfMELTLicensesUsed || 0,
              expiration: licenseInfo?.MELTTime,
              icon: 'vibration',
            },
            {
              name: 'PatientFlow',
              langKey: 'PATIENT',
              amount: licenseInfo?.PatientFlowAmount,
              used: data.NumberOfPatientFlowLicensesUsed || 0,
              expiration: licenseInfo?.PatientFlowTime,
              icon: 'transfer_within_a_station',
            },
            {
              name: 'PeopleFlow',
              langKey: 'PEOPLE',
              amount: licenseInfo?.PeopleFlowAmount,
              used: data.NumberOfPeopleFlowLicensesUsed || 0,
              expiration: licenseInfo?.PeopleFlowTime,
              icon: 'transfer_within_a_station',
            },
            {
              name: 'SDCT',
              langKey: 'SDCT',
              amount: licenseInfo?.SDCTAmount,
              used: data.NumberOfSDCTLicensesUsed || 0,
              expiration: licenseInfo?.SDCTTime,
              icon: '6_ft_apart',
            },
            {
              name: 'StaffSafety',
              langKey: 'STAFF',
              amount: licenseInfo?.StaffSafetyAmount,
              used: data.NumberOfStaffSafetyLicensesUsed || 0,
              expiration: licenseInfo?.StaffSafetyTime,
              icon: 'engineering',
            },
            {
              name: 'TemperatureMonitoring',
              langKey: 'TEMPERATURE',
              amount: licenseInfo?.TemperatureMonitoringAmount,
              used: data.NumberOfTemperatureMonitoringLicensesUsed || 0,
              expiration: licenseInfo?.TemperatureMonitoringTime,
              icon: 'thermostat',
            },
            {
              name: 'BedManagement',
              langKey: 'BED MANAGEMENT',
              amount: licenseInfo?.BedManagementAmount,
              used: data.NumberOfBedManagementLicensesUsed || 0,
              expiration: licenseInfo?.BedManagementTime,
              icon: 'night_shelter',
            },
          ];
          this.licenses$.next(licenses);
          return licenses;
        })
      );
  }

  //http://52.45.17.177:802/XpertRestApi/api/Users/GetPermission?PermissionType=Users&PermissionAction=add
  getPermissionFromAPI(
    permissionType: PermissionResource,
    permissionAction: PermissionAction
  ): Observable<boolean> {
    return this.http
      .get(
        `/api/Users/GetPermission?PermissionType=${permissionType}&PermissionAction=${permissionAction}`
      )
      .pipe(
        map((data: any) => {
          if (data.PermissionGranted) {
            return true;
          } else {
            return false;
          }
        })
      );
  }

  getSubcriptions() {
    return this.http
      .get<Subscriptions>(
        '/api/Customer/GetByCustomerId?CustomerId=' + this.customerId
      )
      .pipe(
        map((data: any) => {
          let subscriptions = data.CustomerApplications
            ? JSON.parse(data.CustomerApplications)
            : {};
          this.subscription$.next(subscriptions);
          return subscriptions;
        })
      );
  }

  getSystemSettings() {
    //console.log('company', this.company);
    //console.log('systemSettings', this.systemSetting$);
    //console.log('roleName', this.roleName);
    return this.http
      .get(
        '/api/Settings/GetNotificationSettingsForSDCT?CustomerId=' +
          this.customerId +
          '&SettingType=System'
      )
      .pipe(
        map((data: any) => {
          if (data.SettingJson && data.SettingJson !== '') {
            let systemSettings = JSON.parse(data.SettingJson);
            this.systemSetting$.next(systemSettings);
            return systemSettings;
          }
        })
      );
  }

  updateContactInfo(data: ContactInfo) {
    return this.http.post(
      `/api/Customer/UpdateCustomer?CustomerId=${this.customerId}` +
        `&CustomerName=${encodeURIComponent(data.companyName)}` +
        `&ContactPerson=${encodeURIComponent(data.name)}` +
        `&ContactEmail=${encodeURIComponent(data.email)}` +
        `&ContactPhone=${data.phone}` +
        `&Domain=${encodeURIComponent(data.domain)}` +
        `&SubDomain=${
          data.subDomain
            ? encodeURIComponent(data.subDomain)
            : encodeURIComponent(data.domain)
        }`,
      {}
    );
  }

  updateSubscriptions(subs: Subscriptions) {
    const subscriptions = JSON.stringify(subs);
    return this.http.post(
      '/api/Customer/UpdateCustomer?CustomerId=' +
        this.customerId +
        '&CustomerApplications=' +
        subscriptions,
      {}
    );
  }

  updateUserSettingsUseCases(userObject: any) {
    if (!userObject) return;

    const LDAPGroups: string[] = userObject.UserDefine1
      ? userObject.UserDefine1.split(',')
      : [];
    if (LDAPGroups.length === 0) return;

    const previousSettings: any = userObject.UserSettings
      ? JSON.parse(userObject.UserSettings)
      : {};
    const previousUseCases: string[] = previousSettings?.useCases ?? [];

    let useCases: string[] = [];

    let assetGroups = this.settings?.LDAPUseCaseGroups?.AssetTracking ?? [];
    let handGroups = this.settings?.LDAPUseCaseGroups?.HandHygiene ?? [];
    let meltGroups = this.settings?.LDAPUseCaseGroups?.MELT ?? [];
    let patientGroups = this.settings?.LDAPUseCaseGroups?.PatientFlow ?? [];
    let peopleGroups = this.settings?.LDAPUseCaseGroups?.PeopleFlow ?? [];
    let sdctGroups = this.settings?.LDAPUseCaseGroups?.SDCT ?? [];
    let staffGroups = this.settings?.LDAPUseCaseGroups?.StaffSafety ?? [];
    let tempGroups =
      this.settings?.LDAPUseCaseGroups?.TemperatureMonitoring ?? [];

    if (
      this.subscriptions.AssetTracking &&
      assetGroups.length > 0 &&
      this.useCaseGroupMatch(assetGroups, LDAPGroups)
    ) {
      useCases.push('AssetTracking');
    }
    if (
      this.subscriptions.HandHygiene &&
      handGroups.length > 0 &&
      this.useCaseGroupMatch(handGroups, LDAPGroups)
    ) {
      useCases.push('HandHygiene');
    }
    if (
      this.subscriptions.MELT &&
      meltGroups.length > 0 &&
      this.useCaseGroupMatch(meltGroups, LDAPGroups)
    ) {
      useCases.push('MELT');
    }
    if (
      this.subscriptions.PatientFlow &&
      patientGroups.length > 0 &&
      this.useCaseGroupMatch(patientGroups, LDAPGroups)
    ) {
      useCases.push('PatientFlow');
    }
    if (
      this.subscriptions.PeopleFlow &&
      peopleGroups.length > 0 &&
      this.useCaseGroupMatch(peopleGroups, LDAPGroups)
    ) {
      useCases.push('PeopleFlow');
    }
    if (
      this.subscriptions.SDCT &&
      sdctGroups.length > 0 &&
      this.useCaseGroupMatch(sdctGroups, LDAPGroups)
    ) {
      useCases.push('SDCT');
    }
    if (
      this.subscriptions.StaffSafety &&
      staffGroups.length > 0 &&
      this.useCaseGroupMatch(staffGroups, LDAPGroups)
    ) {
      useCases.push('StaffSafety');
    }
    if (
      this.subscriptions.TemperatureMonitoring &&
      tempGroups.length > 0 &&
      this.useCaseGroupMatch(tempGroups, LDAPGroups)
    ) {
      useCases.push('TemperatureMonitoring');
    }

    //check to see if the settings need to be updated
    if (JSON.stringify(previousUseCases) === JSON.stringify(useCases)) return;

    const userSettings: any = { useCases: useCases };
    this.updateUserSettings(JSON.stringify(userSettings)).subscribe(
      () => {
        let userData = this.storage.get('uvs-credentials');
        let currentSettings = userData.userObject.UserSettings;

        if (!currentSettings) {
          userData.userObject.UserSettings = JSON.stringify(userSettings);
        } else {
          let settingsObject = JSON.parse(currentSettings);
          settingsObject.useCases = useCases;
          userData.userObject.UserSettings = JSON.stringify(settingsObject);
        }
        this.storage.setUvsCreds(userData, true);
        //MKL need to update user permissions here?
        this.initializeService(true);
      },
      () => {}
    );
  }

  updateUserSettings(userSettings: string) {
    return this.http.post(
      `/api/Users/UpdateUserSettings?UserId=${this.userId}` +
        `&UserSettings=${userSettings}` +
        `&CustomerId=${this.customerId}`,
      {}
    );
  }

  useCaseGroupMatch(useCaseGroups: string[], ldapGroups: string[]): boolean {
    return useCaseGroups.some((x: string) =>
      ldapGroups.some((y: string) => y === x)
    );
  }
}
