<div>
  <div>
    <div class="form-header">
      {{ "MAIN.PASSWORD.CHANGE" | translate }}
    </div>
  </div>

  <form class="form-container" [formGroup]="changePasswordForm">
    <div class="logout">
      <p class="logout-warning">
        {{ "MAIN.PASSWORD.WARNING" | translate }}
      </p>
    </div>
    <mat-form-field>
      <mat-label>{{ "MAIN.PASSWORD.OLD" | translate }}</mat-label>
      <input matInput type="password" formControlName="oldPassword" />
    </mat-form-field>
    <mat-form-field class="new-pass">
      <mat-label>{{ "MAIN.PASSWORD.NEW" | translate }}</mat-label>
      <input matInput type="password" formControlName="newPassword" />
      <mat-error *ngIf="newPassword?.errors?.pattern"
        >Must have {{ getPasswordRequirements().caps }} capital(s),
        {{ getPasswordRequirements().nums }} number(s), and
        {{ getPasswordRequirements().symb }} symbol(s)
      </mat-error>
      <mat-error *ngIf="newPassword?.errors?.minlength"
        >Must be at least {{ getPasswordLength() }} characters long</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "MAIN.PASSWORD.CONFIRM" | translate }}</mat-label>
      <input matInput type="password" formControlName="confirmPassword" />
      <mat-error *ngIf="confirmPassword?.errors?.mustMatch">
        {{ "MAIN.PASSWORD.CONFIRM_ERROR" | translate }}
      </mat-error>
    </mat-form-field>

    <div class="form-buttons" mat-dialog-actions>
      <button
        mat-flat-button
        color="primary"
        [disabled]="changePasswordForm.invalid"
        (click)="onSubmit()"
      >
        <mat-spinner
          class="spinner"
          color="accent"
          diameter="30"
          *ngIf="loading"
        ></mat-spinner>
        <span *ngIf="!loading">
          {{ "FORMS.SUBMIT" | translate }}
        </span>
      </button>
      <button mat-stroked-button mat-dialog-close>
        {{ "FORMS.CANCEL" | translate }}
      </button>
    </div>
  </form>
</div>
