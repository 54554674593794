import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  template: `
    <div class="wrapperSub">
      <reports-view *axLazyElement></reports-view>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class ReportsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
