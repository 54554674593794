import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-settings',
  template: ` <sub-settings *axLazyElement></sub-settings> `,
  styles: [],
})
export class SubSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
