import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AES, enc } from 'crypto-js';
import { ConfirmInputParams, ConfirmOutputParams } from '@app/core/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SYN } from '@app/core/constants';

@Component({
  selector: 'app-confirm-action-popup',
  template: `
    <style>
      .button-container {
        display: flex;
        flex-direction: row;
        justify-content: right;
        margin-top: 1.5em;
      }

      button {
        margin-right: 8px;
      }

      .password-input {
        font-size: 1em;
        margin: auto;
        width: 20em;
      }

      .spinner {
        margin: 4px auto;
      }
    </style>
    <p>
      {{ this.data.message }}
    </p>
    <mat-form-field
      class="password-input"
      *ngIf="data.passwordConfirm"
      appearance="outline"
    >
      <input class="password" [(ngModel)]="password" matInput type="password" />
    </mat-form-field>
    <div class="button-container">
      <button mat-flat-button color="primary" (click)="confirm(true)">
        {{ 'FORMS.CONFIRM' | translate }}
      </button>
      <button mat-stroked-button mat-dialog-close (click)="cancel()">
        {{ 'FORMS.CANCEL' | translate }}
      </button>
    </div>
  `,
})
export class ConfirmPopupComponent implements OnInit {
  password: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmInputParams,
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    private notifier: MatSnackBar
  ) {}

  ngOnInit(): void {}

  checkPassword() {
    const savedCredentials = sessionStorage.getItem('uvs-credentials');
    if (savedCredentials) {
      const credentials = JSON.parse(savedCredentials);
      const bytes = AES.decrypt(
        credentials.token,
        [SYN[0], SYN[2], SYN[4]].join('')
      );
      const originalPass = bytes.toString(enc.Utf8);
      return this.password === originalPass;
    }
    return false;
  }

  confirm(confirmed: boolean = false) {
    const passwordConfirmed: boolean = this.data.passwordConfirm
      ? this.checkPassword()
      : false;

    if (this.data.passwordConfirm && !passwordConfirmed) {
      this.notifier.open(`Password does not match, try again.`, '', {
        panelClass: 'error',
      });
      return;
    }

    const confirmOutput: ConfirmOutputParams = {
      action: this.data.action,
      confirmed: confirmed,
      passwordConfirmed: passwordConfirmed,
    };

    this.dialogRef.close(confirmOutput);
  }

  cancel() {
    const confirmOutput: ConfirmOutputParams = {
      action: this.data.action,
      confirmed: false,
      passwordConfirmed: false,
    };
    this.dialogRef.close(confirmOutput);
  }
}
