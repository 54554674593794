import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Breadcrumb, BreadcrumbData, Department } from '@app/core/interfaces';
import { AssetsService } from '@app/services/assets.service';

@Component({
  selector: 'app-breadcrumb-dept',
  templateUrl: './breadcrumb-dept.component.html',
  styleUrls: ['./breadcrumb-dept.component.scss']
})
export class BreadcrumbDeptComponent implements OnChanges {

  @Output() public navigateEvent = new EventEmitter<Breadcrumb>();

  @Input() deptIdentifier!: BreadcrumbData;
  department: Department | null = null;
  loading: boolean = true;

  //for table
  dataSource: any[] = [];
  displayedColumns: string[] = ['Order', 'Name', 'TimeWaiting']

  constructor(public assetService: AssetsService) { }


  ngOnChanges(changes: SimpleChanges): void {
    this.getDeptData();
  }

  getDeptData() {
    this.loading = true;
    this.assetService.getDepartmentById(this.deptIdentifier.ID).subscribe((res:any)=>{
      this.department = res;
      this.loading = false;
    })
  }

  navigateTo(bc: Breadcrumb) {
    this.navigateEvent.emit(bc);
  }

  getUseCase(): string {
    return window.location.pathname.split('/')[1] || 'none'
  }

}
