import { Component, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerService } from '@app/services/customer.service';
import { UseCase } from '@app/core/interfaces';
import { USECASES, GET_NAME_OF_ASSET } from '@app/core/constants';
import { EventsService } from '@app/services/events.service';
import { StorageService } from '@app/services/storage.service';
import { WebSocketService } from '@app/services/websocket.service';
import { UsersService } from '@app/services/users.service';
import { SharedSendTagMsgComponent } from '@app/shared/shared-send-tag-msg/shared-send-tag-msg.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements AfterViewInit {
  acknowledgeForm: FormGroup;
  actionForm: FormGroup;
  actionTypes: any = [];
  customAction: boolean = false;

  loading: boolean = false;

  loggedInUser: any = {};
  sortOrder: string = 'date';
  systemSettings: any = {};
  useCaseFilter: number = 0;
  useCases: UseCase[] = [];
  userList: any = [];

  selectedEvent: any = null;
  selectedStaff: any = null;
  staffAssociatedWithEvent: any = null;

  constructor(
    public customerService: CustomerService,
    public dialogRef: MatDialogRef<AlertsComponent>,
    public eventsService: EventsService,
    public fb: FormBuilder,
    public notifier: MatSnackBar,
    public storage: StorageService,
    public socketService: WebSocketService,
    public usersService: UsersService,
    public popupDialog: MatDialog
  ) {
    this.socketService.currentEvent$.subscribe((ev: any) => {
      if (ev != null) {
        this.selectEvent(ev);
        this.checkMessageEligibility();
      } else {
        console.log('selected event was null');
      }
    });

    this.useCases = USECASES.filter((use: UseCase) => {
      return this.socketService.allEvents
        .map((ev: any) => ev.UseCase)
        .includes(use.enumId);
    });

    this.customerService.systemSetting$.subscribe((sets) => {
      this.systemSettings = sets;
    });

    this.acknowledgeForm = this.fb.group({
      comment: [''],
    });

    this.actionForm = this.fb.group({
      action: [''],
      comment: [''],
      checked: [false],
      user: [this.loggedInUser, [Validators.required]],
    });
  }

  ngOnInit() {
    this.loggedInUser = this.storage.uvsCreds.userObject;
  }

  ngAfterViewInit(): void {
    this.eventsService.getEventAction().subscribe((res: any) => {
      this.actionTypes = res.List;
    });
    this.getUsers();
  }

  ngOnDestroy() {
    this.sortOrder = 'date';
    this.useCaseFilter = 0;
    if (this.socketService.realEvents.length > 0) {
      this.filterEvents();
    }
  }

  acknowledgeEvent() {
    this.loading = true;
    const comment = this.acknowledgeForm.value.comment
      ? this.acknowledgeForm.value.comment
      : 0;
    this.eventsService
      .acknowledgeEvent(this.selectedEvent.Id, comment)
      .subscribe(
        () => {
          this.loading = false;
          this.socketService.allEvents = this.socketService.allEvents.map(
            (event: any) => {
              if (event.Id === this.selectedEvent.Id) {
                event.Status = 'Acknowledged';
              }
              return event;
            }
          );
          this.socketService.realEvents = this.socketService.realEvents.map(
            (event: any) => {
              if (event.Id === this.selectedEvent.Id) {
                event.Status = 'Acknowledged';
              }
              return event;
            }
          );
          this.selectEvent(this.selectedEvent);
          this.acknowledgeForm.setValue({ comment: '' }); //MKL 10-30-2023
          this.notifier.open('Event acknowledged.', '', {
            panelClass: 'success',
          });
        },
        () => {
          this.loading = false;
          this.notifier.open(
            'There was a problem acknowledging the event.',
            '',
            {
              panelClass: 'error',
            }
          );
        }
      );
  }

  // if the event is an infra timeout, you can't close.
  // if the even is a safety switch alert or ss repeat alert...
  // and if there is no ruledef or no message, you can't close.
  // if there is a ruleDef and message, parse them into json.
  // if the XpertMessage shows that the switch is closed, you can close it.
  // if the switch is open and the rule definition says you can close it, you can close it

  canCloseEvent() {
    if (this.selectedEvent.SystemName === 'SYSTEM_INFRA_TIMEOUT_ALERT')
      return false;

    if (
      this.selectedEvent.SystemName === 'SAFETYSWITCH_ALERT' ||
      this.selectedEvent.SystemName === 'SAFETYSWITCHREPEAT_ALERT'
    ) {
      if (!this.selectedEvent?.RuleDef || !this.selectedEvent?.Message)
        return false;

      const xpertMessage: any = JSON.parse(this.selectedEvent.Message);
      const ruleDef: any = JSON.parse(this.selectedEvent.RuleDef);

      const switchOpen: boolean =
        xpertMessage.DeviceReports[0]?.Status?.Tamper2;
      const allowIfOpen: boolean =
        ruleDef.conditions[0]?.attr[0]?.AllowEventClose;

      if (!switchOpen) return true;
      if (switchOpen && allowIfOpen) return true;

      return false;
    }

    return true;
  }

  //MKL Because Assets can have multiple tags now, alert the user that only a certain tag will receive it.
  checkMessageEligibility(): boolean {
    if (
      this.selectedEvent?.Name.includes('B4') ||
      this.selectedEvent?.PersonName.includes('B4')
    ) {
      return true;
    } else if (
      this.selectedEvent?.Name.includes('E50') ||
      this.selectedEvent?.PersonName.includes('E50')
    ) {
      return true;
    } else return false;
  }

  closeEvent() {
    this.loading = true;
    const form = this.actionForm.value;
    const eventId = this.selectedEvent.Id;
    const userId = form.user.Id;
    const comment: any = form.comment ? form.comment : 0;

    this.eventsService.closeEvent(comment, userId, eventId).subscribe(
      () => {
        this.loading = false;
        if (this.socketService.realEvents.length === 0) {
          this.dialogRef.close();
        }
        if (this.socketService.realEvents.length > 0) {
          this.filterEvents();
        }
        this.notifier.open('Alert closed.', '', {
          panelClass: 'success',
        });
      },
      () => {
        this.loading = false;
        this.notifier.open(
          'There was a problem closing the event action.',
          '',
          {
            panelClass: 'error',
          }
        );
      }
    );
  }

  createEventAction() {
    this.loading = true;
    const form = this.actionForm.value;
    const comment: any = form.comment ? form.comment : 0;
    const actionId = this.customAction ? 0 : form.action.Id;
    const eventId = this.selectedEvent.Id;
    const actionName = this.customAction ? form.action : form.action.Name;
    const userId = form.user.Id;

    if (!actionName) {
      this.notifier.open(`Please submit an action.`, 'Close', {
        duration: 5000,
      });
      this.loading = false;
      return;
    }

    this.eventsService
      .eventAction(eventId, actionId, actionName, comment, userId)
      .subscribe(
        () => {
          this.loading = false;
          this.selectEvent(this.selectedEvent);
          this.notifier.open('Event action applied.', '', {
            panelClass: 'success',
          });
        },
        () => {
          this.loading = false;
          this.notifier.open(
            'There was a problem applying the event action.',
            '',
            {
              panelClass: 'error',
            }
          );
        }
      );
  }

  filterEvents() {
    const events = this.socketService.allEvents.filter((ev: any) => {
      if (this.useCaseFilter === 0) {
        return true;
      } else {
        return ev.UseCase === this.useCaseFilter;
      }
    });

    if (this.sortOrder === 'date') {
      this.groupAlertByDate(events);
    } else if (this.sortOrder === 'status') {
      this.groupAlertsByStatus(events);
    }
  }

  followStaff() {
    this.selectedStaff = this.staffAssociatedWithEvent;
  }

  getColors(index: number) {
    return index % 2 === 0 ? '#f0f0f0' : 'white';
  }

  isSelected(event: any) {
    if (event != null) {
      return event.Id === this.selectedEvent?.Id ? '2px solid #1976d2' : 'none';
    } else return false;
  }

  getUsers() {
    this.usersService.getUsers().subscribe((response: any) => {
      this.userList = response.List;
    });
  }

  groupAlertsByStatus(events: any) {
    const newEvents = events.filter((ev: any) => ev.Status === 'Open');
    const ackdEvents = events.filter((ev: any) => ev.Status === 'Acknowledged');

    this.socketService.realEvents = [...newEvents, ...ackdEvents];
    this.selectEvent(this.selectedEvent);
  }

  groupAlertByDate(events: any) {
    const sorter = (a: any, b: any) => {
      const dateA = new Date(a.DateCreated).getTime();
      const dateB = new Date(b.DateCreated).getTime();
      return dateB - dateA;
    };
    const sortedEvents = events.sort(sorter);
    this.socketService.realEvents = sortedEvents;
    this.selectEvent(this.selectedEvent);
  }

  resetForm() {
    this.actionForm.setValue({
      action: '',
      comment: '',
      user: this.loggedInUser,
      checked: false,
    });
    this.customAction = false;
  }

  selectEvent(event: any) {
    //console.log('selecting...', event.Id);
    if (event.Id > 0) {
      this.eventsService.getEventById(event.Id).subscribe((ev: any) => {
        this.selectedEvent = ev;
        if (ev.ItemId !== 0) {
          this.eventsService.getStaffById(ev.ItemId).subscribe((staff: any) => {
            this.staffAssociatedWithEvent = staff;
          });
        }
        this.resetForm();
      });
    } else {
      console.log('no event ID');
    }
  }

  sendMessage() {
    const dialogRef = this.popupDialog.open(SharedSendTagMsgComponent, {
      data: { Id: this.selectedEvent.ItemId },
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      width: '750px',
    });
  }

  toggleCustomAction() {
    this.customAction = !this.customAction;
    this.actionForm.get('action')?.setValue('');
  }
}
