<div class="wrapper">
  <!-- Title -->
  <h1>
    <span *ngIf="params.name">Search for "{{ params.name }}"</span>
    <span *ngIf="!params.name">{{
      "MAIN.GLOBAL_SEARCH.SEARCH_RESULTS" | translate
    }}</span>
    <span class="advance-filters">
      <span *ngIf="params.application" class="filter-param">
        {{ "COLUMNS.APPLICATION" | translate }}: {{ params.application }}
      </span>
      <span *ngIf="params.groupName" class="filter-param"
        >{{ "COLUMNS.GROUP" | translate }}: {{ params.groupName }}
      </span>
      <span *ngIf="params.departmentName" class="filter-param"
        >{{ "COLUMNS.DEPARTMENT" | translate }}: {{ params.departmentName }}
      </span>
      <span *ngIf="params.battery" class="filter-param"
        >{{ "COLUMNS.BATTERY" | translate }}: <= {{ params.battery }}
      </span>
    </span>
  </h1>

  <!-- Results Table -->
  <div class="header">
    <br />
    <mat-paginator
      [length]="searchService.page.count"
      [pageSizeOptions]="[10, 20, 50, 100]"
      [pageSize]="searchService.page.limit"
      showFirstLastButtons
    ></mat-paginator>
  </div>
  <div class="table-container mat-elevation-z0">
    <mat-spinner
      class="spinner"
      diameter="350"
      strokeWidth="10"
      *ngIf="searchService.loading"
    ></mat-spinner>
    <table mat-table *ngIf="!searchService.loading" [dataSource]="dataSource">
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>
          {{ "COLUMNS.NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "COLUMNS.STATUS" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            color="black"
            *ngIf="!element.UserDefinedStatusIcon"
            [matTooltip]="element.UserDefinedStatus || 'None'"
            [inline]="true"
            >crop_din
          </mat-icon>
          <img
            class="icon"
            [matTooltip]="element.UserDefinedStatus || 'None'"
            *ngIf="element.UserDefinedStatusIcon"
            [src]="element.UserDefinedStatusIcon"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Group">
        <th mat-header-cell *matHeaderCellDef>
          {{ "COLUMNS.GROUP" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.GroupName }}</td>
      </ng-container>

      <ng-container matColumnDef="Department">
        <th mat-header-cell *matHeaderCellDef>
          {{ "COLUMNS.DEPARTMENT" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.DepartmentName }}</td>
      </ng-container>

      <ng-container matColumnDef="Map">
        <th mat-header-cell *matHeaderCellDef>
          {{ "COLUMNS.LOCATION" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <a
            *ngIf="element.FloorName"
            style="color: blue; text-decoration: underline; cursor: pointer"
            (click)="openMap(element)"
          >
            {{ element.BuildingName }} -
            {{ element.FloorName }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="AssociatedDevices">
        <th mat-header-cell cdkDrag *matHeaderCellDef>
          {{ "COLUMNS.ASSOC_DEVICES" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <!-- MKL 3-18-2024 More than 1, 1, and zero options -->
          <a
            *ngIf="element.AssociatedDevices?.length > 1"
            class="hyperlink"
            matTooltip="{{ getDeviceInfo(element.AssociatedDevices) }}"
          >
            {{ getNumberOfDevices(element) }}
            <span
              class="pending"
              *ngIf="getBadDevices(element.AssociatedDevices)"
            >
              <mat-icon [inline]="true">error</mat-icon>
            </span>
          </a>
          <!-- MKL 3-18-2024 More than 1, 1, and zero options -->
          <a
            *ngIf="element.AssociatedDevices?.length === 1"
            class="hyperlink"
            [matTooltip]="
              'Charge - ' + element.AssociatedDevices[0].BatteryLevel + '%'
            "
          >
            {{ element.AssociatedDevices[0].UniqueId }}
          </a>
          <!-- MKL 3-18-2024 More than 1, 1, and zero options -->
          <span *ngIf="element.AssociatedDevices?.length === 0">
            {{ getNumberOfDevices(element) }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="LocationUpdated">
        <th mat-header-cell *matHeaderCellDef>
          {{ "COLUMNS.LOCATION_UPDATED" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.LocationUpdated }}
        </td>
      </ng-container>

      <ng-container matColumnDef="UseCases">
        <th mat-header-cell *matHeaderCellDef>
          {{ "COLUMNS.APPLICATION" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let uc of element.UseCases">{{
            splitOnCapitals(uc.UseCase)
          }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (dblclick)="doubleClick(row)"
      ></tr>
      <!-- (dblclick)="openDialog('edit-group', row.Name)" -->
    </table>
  </div>
</div>
