import { Component, Input, OnInit } from '@angular/core';
import { AssetsService } from '../../../../projects/assets/src/app/assets.service';

/* MARKED FOR DELETION
 *
 *  REPLACE WITH BREADCRUMB ASSETS
 *
 */

@Component({
  selector: 'app-shared-asset-details',
  templateUrl: './shared-asset-details.component.html',
  styleUrls: ['./shared-asset-details.component.scss'],
})
export class SharedAssetDetailsComponent implements OnInit {
  @Input() data: any;

  constructor(public assetsService: AssetsService) {}

  ngOnInit(): void {
    //console.log(this.data);
  }
}
