import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateCustomerComponent } from '@app/login/create-customer/create-customer.component';
import { AuthService } from '@app/services/auth.service';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from '@app/services/storage.service';
import { WebSocketService } from '@app/services/websocket.service';
import { EditAccountSettingsComponent } from '../edit-account-settings/edit-account-settings.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {
  constructor(
    public customerService: CustomerService,
    public dialog: MatDialog,
    public authService: AuthService,
    public router: Router,
    public storage: StorageService,
    public webSocketService: WebSocketService
  ) {}

  ngOnInit(): void {}

  createNewProfile() {
    this.dialog.open(CreateCustomerComponent, {
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '100vw',
      width: this.customerService.getScreenSize() ? '100%' : '750px',
    });
  }

  editAccountSettingsPopup() {
    this.dialog.open(EditAccountSettingsComponent, {
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '100vw',
      width: this.customerService.getScreenSize() ? '100%' : '750px',
    });
  }

  editPasswordPopup() {
    this.dialog.open(ChangePasswordComponent, {
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '100vw',
      width: this.customerService.getScreenSize() ? '100%' : '750px',
    });
  }

  logout() {
    this.authService.logout().subscribe(
      () => {
        this.customerService.alreadyWarned$.next(false);
        this.customerService.licensesExpiring$.next(false);
        this.customerService.licensesWarning$.next(false);
        this.dialog.closeAll();
        // authService.logout calls storageService.removeStorage
        // this refreshes the page and sends you to login since credentials don't exist
        // this.router.navigate(['/login'], { replaceUrl: true });
        this.webSocketService.disconnect();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  switchProfiles() {
    this.customerService.alreadyWarned$.next(false);
    this.customerService.licensesExpiring$.next(false);
    this.customerService.licensesWarning$.next(false);
    this.storage.removeCustomerInfo();
    this.router.navigate(['/select-customer'], { replaceUrl: true });
    this.webSocketService.disconnect();
  }
}
