import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VERSION } from '@app/core/constants';
import { AuthService } from '@app/services/auth.service';
import { StorageService } from '@app/services/storage.service';
import { CustomerService } from '@app/services/customer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  changePassForm!: FormGroup;
  customerSettings: any = {};
  loading: boolean = false;
  version: string = VERSION;
  userData: any = {};
  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    private fb: FormBuilder,
    private notifier: MatSnackBar,
    private router: Router,
    private storage: StorageService,
    private translate: TranslateService
  ) {
    this.customerService.systemSetting$.subscribe((settings: any) => {
      this.customerSettings = settings;
    });
    this.userData = this.storage.get('uvs-credentials');
  }

  get tempPassword() {
    return this.changePassForm.get('tempPassword');
  }

  get newPassword() {
    return this.changePassForm.get('newPassword');
  }

  get confirmPassword() {
    return this.changePassForm.get('confirmPassword');
  }

  ngOnInit(): void {
    if (!this.storage.hasUVSCreds()) {
      this.redirectToLogin();
    }

    this.changePassForm = this.fb.group(
      {
        tempPassword: ['', Validators.required],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(this.getPasswordRequirements().regex),
            Validators.minLength(this.getPasswordLength()),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: MustMatch('newPassword', 'confirmPassword') }
    );
  }

  getPasswordLength() {
    return this.customerSettings.MinPasswordLength
      ? this.customerSettings.MinPasswordLength
      : 8;
  }

  getPasswordRequirements() {
    const numCaps = this.customerSettings.PasswordNumCapitals
      ? this.customerSettings.PasswordNumCapitals
      : 1;
    const numNums = this.customerSettings.PasswordNumNumbers
      ? this.customerSettings.PasswordNumNumbers
      : 1;
    const numSymb = this.customerSettings.PasswordNumSymbols
      ? this.customerSettings.PasswordNumSymbols
      : 1;

    const regex = `(?=(.*[A-Z]){${numCaps},})(?=(.*[\\d]){${numNums},})(?=(.*[!@#$%^&*?]){${numSymb},})[A-Za-z\\d!@#$%^&*?]{1,}`;

    const requirements = {
      caps: numCaps,
      nums: numNums,
      symb: numSymb,
      regex: regex,
    };

    return requirements;
  }

  redirectToLogin() {
    console.log('redirect to login');
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  submit() {
    this.loading = true;
    const formData = this.changePassForm.value;
    this.authService
      .changePassword(
        formData.tempPassword,
        formData.newPassword,
        this.userData?.userObject.Id
      )
      .subscribe(
        () => {
          this.notifier.open(
            this.translate.instant('NOTIFIER.SUCCESS_EDIT'),
            '',
            { panelClass: 'success' }
          );
          setTimeout(() => {
            this.storage.removeStorage();
            this.router.navigate(['/login'], { replaceUrl: true });
          }, 1500);
        },
        () => {
          this.loading = false;
          this.notifier.open(
            this.translate.instant('NOTIFIER.ERROR_EDIT'),
            '',
            { panelClass: 'error' }
          );
        }
      );
  }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
