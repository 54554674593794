import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Breadcrumb, BreadcrumbData, Group } from '@app/core/interfaces';
import { AssetsService } from '@app/services/assets.service';

@Component({
  selector: 'app-breadcrumb-group',
  templateUrl: './breadcrumb-group.component.html',
  styleUrls: ['./breadcrumb-group.component.scss'],
})
export class BreadcrumbGroupComponent implements OnChanges {
  @Output() public navigateEvent = new EventEmitter<Breadcrumb>();

  @Input() groupIdentifier!: BreadcrumbData;
  group: Group | null = null;
  loading: boolean = true;

  constructor(public assetService: AssetsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getGroupData();
  }

  getGroupData() {
    this.loading = true;
    this.assetService
      .getGroupById(this.groupIdentifier.ID)
      .subscribe((res: any) => {
        this.group = res;
        this.loading = false;
      });
  }

  navigateTo(bc: Breadcrumb) {
    this.navigateEvent.emit(bc);
  }
}
