import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardTilesComponent } from './dashboard-tiles/dashboard-tiles.component';
import { ExternalTileComponent } from './external-tile/external-tile.component';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';

@NgModule({
  declarations: [DashboardTilesComponent, ExternalTileComponent, DashboardViewComponent],
  imports: [CommonModule, MaterialModule, RouterModule, TranslateModule],
  providers: [],
})
export class DashboardModule {}
