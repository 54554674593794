import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { LoginForm, Subscriptions } from '@app/core/interfaces';
import { StorageService } from './storage.service';
import { CustomerService } from './customer.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  IpAddress = '';
  enterEmailOrUsername: any = '';
  permissions: any = [];
  authServiceHeaders: any = {};
  authToken: any;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private customerService: CustomerService
  ) {}

  isAuthenticated(): boolean {
    const path = window.location.pathname.split('/')[1];
    if (path === 'set-password' || path === 'login') {
      return false;
    }
    return !!this.storage.get('uvs-credentials');
  }

  changePassword(oldPassword: string, newPassword: string, UserId: number) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      NewPassword: newPassword,
      CurrentPassword: oldPassword,
    });
    return this.http.post(
      `/api/Users/ChangePassword?UserId=${UserId}` +
        `&CurrentPassword=${encodeURIComponent(oldPassword)}` +
        `&NewPassword=${encodeURIComponent(newPassword)}`,
      { headers: httpHeaders }
    );
  }

  createCustomer(formData: any, subscriptions: Subscriptions) {
    subscriptions.integrations = { ARC: false, CMX: false };
    const subscriptionsString: string = JSON.stringify(subscriptions);
    return this.http.post(
      `/api/Customer/CreateCustomer?CompanyName=${encodeURIComponent(
        formData.companyName
      )}` +
        `&Domain=${encodeURIComponent(formData.domain)}` +
        `&SubDomain=${encodeURIComponent(formData.subDomain)}` +
        `&ContactPerson=${encodeURIComponent(formData.name)}` +
        `&ContactEmail=${encodeURIComponent(formData.email)}` +
        `&ContactPhone=${formData.phone}` +
        `&CustomerApplications=${subscriptionsString}`,
      {}
    );
  }

  forgetPassword(formData: any) {
    const username: string = formData ? formData.username : '';
    return this.http.post(
      `/api/Users/ForgotPassword?UserNameOrEmail=${username}`,
      {}
    );
  }

  login(logInForm: LoginForm): Observable<any> {
    this.authServiceHeaders.username = logInForm.username;
    this.authServiceHeaders.password = logInForm.password;
    this.authToken = btoa(logInForm.username + ':' + logInForm.password);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + this.authToken,
    });
    return this.http
      .post<any>(
        '/api/Login/Login?IpAddress=' + this.IpAddress,
        {},
        { headers: httpHeaders }
      )
      .pipe(
        map((user) => {
          if (user == 'send the code now') {
            //console.log("MFA")
          } else {
            this.storage.setEULADate(
              user?.LastAgreedToEULADateTime || 'January 1, 1970 01:00:00'
            );
            this.storage.setUvsCreds({
              username: user.UserName,
              token: logInForm.password,
              userObject: user,
            });
            //this.storage.setUcreds(logInForm);
          }

          // MKL TEST!
          this.customerService.userId = user.Id;
          this.customerService.customerId = user.CustomerId
            ? user.CustomerId
            : 0;
          return user;
        })
      );
  }

  logout(): Observable<boolean> {
    let credentials = this.storage.get('uvs-credentials');
    let token = credentials.userObject.LoginToken;
    return this.http
      .post(
        '/api/Login/Logout?LoginToken=' +
          token +
          '&IpAddress=' +
          localStorage.getItem('ip-address'),
        {}
      )
      .pipe(
        map(() => {
          this.storage.removeStorage();
          return true;
        })
      );
  }

  send2FACode(code: string): Observable<any> {
    this.authToken = btoa(
      this.authServiceHeaders.username + ':' + this.authServiceHeaders.password
    );
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + this.authToken,
    });
    return this.http
      .post<any>(
        '/api/Login/SendMFACode?MFACode=' + code,
        {},
        { headers: httpHeaders }
      )
      .pipe(
        map((user) => {
          this.storage.setEULADate(
            user?.LastAgreedToEULADateTime || 'January 1, 1970 01:00:00'
          );
          this.storage.setUvsCreds({
            username: user.UserName,
            token: this.authServiceHeaders.password,
            userObject: user,
          });
          //this.storage.setUcreds(this.authToken);

          this.customerService.userId = user.Id;
          this.customerService.customerId = user.CustomerId
            ? user.CustomerId
            : 0;
          return user;
        }),
        catchError((error) => {
          console.error('An error occurred while sending the MFA code:', error);
          throw error;
        })
      );
  }

  validateUserName(logInForm: LoginForm): Observable<any> {
    const username = logInForm.username;
    return this.http.get<any>('/api/auth/verifyUserName/' + username);
  }

  validateUser(credentail: any) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + btoa(credentail.username + ':' + credentail.password),
    });
    return this.http.post<any>(
      '/api/Login/Login?IpAddress=' + localStorage.getItem('ip-address'),
      {},
      { headers: httpHeaders }
    );
  }
}
