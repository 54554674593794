import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyElementsModule } from '@angular-extensions/elements';

import { AssetsComponent } from '@app/custom-elements/assets/assets.component';
import { CompanyInfoComponent } from '@app/custom-elements/company-info/company-info.component';
import { DebugToolComponent } from '@app/custom-elements/debug-tool/debug-tool.component';
import { DevicesComponent } from '@app/custom-elements/devices/devices.component';
import { EventsComponent } from '@app/custom-elements/events/events.component';
import { FirmwareComponent } from '@app/custom-elements/firmware/firmware.component';
import { InfrastructuresComponent } from '@app/custom-elements/infrastructures/infrastructures.component';
import { MapEditorComponent } from '@app/custom-elements/map-editor/map-editor.component';
import { MapRealtimeComponent } from '@app/custom-elements/map-realtime/map-realtime.component';
import { ReportsComponent } from '@app/custom-elements/reports/reports.component';
import { RoutesComponent } from '@app/custom-elements/routes/routes.component';
import { RuleComposerComponent } from '@app/custom-elements/rule-composer/rule-composer.component';
import { SubSettingsComponent } from '@app/custom-elements/settings/sub-settings.component';
import { SystemSettingsComponent } from '@app/custom-elements/system-settings/system-settings.component';
import { UsersComponent } from '@app/custom-elements/users/users.component';
import { ServerStatusComponent } from '@app/custom-elements/server-status/server-status.component';
import { FlogoComponent } from '@app/custom-elements/flogo/flogo.component';
import { options } from '@app/custom-elements/custom-elements-routing';

@NgModule({
  declarations: [
    AssetsComponent,
    CompanyInfoComponent,
    DebugToolComponent,
    DevicesComponent,
    EventsComponent,
    FirmwareComponent,
    InfrastructuresComponent,
    MapEditorComponent,
    MapRealtimeComponent,
    ReportsComponent,
    RoutesComponent,
    RuleComposerComponent,
    ServerStatusComponent,
    SubSettingsComponent,
    SystemSettingsComponent,
    UsersComponent,
    FlogoComponent,
  ],
  imports: [CommonModule, LazyElementsModule.forRoot(options)],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomElementsModule {}
