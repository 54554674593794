import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DEFAULT_DEVICE_CONFIG,
  DEFAULT_INFRA_CONFIG,
} from '@app/core/constants';
import { ConfigDetailsInputParams } from '@app/core/interfaces';
import {
  convertDeviceToHtml,
  convertInfraToHtml,
} from '@app/core/settings_conversions';

// Config View
//  Show Scanning/Beaconing in General
//  Show Proximity in General
//  Show OUI in Scanning

@Component({
  selector: 'app-config-details',
  templateUrl: './config-details.component.html',
  styleUrls: ['./config-details.component.scss'],
})
export class ConfigDetailsComponent implements OnInit {
  defaults: any;
  displaySettings: any;
  currentValues: any;
  commands: string[] = [];
  selectPanel: string = 'settings';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfigDetailsInputParams,
    public dialogRef: MatDialogRef<ConfigDetailsComponent>
  ) {}

  ngOnInit(): void {
    this.defaults = this.data.forInfra
      ? DEFAULT_INFRA_CONFIG
      : DEFAULT_DEVICE_CONFIG;
    this.displaySettings = this.data.forInfra
      ? DEFAULT_INFRA_CONFIG
      : DEFAULT_DEVICE_CONFIG;
    this.currentValues = this.data.forInfra
      ? DEFAULT_INFRA_CONFIG
      : DEFAULT_DEVICE_CONFIG;

    let settings = JSON.parse(this.data.config.ConfigDef);
    settings = this.fillInMissingSettings(settings);

    this.displaySettings = this.data.forInfra
      ? convertInfraToHtml(settings)
      : convertDeviceToHtml(settings);
    this.currentValues = settings;
    this.commands = this.data.forInfra
      ? Object.keys(DEFAULT_INFRA_CONFIG)
      : Object.keys(DEFAULT_DEVICE_CONFIG);
  }

  fillInMissingSettings(settings: any) {
    for (const setting in this.defaults) {
      if (settings[setting] === undefined || settings[setting] === null) {
        settings[setting] = this.defaults[setting];
      }
    }
    return settings;
  }

  getAlgorithm(type: number) {
    switch (type) {
      case 0:
        return 'Strongest';
      case 1:
        return 'Weakest';
      case 2:
        return 'Average';
      case 3:
        return 'Medium';
      case 4:
        return 'First';
      case 5:
        return 'Last';
      default:
        return '';
    }
  }

  getBeaconType(type: number) {
    switch (type) {
      case 0:
        return 'AiRISTA';
      case 1:
        return 'iBeacon';
      case 2:
        return 'Eddystone';
      default:
        return '';
    }
  }

  getBuzzerMode(type: number) {
    switch (type) {
      case 0:
        return 'Buzzer enabled';
      case 1:
        return 'Buzzer disabled';
      case 2:
        return 'Buzzer disabled';
      default:
        return '';
    }
  }

  getMotionSensitivity(num: number) {
    switch (num) {
      case 0:
        return 'Disable Motion Sensitivity';
      case 1:
        return 'Low Sensitivity';
      case 2:
        return 'Medium Sensitivity';
      case 3:
        return 'High Sensitivity';
      case 4:
        return 'Very High Sensitivity';
      default:
        return '';
    }
  }

  getSensorMode(num: number) {
    switch (num) {
      case 0:
        return 'Motion and Aftermotion';
      case 1:
        return 'Aftermotion only';
      case 2:
        return 'Motion start and Aftermotion';
      case 3:
        return 'Man down';
      case 4:
        return 'Tilt mode';
      default:
        return '';
    }
  }

  setCommandsForType() {
    let codes: string[] = this.commands;

    if (this.data.forInfra) {
      if (this.data.config?.Type === 'B3G' || this.data.config?.Type === 'G3') {
        codes = codes.filter((cmd) => !['hfo', 'pumr', 'puma'].includes(cmd));
      } else if (
        this.data.config?.Type === 'L1' ||
        this.data.config?.Type === 'L2'
      ) {
        codes = codes.filter((cmd) => !['hfo', 'mn', 'oadp'].includes(cmd));
      } else if (this.data.config?.Type === 'WGU') {
        codes = codes.filter(
          (cmd) => !['mn', 'oadp', 'pumr', 'puma'].includes(cmd)
        );
      } else {
        codes = codes.filter(
          (cmd) => !['hfo', 'mn', 'oadp', 'pumr', 'puma'].includes(cmd)
        );
      }
      return codes;
    } else {
      if (this.data.config?.Type !== 'A1' && this.data.config?.Type !== 'A2') {
        codes = codes.filter((cmd) => !['puma', 'pumr'].includes(cmd));
      }

      if (this.data.config?.Type !== 'B4') {
        codes = codes.filter(
          (cmd) => cmd !== 'b4sm' && cmd !== 'mcm' && cmd !== 'ms'
        );
      }

      if (this.data.config?.Type === 'A1') {
        codes = codes.filter(
          (cmd) => !['wscn', 'wr', 'wfrc', 'oadp'].includes(cmd)
        );
      }
      return codes;
    }
  }
}
