<div class="wrapper">
  <h1 style="text-align: center">
    {{ "EULA.EULA_TITLE" | translate }}
  </h1>
  <div class="scroll-container">
    <p>{{ "EULA.S_1" | translate }}</p>
    <p>{{ "EULA.S_2" | translate }}</p>
    <p>{{ "EULA.S_3" | translate }}</p>
    <p>{{ "EULA.S_4" | translate }}</p>
    <p>{{ "EULA.S_5" | translate }}</p>
    <p>{{ "EULA.S_6" | translate }}</p>
    <p>{{ "EULA.S_7" | translate }}</p>
    <p>{{ "EULA.S_8" | translate }}</p>
    <p>{{ "EULA.S_9" | translate }}</p>
    <p>{{ "EULA.S_10" | translate }}</p>
    <p>{{ "EULA.S_11" | translate }}</p>
    <p>{{ "EULA.S_12" | translate }}</p>
    <p>{{ "EULA.S_13" | translate }}</p>
    <p>{{ "EULA.S_14" | translate }}</p>
    <p>{{ "EULA.S_15" | translate }}</p>
    <p>{{ "EULA.S_16" | translate }}</p>
    <p>{{ "EULA.S_17" | translate }}</p>
    <p>{{ "EULA.S_18" | translate }}</p>
    <p>{{ "EULA.S_19" | translate }}</p>
    <p>{{ "EULA.S_20" | translate }}</p>
    <p>{{ "EULA.S_21" | translate }}</p>
    <p>{{ "EULA.S_22" | translate }}</p>
    <p>{{ "EULA.S_23" | translate }}</p>
    <p>{{ "EULA.S_24" | translate }}</p>
  </div>
  <div class="button-row">
    <button mat-flat-button color="primary" (click)="onSubmit()">
      {{ "EULA.ACCEPT" | translate }}
    </button>
    <button mat-flat-button color="warn" (click)="cancel()">
      {{ "FORMS.CANCEL" | translate }}
    </button>
  </div>
  <footer>
    {{ "EULA.COPYRIGHT" | translate }}
  </footer>
</div>
