export const convertDeviceToHtml = (set: any) => {
  let settings = JSON.parse(JSON.stringify(set));

  // convert boolean
  settings.b4sm.value = numToBool(settings.b4sm.value);
  settings.bdrd.value = numToBool(settings.bdrd.value);
  settings.bebd.value = numToBool(settings.bebd.value);
  settings.bpmr.value = numToBool(settings.bpmr.value);
  settings.bsor.value = numToBool(settings.bsor.value);
  settings.bta.value = numToBool(settings.bta.value);
  settings.dtda.value = numToBool(settings.dtda.value);
  settings.sdri.value = numToBool(settings.sdri.value);

  // convert time
  settings.wsn.value = secondsToTime(settings.wsn.value, settings.wsn.unit);
  settings.mn.value = secondsToTime(settings.mn.value, settings.mn.unit);
  settings.wsai.value = secondsToTime(settings.wsai.value, settings.wsai.unit);
  settings.wscn.value = secondsToTime(settings.wscn.value, settings.wscn.unit);
  settings.ss.value = secondsToTime(settings.ss.value, settings.ss.unit);
  settings.sd.value = secondsToTime(settings.sd.value, settings.sd.unit);

  // convert bit masks
  settings.bsbm.value = scanBitMaksToBool(settings.bsbm.value);
  settings.wr.value = wifiRoamingToBool(settings.wr.value);
  settings.wra.value = reportActionsToBool(settings.wra.value);
  settings.wsm.value = scanModeToBool(settings.wsm.value);
  return settings;
};

export const convertDeviceToValue = (set: any) => {
  let settings = JSON.parse(JSON.stringify(set));

  // convert boolean
  settings.b4sm.value = boolToNum(settings.b4sm.value);
  settings.bdrd.value = boolToNum(settings.bdrd.value);
  settings.bebd.value = boolToNum(settings.bebd.value);
  settings.bpmr.value = boolToNum(settings.bpmr.value);
  settings.bsor.value = boolToNum(settings.bsor.value);
  settings.bta.value = boolToNum(settings.bta.value);
  settings.dtda.value = boolToNum(settings.dtda.value);
  settings.sdri.value = boolToNum(settings.sdri.value);

  // convert time
  settings.wsn.value = timeToSeconds(settings.wsn.value, settings.wsn.unit);
  settings.mn.value = timeToSeconds(settings.mn.value, settings.mn.unit);
  settings.wsai.value = timeToSeconds(settings.wsai.value, settings.wsai.unit);
  settings.wscn.value = timeToSeconds(settings.wscn.value, settings.wscn.unit);
  settings.ss.value = timeToSeconds(settings.ss.value, settings.ss.unit);
  if (settings.ss.value > 57600) {
    settings.ss.value = 57600;
  }

  settings.sd.value = timeToSeconds(settings.sd.value, settings.sd.unit);

  // convert bit masks
  settings.bsbm.value = scanBitMaksToNum(settings.bsbm.value); //error here?
  settings.wr.value = wifiRoamingToNum(settings.wr.value);
  settings.wra.value = reportActionsToNum(settings.wra.value);
  settings.wsm.value = scanModeToNum(settings.wsm.value);

  // Arc value set to match single grace period
  settings.lowtime2.value = settings.lowtime1.value;
  settings.hightime1.value = settings.lowtime1.value;
  settings.hightime2.value = settings.lowtime1.value;

  return settings;
};

//turns the DB config into a JS object.
export const convertInfraToHtml = (set: any) => {
  let settings = JSON.parse(JSON.stringify(set));

  // convert boolean
  settings.hfo.value = numToBool(settings.hfo.value);

  // convert time
  settings.wsn.value = secondsToTime(settings.wsn.value, settings.wsn.unit);
  settings.mn.value = secondsToTime(settings.mn.value, settings.mn.unit);

  // convert bit masks
  settings.bibj.value = iBMajorToDisplay(settings.bibj.value);
  settings.bsbm.value = scanBitMaksToBool(settings.bsbm.value);

  // convert oui
  settings.bebl.value = ouiStringToList(settings.bebl.value);

  return settings;
};

//turns the config JS object into a DB-ready object
export const convertInfraToValue = (set: any) => {
  let settings = JSON.parse(JSON.stringify(set));

  // convert boolean
  settings.hfo.value = boolToNum(settings.hfo.value);

  // convert time
  settings.wsn.value = timeToSeconds(settings.wsn.value, settings.wsn.unit);
  settings.mn.value = timeToSeconds(settings.mn.value, settings.mn.unit);

  // convert bit masks
  settings.bibj.value = iBMajorToNum(settings.bibj.value);
  settings.bsbm.value = scanBitMaksToNum(settings.bsbm.value);

  // convert oui
  settings.bebl.value = ouiListToString(settings.bebl.value);

  return settings;
};

// bool converters
const boolToNum = (bool: boolean) => {
  return bool === false ? 0 : 1;
};

const numToBool = (num: number) => {
  return num === 0 ? false : true;
};

// time converter to seconds
const secondsToTime = (num: number, unit: string) => {
  switch (unit) {
    case 'Hours':
      return num / 3600;
    case 'Minutes':
      return num / 60;
    default:
      return num;
  }
};

const timeToSeconds = (num: number, unit: string): number => {
  switch (unit) {
    case 'Hours':
      num *= 3600;
      break;
    case 'Minutes':
      num *= 60;
      break;
    case 'Seconds':
      num *= 1;
      break;
  }
  return num;
};

// bit mask conversions
const reportActionsToBool = (num: number) => {
  switch (num) {
    case 7:
      return { Gateway: true, HFBle: true, Wifi: true };
    case 6:
      return { Gateway: true, HFBle: true, Wifi: false };
    case 5:
      return { Gateway: true, HFBle: false, Wifi: true };
    case 4:
      return { Gateway: true, HFBle: false, Wifi: false };
    case 3:
      return { Gateway: false, HFBle: true, Wifi: true };
    case 2:
      return { Gateway: false, HFBle: true, Wifi: false };
    case 1:
      return { Gateway: false, HFBle: false, Wifi: true };
    default:
      return { Gateway: false, HFBle: false, Wifi: false };
  }
};

const reportActionsToNum = (options: any) => {
  return (
    (options.Gateway ? 4 : 0) + (options.HFBle ? 2 : 0) + (options.Wifi ? 1 : 0)
  );
};

//Works for the given values MKL - still extremely poor organization
const scanBitMaksToBool = (num: number) => {
  let binArray = num.toString(2).split('');
  while (binArray.length !== 8) {
    binArray.unshift('0');
  }

  const boolArray = binArray.map((bit) => (bit === '1' ? true : false));
  return {
    AFInfraOnly: boolArray[0],
    Eddystone: boolArray[5],
    iBeacon: boolArray[6],
    AF: boolArray[7],
  };
};

const scanBitMaksToNum = (options: any) => {
  return (
    (options.AFInfraOnly ? 128 : 0) +
    (options.Eddystone ? 4 : 0) +
    (options.iBeacon ? 2 : 0) +
    (options.AF ? 1 : 0)
  );
};

const scanModeToBool = (num: number) => {
  switch (num) {
    case 6:
      return { ALP: true, CCX: true, BLINK: false };
    case 5:
      return { ALP: false, CCX: true, BLINK: true };
    case 4:
      return { ALP: false, CCX: true, BLINK: false };
    case 3:
      return { ALP: true, CCX: false, BLINK: true };
    case 1:
      return { ALP: false, CCX: false, BLINK: true };
    default:
      return { ALP: true, CCX: false, BLINK: false };
  }
};

const scanModeToNum = (options: any) => {
  if (options.CCX && options.ALP && !options.BLINK) {
    return 6;
  }
  if (options.CCX && !options.ALP && options.BLINK) {
    return 5;
  }
  if (options.CCX && !options.ALP && !options.BLINK) {
    return 4;
  }
  if (!options.CCX && options.ALP && options.BLINK) {
    return 3;
  }
  if (!options.CCX && !options.ALP && options.BLINK) {
    return 1;
  }
  return 0;
};

const wifiRoamingToBool = (num: number) => {
  switch (num) {
    case 3:
      return { FC: true, FIP: true };
    case 2:
      return { FC: true, FIP: false };
    case 1:
      return { FC: false, FIP: true };
    default:
      return { FC: false, FIP: false };
  }
};

const wifiRoamingToNum = (options: any) => {
  return (options.FC ? 2 : 0) + (options.FIP ? 1 : 0);
};

//Incredibly stupid that we have this logic here.
const iBMajorToDisplay = (num: number) => {
  //console.log('in the DISPLAY bitshifting function for proxes...');
  //console.log(num);

  let binArray = num.toString(2).split('');
  while (binArray.length !== 16) {
    binArray.unshift('0');
  }

  let inProx = parseInt(binArray.slice(0, 8).join(''), 2);
  let outOfProx = parseInt(binArray.slice(8).join(''), 2);

  return { MinimumRSSI: inProx, RSSIOffset: outOfProx - inProx };
};

const iBMajorToNum = (value: any) => {
  let inProx = value.MinimumRSSI.toString(2).split('');
  let outOfProx = (value.MinimumRSSI + value.RSSIOffset).toString(2).split('');

  while (inProx.length < 8) {
    inProx.unshift('0');
  }

  while (outOfProx.length < 8) {
    outOfProx.unshift('0');
  }
  //console.log('in the NUM bitshifting function for proxes...');
  //console.log(parseInt([...inProx, ...outOfProx].join(''), 2));
  return parseInt([...inProx, ...outOfProx].join(''), 2);
};

const ouiListToString = (ouiList: string[]): string => {
  try {
    return ouiList.join('');
  } catch {
    return '';
  }
};

const ouiStringToList = (ouiString: string): string[] => {
  try {
    return ouiString.match(/.{1,6}/g) || [];
  } catch {
    return [];
  }
};
