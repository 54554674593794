import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ScreensaverComponent } from './screensaver/screensaver.component';

@NgModule({
  declarations: [ScreensaverComponent],
  exports: [ScreensaverComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class DemoOnlyModule {}
