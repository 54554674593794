import { EnvService } from '@app/services/env.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AES, enc } from 'crypto-js';
import { SYN } from './constants';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(public env: EnvService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const savedCredentials = sessionStorage.getItem('uvs-credentials');
    if (savedCredentials) {
      const credentials = JSON.parse(savedCredentials);
      const bytes = AES.decrypt(
        credentials.token,
        [SYN[0], SYN[2], SYN[4]].join('')
      );
      const originalPass = bytes.toString(enc.Utf8);
      //console.log(bytes)
      //console.log(originalPass)
      const basicAuth = btoa(credentials.username + ':' + originalPass);
      req = req.clone({
        setHeaders: {
          Authorization: `Basic ${basicAuth}`,
        },
      });
    }

    return next.handle(req);
  }
}
