<div class="controls">
  <mat-icon class="control" [matMenuTriggerFor]="alertSettings"
    >settings</mat-icon
  >
  <mat-icon class="control" mat-dialog-close>close</mat-icon>
</div>

<div class="wrapper">
  <div class="alert-list">
    <div
      [ngStyle]="{
        'background-color': getColors(i),
        border: isSelected(alert)
      }"
      class="alert-container"
      (click)="selectEvent(alert)"
      *ngFor="let i = index; let alert; of: socketService.realEvents"
    >
      <div class="content">
        <div class="sub-content">
          <!-- Zone or Rule is the Name of the Event -->
          <span class="bold-text" *ngIf="systemSettings.EventLocationTitles">
            {{ alert.ModelName }}
            <span *ngIf="alert.ZoneName">{{
              alert?.ZoneName[0]?.ZoneName
            }}</span>
          </span>
          <span class="bold-text" *ngIf="!systemSettings.EventLocationTitles">
            {{ alert.DisplayName }}
          </span>

          <!-- Rule or Zone is the first detail of the Event -->
          <span class="detail"> {{ alert.PersonName }} </span>
          <span *ngIf="!systemSettings.EventLocationTitles">
            {{ alert.ModelName }}
          </span>
          <span *ngIf="systemSettings.EventLocationTitles">
            {{ alert.DisplayName }}
          </span>
        </div>

        <div class="sub-content-right">
          <span
            *ngIf="alert.Status === 'Open' || alert.Status === 'Unknown'"
            title="Open"
            class="new-event"
            >{{ "MAIN.ALERTS.NEW" | translate }}</span
          >
          <span
            *ngIf="alert.Status === 'Acknowledged'"
            title="In progress"
            class="acknowledged-event"
            >{{ "MAIN.ALERTS.ACKNOWLEDGED" | translate }}</span
          >
          <span class="detail" *ngIf="!alert.IsAcknowledged">
            {{ socketService.formatTime(alert.DateCreated) }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="alert-detail">
    <div class="headline">
      <!-- Doesn't look good with GPS Alerts -->
      <!-- *ngIf="!systemSettings.EventLocationTitles" -->
      <span>
        {{ selectedEvent?.DisplayName }}
      </span>
      <!-- <span *ngIf="systemSettings.EventLocationTitles">
        <span *ngIf="selectedEvent?.ModelName">
          {{ selectedEvent?.ModelName }}
        </span>
        <span *ngIf="!selectedEvent?.ModelName">{{
          "MAIN.ALERTS.NO_LOCATION" | translate
        }}</span>
      </span> -->

      <span
        *ngIf="
          selectedEvent?.Status === 'Open' ||
          selectedEvent?.Status === 'Unknown'
        "
        class="new-event"
        >{{ "MAIN.ALERTS.NEW" | translate }}</span
      >
      <span
        *ngIf="selectedEvent?.Status === 'Acknowledged'"
        class="acknowledged-event"
        >{{ "MAIN.ALERTS.ACKNOWLEDGED" | translate }}</span
      >
    </div>

    <!-- Picture of Asset trigger if present -->
    <div class="alert-content">
      <img
        *ngIf="selectedEvent?.Portrait"
        class="alert-content-portrait"
        [src]="'../../assets/portraits/' + selectedEvent?.Portrait"
        onerror="this.src='../../assets/images/noMapImage.jpg'"
      />
    </div>

    <!-- Person -->
    <div class="alert-content">
      <span>{{ "MAIN.ALERTS.TRIGGERED_BY" | translate }}</span>
      <span class="alert-content-detail">
        {{ selectedEvent?.PersonName }}
      </span>
    </div>

    <!-- Date -->
    <div class="alert-content">
      <span>{{ "MAIN.ALERTS.DATE" | translate }} </span>
      <span class="alert-content-detail">
        {{ socketService.formatTime(selectedEvent?.DateCreated) }}</span
      >
    </div>

    <!-- Location -->
    <div *ngIf="!systemSettings.EventLocationTitles" class="alert-content">
      <span>{{ "COLUMNS.LOCATION" | translate }}</span>
      <span *ngIf="selectedEvent?.ModelName" class="alert-content-detail">
        {{ selectedEvent?.ModelName }}

        <span *ngIf="selectedEvent?.Latitude && selectedEvent?.Longitude">
          {{ selectedEvent?.Latitude.toFixed(2) }},
          {{ selectedEvent?.Longitude.toFixed(2) }}
        </span>

        <span *ngIf="selectedEvent?.ZoneName">
          {{ selectedEvent?.ZoneName[0]?.ZoneName }}
        </span>
      </span>

      <span
        *ngIf="
          !selectedEvent?.Latitude &&
          !selectedEvent?.Longitude &&
          !selectedEvent?.ModelName
        "
        class="alert-content-detail"
      >
        N/A
      </span>
    </div>

    <!-- USE CASE -->
    <div class="alert-content">
      <span>{{ "COLUMNS.APPLICATION" | translate }}</span>
      <span class="alert-content-detail">
        {{ eventsService.getUseCaseNameFromID(selectedEvent?.UseCase) }}
      </span>
    </div>

    <div class="alert-content">
      <span>{{ "COLUMNS.DETAILS" | translate }}</span>
      <span class="alert-content-detail">
        {{ selectedEvent?.Details }}
      </span>
    </div>

    <div *ngIf="systemSettings.EventLocationTitles" class="alert-content">
      <span>{{ "COLUMNS.RULE" | translate }}</span>
      <span class="alert-content-detail">
        {{ selectedEvent?.DisplayName }}
      </span>
    </div>

    <!-- Temperature -->
    <div *ngIf="selectedEvent?.ViolationValue" class="alert-content">
      <span>{{ "MAIN.ALERTS.TEMPERATURE" | translate }} </span>
      <span class="alert-content-detail">
        {{ selectedEvent?.ViolationValue.substring(0, 5) }}°C</span
      >
    </div>

    <div class="alert-detail-button-row">
      <button
        mat-flat-button
        color="primary"
        [disabled]="
          !staffAssociatedWithEvent?.CurrentMapId &&
          !staffAssociatedWithEvent?.Latitude
        "
        (click)="followStaff()"
      >
        {{ "MAIN.ALERTS.CURRENT_LOCATION" | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="checkMessageEligibility()"
        (click)="sendMessage()"
      >
        {{ "MAIN.ALERTS.SEND_MESSAGE" | translate }}
      </button>
    </div>
    <div class="alert-sub-header">{{ "MAIN.ALERTS.LOGS" | translate }}</div>
    <mat-divider></mat-divider>
    <div class="alert-log" *ngIf="selectedEvent?.Status === 'Acknowledged'">
      <span>{{ "MAIN.ALERTS.ACKNOWLEDGED" | translate }} </span>
      <span class="alert-content-detail">
        {{
          socketService.getTimeAgo(selectedEvent?.AcknowledgedDateTime)
        }}</span
      >
    </div>
    <div *ngFor="let action of selectedEvent?.EventActions">
      <div class="alert-log" *ngIf="action.ActionType !== 'undefined'">
        <span>{{ action.ActionType }} </span>
        <span class="alert-content-detail">
          {{ socketService.getTimeAgo(action.ActionDateTime) }}</span
        >
      </div>
    </div>

    <!-- MKL new divider 4-24-2024 -->
    <mat-divider *ngIf="selectedEvent?.Status === 'Acknowledged'"></mat-divider>

    <form
      *ngIf="selectedEvent?.Status === 'Open'"
      class="form-container"
      [formGroup]="acknowledgeForm"
      (ngSubmit)="acknowledgeEvent()"
    >
      <mat-form-field appearance="outline">
        <mat-label>{{ "MAIN.ALERTS.ACKNOWLEDGEMENT" | translate }}</mat-label>
        <textarea
          matInput
          [placeholder]="'MAIN.ALERTS.LEAVE_A_COMMENT' | translate"
          formControlName="comment"
        ></textarea>
      </mat-form-field>
      <div class="form-buttons">
        <button
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="acknowledgeForm.invalid"
        >
          <mat-spinner
            class="spinner"
            color="accent"
            diameter="30"
            *ngIf="loading"
          ></mat-spinner>
          <span *ngIf="!loading">{{
            "MAIN.ALERTS.ACKNOWLEDGE_BUTTON" | translate
          }}</span>
        </button>

        <button
          (click)="closeEvent()"
          class="close-event1"
          type="button"
          mat-flat-button
          color="primary"
          *ngIf="canCloseEvent()"
        >
          <mat-spinner
            class="spinner"
            color="accent"
            diameter="30"
            *ngIf="loading"
          ></mat-spinner>
          <span *ngIf="!loading">{{
            "MAIN.ALERTS.CLOSE_BUTTON" | translate
          }}</span>
        </button>
      </div>
    </form>

    <form
      *ngIf="selectedEvent?.Status === 'Acknowledged'"
      class="form-container"
      [formGroup]="actionForm"
      (ngSubmit)="createEventAction()"
    >
      <!-- MKL new label 4-24-2024 -->
      <div class="alert-sub-header">
        {{ "MAIN.ALERTS.ADDITIONAL_LOG_ENTRY" | translate }}
      </div>
      <mat-checkbox
        color="primary"
        formControlName="checked"
        (change)="toggleCustomAction()"
        >{{ "MAIN.ALERTS.CUSTOM_ACTION" | translate }}</mat-checkbox
      >

      <mat-form-field appearance="outline" *ngIf="customAction === false">
        <mat-label>{{ "MAIN.ALERTS.SELECT_ACTION" | translate }}</mat-label>
        <mat-select formControlName="action">
          <mat-option *ngFor="let action of actionTypes" [value]="action">
            {{ action.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="customAction === true">
        <mat-label>{{ "MAIN.ALERTS.CREATE_ACTION" | translate }}</mat-label>
        <input matInput formControlName="action" />
      </mat-form-field>

      <div class="user-select">
        <span>{{ "MAIN.ALERTS.PERFORMED_BY" | translate }} </span>
        <mat-form-field appearance="outline">
          <mat-label>{{ "MAIN.ALERTS.USER" | translate }}</mat-label>
          <mat-select formControlName="user">
            <mat-option [value]="loggedInUser"
              ><b
                ><i>{{ "MAIN.ALERTS.CURRENT_USER" | translate }}</i></b
              >
              --- {{ loggedInUser.FullName }}</mat-option
            >
            <mat-option *ngFor="let user of userList" [value]="user">
              {{ user.FullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{ "MAIN.ALERTS.COMMENT" | translate }}</mat-label>
        <textarea
          matInput
          [placeholder]="'MAIN.ALERTS.LEAVE_A_COMMENT' | translate"
          formControlName="comment"
        ></textarea>
      </mat-form-field>
      <div class="form-buttons">
        <button
          mat-stroked-button
          color="primary"
          type="submit"
          [disabled]="actionForm.invalid"
          class="ackd-alert-button"
        >
          <mat-spinner
            class="spinner"
            color="accent"
            diameter="30"
            *ngIf="loading"
          ></mat-spinner>
          <span *ngIf="!loading">{{ "FORMS.SUBMIT" | translate }}</span>
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="closeEvent()"
          type="button"
          class="ackd-alert-button"
        >
          <mat-spinner
            class="spinner"
            color="accent"
            diameter="30"
            *ngIf="loading"
          ></mat-spinner>
          <span *ngIf="!loading">{{
            "MAIN.ALERTS.CLOSE_EVENT" | translate
          }}</span>
        </button>
      </div>
    </form>
  </div>

  <!-- MAP -->
  <div class="third-panel">
    <app-map-previewer
      *ngIf="!!selectedEvent"
      [selectedEvent]="selectedEvent"
      [selectedStaff]="selectedStaff"
    ></app-map-previewer>
  </div>
</div>

<mat-menu #alertSettings>
  <mat-radio-group
    class="use-filters"
    (change)="filterEvents()"
    [(ngModel)]="sortOrder"
  >
    <mat-radio-button color="primary" class="filter" [value]="'date'">{{
      "MAIN.ALERTS.SORT_DATE" | translate
    }}</mat-radio-button>
    <mat-radio-button color="primary" class="filter" [value]="'status'">{{
      "MAIN.ALERTS.SORT_STATUS" | translate
    }}</mat-radio-button>
  </mat-radio-group>

  <div *ngIf="useCases.length > 1">
    <mat-divider></mat-divider>
    <mat-radio-group
      class="use-filters"
      (change)="filterEvents()"
      [(ngModel)]="useCaseFilter"
    >
      <mat-radio-button color="primary" class="filter" [value]="0">{{
        "MAIN.ALERTS.SHOW_ALERTS" | translate
      }}</mat-radio-button>
      <mat-radio-button
        color="primary"
        *ngFor="let use of useCases"
        class="filter"
        [value]="use.enumId"
        >{{ use.display }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
</mat-menu>
