<h3 mat-dialog-title>{{ "MAIN.CREATE_CUSTOMER.HEADING" | translate }}</h3>
<div mat-dialog-content>
  <form
    class="form-container"
    [formGroup]="createCustomerForm"
    (ngSubmit)="onSubmit()"
  >
    <span class="info"
      >{{ "MAIN.CREATE_CUSTOMER.SUB_HEADING_ONE" | translate }}
    </span>
    <mat-form-field>
      <mat-label>{{
        "MAIN.CREATE_CUSTOMER.COMPANY_NAME" | translate
      }}</mat-label>
      <input
        matInput
        [placeholder]="'MAIN.CREATE_CUSTOMER.COMPANY_NAME' | translate"
        formControlName="companyName"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{
        "MAIN.CREATE_CUSTOMER.COMPANY_SUBSCRIPTIONS" | translate
      }}</mat-label>
      <mat-select
        (selectionChange)="clearRoutes()"
        formControlName="subscriptions"
        multiple
      >
        <mat-option *ngFor="let use of useCases" [value]="use.value">{{
          use.display
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{
        "MAIN.CREATE_CUSTOMER.ROUTE_MANAGEMENT" | translate
      }}</mat-label>
      <mat-select formControlName="routeManagement" multiple>
        <mat-option *ngFor="let use of hasSelectedSub()" [value]="use.value">{{
          use.display
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <span class="info"
      >{{ "MAIN.CREATE_CUSTOMER.SUB_HEADING_TWO" | translate }}
      <span class="sub-info"
        >({{ "MAIN.CREATE_CUSTOMER.TEXT" | translate }})</span
      >
    </span>

    <mat-form-field>
      <mat-label>{{
        "MAIN.CREATE_CUSTOMER.CUSTOMER_NAME" | translate
      }}</mat-label>
      <input matInput placeholder="John Doe" formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "MAIN.CREATE_CUSTOMER.DOMAIN" | translate }}</mat-label>
      <input matInput placeholder="www.domain.com" formControlName="domain" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "MAIN.CREATE_CUSTOMER.SUB_DOMAIN" | translate }}</mat-label>
      <input
        matInput
        placeholder="www.sub-domain.com"
        formControlName="subDomain"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{
        "MAIN.CREATE_CUSTOMER.EMAIL_ADDRESS" | translate
      }}</mat-label>
      <input matInput placeholder="test@test.com" formControlName="email" />
      <mat-error *ngIf="email?.errors?.mustMatch">{{
        "MAIN.CREATE_CUSTOMER.EMAIL_ERROR" | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "COLUMNS.PHONE_NUMBER" | translate }}</mat-label>
      <input matInput placeholder="111-222-1234" formControlName="phone" />
    </mat-form-field>
    <div class="form-buttons" mat-dialog-actions>
      <button
        mat-flat-button
        color="primary"
        [disabled]="createCustomerForm.invalid"
      >
        <mat-spinner
          class="spinner"
          color="accent"
          diameter="30"
          *ngIf="loading"
        ></mat-spinner>
        <span *ngIf="!loading">{{ "FORMS.SUBMIT" | translate }}</span>
      </button>
      <button mat-stroked-button mat-dialog-close>
        {{ "MAIN.CREATE_CUSTOMER.CLOSE" | translate }}
      </button>
    </div>
  </form>
</div>
