import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from '@app/services/storage.service';
import { WebSocketService } from '@app/services/websocket.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from '@app/services/users.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-eula-view',
  templateUrl: './eula-view.component.html',
  styleUrls: ['./eula-view.component.scss'],
})
export class EulaViewComponent implements OnInit {
  constructor(
    public customerService: CustomerService,
    public dialog: MatDialog,
    public authService: AuthService,
    public router: Router,
    public storage: StorageService,
    public userService: UsersService,
    public webSocketService: WebSocketService
  ) {}

  ngOnInit(): void {
    if (!this.customerService.customerId || !this.customerService.userId) {
      console.log("No customer ID or no user ID, rerouting back to login.")
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  cancel() {
    this.authService.logout().subscribe(
      () => {
        this.customerService.alreadyWarned$.next(false);
        this.customerService.licensesExpiring$.next(false);
        this.customerService.licensesWarning$.next(false);
        this.webSocketService.disconnect();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onSubmit() {
    this.userService.agreeToEULA().subscribe((user: any) => {
      this.storage.setEULADate(format(Date.now(), 'MM/dd/yy h:mm aaa'))
      this.router.navigate(['/dashboard'], { replaceUrl: true });
    }, (error: any) => {
      console.log(error);
      this.customerService.alreadyWarned$.next(false);
        this.customerService.licensesExpiring$.next(false);
        this.customerService.licensesWarning$.next(false);
        this.webSocketService.disconnect();
    });
  }
}
