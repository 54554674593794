import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infrastructures',
  template: `
    <div class="wrapper">
      <infrastructures-view *axLazyElement></infrastructures-view>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class InfrastructuresComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
