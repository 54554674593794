<div class="wrapper">
  <div *ngIf="view == 'login'" class="container">
    <img class="sub-logo" src="assets/images/SofiaLogo.svg" />
    <img class="logo" src="assets/images/AiRISTA-FlowLogo-White.svg" />

    <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
      <span class="wrong-password" *ngIf="passwordIncorrect">{{
        "MAIN.LOGIN.INCORRECT" | translate
      }}</span>
      <input
        class="login-input"
        placeholder="{{ 'MAIN.LOGIN.USERNAME' | translate }}"
        type="text"
        formControlName="username"
      />
      <input
        class="login-input"
        placeholder="{{ 'MAIN.LOGIN.PASSWORD' | translate }}"
        type="password"
        formControlName="password"
      />
      <button [disabled]="!loginForm.valid" class="button-primary">
        <mat-spinner
          class="spinner"
          color="accent"
          diameter="21"
          *ngIf="loading"
        ></mat-spinner>
        <span *ngIf="!loading">{{ "MAIN.LOGIN.LOGIN" | translate }}</span>
      </button>
    </form>
    <a class="forgot-password" routerLink="/forgot-password">
      {{ "MAIN.LOGIN.FORGOT" | translate }}
    </a>
    <img
      [ngClass]="env.international ? 'enabled-flag' : 'disabled-flag'"
      [src]="storage.countryFlagUrl"
      [matMenuTriggerFor]="langs"
    />
    <mat-menu #langs="matMenu">
      <button
        class="country"
        *ngFor="let lang of storage.languages"
        (click)="storage.setLang(lang.value)"
        mat-menu-item
      >
        <span>
          {{ "MAIN.LANGUAGES." + lang.value | translate }}
        </span>
        <img class="flag" [src]="getCountryFlag(lang.value)" />
      </button>
    </mat-menu>
  </div>

  <div *ngIf="view == 'mfa'" class="container">
    <img class="sub-logo" src="assets/images/SofiaLogo.svg" />
    <img class="logo" src="assets/images/AiRISTA-FlowLogo-White.svg" />
    <form class="form" [formGroup]="mfaForm" (ngSubmit)="sendMFACode()">
      <input
        class="login-input"
        placeholder="2FA Code"
        type="text"
        formControlName="code"
      />
      <button [disabled]="!mfaForm.valid" class="button-primary">
        <span>Submit</span>
      </button>
    </form>
    <p class="forgot-password" (click)="backToLogin()">Cancel</p>
  </div>

  <div class="copyright">
    {{ "EULA.COPYRIGHT" | translate }}
    <br />
    Version 7.{{ version }}
  </div>
</div>
