import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Group, Department } from '@app/core/interfaces';
import { NavigationExtras, Router } from '@angular/router';
import { GlobalSearchService } from '@app/services/global-search.service';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent implements OnInit {
  filterSearchForm!: FormGroup;
  loading: boolean = false;

  //form field dropdown arrays
  applications: any[] = [];
  groups: Group[] = [];
  departments: Department[] = [];

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public router: Router,
    public dialogRef: MatDialogRef<FilterSearchComponent>,
    public searchService: GlobalSearchService
  ) {
    this.searchService.initializeService(); //should call for company info subscriptions and departments
    this.searchService.dept$.subscribe((departments: any) => {
      this.departments = departments;
    });
    this.searchService.subscriptions$.subscribe((subs: any) => {
      if (subs && subs.routeManagement) {
        // adds the apps that are true for the customer to the list of apps to filter though
        this.applications = Object.keys(subs.routeManagement).filter(
          (app) => subs.routeManagement[app]
        );
      }
    });
  }

  ngOnInit(): void {
    this.buildfilterSearchForm();
  }

  buildfilterSearchForm() {
    this.filterSearchForm = this.fb.group({
      name: [this.data.keyword ? this.data.keyword : ''],
      application: [''],
      group: [null],
      department: [null],
      battery: [],
    });
  }

  applicationChanged(applicationString: string) {
    console.log(applicationString);
    if (applicationString != '') {
      this.searchService
        .getGroupsByUseCase(applicationString)
        .subscribe((groups: any) => {
          this.groups = groups;
        });
    } else {
      this.groups = [];
      this.filterSearchForm.patchValue({ group: null });
    }
  }

  onSubmit() {
    const values = this.filterSearchForm.value;
    const queryParams: any = {};

    if (values.application) queryParams.application = values.application;
    if (values.battery) queryParams.battery = values.battery;
    if (values.department) queryParams.department = values.department.Id;
    if (values.department) queryParams.departmentName = values.department.Name;
    if (values.group) queryParams.group = values.group.Id;
    if (values.group) queryParams.groupName = values.group.Name;
    if (values.name) queryParams.name = values.name;

    const params: NavigationExtras = {
      queryParams,
      replaceUrl: true,
    };

    this.router.navigate(['/search'], params);
    this.dialogRef.close();
  }
}
