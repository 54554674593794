import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VERSION } from '@app/core/constants';
import { AuthService } from '@app/services/auth.service';
import { StorageService } from '@app/services/storage.service';
import { CustomerService } from '@app/services/customer.service';
import { UseCase } from '@app/core/interfaces';
import { USECASES } from '@app/core/constants';
import { WebSocketService } from '@app/services/websocket.service';
import { EnvService } from '@app/services/env.service';
import { parseISO } from 'date-fns';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent implements OnInit {
  loading: boolean = false;
  loginForm: FormGroup;
  mfaForm: FormGroup;
  passwordIncorrect: boolean = false;
  version: string = VERSION;

  view: 'login' | 'mfa' = 'login';

  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    public env: EnvService,
    private fb: FormBuilder,
    private notifier: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public storage: StorageService,
    private socketService: WebSocketService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.mfaForm = this.fb.group({
      code: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  getCountryFlag(countryCode: string): string {
    const url: string = `assets/country-flags/${countryCode}.png`;
    return url;
  }

  async handleLoginRouting() {
    const settings = this.customerService.userSettings;
    const useCase = settings.defaultUseCase
      ? USECASES.find((use: UseCase) => use.value === settings.defaultUseCase)
      : '';
    const tab = settings.defaultTab ? settings.defaultTab : '';

    if (useCase && tab) {
      await this.router.navigate([`/${useCase?.path}/${settings.defaultTab}`], {
        replaceUrl: true,
      });
    } else if (useCase) {
      await this.router.navigate([`/${useCase?.path}/assets`], {
        replaceUrl: true,
      });
    } else {
      await this.router.navigate(['/dashboard'], { replaceUrl: true });
    }
    this.handleLanguage();
  }

  handleLanguage() {
    let language: string = '';
    let systemSettings: any;
    const settings = this.customerService.userSettings;

    this.customerService.getSystemSettings().subscribe((res: any) => {
      systemSettings = res;
      language = settings?.defaultLanguage
        ? settings.defaultLanguage
        : systemSettings?.DefaultLanguage
        ? systemSettings.DefaultLanguage
        : this.env.defaultLang;

      if (language !== this.env.defaultLang) this.storage.setLang(language);
    });
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.login(this.loginForm.value).subscribe(
      (user: any) => {
        const lastEulaDate = new Date('February 21, 2023 03:24:00');
        const formattedDate = parseISO(
          user?.LastAgreedToEULADateTime || 'January 1, 1970 01:00:00'
        );

        if (user.IsSuperUser) {
          this.route.queryParams.subscribe(() =>
            this.router.navigate(['/select-customer'], { replaceUrl: true })
          );
        } else if (user === 'send the code now') {
          this.view = 'mfa';
        } else {
          this.storage.setCustomerInfo({
            id: user.CustomerId,
            name: user.UserName,
          });

          // FORCED RESET PASSWORD
          if (user.ResetPasswordOnLogin) {
            console.log('redirect to set password');
            this.route.queryParams.subscribe(() =>
              this.router.navigate(['/set-password'], { replaceUrl: true })
            );
          }

          //INTERRUPTED ROUTING - EULA
          else if (lastEulaDate > formattedDate) {
            this.router.navigate(['/eula'], { replaceUrl: false });
          }
          //NORMAL ROUTING
          else {
            this.socketService.getSocket();
            this.handleLoginRouting();
          }
        }
      },
      () => {
        this.passwordIncorrect = true;
        this.notifier.open(
          `There was an issue with accessing your account.`,
          '',
          { panelClass: 'error' }
        );
        this.loading = false;
      }
    );
  }

  backToLogin() {
    this.view = 'login';
    this.loading = false;
  }

  sendMFACode() {
    this.authService.send2FACode(this.mfaForm.value.code).subscribe(
      (user: any) => {
        const lastEulaDate = new Date('February 21, 2023 03:24:00');
        const formattedDate = parseISO(
          user?.LastAgreedToEULADateTime || 'January 1, 1970 01:00:00'
        );

        if (user.IsSuperUser) {
          this.route.queryParams.subscribe(() =>
            this.router.navigate(['/select-customer'], { replaceUrl: true })
          );
        } else {
          this.storage.setCustomerInfo({
            id: user.CustomerId,
            name: user.UserName,
          });

          // FORCED RESET PASSWORD
          if (user.ResetPasswordOnLogin) {
            this.route.queryParams.subscribe(() =>
              this.router.navigate(['/set-password'], { replaceUrl: true })
            );
          }

          //INTERRUPTED ROUTING - EULA
          else if (lastEulaDate > formattedDate) {
            this.router.navigate(['/eula'], { replaceUrl: false });
          }
          //NORMAL ROUTING
          else {
            this.socketService.getSocket();
            this.handleLoginRouting();
          }
        }
      },
      () => {
        this.passwordIncorrect = true;
        this.notifier.open(
          `There was an issue with your two-factor authentication code.`,
          '',
          {
            panelClass: 'error',
          }
        );
        this.loading = false;
      }
    );
  }
}
