import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from '@app/services/storage.service';
import { VERSION } from '@app/core/constants';
import { MatDialog } from '@angular/material/dialog';
import { CreateCustomerComponent } from '@app/login/create-customer/create-customer.component';
import { WebSocketService } from '@app/services/websocket.service';
import { EnvService } from '@app/services/env.service';

@Component({
  selector: 'app-select-customer-view',
  templateUrl: './select-customer-view.component.html',
  styleUrls: ['./select-customer-view.component.scss'],
})
export class SelectCustomerViewComponent implements OnInit {
  customers: any = [];
  selectCustomerForm: FormGroup;
  version: string = VERSION;

  constructor(
    public customerService: CustomerService,
    public dialog: MatDialog,
    public env: EnvService,
    private fb: FormBuilder,
    private router: Router,
    private storage: StorageService,
    private socketService: WebSocketService
  ) {
    this.selectCustomerForm = this.fb.group({
      customer: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.customerService.initializeService(); //MKL 4-26-2023
    this.getCompanies();
  }

  changeCustomer(e: any) {
    this.customer?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  get customer() {
    return this.selectCustomerForm.get('customer');
  }

  getCompanies() {
    this.customerService.getAllCustomers().subscribe(
      (res) => {
        this.customers = [...res];
      },
      (error) => console.error(error)
    );
  }

  handleLanguage() {
    let language: string = '';
    let systemSettings: any;
    const settings = this.customerService.userSettings;

    this.customerService.getSystemSettings().subscribe((res: any) => {
      systemSettings = res;
      language = settings?.defaultLanguage
        ? settings.defaultLanguage
        : systemSettings?.DefaultLanguage
        ? systemSettings.DefaultLanguage
        : this.env.defaultLang;

      if (language !== this.env.defaultLang) this.storage.setLang(language);
    });
  }

  async onSubmit() {
    if (this.selectCustomerForm.valid) {
      const selectedCustomer = this.selectCustomerForm.value.customer;
      this.storage.setCustomerInfo({
        id: selectedCustomer.Id,
        name: selectedCustomer.Name,
      });

      //this.customerService.initializeService(false, 'select-customer onSubmit');
      
      this.socketService.getSocket();

      await this.router.navigate(['/dashboard'], { replaceUrl: true });

      this.handleLanguage();
    }
  }

  openSlidingPanel() {
    this.dialog.open(CreateCustomerComponent, {
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '100vw',
      width: this.customerService.getScreenSize() ? '100%' : '750px',
    });
  }
}
