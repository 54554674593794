import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-report',
  template: `
    <iframe
      #report
      frameborder="0"
      style="
    height: 100%;
    margin-bottom: -50px;
    width: 100%;
  "
    ></iframe>
  `,
})
export class ReportIframeComponent implements OnInit {
  @ViewChild('report') iframe!: ElementRef;

  iframeLink: string = '';
  loginToken: string = '';
  subscription: string = '';
  URLPrefix: string = '';
  userId: number = 0;

  constructor(
    public customerService: CustomerService,
    public storageService: StorageService
  ) {
    this.subscription = window.location.pathname.split('/')[1];
  }

  ngOnInit() {
    let userData = this.storageService.get('uvs-credentials');

    if (userData) {
      this.loginToken = userData.userObject.LoginToken;
      this.userId = userData.userObject.Id;
    }

    this.setIframeUrl();
  }

  ngAfterViewInit() {
    this.iframe.nativeElement.setAttribute('src', this.iframeLink);
  }

  setIframeUrl() {
    switch (this.subscription) {
      case 'asset-tracking':
        this.URLPrefix = 'AssetTracking';
        break;
      case 'melt':
        this.URLPrefix = 'MELT';
        break;
      case 'patient-flow':
        this.URLPrefix = 'PatientFlow';
        break;
      case 'people-flow':
        this.URLPrefix = 'PeopleFlow';
        break;
      case 'sdct':
        this.URLPrefix = 'SDCT';
        break;
      case 'staff-safety':
        this.URLPrefix = 'StaffSafety';
        break;
      default:
        break;
    }

    this.iframeLink =
      `/Xpert${this.URLPrefix}Report/?CustomerId=${this.customerService.customerId}` +
      `&uid=${this.userId}&tkn=${this.loginToken}`;
  }
}
