<div class="nav-container" *ngIf="authService.isAuthenticated()">
  <div
    class="nav-left"
    [matMenuTriggerFor]="tilesMenu"
    (menuOpened)="openTilesMenu()"
  >
    <div class="home-link">
      <img src="assets/images/header-logo.svg" />
      <img class="sofia" src="assets/images/SofiaLogo.svg" />
    </div>
  </div>
  <div *ngIf="customerService.isSuperUser" class="super-user">
    {{ "MAIN.NAV.SUPERUSER" | translate }}
    <span *ngIf="storage.company">@ {{ storage.company?.name }}</span>
  </div>
  <div class="nav-right">
    <form *ngIf="storage.company && !env.CMMode" (submit)="search($event)">
      <div class="search-container">
        <input
          matInput
          #searchInput
          type="search"
          placeholder="{{ 'MAIN.NAV.SEARCH' | translate }}"
          minlength="1"
          class="search-field"
        />
        <mat-icon
          class="menu-icon"
          (click)="openFilterSearch(searchInput.value)"
        >
          filter_list
        </mat-icon>
      </div>
      <input type="submit" style="display: none" />
    </form>
    <div class="drop-button" [matMenuTriggerFor]="navMenu">
      <span class="user-info">{{ storage.uvsCreds?.userObject.UserName }}</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
    <div *ngIf="!env.CMMode" [matMenuTriggerFor]="alerts" class="notifications">
      <mat-icon>notifications_none</mat-icon>
      <span *ngIf="socketService.realEvents.length > 0" class="alert-count">{{
        socketService.realEvents.length
      }}</span>
    </div>
    <img
      [ngClass]="env.international ? 'enabled-flag' : 'disabled-flag'"
      [ngStyle]="{
        'margin-left': socketService.realEvents.length > 0 ? '1em' : '0em'
      }"
      [src]="storage.countryFlagUrl"
      [matMenuTriggerFor]="langs"
    />
    <mat-menu #langs="matMenu">
      <button
        class="country"
        *ngFor="let lang of storage.languages"
        (click)="updateLanguage(lang.value)"
        mat-menu-item
      >
        <span>
          {{ "MAIN.LANGUAGES." + lang.value | translate }}
        </span>
        <img class="flag" [src]="getCountryFlag(lang.value)" />
      </button>
    </mat-menu>
  </div>
</div>
<div
  routerLink="company-information"
  class="ticker-wrap"
  [ngStyle]="{
    'background-color': licensesWarning ? '#d0021b' : '#f5a623'
  }"
  *ngIf="authService.isAuthenticated() && licensesExpiring"
>
  <div class="ticker">
    <div class="ticker__item" *ngIf="!licensesWarning">
      {{ "MAIN.NAV.EXP.ATTENTION" | translate }}
    </div>
    <div class="ticker__item" *ngIf="licensesWarning">
      {{ "MAIN.NAV.EXP.WARNING" | translate }}
    </div>
    <div class="ticker__item" *ngFor="let l of licensesAboutToExpire">
      <div class="licenses">
        <mat-icon [inline]="true">{{ l.icon }}</mat-icon>
        <span>
          {{ getLicenseDisplay(l.langKey) }}:
          {{ getLicenseDaysRemaining(l.expiration) }}
        </span>
      </div>
    </div>
  </div>
</div>

<mat-menu #tilesMenu xPosition="after">
  <app-tiles-menu *ngIf="authService.isAuthenticated()"></app-tiles-menu>
</mat-menu>

<mat-menu #navMenu xPosition="before">
  <app-nav-menu *ngIf="authService.isAuthenticated()"></app-nav-menu>
</mat-menu>

<mat-menu #alerts matTooltip="View and mangage alerts.">
  <div *ngIf="socketService.realEvents.length === 0" class="alerts-menu">
    <div class="no-alerts">{{ "MAIN.NAV.MENU_ALERT.NONE" | translate }}</div>
  </div>

  <div *ngIf="socketService.realEvents.length > 0" class="alerts-menu">
    <div class="section">
      <button mat-stroked-button color="warn" (click)="showAllAlerts()">
        {{
          "MAIN.NAV.MENU_ALERT.VIEW"
            | translate : { number: socketService.realEvents.length }
        }}
      </button>
    </div>
    <mat-divider></mat-divider>
    <div class="section">{{ "MAIN.NAV.MENU_ALERT.CURRENT" | translate }}</div>

    <div class="section-content" (click)="showAllAlerts()">
      <span>{{ selectedEvent?.DisplayName }}</span>
      <span
        >{{ selectedEvent?.ModelName }}
        <span *ngIf="selectedEvent?.ZoneName">
          {{ selectedEvent?.ZoneName[0]?.ZoneName }}
        </span>
      </span>
      <span>{{ socketService.formatTime(selectedEvent?.DateCreated) }}</span>
    </div>
  </div>
</mat-menu>
