<div class="wrapper">
  <div class="container">
    <div class="header">{{ "MAIN.EMAIL_SEND.HEADER" | translate }}</div>
    <p class="text">
      {{ "MAIN.EMAIL_SEND.TEXT" | translate }}
    </p>
    <button class="button-primary" (click)="tryAgain()">
      {{ "MAIN.EMAIL_SEND.TRY_AGAIN" | translate }}
    </button>
    <button class="button-cancel" (click)="redirectToLogin()">
      {{ "MAIN.EMAIL_SEND.BACK_TO_LOGIN" | translate }}
    </button>
  </div>

  <div class="copyright">
    {{ "EULA.COPYRIGHT" | translate }}
    <br />
    Version 7.{{ version }}
  </div>
</div>
