import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsService } from '@app/services/events.service';
import { TranslateService } from '@ngx-translate/core';

//5-7-2024 Add "B4 Only" language

@Component({
  selector: 'app-shared-send-tag-msg',
  template: `
    <h2>Send Message</h2>
    <p>
      Message-capable tags include: the B4n and the Myco and Spectralink devices
      with AiRISTA's Soft-Tag app.
    </p>
    <form [formGroup]="messageForm" class="wrapper">
      <mat-form-field appearance="fill">
        <mat-label>Message</mat-label>
        <textarea matInput formControlName="message"></textarea>
      </mat-form-field>
      <mat-error *ngIf="messageForm.value.message.length > 80">
        Message is too long.
      </mat-error>
      <p>Remaining Characters: {{ getRemainingChars() }} / {{ MAX_CHARS }}</p>
      <mat-form-field appearance="fill">
        <mat-label>Notification Sound</mat-label>
        <mat-select matInput formControlName="sound">
          <mat-option value="tone-four">Beep</mat-option>
          <mat-option value="tone-two">Twinkle</mat-option>
          <mat-option value="tone-three">Stairs</mat-option>
          <mat-option value="tone-one">Siren</mat-option>
          <mat-option value="silent">Silent</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div class="button-row">
      <button mat-flat-button color="primary" (click)="submit()">Submit</button>
      <button
        mat-flat-button
        color="primary"
        style="margin-left: 1em;"
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: flex;
        flex-direction: column;
        width: 50%;
      }
    `,
  ],
})
export class SharedSendTagMsgComponent implements OnInit {
  MAX_CHARS: number = 80; //MKL max char display

  messageForm!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public eventsService: EventsService,
    private fb: FormBuilder,
    private notifier: MatSnackBar,
    private dialogRef: MatDialogRef<SharedSendTagMsgComponent>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.messageForm = this.fb.group({
      message: ['', [Validators.required]],
      sound: ['', [Validators.required]],
    });
  }

  submit() {
    if (this.messageForm.valid) {
      if (this.data.Id != 0) {
        this.eventsService
          .sendMessageWithSound(
            this.data.Id,
            this.messageForm.value.message,
            this.messageForm.value.sound
          )
          .subscribe(
            (res: any) => {
              //success note?
              this.dialogRef.close();
            },
            (err: any) => {
              this.notifier.open(
                this.translate.instant('NOTIFIER.ERROR_CREATE'),
                '',
                { panelClass: 'error' }
              );
            }
          );
      } else {
        //error note?
      }
    } else {
      this.notifier.open(
        this.translate.instant('NOTIFIER.FORM_INCOMPLETE'),
        '',
        { panelClass: 'error' }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  getRemainingChars(): number {
    return this.MAX_CHARS - (this.messageForm.value.message.length || 0);
  }
}
