import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assets',
  template: `
    <div class="wrapperSub">
      <assets-view *axLazyElement></assets-view>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class AssetsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
