<p>{{ "COLUMNS.NAME" | translate }}: {{ assetIdentifier.Name }}</p>

<div class="post-load" *ngIf="asset != null">
  <p
    *ngIf="asset.GroupName && asset.GroupID"
    class="breadcrumb-link"
    (click)="
      navigateTo({
        PanelName: 'group',
        Params: { Name: asset.GroupName, ID: asset.GroupID }
      })
    "
  >
    <b>{{ "COLUMNS.GROUP" | translate }}:</b> {{ asset.GroupName }}
  </p>

  <p *ngIf="asset.DepartmentName && asset.DepartmentID">
    <b>{{ "COLUMNS.DEPARTMENT" | translate }}:</b> {{ asset.DepartmentName }}
  </p>

  <p *ngIf="asset.BedStatus">
    {{ "COLUMNS.BED_STATUS" | translate }}: {{ asset.BedStatus }}
  </p>
  <p *ngIf="asset.Email">
    {{ "COLUMNS.EMAIL_ADDRESS" | translate }}: {{ asset.Email }}
  </p>

  <p
    *ngFor="let device of asset.AssociatedDevices"
    class="breadcrumb-link"
    (click)="
      navigateTo({
        PanelName: 'device',
        Params: {
          Name: device.UniqueId,
          ID: device.Id
        }
      })
    "
  >
    <b>{{ "COLUMNS.PAIRED_DEVICE" | translate }}:</b> {{ device.Name }}
  </p>
  <p *ngIf="asset.AssociatedDevices.length == 0">
    {{ "COLUMNS.NO_PAIRED_DEVICE" | translate }}
  </p>

  <p
    *ngIf="asset.AssocItemID && asset.AssocItemName"
    class="breadcrumb-link"
    (click)="
      navigateTo({
        PanelName: 'asset',
        Params: { Name: asset.AssocItemName, ID: asset.AssocItemID }
      })
    "
  >
    {{ "COLUMNS.ASSOC_ASSET" | translate }}: {{ asset.AssocItemName }}
  </p>

  <!-- MKL new props to show 8-10-2023 -->
  <p *ngIf="asset.BuildingName && asset.FloorName">
    {{ "COLUMNS.LOCATION" | translate }}: {{ asset.BuildingName }} -
    {{ asset.FloorName }}
  </p>
  <p *ngIf="asset.LocationUpdated">
    {{ "COLUMNS.LOCATION_UPDATED" | translate }}: {{ asset.LocationUpdated }}
  </p>

  <!-- UDA props to show 1-30-2024 -->
  <!-- Make UDAs mandatory? -->
  <div
    *ngIf="
      asset.UserDefinedAttributes && asset.UserDefinedAttributes.length > 0
    "
    class="uda"
  >
    <div *ngFor="let uda of asset.UserDefinedAttributes">
      <div *ngIf="uda.AttributeType == 'String'" class="prop">
        <span class="header">{{ uda.AttributeName }}</span>
        <span *ngIf="uda.AttrString">{{ uda.AttrString }}</span>
        <span *ngIf="!uda.AttrString">N/A</span>
      </div>
      <div *ngIf="uda.AttributeType == 'DateTime'" class="prop">
        <span class="header">{{ uda.AttributeName }}</span>
        <span *ngIf="uda.AttrString">{{ uda.AttrString }}</span>
        <span *ngIf="!uda.AttrString">N/A</span>
      </div>
      <div *ngIf="uda.AttributeType == 'Int'" class="prop">
        <span class="header">{{ uda.AttributeName }}</span>
        <span *ngIf="uda.AttrInt">{{ uda.AttrInt }}</span>
        <span *ngIf="!uda.AttrInt">N/A</span>
      </div>
      <div *ngIf="uda.AttributeType == 'YesNo'" class="prop">
        <span class="header">{{ uda.AttributeName }}</span>
        <!-- Needed for Bool? MKL 2/12 -->
        <span>{{ uda.AttrBool?.toString() }}</span>
      </div>
    </div>
  </div>

  <app-shared-map
    *ngIf="asset != null"
    [data]="asset"
    [inputType]="'asset'"
    style="width: 98%"
  ></app-shared-map>
</div>
