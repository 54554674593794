import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Asset, Device, Infrastructure } from '@app/core/interfaces';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { format, parseISO } from 'date-fns';
import { MaxRecordReturn } from '@app/core/constants';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  customerId: number = -1;
  isSuperUser: boolean = false;
  permissions: any[] = [];

  constructor(private http: HttpClient, private storage: StorageService) {}

  initializeService() {
    // // ==> production
    let uvsC = JSON.parse(<string>sessionStorage.getItem('uvs-credentials'));
    let customerData = sessionStorage.getItem('company');
    this.customerId = customerData
      ? JSON.parse(customerData).id || JSON.parse(customerData).company
      : undefined;
    this.isSuperUser = uvsC?.userObject?.IsSuperUser
      ? uvsC.userObject.IsSuperUser
      : false;
    this.permissions = uvsC?.userObject?.Permissions
      ? uvsC.userObject.Permissions
      : [];
  }

  getStaffById(staffId: number) {
    return this.http
      .get(
        `/api/Staff/GetByStaffId?StaffId=${staffId}&CustomerId=${this.customerId}`
      )
      .pipe(
        map((asset: any) => {
          const newAsset: Asset = {
            Id: asset.Id,
            AlertStatus: asset.AlertStatus,
            AssociatedDevices: asset.AssociatedDevices,
            BedStatus: asset.BedStatus || '',
            StatusLabel: asset.AlertStatus,
            Name: asset.Name,
            Email: asset.Email,
            EnableAlerts: asset.EnableAlerts,
            EnableSDCT: asset.EnableSDCT,
            EnableHygiene: asset.EnableHygiene,
            DepartmentName: asset.DepartmentName,
            DepartmentID: asset.DepartmentID || 0,
            AssocItemName: asset.AssocItemName,
            AssocItemID: asset.AssocItemID,
            PendingDepartmentName: asset.PendingDepartmentName,
            PendingDepartmentId: asset.PendingDepartmentId,
            DeviceID: asset.DeviceID,
            DeviceName: asset.DeviceName,
            Temperature: Number(asset.Temperature) || 'N/A',
            GroupName: asset.GroupName,
            GroupID: asset.GroupID,
            BuildingName: asset.CurrentBuildingName,
            SiteName: asset.CurrentSiteName,
            FloorName: asset.CurrentFloorName,
            MapId: asset.CurrentModelId,
            CurrentZones: asset.CurrentZones,
            Latitude: asset.Latitude || 0,
            Longitude: asset.Longitude || 0,
            Address: asset.Address,
            X: asset.CurrentX,
            Y: asset.CurrentY,
            LocationUpdated: asset.LocationUpdated
              ? format(
                  parseISO(asset.LocationUpdated + 'Z'),
                  'MM/dd/yy h:mm aaa'
                )
              : '',
            ModelName: asset.ModelName,
            PhoneNumber: asset.PhoneNumber,
            StaffID: asset.StaffID,
            Icon: asset.Icon,
            Portrait: asset.Portrait,
            UserDefinedAttributes: asset.UserDefinedAttributes,
            UserDefinedStatus: asset.UserDefinedStatus,
            UserDefinedStatusIcon: asset.UserDefinedStatusIcon,
            //UserDefinedStatuses: asset.UserDefinedStatuses,
          };
          return newAsset;
        })
      );
  }

  getDepartmentById(departmentId: number) {
    return this.http
      .get('/api/MetaData/GetDepartments?CustomerId=' + this.customerId)
      .pipe(
        map((data: any) => {
          const department = data.List.find(
            (dept: any) => dept.Id === departmentId
          );
          if (department) {
            return { Id: department.Id, Name: department.Name };
          } else {
            return null; // Return null if department with the given ID is not found
          }
        })
      );
  }

  getDeviceById(deviceId: number): Observable<Device> {
    return this.http
      .get(
        `/api/Device/GetByDeviceId?DeviceId=${deviceId}&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          const newDevice: Device = {
            Id: data.Id,
            UniqueId: data.UniqueId,
            MapId: data.MapId,
            X: data.X,
            Y: data.Y,
            Latitude: data.Latitude,
            Longitude: data.Longitude,
            ItemId: data.ItemId,
            Temperature: data.Temperature || -273,
            TemperatureAdjustment: data.TemperatureAdjustment || 0,
            PersonAssociated: data.PersonAssociated,
            DateCreated: data.DateCreated
              ? format(parseISO(data.DateCreated + 'Z'), 'MM/dd/yy h:mm aaa')
              : '',
            DateUpdated: data.DateUpdated
              ? format(parseISO(data.DateUpdated + 'Z'), 'MM/dd/yy h:mm aaa')
              : '',
            BatteryLevel: data.BatteryLevel,
            NonConfigurable: data.NonConfigurable,
          };
          return newDevice;
        })
      );
  }

  ///api/MetaData/GetGroupByID?GroupId=5586&CustomerId=1
  getGroupById(groupId: number) {
    return this.http.get(
      `/api/MetaData/GetGroupByID?GroupId=${groupId}&CustomerId=${this.customerId}`
    );
  }

  getAllInfras() {
    return this.http
      .get(
        `/api/Infrastructure/GetAll?CustomerId=${this.customerId}&NumberOfRecords=${MaxRecordReturn}`
      )
      .pipe(
        map((data: any) => {
          const infras = data.List.map((el: any) => {
            const infrastructure: Infrastructure = {
              Id: el.Id,
              DeviceTableId: el.DeviceTableId,
              UniqueId: el.UniqueId,
              BatteryLevel: el.BatteryLevel,
              ConfigId: el.ConfigId,
              PendingConfigId: el.PendingConfigId,
              Type: el.Type,
              GroupId: el.GroupId,
              FirmwareVersion: el.FirmwareVersion,
              DeviceType: el.DeviceType,
              IsEnabled: el.IsEnabled,
              IsMonitored: el.IsMonitored,
              Name: el.Name,
              DateUpdated: el.DateUpdated
                ? format(
                    parseISO(el.DateUpdated + 'Z'),
                    'MMM do, yyyy h:mm aaa'
                  )
                : '',
              Status: el.Status,
              SiteId: el.SiteId,
              BuildingId: el.BuildingId,
              FloorId: el.ModelId,
              MapId: el.MapId,
              X: el.X,
              Y: el.Y,
              SiteName: el.SiteName,
              BuildingName: el.BuildingName,
              MapName: el.MapName,
              ZoneName: el.ZoneName,
              ZoneId: el.ZoneID,
            };
            return infrastructure;
          });
          return infras;
        })
      );
  }

  getAllCameras() {
    return this.http
      .get(
        `/api/Infrastructure/GetAll?CustomerId=${this.customerId}&NumberOfRecords=${MaxRecordReturn}&SearchText=Camera`
      )
      .pipe(
        map((data: any) => {
          const infras = data.List.map((el: any) => {
            const infrastructure: Infrastructure = {
              Id: el.Id,
              DeviceTableId: el.DeviceTableId,
              UniqueId: el.UniqueId,
              BatteryLevel: el.BatteryLevel,
              ConfigId: el.ConfigId,
              PendingConfigId: el.PendingConfigId,
              Type: el.Type,
              GroupId: el.GroupId,
              FirmwareVersion: el.FirmwareVersion,
              DeviceType: el.DeviceType,
              IsEnabled: el.IsEnabled,
              IsMonitored: el.IsMonitored,
              Name: el.Name,
              DateUpdated: el.DateUpdated
                ? format(
                    parseISO(el.DateUpdated + 'Z'),
                    'MMM do, yyyy h:mm aaa'
                  )
                : '',
              Status: el.Status,
              SiteId: el.SiteId,
              BuildingId: el.BuildingId,
              FloorId: el.ModelId,
              MapId: el.MapId,
              X: el.X,
              Y: el.Y,
              SiteName: el.SiteName,
              BuildingName: el.BuildingName,
              MapName: el.MapName,
              ZoneName: el.ZoneName,
              ZoneId: el.ZoneID,
              Port: el.Port,
            };
            return infrastructure;
          });
          return infras;
        })
      );
  }

  // GET waitlist for specified Dept.
  getAllByPendingDepartment(pendDeptId: number): Observable<any> {
    return this.http
      .get<any>(
        `/api/Staff/GetAllByPendingDepartment?CustomerId=${this.customerId}&PendingDepartmentId=${pendDeptId}&PageNumber=1&NumberOfRecords=50&IsDescending=true&UseCase=PatientFlow`
      )
      .pipe(
        map((data: any) => {
          data.List.forEach((element: any) => {
            const timeDiff = this.getTimeDifference(
              element.PendingDepartmentDateUpdated
            );
            element.TimeSinceCreated = timeDiff;
          });
          //console.log("Waitlist: ", data)
          return data;
        })
      );
  }

  getTimeDifference(oldTime: string): string {
    const currentTime = new Date();
    const timestampDate = new Date(oldTime);
    const timeDifference = Math.abs(
      currentTime.getTime() - timestampDate.getTime()
    );

    const hours = Math.floor(timeDifference / (1000 * 60 * 60))
      .toString()
      .padStart(2, '0');
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor((timeDifference / 1000) % 60)
      .toString()
      .padStart(2, '0');

    return `${hours}h:${minutes}m:${seconds}s`;
  }
}
