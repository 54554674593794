import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Feature, Map as OpMap, View } from 'ol/';
import { OSM, WMTS, Stamen, CartoDB } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import * as olProj from 'ol/proj';
import { MousePosition, ZoomSlider } from 'ol/control';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { defaults as defaultControls } from 'ol/control';
import { createStringXY } from 'ol/coordinate';
import { MapRenderService } from '@app/services/map-render.service';
import { EventsService } from '@app/services/events.service';

const BOOST_VISIBILITY_FOR_DEMOS = true;

@Component({
  selector: 'app-shared-gps-map',
  template: `<div class="map" id="map">Error</div>`,
  styleUrls: ['./shared-gps-map.component.scss'],
})
export class SharedGpsMapComponent implements OnInit, OnChanges {
  @Input() data: any = {};
  @Input() customerId: number = 0;
  @Input() inputType: string = 'error!';

  errorMessage: string = '';
  mapId: number = 0;
  mapData: any;
  zones: any;
  zoneFeatures: any[] = [];
  zoneSplitterRegEx: RegExp = new RegExp(',|;');
  pinFeatures: Feature[] = [];

  constructor(
    public mapService: EventsService,
    public renderService: MapRenderService
  ) {}

  ngOnInit(): void {
    //console.log("GPS Map Chosen!")
    this.mapService.initializeService();
    this.mapService.getGPSMapByCustomerId().subscribe(
      (res: any) => {
        this.drawMap(res);
      },
      (err: any) => {
        this.errorMessage = 'No Map Found for Device';
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(this.data)
    this.ngOnInit();
  }

  drawMap(res: any) {
    this.mapData = res;
    this.mapData.Zones.forEach((zone: any) => {
      this.zoneFeatures.push(this.renderService.createGPSZoneFeature(zone));
    });

    this.pinFeatures.push(this.extractPinFeature(this.data, this.inputType));

    if (BOOST_VISIBILITY_FOR_DEMOS) {
      this.pinFeatures.push(
        this.renderService.createFollowBracket(this.pinFeatures[0])
      );
    }

    var tileLayer = new TileLayer({ source: new OSM({}) });
    var zoneLayer = new VectorLayer({
      source: new VectorSource({ features: this.zoneFeatures }),
    });
    var vectorPinLayer = new VectorLayer();

    //console.log(this.pinFeature);
    if (this.pinFeatures.length > 0) {
      var vectorPinLayer = new VectorLayer({
        source: new VectorSource({ features: this.pinFeatures }),
      });
    } else {
      var vectorPinLayer = new VectorLayer({
        source: new VectorSource({ features: [] }),
      });
    }

    var oldmap = document.querySelector('#map');
    while (oldmap?.firstChild) {
      oldmap.removeChild(oldmap.firstChild);
    }

    var mapView = new View({
      center: olProj.fromLonLat([0, 0]),
      zoom: 3,
    });

    // var mousePositionControl = new MousePosition({
    //   coordinateFormat: createStringXY(1),
    //   projection: 'EPSG:4326',
    //   undefinedHTML: '&nbsp;',
    // });

    const mapElement = <HTMLElement>document.querySelector('#map');
    if (mapElement) {
      var map = new OpMap({
        layers: [tileLayer, zoneLayer, vectorPinLayer],
        target: mapElement,
        view: mapView,
        //controls: defaultControls().extend([mousePositionControl]),
      });

      var zoomslider = new ZoomSlider();
      map.addControl(zoomslider);

      // map.on('pointermove', (event: any) => {
      //   var layer = <any>map.getLayers().getArray()[1];
      //   var feats = layer.getSource().getFeatures();
      //   for (let i = 0; i < feats.length; i++) {
      //     feats[i].setStyle(this.mapService.returnCustomZoneStyle(false));
      //   }
      //   map.forEachFeatureAtPixel(event.pixel, (feature: any) => {
      //     if (feature && feature?.values_?.FeatureType == 'zone') {
      //       feature.setStyle(this.mapService.returnCustomZoneStyle(false,feature?.values_?.Name));
      //     }
      //   });
      // });

      map.once('postrender', () => {
        var geo = this.pinFeatures[0].getGeometry();
        var ext = geo?.getExtent();
        //console.log(ext)
        ext && mapView.fit(ext, { maxZoom: 12 });
      });
    } else {
      console.log('map failed to draw.');
    }
  }

  extractPinFeature(anyObject: any, featureType: string): Feature {
    let retFeat = new Feature();
    // console.log(featureType);
    // console.log(anyObject);
    switch (featureType) {
      case 'asset':
        retFeat = this.renderService.createIconStaffFeatureGPS(anyObject);
        break;
      case 'infra':
        console.log('ERROR - trying to plot an INFRA on World Map!');
        break;
      case 'device':
        retFeat = this.renderService.createIconDeviceFeatureGPS(anyObject);
        break;
      case 'event':
        retFeat = this.renderService.createIconEventFeatureGPS(anyObject);
        break;
      default:
        console.log('ERROR - feature type not recognized!');
        break;
    }
    return retFeat;
  }
}
