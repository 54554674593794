<p>{{ "COLUMNS.MAC" | translate }}: {{ deviceIdentifier.Name }}</p>

<div class="post-load" *ngIf="device != null">
  <p>{{ "COLUMNS.BATTERY" | translate }}: {{ device.BatteryLevel }}%</p>
  <p *ngIf="device.DateCreated">
    {{ "COLUMNS.DATE_CREATED" | translate }}: {{ device.DateCreated }}
  </p>
  <p *ngIf="device.DateUpdated">
    {{ "COLUMNS.DATE_UPDATED" | translate }}: {{ device.DateUpdated }}
  </p>

  <a
    *ngIf="device.ItemId && device.PersonAssociated"
    class="breadcrumb-link"
    (click)="
      navigateTo({
        PanelName: 'asset',
        Params: { Name: device.PersonAssociated, ID: device.ItemId }
      })
    "
    >{{ "COLUMNS.ASSOC_ASSET" | translate }}: {{ device.PersonAssociated }}</a
  >
</div>
