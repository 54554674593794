import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-tiles',
  templateUrl: './dashboard-tiles.component.html',
  styleUrls: ['./dashboard-tiles.component.scss'],
})
export class DashboardTilesComponent implements OnInit {
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() path: string = '';
  @Input() tooltip: string = '';
  @Input() tileUseString: 'useCase' | 'subApp' | 'disabled' | 'superUserOnly' | 'error' = 'error'
 
  constructor() { }
  ngOnInit(): void { }
}
