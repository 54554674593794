import { Injectable } from '@angular/core';
import { EnvService } from '@app/services/env.service';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  allEvents: any = [];
  currentEvent$ = new BehaviorSubject({});
  eventPing$ = new BehaviorSubject(null);
  newEvent$ = new BehaviorSubject(null);
  realEvents: any = [];
  subscribe: any = {
    LoginToken: '',
    CustomerId: '',
    UserId: '',
    Monitored: '',
    EmailList: '',
    EffectiveDate: '8/19/2020',
    MessageTypes: ['Events'],
    UseCase: '',
  };
  systemSettings: any;
  alertBell = new Howl({ src: ['assets/sounds/alert_bell_1.mp3'] });
  alertBellEnabled = false;
  alertInProgress: boolean = false;

  public ws1: any;
  public status: string = '';

  constructor(
    private customerService: CustomerService,
    private env: EnvService,
    private storage: StorageService
  ) {
    this.ws1 = new WebSocket(this.env.socketUrl);
    this.customerService.systemSetting$.subscribe((sets) => {
      this.systemSettings = sets;
    });
  }

  alertDelay() {
    this.alertInProgress = true;
    setTimeout(() => {
      this.alertInProgress = false;
    }, 1000);
  }

  bindOnMessage(onMessage: any) {
    this.ws1.onmessage = onMessage;
  }

  connect(onMessage: any) {
    this.ws1 = new WebSocket(this.env.socketUrl);
    const t = this;
    return new Promise((resolve, reject) => {
      this.ws1.onopen = function () {
        t.status = 'Connected';
        t.ws1.onclose = function () {
          t.status = 'Closed';
        };
        t.ws1.onmessage = onMessage;
        return resolve(t.status);
      };

      t.ws1.onerror = function () {
        t.status = 'Error';
        return reject(t.status);
      };
    });
  }

  disconnect() {
    this.ws1.send('DISCONNECT');
    this.ws1.close();
    this.status = 'Closed';
    this.allEvents = [];
    this.realEvents = [];
    this.subscribe = {
      LoginToken: '',
      CustomerId: '',
      UserId: '',
      Monitored: '',
      EmailList: '',
      EffectiveDate: '8/19/2020',
      MessageTypes: ['Events'],
    };
  }

  formatTime(date: string): string {
    if (date) {
      const formatDate = format(parseISO(date + 'Z'), 'MMM do h:mm aaa');
      const toNow = formatDistanceToNow(parseISO(date + 'Z'));
      return `${formatDate} (${toNow} ago)`;
    }
    return '';
  }

  getTimeAgo(date: string): string {
    const toNow = formatDistanceToNow(parseISO(date + 'Z'));
    return `(${toNow} ago)`;
  }

  getSocket() {
    const credentials = this.storage.get('uvs-credentials');
    const customerInfo = this.storage.get('company');
    const userSettings = credentials?.userObject?.UserSettings
      ? JSON.parse(credentials.userObject.UserSettings)
      : {};

    this.alertBellEnabled = !!userSettings.alertBell;

    this.subscribe.CustomerId = customerInfo.id;
    this.subscribe.Monitored = credentials['userObject'].UserDefine1;
    this.subscribe.EmailList = credentials['userObject'].UserDefine2;
    if (credentials['userObject'].LoginToken) {
      this.subscribe.LoginToken = credentials['userObject'].LoginToken;
      this.subscribe.UserId = credentials['userObject'].Id;
    }

    this.connect(this.onMessage)
      .then(() => {
        this.sendMessage('SUBSCRIBE ' + JSON.stringify(this.subscribe));
      })
      .catch(() => {});
  }

  getSocketWithoutLogin() {
    //MKL 12-5-2023 CoE Demo
    this.subscribe.CustomerId = 156;
    this.subscribe.LoginToken = 'U2FsdGVkX19GdglZjvYT3Bm4DMR1v6SgKVWE1GYVHF8=';
    this.subscribe.UserId = 1;

    this.connect(this.onMessage)
      .then(() => {
        this.sendMessage('SUBSCRIBE ' + JSON.stringify(this.subscribe));
      })
      .catch(() => {});
  }

  onMessage = (ev: MessageEvent) => {
    try {
      const a: any = JSON.parse(ev.data);
      const alertType: string = a.type;
      const eventdata: any = JSON.parse(JSON.stringify(a.data));

      if (
        alertType === 'event' &&
        eventdata.SystemName?.includes('ALERT') &&
        eventdata.CustomerId === this.customerService.customerId
      ) {
        // clear closed events
        if (eventdata.Status === 'Closed') {
          this.allEvents = this.allEvents.filter((event: any) => {
            return event.Id !== eventdata.Id;
          });
          this.realEvents = this.realEvents.filter((event: any) => {
            return event.Id !== eventdata.Id;
          });
        }

        if (eventdata.Status !== 'Closed') {
          // check for duplicates in websocket
          if (!this.allEvents.includes((ev: any) => ev.Id === eventdata.Id)) {
            this.allEvents.push(eventdata);
            const sorted = this.allEvents.sort((a: any, b: any) =>
              a.DateCreated > b.DateCreated ? -1 : 1
            );
            this.realEvents = [...sorted];

            //always make the current event the latest event after sorting.
            this.currentEvent$.next(this.realEvents[0]);

            if (!this.alertInProgress && this.alertBellEnabled) {
              this.alertBell.play();
              this.alertDelay();
            }

            this.newEvent$.next(eventdata);
          }
        }

        // used to update the ShellEvent Alert Number
        this.eventPing$.next(eventdata);
      }
    } catch (e) {
      console.log(e);
    }
  };

  sendMessage(msg: any) {
    if (this.status == 'Connected') {
      this.ws1.send(msg);
    } else {
      console.log('Socket not connected yet!');
    }
  }
}
