import { LazyElementModuleOptions } from '@angular-extensions/elements';

export const options: LazyElementModuleOptions = {
  elementConfigs: [
    {
      tag: 'assets-view',
      url: 'assets/custom-elements/assets/main.js',
    },
    {
      tag: 'company-info',
      url: 'assets/custom-elements/company-information/main.js',
    },
    {
      tag: 'debug-tool',
      url: 'assets/custom-elements/debug-tool/main.js',
    },
    {
      tag: 'devices-view',
      url: 'assets/custom-elements/devices/main.js',
    },
    {
      tag: 'events-view',
      url: 'assets/custom-elements/events/main.js',
    },
    {
      tag: 'firmware-view',
      url: 'assets/custom-elements/firmware/main.js',
    },
    {
      tag: 'infrastructures-view',
      url: 'assets/custom-elements/infrastructures/main.js',
    },
    {
      tag: 'map-editor',
      url: 'assets/custom-elements/map-editor/main.js',
    },
    {
      tag: 'map-realtime',
      url: 'assets/custom-elements/map-realtime/main.js',
    },
    // DEAD MKL
    // {
    //   tag: 'provisioning-view',
    //   url: 'assets/custom-elements/provisioning/main.js',
    // },
    {
      tag: 'reports-view',
      url: 'assets/custom-elements/reports/main.js',
    },
    {
      tag: 'route-manager',
      url: 'assets/custom-elements/route-manager/main.js',
    },
    {
      tag: 'rule-composer',
      url: 'assets/custom-elements/rule-composer/main.js',
    },
    {
      tag: 'server-status',
      url: 'assets/custom-elements/server-status/main.js',
    },
    {
      tag: 'sub-settings',
      url: 'assets/custom-elements/sub-settings/main.js',
    },
    {
      tag: 'system-settings',
      url: 'assets/custom-elements/system-settings/main.js',
    },
    {
      tag: 'users-view',
      url: 'assets/custom-elements/users/main.js',
    },
    {
      tag: 'flogo-view',
      url: 'assets/custom-elements/flogo/main.js',
    },
  ],
};
