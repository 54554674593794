import { environment as env } from 'environments/environment';

export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  //key gone mkl

  // server url
  public serverUrl: string = env.production ? '' : '';
  public statusServerUrl: string = env.production ? '' : '';

  // socket url
  public socketUrl: string = env.production ? '' : 'ws://52.45.17.177:2012'; // 52.45.17.177 or 3.212.201.170 or 34.231.222.92 or 54.205.53.143
  public cameraUrl: string = env.production ? '' : 'ws://192.168.1.26:8025'; // 52.45.17.177 or 3.212.201.170 or 34.231.222.92 or 54.205.53.143

  // contract manager mode
  public CMMode: boolean = false;

  // i18n multi lingual setup
  public international: boolean = true;
  public defaultLang: string = 'en';

  // Vision Reports Standalone App
  public visionReportsFlag: boolean = false;
  public visionReportsUsername: string = env.production ? '' : '';
  public visionReportsPassword: string = env.production ? '' : '';

  // mqtt vars
  public mqttHost: string = env.production ? '' : '52.45.17.177';
  public mqttPort: number = env.production ? 0 : 9001;
  public mqttPath: string = env.production ? '' : '/mqtt';
  public mqttProtocol: string = env.production ? '' : 'ws';
  public mqttTopicIn: string = 'XpertDebug';
  public mqttTopicOut: string = 'XpertDebugHeartbeat';

  // mqtt auth gone mkl

  // Whether or not to enable debug mode
  public enableDebug = true;

  // Whether or not to enable flows mode
  public enableFlows = false;

  constructor() {}
}
