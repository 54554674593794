import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from '@app/services/customer.service';
import { TranslateService } from '@ngx-translate/core';
import { add, formatDistanceStrict } from 'date-fns';

interface PopupData {
  expiringLicenses: any;
}

@Component({
  selector: 'app-expiration-warning',
  template: `
    <style>
      .days-left {
        color: #d0021b;
      }
      .license {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 75%;
      }
      .licenses {
        display: flex;
        flex-direction: column;
      }
      .name {
        font-weight: 500;
      }
      .warning {
        font-weight: 700;
        font-size: 1.5em;
        font-style: italic;
        margin: 0.5em 0 1em 0;
        text-align: center;
      }
    </style>
    <div class="warning">{{ 'MAIN.NAV.EXP.FOLLOWING_EXPIRE' | translate }}</div>
    <div class="licenses">
      <div class="license" *ngFor="let license of data.expiringLicenses">
        <span class="name">
          {{ getApplicationName(license.langKey) }}
        </span>
        <span class="days-left">{{ getDaysLeft(license.expiration) }}</span>
      </div>
    </div>
  `,
})
export class ExpirationWarningComponent implements OnInit {
  expiringLicenses: any = [];
  licenses: any = [];
  systemSetting: any;
  today: Date = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupData,
    public customerService: CustomerService,
    public dialogRef: MatDialogRef<ExpirationWarningComponent>,
    public translate: TranslateService
  ) {
    this.customerService.systemSetting$.subscribe((sets: any) => {
      this.systemSetting = sets;
    });
  }

  ngOnInit(): void {}

  getDaysLeft(date: string): string {
    const licenseGrace = this.systemSetting.LicenseGracePeriod
      ? this.systemSetting.LicenseGracePeriod
      : 90;

    const graceExpDate = add(new Date(date), { days: licenseGrace });

    if (graceExpDate <= this.today) {
      return this.translate.instant('MAIN.NAV.EXP.EXPIRED');
    }

    return `${formatDistanceStrict(
      graceExpDate,
      this.today
    )} ${this.translate.instant('MAIN.NAV.EXP.REMAINING')}`;
  }

  getApplicationName(langKey: string): string {
    return this.translate.instant(`MAIN.DASHBOARD.SUBS.${langKey}`);
  }
}
