import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  template: `
    <div class="wrapper">
      <div class="logo-wrapper">
        <img src="assets/images/AiRISTA-FlowLogo-White.svg" />
      </div>
      <h2 class="message">{{
        "MAIN.PAGE_NOT_FOUND.MESSAGE" | translate
      }}</h2>
      <button mat-stroked-button color="primary" (click)="goBack()">
      {{
        "MAIN.PAGE_NOT_FOUND.BUTTON" | translate
      }}
      </button>
    </div>
  `,
  styles: [
    `
      .logo-wrapper {
        align-items: center;
        background-color: #1565c0;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        height: 125px;
        justify-content: center;
        width: 250px;
      }
      .message {
        margin: 2em;
        text-align: center;
      }
      .wrapper {
        align-items: center;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        position: absolute;
        top: -50px;
        width: 100%;
        z-index: 1000;
      }
    `,
  ],
})
export class PageNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goBack() {
    history.back();
  }
}
