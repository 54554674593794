import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-routes',
  template: `
    <div
      [ngClass]="{
        wrapper: subscription === 'route-manager',
        wrapperSub: subscription !== 'route-manager'
      }"
    >
      <route-manager *axLazyElement></route-manager>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class RoutesComponent implements OnInit {
  subscription: string = '';
  constructor() {
    this.subscription = window.location.pathname.split('/')[1];
  }
  ngOnInit(): void {}
}
