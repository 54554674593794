import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VERSION } from '@app/core/constants';
import { AuthService } from '@app/services/auth.service';
import { StorageService } from '@app/services/storage.service';
import { EnvService } from '@app/services/env.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  loading: boolean = false;
  version: string = VERSION;
  username: string = '';

  constructor(
    private authService: AuthService,
    public env: EnvService,
    private fb: FormBuilder,
    private notifier: MatSnackBar,
    private router: Router,
    public storage: StorageService
  ) {
    this.forgotPasswordForm = this.fb.group({
      username: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  getCountryFlag(countryCode: string): string {
    const url: string = `assets/country-flags/${countryCode}.png`;
    return url;
  }

  redirectToLogin() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  submit() {
    this.loading = true;

    this.authService.forgetPassword(this.forgotPasswordForm.value).subscribe(
      () => {
        this.router.navigate(['/email-sent']);
      },
      () => {
        this.notifier.open(`Something went wrong.`, '', {
          panelClass: 'error',
        });
        this.loading = false;
      }
    );
  }
}
