import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-external-tile',
  templateUrl: './external-tile.component.html',
  styleUrls: ['./external-tile.component.scss'],
})
export class ExternalTileComponent implements OnInit {
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() externalLink: string = '';
  @Input() tooltip: string = '';
  @Input() isUseCase: boolean = false;
  @Input() isSuperUserOnly: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
