<div class="section">
  <div class="icon">
    <mat-icon [inline]="true">person_outline</mat-icon>
  </div>
  <div class="section-content">
    <span class="name">
      {{ storage.uvsCreds?.userObject.FirstName }}
      {{ storage.uvsCreds?.userObject.LastName }}
    </span>
    <span> {{ storage.uvsCreds?.userObject.RoleName }}</span>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="form-header">
    {{ "MAIN.NAV.MENU_COMPANY.SETTINGS" | translate }}
  </div>
  <div class="warning">
    {{ "MAIN.NAV.MENU_COMPANY.CHANGE_LOGOUT" | translate }}
  </div>
  <form class="form-container" [formGroup]="accountSettingsForm">
    <mat-form-field>
      <mat-label>{{ "COLUMNS.USERNAME" | translate }}</mat-label>
      <input matInput formControlName="username" />
      <mat-error *ngIf="username?.invalid">
        {{ "MAIN.NAV.MENU_COMPANY.USERNAME_MUST_END_WITH" | translate }} @{{
          this.domain
        }}
        <span *ngIf="domainTwo && domainTwo !== domain">
          / {{ this.domainTwo }}
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "COLUMNS.FIRST_NAME" | translate }}</mat-label>
      <input matInput placeholder="John" formControlName="firstName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "COLUMNS.LAST_NAME" | translate }}</mat-label>
      <input matInput placeholder="Doe" formControlName="lastName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "COLUMNS.PHONE_NUMBER" | translate }}</mat-label>
      <input matInput placeholder="111-222-1234" formControlName="phone" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "COLUMNS.EMAIL_ADDRESS" | translate }}</mat-label>
      <input matInput placeholder="test@test.com" formControlName="email" />
      <mat-error *ngIf="email?.invalid"
        >{{ "USERS.CREATE.EMAIL_ERROR" | translate }}
      </mat-error>
    </mat-form-field>
  </form>

  <div
    *ngIf="!changeSettings"
    class="change-pass"
    (click)="changeSettings = true"
  >
    {{ "MAIN.NAV.MENU_COMPANY.CHANGE_USER_SETTINGS" | translate }}
  </div>

  <form
    *ngIf="changeSettings"
    class="form-container"
    [formGroup]="userSettingsForm"
  >
    <mat-form-field>
      <mat-label>{{
        "MAIN.NAV.MENU_COMPANY.DEFAULT_LANG" | translate
      }}</mat-label>
      <mat-select formControlName="defaultLanguage">
        <mat-option *ngFor="let lang of storage.languages" [value]="lang.value">
          {{ "MAIN.LANGUAGES." + lang.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="customerService.getPermission('Settings', 'view')">
      <mat-label>{{
        "MAIN.NAV.MENU_COMPANY.DEFAULT_APP" | translate
      }}</mat-label>
      <mat-select formControlName="defaultUseCase">
        <mat-option [value]="">None</mat-option>
        <mat-option *ngFor="let useCase of useCases" [value]="useCase.value">
          {{ useCase.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="customerService.getPermission('Settings', 'view')">
      <mat-label>
        {{ "MAIN.NAV.MENU_COMPANY.DEFAULT_TAB" | translate }}
      </mat-label>
      <mat-select formControlName="defaultTab">
        <mat-option [value]="">None</mat-option>
        <mat-option *ngFor="let tab of tabs" [value]="tab.path">
          {{ tab.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      *ngIf="customerService.getPermission('LogoutTimers', 'edit')"
    >
      <mat-label> Logout Timer (minutes)</mat-label>
      <input matInput type="number" min="5" formControlName="logoutTimer" />
      <mat-error *ngIf="logoutTimer?.errors?.min">
        {{ "MAIN.NAV.MENU_COMPANY.LOGOUT_TIMER_MIN" | translate }}</mat-error
      >
    </mat-form-field>

    <div class="checkbox-container">
      <mat-checkbox
        class="checkbox"
        color="primary"
        formControlName="alertBell"
      >
        {{ "MAIN.NAV.MENU_COMPANY.ALERT_BELL_ENABLED" | translate }}
      </mat-checkbox>
    </div>
  </form>

  <div class="form-buttons" mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      [disabled]="accountSettingsForm.invalid"
      (click)="onSubmit()"
    >
      <mat-spinner
        class="spinner"
        color="accent"
        diameter="30"
        *ngIf="loading"
      ></mat-spinner>
      <span *ngIf="!loading">{{ "FORMS.SUBMIT" | translate }}</span>
    </button>
    <button mat-stroked-button mat-dialog-close>
      {{ "FORMS.CANCEL" | translate }}
    </button>
  </div>
</div>
