import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Breadcrumb, BreadcrumbData, Device } from '@app/core/interfaces';
import { AssetsService } from '@app/services/assets.service';

@Component({
  selector: 'app-breadcrumb-device',
  templateUrl: './breadcrumb-device.component.html',
  styleUrls: ['./breadcrumb-device.component.scss']
})
export class BreadcrumbDeviceComponent implements OnChanges {

  @Input() deviceIdentifier!: BreadcrumbData;
  device: Device | null = null;

  @Output() public navigateEvent = new EventEmitter<Breadcrumb>();

  loading: boolean = true;

  constructor(public assetService: AssetsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.assetService.initializeService(); //re-initialize?
    this.getDeviceData();
  }

  navigateTo(bc: Breadcrumb) {
    this.navigateEvent.emit(bc);
  }

  getDeviceData() {
    this.assetService.getDeviceById(this.deviceIdentifier.ID).subscribe((res:any)=>{
      this.device = res;
    })
  }
}
