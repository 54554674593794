<mat-drawer-container
  [ngStyle]="{
    top: licensesExpiring ? '85px' : '50px'
  }"
  class="container"
>
  <mat-drawer
    [ngClass]="collapsed ? 'sidenav-small' : 'sidenav-big'"
    mode="side"
    opened
  >
    <div *ngIf="!collapsed">
      <div (click)="toggleSideBar()" class="arrow-back">
        <mat-icon class="arrow-l">arrow_back_ios</mat-icon>
      </div>

      <p class="section heading">{{ getApplicationTitle() }}</p>

      <a
        *ngIf="customerService.getPermission(setRouteManagementKey(), 'view')"
        class="section"
        routerLink="home"
        routerLinkActive="active-link"
      >
        <mat-icon>home</mat-icon>
        <p class="label">{{ "MAIN.NAV.DASHBOARD" | translate }}</p>
      </a>

      <a
        *ngIf="customerService.getPermission('Staff', 'view')"
        class="section"
        routerLink="assets"
        routerLinkActive="active-link"
      >
        <mat-icon>person</mat-icon>
        <p class="label">
          {{ GET_NAME_OF_ASSET(this.selectedSub, true) | translate }}
        </p>
      </a>

      <a
        *ngIf="customerService.getPermission('Devices', 'view')"
        class="section"
        routerLink="devices"
        routerLinkActive="active-link"
      >
        <mat-icon>wifi</mat-icon>
        <p class="label">{{ "MAIN.DASHBOARD.DEVICES" | translate }}</p>
      </a>

      <a
        *ngIf="customerService.getPermission('Events', 'view')"
        class="section"
        routerLink="events"
        routerLinkActive="active-link"
      >
        <mat-icon>notifications_none</mat-icon>
        <p class="label">{{ "MAIN.NAV.SHELL.EVENTS" | translate }}</p>
        <span *ngIf="eventCount > 0" class="alert-count">{{ eventCount }}</span>
      </a>

      <a
        *ngIf="
          customerService.getPermission(setRouteManagementKey(), 'view') &&
          selectedSub != 'temperature-monitoring' &&
          selectedSub != 'melt'
        "
        class="section"
        routerLink="dashboard"
        routerLinkActive="active-link"
      >
        <mat-icon>pie_chart</mat-icon>
        <p class="label">{{ "MAIN.NAV.SHELL.ALERT_METRICS" | translate }}</p>
      </a>

      <a
        *ngIf="customerService.getPermission('Reports', 'view')"
        class="section"
        [routerLink]="routeForReports()"
        routerLinkActive="active-link"
      >
        <mat-icon>insert_chart_outlined</mat-icon>
        <p class="label">{{ "MAIN.NAV.SHELL.REPORTS" | translate }}</p>
      </a>

      <a
        *ngIf="customerService.getPermission('Maps', 'view')"
        class="section"
        routerLink="sub-map"
        routerLinkActive="active-link"
      >
        <mat-icon>map</mat-icon>
        <p class="label">{{ "MAIN.DASHBOARD.MAP-REALTIME" | translate }}</p>
      </a>

      <a
        *ngIf="
          customerService.subscriptions.routeManagement &&
          customerService.subscriptions.routeManagement[setRouteManagementKey()]
        "
        class="section"
        routerLink="route-manager"
        routerLinkActive="active-link"
      >
        <mat-icon>alt_route</mat-icon>
        <p class="label">{{ "MAIN.NAV.SHELL.ROUTES" | translate }}</p>
      </a>

      <a
        *ngIf="customerService.getPermission('Rules', 'view')"
        class="section"
        routerLink="sub-rule"
        routerLinkActive="active-link"
      >
        <mat-icon>rule</mat-icon>
        <p class="label">{{ "MAIN.DASHBOARD.RULES" | translate }}</p>
      </a>

      <a
        *ngIf="customerService.getPermission('Users', 'view')"
        class="section"
        routerLink="sub-users"
        routerLinkActive="active-link"
      >
        <mat-icon>people</mat-icon>
        <p class="label">{{ "MAIN.DASHBOARD.USERS" | translate }}</p>
      </a>

      <a
        *ngIf="
          customerService.getPermission('Settings', 'view') &&
          (this.selectedSub === 'asset-tracking' ||
            this.selectedSub === 'hand-hygiene' ||
            this.selectedSub === 'sdct' ||
            this.selectedSub === 'melt' ||
            this.selectedSub === 'temperature-monitoring' ||
            this.selectedSub === 'bed-management' ||
            this.selectedSub === 'patient-flow')
        "
        class="section"
        routerLink="settings"
        routerLinkActive="active-link"
      >
        <mat-icon>settings</mat-icon>
        <p class="label">{{ "MAIN.DASHBOARD.SETTINGS" | translate }}</p>
      </a>
    </div>

    <div *ngIf="collapsed">
      <div (click)="toggleSideBar()" class="arrow-forward">
        <mat-icon class="arrow-r">arrow_forward_ios</mat-icon>
      </div>
      <p class="section heading"></p>
      <a
        *ngIf="customerService.getPermission(setRouteManagementKey(), 'view')"
        class="section"
        routerLink="home"
        routerLinkActive="active-link"
      >
        <mat-icon>home</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Staff', 'view')"
        class="section"
        routerLink="assets"
        routerLinkActive="active-link"
      >
        <mat-icon>person</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Devices', 'view')"
        class="section"
        routerLink="devices"
        routerLinkActive="active-link"
      >
        <mat-icon>wifi</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Events', 'view')"
        class="section"
        routerLink="events"
        routerLinkActive="active-link"
      >
        <mat-icon>notifications_none</mat-icon>
        <span *ngIf="eventCount > 0" class="alert-count">{{ eventCount }}</span>
      </a>

      <a
        *ngIf="customerService.getPermission('Settings', 'view')"
        class="section"
        routerLink="dashboard"
        routerLinkActive="active-link"
      >
        <mat-icon>pie_chart</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Reports', 'view')"
        class="section"
        routerLink="reports"
        routerLinkActive="active-link"
      >
        <mat-icon>insert_chart_outlined</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Maps', 'view')"
        class="section"
        routerLink="sub-map"
        routerLinkActive="active-link"
      >
        <mat-icon>map</mat-icon>
      </a>

      <a
        *ngIf="
          customerService.subscriptions.routeManagement &&
          customerService.subscriptions.routeManagement[setRouteManagementKey()]
        "
        class="section"
        routerLink="route-manager"
        routerLinkActive="active-link"
      >
        <mat-icon>alt_route</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Rules', 'view')"
        class="section"
        routerLink="sub-rule"
        routerLinkActive="active-link"
      >
        <mat-icon>rule</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Staff', 'view')"
        class="section"
        routerLink="sub-users"
        routerLinkActive="active-link"
      >
        <mat-icon>people</mat-icon>
      </a>

      <a
        *ngIf="customerService.getPermission('Settings', 'view')"
        class="section"
        routerLink="settings"
        routerLinkActive="active-link"
      >
        <mat-icon>settings</mat-icon>
      </a>
    </div>
  </mat-drawer>
  <router-outlet></router-outlet>
</mat-drawer-container>
