import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Breadcrumb } from '@app/core/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shared-breadcrumb-frame',
  template: `
    <h1 mat-dialog-title>{{ 'COLUMNS.DETAILS' | translate }}</h1>
    <div *ngIf="loaded">
      <div class="trail">
        <span
          class="crumb"
          *ngFor="let event of history; let i = index"
          (click)="rewindTo(i)"
        >
          <span>{{ getBreadcrumbName(i) }}</span>
          <span *ngIf="i != history.length - 1"> > </span>
        </span>
      </div>
      <hr />
      <div class="outlet">
        <app-breadcrumb-asset
          *ngIf="history[history.length - 1].PanelName == 'asset'"
          [assetIdentifier]="history[history.length - 1].Params"
          (navigateEvent)="navigateTo($event)"
        ></app-breadcrumb-asset>

        <app-breadcrumb-device
          *ngIf="history[history.length - 1].PanelName == 'device'"
          [deviceIdentifier]="history[history.length - 1].Params"
          (navigateEvent)="navigateTo($event)"
        ></app-breadcrumb-device>

        <app-breadcrumb-group
          *ngIf="history[history.length - 1].PanelName == 'group'"
          [groupIdentifier]="history[history.length - 1].Params"
          (navigateEvent)="navigateTo($event)"
        ></app-breadcrumb-group>

        <app-breadcrumb-dept
          *ngIf="history[history.length - 1].PanelName == 'department'"
          [deptIdentifier]="history[history.length - 1].Params"
          (navigateEvent)="navigateTo($event)"
        ></app-breadcrumb-dept>
      </div>
    </div>
  `,
  styles: [
    `
      * {
        box-sizing: border-box;
        font-family: 'Segoe UI', sans-serif;
      }
      hr {
        background-color: 1px solid #e0e0e0;
        border: none;
        height: 1px;
      }
      .mat-dialog-content {
        max-height: initial;
      }
      .trail {
      }
      .crumb {
        color: #1565c0;
        font-weight: bold;
        cursor: pointer;
      }
      .outlet {
        border: 1px solid #e0e0e0;
        padding: 1em;
      }
    `,
  ],
})
export class SharedBreadcrumbFrameComponent implements OnInit {
  history: Breadcrumb[] = [];
  loaded: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Breadcrumb,
    public translate: TranslateService
  ) {
    this.history.push(data);
    this.loaded = true;
  }

  ngOnInit(): void {}

  //adds a history node, goes 1 deeper
  navigateTo(event: Breadcrumb) {
    this.history.push(event);
  }

  //off by one error thing?
  rewindTo(index: number) {
    this.history = this.history.slice(0, index + 1);
  }

  getBreadcrumbName(index: number): string {
    if (this.history[index]?.Params?.Name) {
      return this.history[index].Params.Name;
    } else {
      return 'Could not find asset';
    }
  }
}
