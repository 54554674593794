import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {
  format,
  parseISO,
  formatDistance,
  add,
  differenceInMinutes,
  sub,
  Duration,
} from 'date-fns';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Asset,
  Group,
  AnimationFrame,
  Department,
  Infrastructure,
  Status,
  Event,
  User,
  Checkpoint,
  CheckTimeFrame,
} from '@app/core/interfaces';
import { FULL_PERMISSIONS } from '../../../../src/assets/PERMISSIONS';
import {
  AssetAssociationType,
  PermissionAction,
  PermissionResource,
  MaxRecordReturn,
  GetUseCaseIDFromSubscriptionName,
} from '@app/core/constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  allAssets$ = new BehaviorSubject([]);
  asset$ = new BehaviorSubject([]);
  companyInfo: any = {};
  customerId: number = 0;
  dept$ = new BehaviorSubject([]);
  group$ = new BehaviorSubject([]);
  statuses$ = new BehaviorSubject([]);
  licenses$ = new BehaviorSubject({});
  siteHierarchy$ = new BehaviorSubject([]); // for fill bed
  allGroup$ = new BehaviorSubject([]);
  loading: boolean = false;
  pageAll = {
    limit: 15,
    count: 0,
    SortField: 'Name',
    IsDescending: false,
    SearchText: '',
    pageNumber: 1,
  };
  page = {
    limit: 20,
    count: 0,
    SortField: 'Name', //these need to correspond to the enums on API
    IsDescending: false,
    SearchText: '',
    pageNumber: 1,
  };
  pageDepts = {
    count: 0,
    SearchText: '',
  };
  pageGroups = {
    count: 0,
    SearchText: '',
  };
  pageStatuses = {
    count: 0,
    SearchText: '',
  };

  selectedSub: string = '';
  settings$ = new BehaviorSubject([]);
  camera$ = new BehaviorSubject<Infrastructure[]>([]);
  subscriptions$ = new BehaviorSubject({});
  gpsEnabled: boolean = false;

  useCaseSettings: any;

  permissions: any[] = []; //4-26-2023
  isSuperUser: boolean = false;

  scrHeight: number = window.innerHeight;
  scrWidth: number = window.innerWidth;
  localOffsetInMinutes = new Date().getTimezoneOffset();
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    return this.scrHeight < 750 || this.scrWidth < 500;
  }
  constructor(
    private http: HttpClient,
    private notifier: MatSnackBar,
    private translate: TranslateService
  ) {}

  initializeService() {
    this.loading = true;

    // ==> for production
    let uvsC = JSON.parse(<string>sessionStorage.getItem('uvs-credentials'));
    let customerData = sessionStorage.getItem('company');
    this.customerId = customerData
      ? JSON.parse(customerData).id || JSON.parse(customerData).company
      : undefined;
    this.isSuperUser = uvsC?.userObject?.IsSuperUser
      ? uvsC.userObject.IsSuperUser
      : false;
    this.permissions = uvsC?.userObject?.Permissions
      ? uvsC.userObject.Permissions
      : [];

    // // // ==> for development
    // this.customerId = 1;
    // this.selectedSub = 'asset-tracking'; //bed-management //temperature-monitoring
    // this.permissions = FULL_PERMISSIONS;
    // // this.isSuperUser = true;

    forkJoin([
      this.getCompanyInfo(),
      this.getSubAppSettings(), //needed for every use case eventually
    ]).subscribe(
      ([companyData, settings]) => {
        //console.log(settings); //MKL 3/28/2024
        this.companyInfo = companyData;
        try {
          // empty settings if can't get them.
          this.useCaseSettings = JSON.parse(settings.SettingJson);
        } catch {
          this.useCaseSettings = {};
        }
        this.refreshData();
      },
      () => {
        this.loading = false;
        this.notifier.open(this.translate.instant('NOTIFIER.ERROR_GET'), '', {
          panelClass: 'error',
        });
      }
    );
  }

  //MKL changed these to TIERS on 5-9-2024
  getPermission(
    permissionType: PermissionResource,
    permissionAction: PermissionAction
  ): boolean {
    if (this.isSuperUser) return true;
    const p = this.permissions.find(
      (permission: any) => permission.Name == permissionType
    );
    if (p) {
      switch (permissionAction) {
        case 'view': //4
          return p.EnableView || p.EnableAdd || p.EnableEdit || p.EnableDelete;
        case 'add': //3
          return p.EnableAdd || p.EnableEdit || p.EnableDelete;
        case 'edit': //2
          return p.EnableEdit || p.EnableDelete;
        case 'delete': //1
          return p.EnableDelete;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  addAssetUseCase(assetId: number) {
    return this.http.post(
      `/api/Staff/AddItemUseCase?ItemId=${assetId}&UseCase=${this.setUseCase()}` +
        `&CustomerId=${this.customerId}`,
      {}
    );
  }

  addGroupUseCase(groupId: number) {
    return this.http.post(
      `/api/MetaData/AddGroupUseCase?GroupId=${groupId}&UseCase=${this.setUseCase()}` +
        `&CustomerId=${this.customerId}`,
      {}
    );
  }

  changeAlert(assetId: number, enableAlertStatus: boolean) {
    return this.http.post(
      `/api/Staff/UpdateStaff?StaffId=${assetId}&EnableAlerts=${enableAlertStatus}` +
        `&CustomerId=${this.customerId}`,
      {}
    );
  }

  changeDepartment(staffId: number, deptId: number) {
    return this.http.post(
      `/api/Staff/ChangeDepartment?StaffId=${staffId}&DepartmentId=${deptId}` +
        `&CustomerId=${this.customerId}`,
      {}
    );
  }

  createAsset(form: any) {
    //no MACS
    return this.http.post(
      `/api/Staff/CreateStaff?CustomerId=${this.customerId}` +
        `&StaffId=${form.staffId}` +
        `&Name=${form.name}` +
        (form.group ? '&Group=' + form.group : '') +
        (form.department ? '&Department=' + form.department : '') +
        `&EnableAlerts=${form.enableAlerts}` +
        (form.email ? '&Email=' + form.email : '') +
        (form.phone ? '&PhoneNumber=' + form.phone.trim() : '') +
        `&UseCase=${this.setUseCase()}`,
      {}
    );
  }

  //an UPSERT method MKL 12-8-2023
  createAssetWithImage(form: any, imageData: any, oldPortraitString: string) {
    var asset = {
      Name: form.name,
      GroupName: form.group,
      EnableAlerts: form.enabledAlerts,
      DepartmentName: form.department,
      UserDefinedAttributes: form.UserDefinedAttributes,
      CustomerId: this.customerId,
      StaffID: form.staffId,
      Email: form.email,
      PhoneNumber: form.phone.trim(),
      ImageType: imageData.FileType,
      ImageData: imageData.ImageData,
      Portrait: oldPortraitString,
    };
    return this.http.post(
      `/api/Staff/CreateStaffWithImage?UseCase=${this.setUseCase()}&CustomerId=${
        this.customerId
      }`,
      asset
    );
  }

  createAssetFromFile(form: any): Observable<any> {
    return this.http.post(
      `/api/Staff/CreateStaff?CustomerId=${this.customerId}` +
        `&StaffId=${form.StaffId}` +
        `&Name=${form.Name}` +
        (form.Group ? '&Group=' + form.Group : '') +
        (form.Department ? '&Department=' + form.Department : '') +
        `&EnableAlerts=${form.enableAlerts}` +
        (form.Email ? '&Email=' + form.Email : '') +
        (form.PhoneNumber ? '&PhoneNumber=' + form.PhoneNumber.trim() : '') +
        `&UseCase=${this.setUseCase()}`,
      {}
    );
  }

  assignAssetFromARCdata(StaffMember: any) {
    let assetID = '';
    if (StaffMember.assetid) {
      assetID = StaffMember.assetid;
    } else {
      assetID = StaffMember.id;
    }
    let department = '';
    if (StaffMember.department) {
      department = '&Department=' + encodeURIComponent(StaffMember.department);
    }
    return this.http.post(
      `/api/Staff/CreateStaff?CustomerId=${this.customerId}` +
        `&StaffId=${assetID}` +
        `&Name=${encodeURIComponent(StaffMember.name)}` +
        '&Group=' +
        encodeURIComponent(StaffMember.group) +
        department +
        `&EnableAlerts=true` +
        `&UseCase=${this.setUseCase()}`,
      {}
    );
  }

  //MKL 3-13-2024
  //http://52.45.17.177:802/XpertRestApi/api/MetaData/AssignUserResponsibilitiesToGroup?GroupId=1&CheckTimeFrame=12%3A00%2C13%3A00&CustomerId=1
  assignUserResponsibilitesToGroup(
    groupId: number,
    timeframe: string,
    userArray: number[]
  ): Observable<any> {
    const body: any = {
      UsersResponsible: userArray,
      Times: timeframe,
    };
    this.loading = true; // set to false where called
    return this.http.post(
      `/api/MetaData/AssignUserResponsibilitiesToGroup?GroupId=${groupId}&CustomerId=${this.customerId}`,
      body
    );
  }

  createTemperatureCheckpoint(
    groupId: number,
    comments: string,
    violationAcknowledged: boolean = false
  ): Observable<any> {
    // update api to be able to receive 'comments' in the body
    return this.http.post(
      `/api/MetaData/CreateTemperatureCheckpoint?GroupId=${groupId}&Note=${comments}` +
        `&ViolationAcknowledged=${violationAcknowledged}&CustomerId=${this.customerId}`,
      {}
    );
  }

  createDepartment(Name: string) {
    const deptName = encodeURIComponent(Name);
    return this.http.post(
      `/api/MetaData/CreateDepartment?Name=${deptName}&CustomerId=${this.customerId}`,
      {}
    );
  }

  createGroup(groupObject: any) {
    return this.http.post(
      `/api/MetaData/CreateGroupAdvance?UseCase=${this.setUseCase()}`,
      groupObject
    );
  }

  editGroup(groupObject: Group) {
    return this.http.post(
      `/api/MetaData/EditGroup?CustomerId=${this.customerId}`,
      groupObject
    );
  }

  createIcon(icon: any) {
    return this.http.post(
      `/api/MetaData/CreateIcon?CustomerId=${this.customerId}`,
      icon
    );
  }

  createItemAssociation(
    itemId: number,
    assocItemId: number,
    associationType: AssetAssociationType
  ) {
    return this.http.post(
      `/api/Staff/CreateItemAssociation?CustomerId=${this.customerId}&AssociationType=${associationType}&ItemID=${itemId}&AssociatedItemID=${assocItemId}`,
      {}
    );
  }

  createStatus(statusDetails: any) {
    statusDetails.UseCaseID = GetUseCaseIDFromSubscriptionName(
      this.selectedSub
    );
    return this.http.post(
      `/api/MetaData/CreateStatus?CustomerId=${this.customerId}`,
      statusDetails
    );
  }

  changeItemAssociation(
    itemId: number,
    assocItemId: number,
    associationType: AssetAssociationType
  ) {
    return this.http.post(
      `/api/Staff/ChangeItemAssociation?CustomerId=${this.customerId}&AssociationType=${associationType}&ItemID=${itemId}&AssociatedItemID=${assocItemId}`,
      {}
    );
  }

  endItemAssociation(itemId: number, assocItemId: number) {
    return this.http.post(
      `/api/Staff/EndItemAssociation?CustomerId=${this.customerId}&ItemID=${itemId}&AssociatedItemID=${assocItemId}`,
      {}
    );
  }

  deleteAsset(id: number) {
    return this.http.delete(
      `/api/Staff/DeleteStaff?StaffId=${id}&CustomerId=${this.customerId}`
    );
  }

  deleteDepartment(Id: any) {
    return this.http.delete(
      `/api/MetaData/DeleteDepartment?DepartmentId=${Id}&CustomerId=${this.customerId}`,
      {}
    );
  }

  deleteGroup(Id: any) {
    return this.http.delete(
      `/api/MetaData/DeleteGroup?GroupId=${Id}&CustomerId=${this.customerId}`,
      {}
    );
  }

  deleteStatus(Id: any) {
    return this.http.delete(
      `/api/MetaData/DeleteStatus?StatusId=${Id}&CustomerId=${this.customerId}`,
      {}
    );
  }

  editStatus(statusDetails: any) {
    statusDetails.UseCaseID = GetUseCaseIDFromSubscriptionName(
      this.selectedSub
    );
    return this.http.post(
      `/api/MetaData/UpdateStatus?CustomerId=${this.customerId}`,
      statusDetails
    );
  }

  enableAlertsForAll(enable: boolean) {
    return this.http.post(
      `/api/Staff/UpdateAllStaffEnableAlerts?EnableAlerts=${enable}&CustomerId=${this.customerId}`,
      {}
    );
  }

  getAllAssets() {
    return this.http
      .get(
        `/api/Staff/GetAll?PageNumber=${this.pageAll.pageNumber}` +
          `&NumberOfRecords=${this.pageAll.limit}` +
          `&SearchText=${this.pageAll.SearchText}` +
          `&SortField=${this.pageAll.SortField.replace(' ', '')}` + //Replacing spaced column names with SentenceCase titles for API
          `&IsDescending=${this.pageAll.IsDescending}` +
          `&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          this.pageAll.count = data.TotalRecordsCount;
          const assets = data.List.map((asset: any) => {
            const newAsset: Asset = {
              Id: asset.Id,
              AlertStatus: asset.AlertStatus,
              AssociatedDevices: asset.AssociatedDevices,
              BedStatus: asset.BedStatus || '',
              StatusLabel: this.setStatusLabel(asset.AlertStatus),
              Name: asset.Name,
              DisplayName: asset.DisplayName,
              Email: asset.Email,
              EnableAlerts: asset.EnableAlerts,
              EnableSDCT: asset.EnableSDCT,
              EnableHygiene: asset.EnableHygiene,
              DepartmentName: asset.DepartmentName,
              DepartmentID: asset.DepartmentID || 0,
              AssocItemName: asset.AssocItemName,
              AssocItemID: asset.AssocItemID,
              PendingDepartmentName: asset.PendingDepartmentName,
              PendingDepartmentId: asset.PendingDepartmentId,
              DeviceID: asset.DeviceID,
              DeviceName: asset.DeviceName,
              Temperature: Number(asset.Temperature),
              GroupName: asset.GroupName,
              GroupID: asset.GroupID,
              BuildingName: asset.CurrentBuildingName,
              SiteName: asset.CurrentSiteName,
              FloorName: asset.CurrentFloorName,
              Latitude: asset.Latitude || 0,
              Longitude: asset.Longitude || 0,
              Address: asset.Address,
              MapId: asset.CurrentModelId,
              CurrentZones: asset.CurrentZones,
              X: asset.CurrentX,
              Y: asset.CurrentY,
              LocationUpdated: asset.LocationUpdated
                ? format(
                    parseISO(asset.LocationUpdated + 'Z'),
                    'MM/dd/yy h:mm aaa'
                  )
                : '',
              ModelName: asset.ModelName,
              PhoneNumber: asset.PhoneNumber,
              StaffID: asset.StaffID,
              Icon: asset.Icon,
              Portrait: asset.Portrait,
              UserDefinedAttributes: asset.UserDefinedAttributes,
              //UserDefinedStatuses: asset.UserDefinedStatuses,
              UserDefinedStatus: asset.UserDefinedStatus,
              UserDefinedStatusIcon: asset.UserDefinedStatusIcon,
            };

            //change Current Zones from stringifiedJson to array
            try {
              if (newAsset.CurrentZones) {
                newAsset.CurrentZones = JSON.parse(newAsset.CurrentZones);
              }
            } catch (err) {
              newAsset.CurrentZones = [];
            }

            return newAsset;
          });
          this.allAssets$.next(assets);
          return assets;
        })
      );
  }

  //BAD NUMBER OF RECORDS
  getAllAssetsUnPaged() {
    return this.http
      .get(
        `/api/Staff/GetAll?` +
          `NumberOfRecords=${MaxRecordReturn}` +
          `&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          this.pageAll.count = data.TotalRecordsCount;
          const assets = data.List.map((asset: any) => {
            const newAsset: Asset = {
              Id: asset.Id,
              AlertStatus: asset.AlertStatus,
              AssociatedDevices: asset.AssociatedDevices,
              BedStatus: asset.BedStatus || '',
              StatusLabel: this.setStatusLabel(asset.AlertStatus),
              Name: asset.Name,
              DisplayName: asset.DisplayName,
              Email: asset.Email,
              EnableAlerts: asset.EnableAlerts,
              EnableSDCT: asset.EnableSDCT,
              EnableHygiene: asset.EnableHygiene,
              DepartmentName: asset.DepartmentName,
              DepartmentID: asset.DepartmentID || 0,
              AssocItemName: asset.AssocItemName,
              AssocItemID: asset.AssocItemID,
              PendingDepartmentName: asset.PendingDepartmentName,
              PendingDepartmentId: asset.PendingDepartmentId,
              DeviceID: asset.DeviceID,
              DeviceName: asset.DeviceName,
              Temperature: Number(asset.Temperature) || 'N/A',
              GroupName: asset.GroupName,
              GroupID: asset.GroupID,
              BuildingName: asset.CurrentBuildingName,
              SiteName: asset.CurrentSiteName,
              FloorName: asset.CurrentFloorName,
              MapId: asset.CurrentModelId,
              CurrentZones: asset.CurrentZones,
              Latitude: asset.Latitude || 0,
              Longitude: asset.Longitude || 0,
              Address: asset.Address,
              X: asset.CurrentX,
              Y: asset.CurrentY,
              LocationUpdated: asset.LocationUpdated
                ? format(
                    parseISO(asset.LocationUpdated + 'Z'),
                    'MM/dd/yy h:mm aaa'
                  )
                : '',
              ModelName: asset.ModelName,
              PhoneNumber: asset.PhoneNumber,
              StaffID: asset.StaffID,
              Icon: asset.Icon,
              Portrait: asset.Portrait,
              UserDefinedAttributes: asset.UserDefinedAttributes,
              //UserDefinedStatuses: asset.UserDefinedStatuses,
              UserDefinedStatus: asset.UserDefinedStatus,
              UserDefinedStatusIcon: asset.UserDefinedStatusIcon,
            };

            //change Current Zones
            try {
              if (newAsset.CurrentZones) {
                newAsset.CurrentZones = JSON.parse(newAsset.CurrentZones) || [];
              }
            } catch (err) {
              newAsset.CurrentZones = [];
            }

            return newAsset;
          });
          return assets;
        })
      );
  }

  getAllBeds() {
    return this.http.get<any>(
      `/api/Staff/GetAll?CustomerId=${this.customerId}&PageNumber=1&NumberOfRecords=500&IsDescending=true&UseCase=BedManagement`
    );
  }

  getAllCameras() {
    return this.http
      .get(
        `/api/Infrastructure/GetAll?CustomerId=${this.customerId}&NumberOfRecords=${MaxRecordReturn}&SearchText=Camera`
      )
      .pipe(
        map((data: any) => {
          const cams = data.List.map((el: any) => {
            const infrastructure: Infrastructure = {
              Id: el.Id,
              DeviceTableId: el.DeviceTableId,
              UniqueId: el.UniqueId,
              BatteryLevel: el.BatteryLevel,
              ConfigId: el.ConfigId,
              PendingConfigId: el.PendingConfigId,
              Type: el.Type,
              GroupId: el.GroupId,
              FirmwareVersion: el.FirmwareVersion,
              DeviceType: el.DeviceType,
              IsEnabled: el.IsEnabled,
              IsMonitored: el.IsMonitored,
              Name: el.Name,
              DateUpdated: el.DateUpdated
                ? format(
                    parseISO(el.DateUpdated + 'Z'),
                    'MMM do, yyyy h:mm aaa'
                  )
                : '',
              Status: el.Status,
              SiteId: el.SiteId,
              BuildingId: el.BuildingId,
              FloorId: el.ModelId,
              MapId: el.MapId,
              X: el.X,
              Y: el.Y,
              SiteName: el.SiteName,
              BuildingName: el.BuildingName,
              MapName: el.MapName,
              ZoneName: el.ZoneName,
              ZoneId: el.ZoneID,
              Port: el.Port,
            };
            return infrastructure;
          });
          this.camera$.next(cams);
          return cams;
        })
      );
  }

  getAllByDeptId(id: number) {
    return this.http.get(
      `/api/Staff/GetAllByDepartment?CustomerId=${this.customerId}&DepartmentId=${id}&PageNumber=1&NumberOfRecords=99`
    );
  }

  getAllByGroupId(id: number): Observable<any> {
    return this.http.get(
      `/api/Staff/GetAllByGroup?CustomerId=${this.customerId}&GroupId=${id}&PageNumber=1&NumberOfRecords=99`
    );
  }

  getAvailableBeds(deptId: number = 0) {
    if (deptId != 0) {
      return this.http.get<any>(
        `/api/Staff/GetAllByDepartment?CustomerId=${this.customerId}&PageNumber=1&NumberOfRecords=50&IsDescending=true&UseCase=BedManagement&DepartmentId=${deptId}`
      );
    } else {
      return this.http.get<any>(
        `/api/Staff/GetAll?CustomerId=${this.customerId}&PageNumber=1&NumberOfRecords=50&IsDescending=true&UseCase=BedManagement`
      );
    }
  }

  getGroupsResponsibleFor() {
    return this.http
      .get(
        `/api/MetaData/GetGroupsResponsibleFor?CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          const groups = data.List.map((group: any) => {
            //console.log(group.CheckTimeFrames);
            const tempGroup: Group = {
              Id: group.Id,
              Name: group.Name,
              UserDefinedAttributes: [],
              CheckTimeFrames: this.getTimeFramesArray(group.CheckTimeFrames),
            };
            return tempGroup;
          });
          return groups;
        })
      );
  }

  //MKL
  getTimeFramesArray(frames: string): CheckTimeFrame[] {
    let retArr: CheckTimeFrame[] = [];
    let firstSplit = frames.split(';');
    firstSplit.forEach((subFrame: string) => {
      let subSplit = subFrame.split(',');
      retArr.push({ startTime: subSplit[0], endTime: subSplit[1] });
    });
    return retArr;
  }

  //Get waitlist for specified Dept.
  getAllByPendingDepartment(pendDeptId: number): Observable<any> {
    return this.http
      .get<any>(
        `/api/Staff/GetAllByPendingDepartment?CustomerId=${this.customerId}&PendingDepartmentId=${pendDeptId}&PageNumber=1&NumberOfRecords=50&IsDescending=true&UseCase=PatientFlow`
      )
      .pipe(
        map((data: any) => {
          const assets = data.List.map((asset: any) => {
            const newAsset: Asset = {
              Id: asset.Id,
              AlertStatus: asset.AlertStatus,
              AssociatedDevices: asset.AssociatedDevices,
              BedStatus: asset.BedStatus || '',
              StatusLabel: this.setStatusLabel(asset.AlertStatus),
              Name: asset.Name,
              DisplayName: asset.DisplayName,
              Email: asset.Email,
              EnableAlerts: asset.EnableAlerts,
              EnableSDCT: asset.EnableSDCT,
              EnableHygiene: asset.EnableHygiene,
              DepartmentName: asset.DepartmentName,
              DepartmentID: asset.DepartmentID || 0,
              AssocItemName: asset.AssocItemName,
              AssocItemID: asset.AssocItemID,
              PendingDepartmentName: asset.PendingDepartmentName,
              PendingDepartmentId: asset.PendingDepartmentId,
              DeviceID: asset.DeviceID,
              DeviceName: asset.DeviceName,
              Temperature: Number(asset.Temperature) || 'N/A',
              GroupName: asset.GroupName,
              GroupID: asset.GroupID,
              BuildingName: asset.CurrentBuildingName,
              SiteName: asset.CurrentSiteName,
              FloorName: asset.CurrentFloorName,
              Latitude: asset.Latitude || 0,
              Longitude: asset.Longitude || 0,
              Address: asset.Address,
              MapId: asset.CurrentModelId,
              CurrentZones: asset.CurrentZones,
              X: asset.CurrentX,
              Y: asset.CurrentY,
              LocationUpdated: asset.LocationUpdated
                ? format(
                    parseISO(asset.LocationUpdated + 'Z'),
                    'MM/dd/yy h:mm aaa'
                  )
                : '',
              PendingDepartmentDateUpdated: asset.LocationUpdated
                ? format(
                    parseISO(asset.PendingDepartmentDateUpdated + 'Z'),
                    'MM/dd/yy h:mm aaa'
                  )
                : '',
              ModelName: asset.ModelName,
              PhoneNumber: asset.PhoneNumber,
              StaffID: asset.StaffID,
              Icon: asset.Icon,
              Portrait: asset.Portrait,
              UserDefinedAttributes: asset.UserDefinedAttributes,
              //UserDefinedStatuses: asset.UserDefinedStatuses,
              UserDefinedStatus: asset.UserDefinedStatus,
              UserDefinedStatusIcon: asset.UserDefinedStatusIcon,
            };

            const timeDiff = differenceInMinutes(
              Date.now(),
              Date.parse(asset.PendingDepartmentDateUpdated + 'Z')
            );
            newAsset.TimeSinceWaitlisted = timeDiff;

            //change Current Zones
            try {
              if (newAsset.CurrentZones) {
                newAsset.CurrentZones = JSON.parse(newAsset.CurrentZones) || [];
              }
            } catch (err) {
              newAsset.CurrentZones = [];
            }
            return newAsset;
          });
          return assets;
        })
      );
  }

  //Get staff for specific sub, allows pulling assets from other use cases to associate
  getStaff(sub: string, mapId: number) {
    var sentenceCaseString = this.returnUseCase(sub);
    return this.http.get<any>(
      `/api/Staff/GetAll?CustomerId=${this.customerId}&MapId=${mapId}&UseCase=${sentenceCaseString}`
    );
  }

  //Allows getting patients in a specific department
  getStaffByDepartmentId(deptId: number): Observable<any> {
    return this.http.get<any>(
      `/api/Staff/GetAllByDepartment?CustomerId=${this.customerId}&DepartmentId=${deptId}&PageNumber=1&NumberOfRecords=50&IsDescending=true&UseCase=PatientFlow`
    );
  }

  getAnimationFramesByStaffId(
    staffId: number,
    s: string,
    e: string,
    GPSOnly: boolean = false,
    TwoDOnly: boolean = false
  ) {
    return this.http
      .get<any>(
        `/api/DeviceLogs/GetAnimationFramesByStaffId?StartDateTime=${s}&EndDateTime=${e}&StaffId=${staffId}&GPSOnly=${GPSOnly}&TwoDOnly=${TwoDOnly}`
      )
      .pipe(
        map((data: any) => {
          const animationFrames = data.List.map((frame: any) => {
            const newFrame: AnimationFrame = {
              EventDisplayName: frame.EventDisplayName,
              DataMessageId: frame.DataMessageId,
              DeviceId: frame.DeviceID,
              EventID: frame.EventID,
              DeviceUniqueId: frame.DeviceUniqueId,
              ItemId: frame.ItemId,
              MapId: frame.MapId,
              X: frame.X,
              Y: frame.Y,
              Latitude: frame.Lat,
              Longitude: frame.Lon,
              GPS: frame.GPS,
              CustomerId: frame.CustomerId,
              DateCreated: frame.DateCreated
                ? format(
                    parseISO(frame.DateCreated + 'Z'), //ADDING + 'Z' is crucial to tell the func that a Zulu date has come back from the API.
                    'MM/dd/yy h:mm:ss aaa'
                  )
                : '',
              DateUpdated: frame.DateUpdated
                ? format(
                    parseISO(frame.DateUpdated + 'Z'), //ADDING + 'Z' is crucial to tell the func that a Zulu date has come back from the API.
                    'MM/dd/yy h:mm:ss aaa'
                  )
                : '',
              Description: frame.Description,
              Id: frame.Id,
            };
            return newFrame;
          });
          this.loading = false;
          return animationFrames;
        })
      );
  }

  //Used by the main assets table.
  getAssets() {
    let settings: any = this.settings$.getValue();
    this.loading = true;

    //console.log('mkl sorting on ...', this.page.SortField);

    return this.http
      .get(
        `/api/Staff/GetAll?PageNumber=${this.page.pageNumber}` +
          `&NumberOfRecords=${this.page.limit}` +
          `&SearchText=${this.page.SearchText}` +
          `&SortField=${this.page.SortField.replace(' ', '')}` +
          `&IsDescending=${this.page.IsDescending}` +
          `&CustomerId=${this.customerId}` +
          `&UseCase=${this.setUseCase()}`
      )
      .pipe(
        map((data: any) => {
          this.page.count = data.TotalRecordsCount;
          const assets = data.List.map((asset: any) => {
            const newAsset: Asset = {
              Id: asset.Id,
              AlertStatus: asset.AlertStatus,
              AssociatedDevices: asset.AssociatedDevices,
              BedStatus: asset.BedStatus || '',
              StatusLabel: this.setStatusLabel(asset.AlertStatus),
              Name: asset.Name,
              DisplayName: asset.DisplayName,
              Email: asset.Email,
              EnableAlerts: asset.EnableAlerts,
              EnableSDCT: asset.EnableSDCT,
              EnableHygiene: asset.EnableHygiene,
              DepartmentName: asset.DepartmentName,
              DepartmentID: asset.DepartmentID || 0,
              AssocItemName: asset.AssocItemName,
              AssocItemID: asset.AssocItemID,
              PendingDepartmentName: asset.PendingDepartmentName,
              PendingDepartmentId: asset.PendingDepartmentId,
              PendingDepartmentDateUpdated:
                asset.PendingDepartmentDateUpdated != '0001-01-01T00:00:00'
                  ? format(
                      parseISO(asset.PendingDepartmentDateUpdated + 'Z'),
                      'MM/dd/yy h:mm aaa'
                    )
                  : '',
              ExpectedDischargeDate:
                asset.PendingDepartmentDateUpdated != '0001-01-01T00:00:00'
                  ? format(
                      add(parseISO(asset.PendingDepartmentDateUpdated + 'Z'), {
                        minutes: settings?.MaximumWaitTime || 0,
                      }),
                      'MM/dd/yy h:mm aaa'
                    )
                  : '',
              DeviceID: asset.DeviceID,
              DeviceName: asset.DeviceName,
              Temperature: Number(asset.Temperature) || 'N/A',
              GroupName: asset.GroupName,
              GroupID: asset.GroupID,
              BuildingName: asset.CurrentBuildingName,
              SiteName: asset.CurrentSiteName,
              FloorName: asset.CurrentFloorName,
              MapId: asset.CurrentModelId,
              CurrentZones: asset.CurrentZones,
              Latitude: asset.Latitude || 0,
              Longitude: asset.Longitude || 0,
              Address: asset.Address,
              X: asset.CurrentX,
              Y: asset.CurrentY,
              LocationUpdated: asset.LocationUpdated
                ? format(
                    parseISO(asset.LocationUpdated + 'Z'),
                    'MM/dd/yy h:mm aaa'
                  )
                : '',
              ModelName: asset.ModelName,
              PhoneNumber: asset.PhoneNumber,
              StaffID: asset.StaffID,
              Icon: asset.Icon,
              Portrait: asset.Portrait,
              AlarisStatus: asset.AlarisStatus,
              UserDefinedAttributes: asset.UserDefinedAttributes,
              //UserDefinedStatuses: asset.UserDefinedStatuses,
              UserDefinedStatus: asset.UserDefinedStatus,
              UserDefinedStatusIcon: asset.UserDefinedStatusIcon,
            };

            //change Current Zones
            try {
              if (newAsset.CurrentZones) {
                newAsset.CurrentZones = JSON.parse(newAsset.CurrentZones) || [];
              }
            } catch (err) {
              newAsset.CurrentZones = [];
            }

            return newAsset;
          });
          this.asset$.next(assets);
          this.loading = false;
          return assets;
        })
      );
  }

  getAssetsByUseCase(useCase: string) {
    this.loading = true;
    return this.http
      .get(
        `/api/Staff/GetAll?PageNumber=${this.page.pageNumber}` +
          `&NumberOfRecords=${this.page.limit}` +
          `&SearchText=${this.page.SearchText}` +
          `&SortField=${this.page.SortField.replace(' ', '')}` +
          `&IsDescending=${this.page.IsDescending}` +
          `&CustomerId=${this.customerId}` +
          `&UseCase=${useCase}`
      )
      .pipe(
        map((data: any) => {
          this.page.count = data.TotalRecordsCount;
          const assets = data.List.map((asset: any) => {
            const newAsset: Asset = {
              Id: asset.Id,
              AlertStatus: asset.AlertStatus,
              AssociatedDevices: asset.AssociatedDevices,
              BedStatus: asset.BedStatus || '',
              StatusLabel: this.setStatusLabel(asset.AlertStatus),
              Name: asset.Name,
              DisplayName: asset.DisplayName,
              Email: asset.Email,
              EnableAlerts: asset.EnableAlerts,
              EnableSDCT: asset.EnableSDCT,
              EnableHygiene: asset.EnableHygiene,
              DepartmentName: asset.DepartmentName,
              DepartmentID: asset.DepartmentID || 0,
              AssocItemName: asset.AssocItemName,
              AssocItemID: asset.AssocItemID,
              PendingDepartmentName: asset.PendingDepartmentName,
              PendingDepartmentId: asset.PendingDepartmentId,
              DeviceID: asset.DeviceID,
              DeviceName: asset.DeviceName,
              Temperature: Number(asset.Temperature) || 'N/A',
              GroupName: asset.GroupName,
              GroupID: asset.GroupID,
              BuildingName: asset.CurrentBuildingName,
              SiteName: asset.CurrentSiteName,
              FloorName: asset.CurrentFloorName,
              MapId: asset.CurrentModelId,
              CurrentZones: asset.CurrentZones,
              Latitude: asset.Latitude || 0,
              Longitude: asset.Longitude || 0,
              Address: asset.Address,
              X: asset.CurrentX,
              Y: asset.CurrentY,
              LocationUpdated: asset.LocationUpdated
                ? format(
                    parseISO(asset.LocationUpdated + 'Z'),
                    'MM/dd/yy h:mm aaa'
                  )
                : '',
              ModelName: asset.ModelName,
              PhoneNumber: asset.PhoneNumber,
              StaffID: asset.StaffID,
              Icon: asset.Icon,
              Portrait: asset.Portrait,
              AlarisStatus: asset.AlarisStatus,
              UserDefinedAttributes: asset.UserDefinedAttributes,
              //UserDefinedStatuses: asset.UserDefinedStatuses,
              UserDefinedStatus: asset.UserDefinedStatus,
              UserDefinedStatusIcon: asset.UserDefinedStatusIcon,
            };

            //change Current Zones
            try {
              if (newAsset.CurrentZones) {
                newAsset.CurrentZones = JSON.parse(newAsset.CurrentZones) || [];
              }
            } catch (err) {
              newAsset.CurrentZones = [];
            }

            return newAsset;
          });
          this.asset$.next(assets);
          this.loading = false;
          return assets;
        })
      );
  }

  //BAD NUMBER OF RECORDS
  getAssetsForExport(pageNumber: number) {
    return this.http.get(
      `/api/Staff/GetAllTruncated?PageNumber=${pageNumber}` +
        `&NumberOfRecords=${MaxRecordReturn}` +
        `&SearchText=${this.page.SearchText}` +
        `&CustomerId=${this.customerId}` +
        `&UseCase=${this.setUseCase()}`
    );
  }

  getCompanyInfo() {
    return this.http
      .get('/api/Customer/GetByCustomerId?CustomerId=' + this.customerId)
      .pipe(
        map((data: any) => {
          var custApps: any = {};
          try {
            custApps = JSON.parse(data.CustomerApplications);
          } catch {
            console.log('error parsing custapps');
            custApps = {};
          }
          this.subscriptions$.next(custApps);
          this.gpsEnabled = custApps.integrations.GPS;
          return data;
        })
      );
  }

  getDepartmentDevices(deptIds: string) {
    return this.http.get(
      `/api/MetaData/GetItemsByDepartmentandGroup?CustomerId=${this.customerId}` +
        `&DepartmentIds=${deptIds}`
    );
  }

  getDepartments() {
    this.loading = true;
    return this.http
      .get(
        `/api/MetaData/GetDepartments?` +
          `SearchText=${this.pageDepts.SearchText}` +
          `&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          this.pageDepts.count = data.TotalRecordsCount;
          const departments = data.List.map((dept: any) => {
            const newDept: Department = {
              Id: dept.Id,
              Name: dept.Name,
              Icon: dept.Icon,
              NumberOfMembers: dept.NumberOfMembers,
            };
            return newDept;
          });
          this.dept$.next(departments);
          this.loading = false;
          return departments;
        })
      );
  }

  //start and end times default to yesterday 00:01 and 'now'
  getEventsByGroupId(
    groupId: number,
    startTime: string = '',
    endTime: string = ''
  ): Observable<any> {
    //needs timezone offsetting
    var startDate = format(
      sub(Date.now(), { days: 1, minutes: -new Date().getTimezoneOffset() }),
      'MM/dd/yyyy kk:mm'
    );
    var endDate = format(
      sub(Date.now(), { minutes: -new Date().getTimezoneOffset() }),
      'MM/dd/yyyy kk:mm'
    );
    return this.http
      .get(
        `/api/Events/GetEventsByGroupId?GroupId=${groupId}&StartDateTime=${startDate}&EndDateTime=${endDate}&UseCase=${this.setUseCase()}&CustomerId=${
          this.customerId
        }`
      )
      .pipe(
        map((data: any) => {
          this.page.count = data.TotalRecordsCount;
          const events = data.List.map((event: any) => {
            const newEvent: Event = {
              Id: event.Id,
              Status: event.Status,
              Name: event.Name,
              SystemName: event.SystemName,
              EventActions: event.EventActions,
              EventTime: 'this.formatTime(event.EventTime)',
              EndDateTime: 'this.formatTime(event.EndDateTime)',
              Duration: event.Duration,
              PersonName: event.PersonName,
              PersonAssociated: event.PersonAssociated,
              Details: event.Details,
              AcknowledgedName: event.AcknowledgedName,
              AcknowledgedDateTime:
                'this.formatTime(event.AcknowledgedDateTime)',
              Infrastructure: event.Infrastructure,
              ItemId: event.ItemId,
              UseCaseId: event.UseCaseId,
              ViolationValue: event?.ViolationValue,
              X: event.X,
              Y: event.Y,
              Latitude: event.Latitude || 0,
              Longitude: event.Longitude || 0,
              MinValue: event?.MinValue,
              MaxValue: event?.MaxValue,
              RuleDef: event.RuleDef ? JSON.parse(event.RuleDef) : '',
              Message: event.Message ? JSON.parse(event.Message) : '',
              ModelName: event.ModelName,
              ZoneName: event.ZoneName ? JSON.parse(event.ZoneName) : '',
              GroupID: event.GroupID || 0,
            };
            return newEvent;
          });
          return events;
        })
      );
  }

  //http://52.45.17.177:802/XpertRestApi/api/MetaData/GetIconURLs?CustomerId=1&forStatuses=true
  getIconURLs(forStatuses: boolean = false) {
    return this.http
      .get(
        '/api/MetaData/GetIconURLs?CustomerId=' +
          this.customerId +
          '&forStatuses=' +
          forStatuses
      )
      .pipe(
        map((data: any) => {
          const formatPaths = data.map(
            (el: string) => 'assets' + el.split('assets').slice(1)
          );
          return formatPaths;
        })
      );
  }

  getGroupDevices(groupIds: string) {
    return this.http.get(
      `/api/MetaData/GetItemsByDepartmentandGroup?CustomerId=${this.customerId}&GroupIds=${groupIds}`
    );
  }

  getGroups() {
    this.loading = true;
    return this.http
      .get(
        `/api/MetaData/GetGroups?CustomerId=${this.customerId}` +
          `&SearchText=${this.pageGroups.SearchText}` +
          `&UseCase=${this.setUseCase()}`
      )
      .pipe(
        map((data: any) => {
          this.pageGroups.count = data.TotalRecordsCount;
          const groups = data.List.map((group: any) => {
            const newGroup: Group = {
              Id: group.Id,
              Name: group.Name,
              Icon: group.Icon,
              UserDefinedAttributes: group.UserDefinedAttributes,
              PrimaryKey: group.PrimaryKey,
              SecondaryKey: group.SecondaryKey,
              EnableDwell: group.EnableDwell,
              NumberOfMembers: group.NumberOfMembers,
              ResponsibleUsers: group.ResponsibleUsers,
            };
            return newGroup;
          });
          this.group$.next(groups);
          this.loading = false;
          return groups;
        })
      );
  }

  getAllGroups() {
    this.loading = true;
    return this.http
      .get(
        `/api/MetaData/GetGroups?CustomerId=${this.customerId}` +
          `&SearchText=${this.pageGroups.SearchText}`
      )
      .pipe(
        map((data: any) => {
          const groups = data.List.map((group: any) => {
            const newGroup: Group = {
              Id: group.Id,
              Name: group.Name,
              Icon: group.Icon,
              UserDefinedAttributes: group.UserDefinedAttributes,
              PrimaryKey: group.PrimaryKey,
              SecondaryKey: group.SecondaryKey,
              EnableDwell: group.EnableDwell,
              NumberOfMembers: group.NumberOfMembers,
              ResponsibleUsers: group.ResponsibleUsers,
            };
            return newGroup;
          });
          this.allGroup$.next(groups);
          this.loading = false;
          return groups;
        })
      );
  }

  getLicenseInfo() {
    return this.http
      .get('/api/Customer/GetLicenseInfo?CustomerId=' + this.customerId)
      .pipe(
        map((data: any) => {
          let licenseInfo = data.LicenseInfo
            ? JSON.parse(data.LicenseInfo)
            : '';
          let licenses: any = [
            {
              name: 'Devices',
              langKey: 'DEVICES',
              amount: licenseInfo?.NumberOfTags,
              used: data.NumberOfTagLicensesUsed || 0,
              expiration: licenseInfo?.TagsExpTime,
              icon: 'wifi',
            },
            {
              name: 'Infrastructures',
              langKey: 'INFRASTRUCTURE',
              amount: licenseInfo?.NumberOfInfrastructure,
              used: data.NumberOfBeaconLicensesUsed || 0,
              expiration: licenseInfo?.InfrasExpTime,
              icon: 'wifi_tethering',
            },
            {
              name: 'AssetTracking',
              langKey: 'ASSET',
              amount: licenseInfo?.AssetTrackingAmount,
              used: data.NumberOfAssetTrackingLicensesUsed || 0,
              expiration: licenseInfo?.AssetTrackingTime,
              icon: 'share_location',
            },
            {
              name: 'HandHygiene',
              langKey: 'HYGIENE',
              amount: licenseInfo?.HandHygieneAmount,
              used: data.NumberOfHandHygieneLicensesUsed || 0,
              expiration: licenseInfo?.HandHygieneTime,
              icon: 'clean_hands',
            },
            {
              name: 'MELT',
              langKey: 'MELT',
              amount: licenseInfo?.MELTAmount,
              used: data.NumberOfMELTLicensesUsed || 0,
              expiration: licenseInfo?.MELTTime,
              icon: 'vibration',
            },
            {
              name: 'PatientFlow',
              langKey: 'PATIENT',
              amount: licenseInfo?.PatientFlowAmount,
              used: data.NumberOfPatientFlowLicensesUsed || 0,
              expiration: licenseInfo?.PatientFlowTime,
              icon: 'transfer_within_a_station',
            },
            {
              name: 'PeopleFlow',
              langKey: 'PEOPLE',
              amount: licenseInfo?.PeopleFlowAmount,
              used: data.NumberOfPeopleFlowLicensesUsed || 0,
              expiration: licenseInfo?.PeopleFlowTime,
              icon: 'transfer_within_a_station',
            },
            {
              name: 'SDCT',
              langKey: 'SDCT',
              amount: licenseInfo?.SDCTAmount,
              used: data.NumberOfSDCTLicensesUsed || 0,
              expiration: licenseInfo?.SDCTTime,
              icon: '6_ft_apart',
            },
            {
              name: 'StaffSafety',
              langKey: 'STAFF',
              amount: licenseInfo?.StaffSafetyAmount,
              used: data.NumberOfStaffSafetyLicensesUsed || 0,
              expiration: licenseInfo?.StaffSafetyTime,
              icon: 'engineering',
            },
            {
              name: 'TemperatureMonitoring',
              langKey: 'TEMPERATURE',
              amount: licenseInfo?.TemperatureMonitoringAmount,
              used: data.NumberOfTemperatureMonitoringLicensesUsed || 0,
              expiration: licenseInfo?.TemperatureMonitoringTime,
              icon: 'thermostat',
            },
            {
              name: 'PeopleFlow',
              langKey: 'PEOPLE',
              amount: licenseInfo?.PeopleFlowAmount,
              used: data.NumberOfPeopleFlowLicensesUsed || 0,
              expiration: licenseInfo?.PeopleFlowTime,
              icon: 'thermostat',
            },
            {
              name: 'BedManagement',
              langKey: 'BED MANAGEMENT',
              amount: licenseInfo?.BedManagementAmount,
              used: data.NumberOfBedManagementLicensesUsed || 0,
              expiration: licenseInfo?.BedManagementTime,
              icon: 'thermostat',
            },
          ];
          this.licenses$.next(licenses);
          return licenses;
        })
      );
  }

  getSettings() {
    return this.http
      .get(
        '/api/Settings/GetNotificationSettingsForSDCT?CustomerId=' +
          this.customerId +
          '&SettingType=Staff'
      )
      .pipe(
        map((data: any) => {
          let systemSettings: any = {};
          try {
            systemSettings = JSON.parse(data.SettingJson);
          } catch {
            systemSettings = {};
          }
          this.settings$.next(systemSettings);
          return systemSettings;
        })
      );
  }

  getSiteHierarchy(): Observable<any> {
    return this.http.get<any>(
      `/api/MetaData/GetLocationHierarchy?CustomerId=${this.customerId}`
    );
  }

  getSiteHierarchyMap(): Observable<any> {
    return this.http
      .get<any>(
        `/api/MetaData/GetLocationHierarchy?CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          this.siteHierarchy$.next(data.Sites);
          return data;
        })
      );
  }

  getStatuses() {
    // this.loading = true;
    return this.http
      .get(
        `/api/MetaData/GetStatuses?CustomerId=${this.customerId}` +
          `&UseCase=${this.setUseCase()}`
      )
      .pipe(
        map((data: any) => {
          this.pageStatuses.count = data.TotalRecordsCount;
          const statuses = data.List.map((status: any) => {
            const newStatus: Status = {
              Id: status.Id,
              Name: status.Name,
              Icon: status.Icon,
              Description: status.Description,
              UseCaseID: status.UseCaseID,
            };
            return newStatus;
          });
          this.statuses$.next(statuses);
          this.loading = false;
          return statuses;
        })
      );
  }

  getSubAppSettings(): Observable<any> {
    if (this.selectedSub == 'patient-flow') {
      return this.http.get(
        `/api/Settings/GetNotificationSettingsForSDCT?CustomerId=` +
          `${this.customerId}&SettingType=Patient`
      );
    } else if (this.selectedSub == 'temperature-monitoring') {
      return this.http.get(
        `/api/Settings/GetNotificationSettingsForSDCT?CustomerId=` +
          `${this.customerId}&SettingType=Temp`
      );
    } else {
      // redundant staff settings getter
      return this.http.get(
        `/api/Settings/GetNotificationSettingsForSDCT?CustomerId=` +
          `${this.customerId}&SettingType=Staff`
      );
    }
  }

  getTemperatureLogsByStaffId(
    startDate: string,
    endDate: string,
    staffID: number
  ): Observable<any> {
    return this.http.get(
      `/api/DeviceLogs/GetTempLogsByItemOrDeviceId?StartDateTime=${startDate}&EndDateTime=${endDate}&StaffId=${staffID}&DeviceId=0`
    );
  }

  getTemperatureEventsByStaffId(
    startDate: string,
    endDate: string,
    staffID: number
  ): Observable<any> {
    return this.http.get(
      `/api/Events/GetTempEventsByItemId?ItemId=${staffID}&StartDateTime=${startDate}&EndDateTime=${endDate}&TimezoneOffset=${'240'}&CustomerId=${
        this.customerId
      }`
    );
  }

  //http://52.45.17.177:802/XpertRestApi/api/MetaData/GetTemperatureCheckpoints?CustomerId=1
  getTemperatureCheckpoints(groupId: number) {
    return this.http
      .get(
        `/api/MetaData/GetTemperatureCheckpoints?GroupId=${groupId}&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          const checks = data.List.map((check: any) => {
            const newCheck: Checkpoint = {
              UserId: check.UserId,
              DateCreated: format(
                parseISO(check.DateCreated + 'Z'),
                'MM/dd/yy h:mm aaa'
              ),
              UserName: check.UserName,
              GroupName: check.GroupName,
              GroupId: check.GroupId,
              Note: check.Note,
            };
            return newCheck;
          });
          this.loading = false;
          return checks;
        })
      );
  }

  getMapById(id: number) {
    return this.http.get<any>(
      `/api/Maps/GetByMapId?MapId=${id}&CustomerId=${this.customerId}`
    );
  }

  getGPSMapByCustomerId() {
    return this.http.get<any>(
      `/api/Maps/GetGPSMapByCustomerId?CustomerId=${this.customerId}`
    );
  }

  getAllUsers() {
    this.loading = true;
    return this.http
      .get(
        `/api/Users/GetAll?CustomerId=${this.customerId}` +
          `&NumberOfRecords=9999&SortField=FirstName`
      )
      .pipe(
        map((data: any) => {
          this.page.count = data.TotalRecordsCount;
          const users = data.List.map((user: any) => {
            const userData: User = {
              Id: user.Id,
              UserName: user.UserName,
              FirstName: user.FirstName,
              LastName: user.LastName,
              Email: user.Email,
              Phone: user.Phone,
              RoleId: user.RoleId,
              RoleName: user.RoleName,
              IsSuperUser: user.IsSuperUser,
              Permissions: user.Permissions,
              EnableAccess: user.EnableAccess,
              FromLDAP: user.FromLDAP,
              AccountLockedOut: user.AccountLockedOut,
              AssociatedGroups: user.AssociatedGroups,
              AssociatedDepts: user.AssociatedDepts,
              UserSettings: user.UserSettings,
            };
            return userData;
          });
          this.loading = false;
          return users;
        })
      );
  }

  isPerson(): boolean {
    return this.selectedSub === 'hand-hygiene' ||
      this.selectedSub === 'sdct' ||
      this.selectedSub === 'staff-safety'
      ? true
      : false;
  }

  refreshData() {
    this.loading = true;
    forkJoin([
      this.getAssets(),
      this.getGroups(),
      this.getDepartments(),
      this.getLicenseInfo(),
    ]).subscribe(
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.notifier.open(this.translate.instant('NOTIFIER.ERROR_GET'), '', {
          panelClass: 'error',
        });
      }
    );
  }

  removeAssetUseCase(assetId: number) {
    return this.http.delete(
      `/api/Staff/DeleteItemUseCase?ItemId=${assetId}&UseCase=${this.setUseCase()}` +
        `&CustomerId=${this.customerId}`,
      {}
    );
  }

  removeGroupUseCase(groupId: number) {
    return this.http.delete(
      `/api/MetaData/DeleteGroupUseCase?GroupId=${groupId}&UseCase=${this.setUseCase()}` +
        `&CustomerId=${this.customerId}`,
      {}
    );
  }

  renameAsset(
    id: any,
    name: any,
    staffid: any,
    email?: any,
    phoneNumber?: any
  ) {
    return this.http.post(
      `/api/Staff/RenameStaff?StaffId=${id}` +
        `&NewName=${name}` +
        `&NewStaffRef=${staffid} ` +
        `&CustomerId=${this.customerId}` +
        `&Email=${email ? email : ''}` +
        `&PhoneNumber=${phoneNumber ? phoneNumber : ''}`,
      {}
    );
  }

  renameDepartment(deptId: number, name: string) {
    return this.http.post(
      `/api/MetaData/RenameDepartment?DepartmentId=${deptId}&NewName=${name}&CustomerId=${this.customerId}`,
      {}
    );
  }

  //http://52.45.17.177:802/XpertRestApi/api/Device/DisplayMessageOnTag?StaffIdList=1800%2C2500&Message=Hello&CustomerId=1
  sendMessage(ids: string, msg: string) {
    return this.http.post(
      `/api/Device/DisplayMessageOnTag?StaffIdList=${ids}&Message=${msg}&CustomerId=${this.customerId}`,
      {}
    );
  }

  //calls DisplayPriorityMessageOnTag to add notification sound to message sent
  sendMessageWithSound(ids: string, msg: string, options: string) {
    return this.http.post(
      `/api/Device/DisplayPriorityMessageOnTagNotif?StaffIdList=${ids}&Message=${msg}&MessageOptions=${options}&CustomerId=${this.customerId}`,
      {}
    );
  }

  //http://52.45.17.177:802/XpertRestApi/api/Staff/SetAssetPendingDepartment?CustomerId=1&PendingDepartmentId=-1
  setAssetPendingDepartment(pendingDeptId: number, assetIds: string[]) {
    return this.http.post<any>(
      `/api/Staff/SetAssetPendingDepartment?CustomerId=${this.customerId}&PendingDepartmentId=${pendingDeptId}`,
      assetIds
    );
  }

  setStatusLabel(status: string) {
    switch (status) {
      case 'Blue':
        return 'Uninfected';
      case 'Red':
        return 'Infected';
      case 'Yellow':
        return 'Under Investigation';
      case 'Green':
        return 'Recovered';
      default:
        return '';
    }
  }

  setUseCase() {
    switch (this.selectedSub) {
      case 'asset-tracking':
        return 'AssetTracking';
      case 'hand-hygiene':
        return 'HandHygiene';
      case 'melt':
        return 'MELT';
      case 'patient-flow':
        return 'PatientFlow';
      case 'people-flow':
        return 'PeopleFlow';
      case 'sdct':
        return 'SDCT';
      case 'staff-safety':
        return 'StaffSafety';
      case 'temperature-monitoring':
        return 'TemperatureMonitoring';
      case 'bed-management':
        return 'BedManagement';
      default:
        return 0;
    }
  }

  returnUseCase(subString: string) {
    switch (subString) {
      case 'asset-tracking':
        return 'AssetTracking';
      case 'hand-hygiene':
        return 'HandHygiene';
      case 'melt':
        return 'MELT';
      case 'patient-flow':
        return 'PatientFlow';
      case 'people-flow':
        return 'PeopleFlow';
      case 'sdct':
        return 'SDCT';
      case 'staff-safety':
        return 'StaffSafety';
      case 'temperature-monitoring':
        return 'TemperatureMonitoring';
      default:
        return 'error';
    }
  }

  updateSettings(settings: any) {
    return this.http.post<any>(
      '/api/Settings/UpdateSDCTSettings?CustomerId=' +
        this.customerId +
        '&SettingType=Staff',
      settings,
      {}
    );
  }
}
