import { Injectable } from '@angular/core';
import { enc, HmacSHA256 } from 'crypto-js';

@Injectable()
export class BoldBIService {
  serverUrl: string = 'https://app1696275316.boldbi.com/bi/';
  userEmail: string = 'dietrick.butler@airista.com';
  embedSecret: string = 'QvMbTwPsu7vPwnVPg9QQFmg5DySFq1kQ';
  embedType: string = 'component';
  environment: string = 'cloud';
  expirationTime: string = '10000';
  siteIdentifier: string = '';

  generateNonce(): string {
    let length = 36;
    const charset =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const values = new Uint32Array(length);
    crypto.getRandomValues(values);

    let nonce = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = values[i] % charset.length;
      nonce += charset.charAt(randomIndex);
    }

    return nonce;
  }

  getSignatureUrl(embedParameters: string, embedSecretKey: string): string {
    const keyBytes = enc.Utf8.parse(embedSecretKey);
    const messageBytes = enc.Utf8.parse(embedParameters);
    const embedSignature = HmacSHA256(messageBytes, keyBytes);
    const base64Signature = embedSignature.toString(enc.Base64);
    return base64Signature;
  }

  iframeURL(
    dashboardId: string,
    siteIdentifier: string,
    nonce: string,
    signature: string,
    companyId: number
  ): string {
    // full example url
    // https://test.boldbi.com/bi/dashboards/8428c9d9-85db-418c-b877-ea4495dcddd7/Predictive%20Analytics/Personal%20Expense%20Analysis?embed_nonce=3e253410-1a82-4fb3-a337-122a8007dafc&embed_user_email=admin@domain.com&embed_signature=VYrDMVX4h85PrRBKX9mystRHYkU8z+HVC9bkVMc2qGY=

    // Example url for Dashboard Designer
    // https://app1696275316.boldbi.com/bi/dashboard-designer/1bc95cff-cccb-4053-8555-f5477282b9fd/Asset%20Tracking/Asset%20Tracking%20-%20Home

    return `${this.serverUrl}dashboards/${dashboardId}/${siteIdentifier}/${siteIdentifier}-Home?embed_nonce=${nonce}&embed_user_email=${this.userEmail}&embed_signature=${signature}&&CustomerId=${companyId}`;
  }
}
