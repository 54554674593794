import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerService } from './customer.service';
import { User } from '@app/core/interfaces';
import { map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { MaxRecordReturn } from '@app/core/constants';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private customerService: CustomerService,
    private http: HttpClient,
    private storage: StorageService
  ) {}

  agreeToEULA() {
    return this.http.post(
      `/api/Users/AgreeToEULA?UserId=${this.customerService.userId}&CustomerId=${this.customerService.customerId}`,
      {}
    );
  }

  //BAD NUMBER OF RECORDS
  getUsers() {
    return this.http
      .get(
        `/api/Users/GetAll?CustomerId=${this.customerService.customerId}&NumberOfRecords=${MaxRecordReturn}`
      )
      .pipe(
        map((data: any) => {
          const users = data.List.map((user: any) => {
            const userData: User = {
              Id: user.Id,
              UserName: user.UserName,
              FirstName: user.FirstName,
              LastName: user.LastName,
              Email: user.Email,
              Phone: user.Phone,
              RoleId: user.RoleId,
              RoleName: user.RoleName,
              IsSuperUser: user.IsSuperUser,
              Permissions: user.Permissions,
              EnableAccess: user.EnableAccess,
              FromLDAP: user.FromLDAP,
              AccountLockedOut: user.AccountLockedOut,
              AssociatedGroups: user.AssociatedGroups,
              AssociatedDepts: user.AssociatedDepts,
              UserSettings: user.UserSettings
                ? JSON.parse(user.UserSettings)
                : {},
            };
            return userData;
          });
          return users;
        })
      );
  }

  updateUser(form: any, userSettings: any, userId?: number) {
    return this.http.post(
      `/api/Users/UpdateUser?UserId=${userId}` +
        `&NewUserName=${encodeURIComponent(form.username)}` +
        `&NewPhone=${encodeURIComponent(form.phone)}` +
        `&NewEmailAddress=${encodeURIComponent(form.email)}` +
        `&NewFirstName=${encodeURIComponent(form.firstName)}` +
        `&NewLastName=${encodeURIComponent(form.lastName)}` +
        `&CustomerId=${this.customerService.customerId}`,
      userSettings
    );
  }

  updateUserSettings(userId: number, userSettings: string) {
    return this.http.post(
      `/api/Users/UpdateUser?UserId=${userId}&CustomerId=${this.customerService.customerId}`,
      userSettings
    );
  }
}
