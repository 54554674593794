<div class="wrapper">
  <mat-spinner
    class="spinner"
    diameter="350"
    strokeWidth="10"
    *ngIf="customerService.loading && loading"
  ></mat-spinner>

  <div
    *ngIf="!customerService.loading && !loading && !env.CMMode"
    class="tile-container"
  >
    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.AssetTracking"
      icon="share_location"
      label="{{ 'MAIN.DASHBOARD.SUBS.ASSET' | translate }}"
      path="/asset-tracking"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('AssetTracking', 'view')
          ? 'useCase'
          : 'disabled'
      "
    >
    </app-dashboard-tiles>

    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.StaffSafety"
      icon="engineering"
      label="{{ 'MAIN.DASHBOARD.SUBS.STAFF' | translate }}"
      path="/staff-safety"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('StaffSafety', 'view')
          ? 'useCase'
          : 'disabled'
      "
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.PatientFlow"
      icon="transfer_within_a_station"
      label="{{ 'MAIN.DASHBOARD.SUBS.PATIENT' | translate }}"
      path="/patient-flow"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('PatientFlow', 'view')
          ? 'useCase'
          : 'disabled'
      "
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.HandHygiene"
      icon="clean_hands"
      label="{{ 'MAIN.DASHBOARD.SUBS.HYGIENE' | translate }}"
      path="/hand-hygiene"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('HandHygiene', 'view')
          ? 'useCase'
          : 'disabled'
      "
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.MELT"
      icon="vibration"
      label="{{ 'MAIN.DASHBOARD.SUBS.MELT' | translate }}"
      path="/melt"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('MELT', 'view') ? 'useCase' : 'disabled'
      "
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.PeopleFlow"
      icon="settings_accessibility"
      label="{{ 'MAIN.DASHBOARD.SUBS.PEOPLE' | translate }}"
      path="/people-flow"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('PeopleFlow', 'view')
          ? 'useCase'
          : 'disabled'
      "
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.SDCT"
      icon="6_ft_apart"
      label="{{ 'MAIN.DASHBOARD.SUBS.SDCT' | translate }}"
      path="/sdct"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('SDCT', 'view') ? 'useCase' : 'disabled'
      "
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.TemperatureMonitoring"
      icon="thermostat"
      label="{{ 'MAIN.DASHBOARD.SUBS.TEMPERATURE' | translate }}"
      path="/temperature-monitoring"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('TemperatureMonitoring', 'view')
          ? 'useCase'
          : 'disabled'
      "
    ></app-dashboard-tiles>

    <!-- label="{{ 'MAIN.DASHBOARD.MANAGEMENT' | translate }}" -->
    <app-dashboard-tiles
      *ngIf="customerService.subscriptions?.BedManagement"
      icon="night_shelter"
      label="{{ 'MAIN.DASHBOARD.SUBS.BED_MANAGEMENT' | translate }}"
      path="/bed-management"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('BedManagement', 'view')
          ? 'useCase'
          : 'disabled'
      "
    ></app-dashboard-tiles>

    <!-- DEMO TILE - MKL 6-29-2023 -->
    <!-- <app-dashboard-tiles
      *ngIf="customerService.getPermission('BedManagement', 'view')"
      icon="analytics"
      label="Analytics"
      path="/analytics"
      tooltip=""
      [tileUseString]="
        customerService.getPermission('BedManagement', 'view')
          ? 'useCase'
          : 'disabled'
      "
    ></app-dashboard-tiles> -->

    <!-- END USECASES, START SUBAPPS -->
    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Devices', 'view') ? 'subApp' : 'disabled'
      "
      icon="wifi"
      label="{{ 'MAIN.DASHBOARD.DEVICES' | translate }}"
      path="/devices"
      tooltip="Tags"
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Infrastructures', 'view')
          ? 'subApp'
          : 'disabled'
      "
      icon="wifi_tethering"
      label="{{ 'MAIN.DASHBOARD.INFRASTRUCTURE' | translate }}"
      path="/infrastructures"
      tooltip=""
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Maps', 'view') ? 'subApp' : 'disabled'
      "
      icon="location_on"
      label="{{ 'MAIN.DASHBOARD.MAPS' | translate }}"
      path="/map-editor"
      tooltip=""
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Users', 'view') ? 'subApp' : 'disabled'
      "
      icon="people"
      label="{{ 'MAIN.DASHBOARD.USERS' | translate }}"
      path="/users"
      tooltip=""
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Rules', 'view') ? 'subApp' : 'disabled'
      "
      icon="rule"
      label="{{ 'MAIN.DASHBOARD.RULES' | translate }}"
      path="/rule-composer"
      tooltip=""
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Settings', 'view')
          ? 'subApp'
          : 'disabled'
      "
      icon="settings"
      label="{{ 'MAIN.DASHBOARD.SETTINGS' | translate }}"
      path="/system-settings"
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Settings', 'view')
          ? 'subApp'
          : 'disabled'
      "
      icon="info"
      label="{{ 'MAIN.DASHBOARD.COMPANY' | translate }}"
      path="/company-information"
    ></app-dashboard-tiles>

    <!-- customerService.getPermission('SUPER_USER_ONLY', 'view') -->
    <app-external-tile
      *ngIf="false"
      icon="sync_problem"
      label="Workflows"
      tooltip=""
      externalLink="http://18.234.242.171:801/"
    ></app-external-tile>

    <!-- DEMO TILE - SBM 11-10-2023 -->
    <app-external-tile
      *ngIf="
        false && customerService.getPermission('DashboardDesigner', 'view')
      "
      icon="add_chart"
      label="Dashboard Designer"
      tooltip=""
      externalLink="https://app1696275316.boldbi.com/bi/dashboards?view=all"
    ></app-external-tile>

    <!-- make debugtool available to admins as well 8/9/2023 -->
    <!-- think about hiding beyond additional permissions, SUPER_USER_ONLY? MKL 3/5/2024 -->
    <app-dashboard-tiles
      [tileUseString]="
        customerService.getPermission('Settings', 'view')
          ? 'subApp'
          : 'disabled'
      "
      icon="handyman"
      label="{{ 'MAIN.DASHBOARD.DEBUG' | translate }}"
      path="/debug-tool"
      tooltip=""
      tileUseString="error"
    ></app-dashboard-tiles>

    <!-- END SUBAPPS, START SUPERUSER ONLY -->
    <!-- HIDE for non superuser [tileUseString]=" customerService.getPermission('SUPER_USER_ONLY', 'view') ? 'superUserOnly' : 'disabled' " -->
    <app-dashboard-tiles
      *ngIf="customerService.getPermission('SUPER_USER_ONLY', 'view')"
      [tileUseString]="
        customerService.getPermission('SUPER_USER_ONLY', 'view')
          ? 'superUserOnly'
          : 'disabled'
      "
      icon="app_settings_alt"
      label="{{ 'MAIN.DASHBOARD.FIRMWARE' | translate }}"
      path="/firmware"
      tooltip=""
      tileUseString="error"
    ></app-dashboard-tiles>

    <!-- <app-dashboard-tiles
      *ngIf="customerService.getPermission('SUPER_USER_ONLY', 'view')"
      [tileUseString]="
        customerService.getPermission('SUPER_USER_ONLY', 'view')
          ? 'superUserOnly'
          : 'disabled'
      "
      icon="memory"
      label="{{ 'MAIN.DASHBOARD.SERVER' | translate }}"
      path="/server-status"
      tooltip=""
      tileUseString="error"
    ></app-dashboard-tiles> -->

    <app-dashboard-tiles
      *ngIf="
        customerService.getPermission('SUPER_USER_ONLY', 'view') &&
        env.enableFlows
      "
      icon="account_tree"
      label="Apps"
      path="/flow"
      tooltip=""
      [tileUseString]="'superUserOnly'"
    ></app-dashboard-tiles>

    <!-- <app-dashboard-tiles
       icon="settings"
       label="User Manuals"
       path="/manuals"
       tooltip="">
    </app-dashboard-tiles> -->
  </div>

  <div *ngIf="!customerService.loading && env.CMMode" class="tile-container">
    <app-dashboard-tiles
      icon="wifi"
      label="{{ 'MAIN.DASHBOARD.DEVICES' | translate }}"
      path="/devices"
      tooltip="Tags"
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      icon="wifi_tethering"
      label="{{ 'MAIN.DASHBOARD.INFRASTRUCTURE' | translate }}"
      path="/infrastructures"
      tooltip=""
    ></app-dashboard-tiles>

    <app-dashboard-tiles
      icon="handyman"
      label="{{ 'MAIN.DASHBOARD.DEBUG' | translate }}"
      path="/debug-tool"
      tooltip=""
    ></app-dashboard-tiles>
  </div>
</div>
