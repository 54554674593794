import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { format, parseISO, formatDistanceToNowStrict } from 'date-fns';
import { forkJoin } from 'rxjs';
import { ConfirmPopupComponent } from '@app/shared/confirm-popup/confirm-popup.component';
import { ConfigDetailsComponent } from '@app/shared/config-details/config-details.component';

import {
  Device,
  Config,
  Infrastructure,
  SingleDeviceSettingsInputParams,
} from '@app/core/interfaces';
import { SingleDeviceSettingsService } from '@app/services/single-device-settings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedMapComponent } from '../shared-map/shared-map.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-single-device-settings',
  templateUrl: './single-device-settings.component.html',
  styleUrls: ['./single-device-settings.component.scss'],
})
export class SingleDeviceSettingsComponent implements OnInit {
  loading: boolean = false;
  selectedDevice: any;

  table?: any;
  device: Device[] = [];
  configs: Config[] = [];

  infras: Infrastructure[] = [];
  currentSettings: any;

  showInfraDetected: boolean = true;
  showProximityDevices: boolean = false;
  showLocationRssi: boolean = false;

  pendingCommands: any;
  deviceSettings: string = 'property';

  statusMessage: any;
  locMessage: any;
  proximityMessage: any;

  infraDevice: any;
  selectedInfra: any;

  //table definition for commands page
  currentColumns: string[] = [
    'CommandCode',
    'CommandValue',
    'CommandDescription',
    'DateUpdated',
  ];
  pendingColumns: string[] = ['CommandCode', 'CommandValue', 'Description'];

  //table definitions for RSSI page
  detectedInfrasColumns: string[] = [
    'AntennaDisplayName',
    'ReaderName',
    'RSSI',
    'MinRssi',
    'MaxRssi',
    'Map',
    'Zone',
  ];
  proximityReportColumns: string[] = ['MacAddress', 'RSSI'];
  locationRssiColumns: string[] = ['MacAddress', 'RSSI'];

  selection = new SelectionModel<any>(true, []);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SingleDeviceSettingsInputParams,
    public dialog: MatDialog,
    public notifier: MatSnackBar,
    public service: SingleDeviceSettingsService,
    public translate: TranslateService
  ) {
    service.initializeService(data.customerId, data.forInfra, data.permissions);

    service.currentDeviceCommands$.subscribe((current) => {
      this.currentSettings = current;
    });
    service.pendingDeviceCommands$.subscribe((pending) => {
      this.pendingCommands = pending;
    });
    service.configs$.subscribe((configs) => {
      this.configs = configs;
    });
    service.infrastructure$.subscribe((infrastructure) => {
      this.infras = infrastructure;
    });
  }

  //map does not update on refresh of button. MKL 3/6/2024
  ngOnInit() {
    this.loading = true;
    this.selectedDevice = this.data.selectedDevice;
    const deviceId = this.data.forInfra
      ? this.selectedDevice.DeviceTableId
      : this.selectedDevice.Id;
    const mac = this.selectedDevice.UniqueId;

    if (mac) {
      this.getDeviceCommands(mac);
      this.service.getDeviceById(deviceId).subscribe((res: any) => {
        this.selectedDevice = res;
        this.statusMessage = res.StatusMessage
          ? JSON.parse(res.StatusMessage)
          : null;
        this.locMessage =
          this.statusMessage?.DeviceReports[0]?.LOCMessageContent?.split(';');
        this.locMessage?.forEach((message: any, index: number) => {
          this.locMessage[index] = {
            MacAddress: message.substring(0, 17),
            RSSI: message.substring(18),
          };
          if (this.locMessage[index].RSSI > 0)
            this.locMessage[index].RSSI *= -1;
        });
        this.proximityMessage = this.statusMessage?.ProximityReports;
      });
      this.service.getInfra(
        this.statusMessage?.DeviceReports[0]?.RFID?.Readers.AntennaDisplayName
      );
    }
    this.loading = false;
  }

  get selected() {
    return this.table ? this.table.selection.selected[0] : {};
  }

  confirmDelete() {
    //MKL 6-22-2024 TRANSLATEME
    const popupMessage = `Are you sure you want to delete the pending settings?`;
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        message: popupMessage,
        action: 'delete-pending-commands',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.confirmed) this.deletePendingCommands();
    });
  }

  deletePendingCommands() {
    this.loading = true;
    const commands = this.pendingCommands ? this.pendingCommands : [];
    const currentDevice = commands[0]?.UniqueId;
    let count: number = 0;

    commands.forEach((command: any) => {
      this.service.deleteDeviceCommandsById(command).subscribe(
        () => {
          count += 1;
          if (count === commands.length) {
            this.service.getCurrentCommandsByMac(currentDevice).subscribe();
            this.service.getPendingCommandsByMac(currentDevice).subscribe();
            this.notifier.open(
              this.translate.instant('NOTIFIER.SUCCESS_DELETE'),
              '',
              { panelClass: 'success' }
            );
          }
        },
        () => {
          count += 1;
          this.loading = count === commands.length;
          this.notifier.open(
            this.translate.instant('NOTIFIER.ERROR_DELETE'),
            '',
            { panelClass: 'error' }
          );
        }
      );
    });
  }

  formatDate(date: string): string {
    if (date) {
      const formatDate = format(parseISO(date + 'Z'), 'MMM do h:mm:ss aaa');
      const toNow = formatDistanceToNowStrict(parseISO(date + 'Z'));
      return `${formatDate} (${toNow} ago)`;
    }
    return '';
  }

  getDeviceCommands(deviceMac: string) {
    this.loading = true;
    forkJoin([
      this.service.getCurrentCommandsByMac(deviceMac),
      this.service.getPendingCommandsByMac(deviceMac),
    ]).subscribe(
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.notifier.open(this.translate.instant('NOTIFIER.ERROR_GET'), '', {
          panelClass: 'error',
        });
      }
    );
  }

  getConfigName(id: number) {
    const config = this.configs.find((config: Config) => config.Id === id);
    const configName = config ? config.Name : 'None';
    return configName;
  }

  getDeviceMap(id: number) {
    const infra = this.infras.find(
      (infras: Infrastructure) => infras.Id === id
    );
    const mapName = infra?.MapName ? infra.MapName : 'None';
    return mapName;
  }

  getDeviceZone(id: number) {
    const infra = this.infras.find(
      (infras: Infrastructure) => infras.Id === id
    );
    const zoneName = infra?.ZoneName ? infra.ZoneName : 'None';
    return zoneName;
  }

  refresh() {
    this.ngOnInit();
  }

  selectConfig(id: number) {
    const config: Config | undefined = this.configs.find(
      (config: Config) => config.Id === id
    );

    if (!config) return;

    this.dialog.open(ConfigDetailsComponent, {
      data: { config: config, forInfra: this.data.forInfra },
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      width: '750px',
    });
  }
}
