<h3 mat-dialog-title>{{ "COLUMNS.SEARCH" | translate }}</h3>
<form class="form-container" [formGroup]="filterSearchForm">
  <mat-form-field>
    <mat-label>{{ "COLUMNS.NAME" | translate }}</mat-label>
    <input
      matInput
      [placeholder]="'COLUMNS.NAME' | translate"
      formControlName="name"
    />
  </mat-form-field>

  <div class="application">
    <mat-form-field>
      <mat-label>{{ "COLUMNS.APPLICATION" | translate }}</mat-label>
      <mat-select
        formControlName="application"
        (valueChange)="applicationChanged($event)"
      >
        <mat-option [value]="''">{{
          "MAIN.GLOBAL_SEARCH.ALL_APPLICATIONS" | translate
        }}</mat-option>
        <mat-option
          *ngFor="let application of applications"
          [value]="application"
        >
          {{ application }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="application">
    <mat-form-field>
      <mat-label>{{ "COLUMNS.DEPARTMENT" | translate }}</mat-label>
      <!-- Group field disabled until a specific application is selected. -->
      <mat-select
        formControlName="group"
        [disabled]="filterSearchForm.value.application == ''"
      >
        <mat-option *ngFor="let group of groups" [value]="group">
          {{ group.Name }}
        </mat-option>
      </mat-select>
      <mat-hint
        >{{ "MAIN.GLOBAL_SEARCH.PICK_ONE_APPLICATION" | translate }}
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="application">
    <mat-form-field>
      <mat-label>{{ "COLUMNS.DEPARTMENT" | translate }}</mat-label>
      <mat-select formControlName="department">
        <mat-option *ngFor="let department of departments" [value]="department">
          {{ department.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="application">
    <mat-form-field>
      <mat-label>{{ "COLUMNS.BATTERY" | translate }}</mat-label>
      <mat-select formControlName="battery">
        <mat-option [value]="100">{{
          "MAIN.GLOBAL_SEARCH.LESS_100" | translate
        }}</mat-option>
        <mat-option [value]="75">{{
          "MAIN.GLOBAL_SEARCH.LESS_75" | translate
        }}</mat-option>
        <mat-option [value]="50">{{
          "MAIN.GLOBAL_SEARCH.LESS_50" | translate
        }}</mat-option>
        <mat-option [value]="25">{{
          "MAIN.GLOBAL_SEARCH.LESS_25" | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>

<div class="form-buttons">
  <button
    mat-flat-button
    color="primary"
    [disabled]="filterSearchForm.invalid"
    (click)="onSubmit()"
  >
    <mat-spinner
      class="spinner"
      color="accent"
      diameter="30"
      *ngIf="loading"
    ></mat-spinner>
    <span *ngIf="!loading">{{ "FORMS.SUBMIT" | translate }}</span>
  </button>
  <button mat-stroked-button mat-dialog-close>
    {{ "FORMS.CANCEL" | translate }}
  </button>
</div>
