import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-server-status',
  template: `
    <div>
      <server-status *axLazyElement></server-status>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class ServerStatusComponent implements OnInit {
  subscription: string = '';
  constructor() {
    this.subscription = window.location.pathname.split('/')[1];
  }
  ngOnInit(): void {}
}
