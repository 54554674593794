import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Asset,
  Breadcrumb,
  Device,
  GlobalSearchParameters,
} from '@app/core/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { GlobalSearchService } from '@app/services/global-search.service';
import { SharedBreadcrumbFrameComponent } from '@app/shared/shared-breadcrumb-frame/shared-breadcrumb-frame.component';
import { CustomerService } from '@app/services/customer.service';
import { MatPaginator } from '@angular/material/paginator';
import { splitOnCapitals } from '@app/core/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-global-search-advance',
  templateUrl: './global-search-advance.component.html',
  styleUrls: ['./global-search-advance.component.scss'],
})
export class GlobalSearchAdvanceComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() public doubleClickEvent = new EventEmitter<Breadcrumb>();

  displayedColumns: string[] = [
    'Name',
    'Status',
    'Group',
    'Department',
    'Map',
    'AssociatedDevices',
    'LocationUpdated',
    'UseCases',
  ];

  params!: GlobalSearchParameters;
  dataSource: MatTableDataSource<Asset> = new MatTableDataSource<Asset>();

  splitOnCapitals = splitOnCapitals;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public searchService: GlobalSearchService,
    public dialog: MatDialog,
    public customerService: CustomerService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    //on initialization, grab params and run the search.
    this.searchService.initializeService();
    this.route.queryParams.subscribe((ps) => {
      this.params = {
        name: ps.name,
        department: ps.department,
        departmentName: ps.departmentName,
        group: ps.group,
        groupName: ps.groupName,
        application: ps.application,
        battery: ps.battery,
      };
      //console.log(ps);
      this.searchService.getAssetsAdvance(ps).subscribe((assets: any) => {
        this.dataSource.data = assets;
      });
    });

    //on refresh, do the same.
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        //console.log(evt);
        this.route.queryParams.subscribe((ps) => {
          this.params = {
            name: ps.name,
            department: ps.department,
            departmentName: ps.departmentName,
            group: ps.group,
            groupName: ps.groupName,
            application: ps.application,
            battery: ps.battery,
          };
          //console.log(ps);
          this.searchService.getAssetsAdvance(ps).subscribe((assets: any) => {
            this.dataSource.data = assets;
          });
        });
      }
    });
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe((ev: any) => {
      //console.log('paginator change: ', ev);
      this.searchService.page.pageNumber = this.paginator.pageIndex + 1;
      this.searchService.page.limit = this.paginator.pageSize;
      this.searchService
        .getAssetsAdvance(this.params)
        .subscribe((assets: any) => {
          this.dataSource.data = assets;
        });
    });
    this.translate
      .stream('COLUMNS.PAGINATOR_TEXT')
      .subscribe((translatedString: string) => {
        this.paginator._intl.itemsPerPageLabel = translatedString;
      });
  }

  doubleClick(row: any) {
    this.dialog.open(SharedBreadcrumbFrameComponent, {
      data: { PanelName: 'asset', Params: { Name: row.Name, ID: row.Id } },
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '100vw',
      width: this.customerService.getScreenSize() ? '100%' : '850px',
    });
  }

  openMap(element: any) {
    //console.log('open map');
    this.openBreadcrumbWindow(element);
  }

  openBreadcrumbWindow(bc: any) {
    this.dialog.open(SharedBreadcrumbFrameComponent, {
      data: { PanelName: 'asset', Params: { Name: bc.Name, ID: bc.Id } },
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '100vw',
      width: this.customerService.getScreenSize() ? '100%' : '850px',
    });
  }

  //For Showing Device Stuff
  getNumberOfDevices(asset: Asset) {
    const numDevices: number =
      asset.AssociatedDevices?.length > 0 ? asset.AssociatedDevices?.length : 0;
    switch (numDevices) {
      case 0:
        return 'None';
      case 1:
        return '1 Device';
      default:
        return `${numDevices} Devices`;
    }
  }
  getBadDevices(devices: Device[]) {
    return devices.some((d: Device) => d.BatteryStatus === 'Red');
  }
  getDeviceInfo(devices: Device[]) {
    const deviceMacs = devices.map((d: Device) => {
      let retString = `${d.UniqueId} -- ${d.BatteryLevel}%`;
      return retString;
    });
    return deviceMacs.join('\n');
  }
}
