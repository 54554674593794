import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-info',
  template: `
    <div class="wrapper-no-table">
      <company-info *axLazyElement></company-info>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class CompanyInfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
