<div class="container">
  <img class="sub-logo" src="assets/images/SofiaLogo.svg" />
  <img class="logo" src="assets/images/AiRISTA-FlowLogo-White.svg" />

  <form class="form" [formGroup]="selectCustomerForm" (ngSubmit)="onSubmit()">
    <select
      class="customer-select"
      (change)="changeCustomer($event)"
      formControlName="customer"
    >
      <option value="" disabled>
        {{ "MAIN.SELECT_CUSTOMER.HEADER" | translate }}
      </option>
      <option *ngFor="let customer of customers" [ngValue]="customer">
        {{ customer.Name }}
      </option>
    </select>
    <button class="button-primary" [disabled]="!selectCustomerForm.valid">
      {{ "MAIN.SELECT_CUSTOMER.PROFILE" | translate }}
    </button>
  </form>

  <a class="create-new" (click)="openSlidingPanel()">{{
    "MAIN.SELECT_CUSTOMER.CREATE" | translate
  }}</a>
  <p class="copyright">
    {{ "EULA.COPYRIGHT" | translate }}
    <br />
    Version 7.{{ version }}
  </p>
</div>
