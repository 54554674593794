import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { StorageService } from '@app/services/storage.service';
import { CustomerService } from './services/customer.service';
import { LogoutMessageComponent } from '@app/nav/logout-message/logout-message.component';
import { MatDialog } from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

@Component({
  selector: 'app-root',
  template: `
    <app-navbar [customerId]="customerService.customerId"></app-navbar>
    <div
      [ngClass]="{
        wrapper: !this.authService.isAuthenticated(),
        wrapperLoggedIn: this.authService.isAuthenticated()
      }"
    >
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  systemSetting: any = null;
  timer: number = 1200;
  constructor(
    public authService: AuthService,
    public idle: Idle,
    public customerService: CustomerService,
    public dialog: MatDialog,
    private router: Router,
    public storage: StorageService,
    public window: Window
  ) {
    this.customerService.initializeService();

    // restarts the timer if a customer change has occured
    this.customerService.systemSetting$.subscribe((sets) => {
      this.systemSetting = sets;
      this.timer = this.decideLogoutTimer();
      idle.setIdle(this.timer - 60);
      this.idle.watch();
      // console.log('new timer value: ' + this.timer);
    });

    idle.setIdle(this.timer - 60);
    idle.setTimeout(60);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => console.log('No longer idle.'));
    idle.onTimeout.subscribe(() => this.timeout());
    idle.onIdleStart.subscribe(() => this.showTimerWarning());
    idle.onTimeoutWarning.subscribe((countdown: any) => {
      //console.log('You will time out in ' + countdown + ' seconds!')
    });
    this.idle.watch();

    // check to make sure the timer needs to be running
    this.storage.stopTimer$.subscribe((bool) => {
      if (bool) {
        // console.log("idle stop");
        this.idle.stop();
      }
    });
  }

  ngOnInit(): void {}

  decideLogoutTimer() {
    const systemSetting = this.systemSetting.LogoutTimerMinutes
      ? this.systemSetting.LogoutTimerMinutes * 60
      : 1200;
    const userSetting = this.customerService.userSettings.logoutTimer
      ? this.customerService.userSettings.logoutTimer * 60
      : systemSetting;

    if (this.systemSetting.AllowUserLogoutTimers) {
      //console.log("users are allowed to choose their own logout timer...")
      if (
        this.systemSetting.AllowUserLogoutTimers.includes(
          this.customerService.roleName
        )
      ) {
        //console.log("and THIS user is included in the list of roles that can change it: " + userSetting)
        return userSetting;
      } else {
        //console.log("and this user is NOT included in the list of roles that can change it: " + systemSetting)
        return systemSetting;
      }
    } else {
      //console.log("users are NOT allowed to choose their own logout timer, defaulting to company-wide, or 1200: " + systemSetting)
      return systemSetting;
    }
  }

  showTimerWarning() {
    if (this.router.url == '/screensaver') {
      return;
    }
    this.dialog.open(LogoutMessageComponent, {});
  }

  timeout() {
    if (this.router.url == '/screensaver') {
      return;
    }
    if (this.storage.get('uvs-credentials')) {
      this.authService.logout().subscribe(
        () => {
          this.customerService.alreadyWarned$.next(false);
          this.customerService.licensesExpiring$.next(false);
          this.customerService.licensesWarning$.next(false);
          this.dialog.closeAll();
          this.router.navigate(['/login'], { replaceUrl: true });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }
}
