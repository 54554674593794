import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { CustomerService } from '@app/services/customer.service';
import { UsersService } from '@app/services/users.service';
import { TABS, USECASES } from '@app/core/constants';
import { Tab, UseCase } from '@app/core/interfaces';
import { WebSocketService } from '@app/services/websocket.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-edit-account-settings',
  templateUrl: './edit-account-settings.component.html',
  styleUrls: ['./edit-account-settings.component.scss'],
})
export class EditAccountSettingsComponent implements OnInit {
  accountSettingsForm!: FormGroup;
  changePasswordForm!: FormGroup;
  userSettingsForm!: FormGroup;
  changeSettings: boolean = false;
  customerSettings: any = {};
  domain: string = '';
  domainTwo: string = '';
  loading: boolean = false;
  tabs: Tab[] = TABS;
  userSettings: any = {};
  useCases: UseCase[] = [];
  roleName: string = '';
  component: any;

  constructor(
    public authService: AuthService,
    public customerService: CustomerService,
    public dialogRef: MatDialogRef<EditAccountSettingsComponent>,
    public fb: FormBuilder,
    private notifier: MatSnackBar,
    public router: Router,
    public socketService: WebSocketService,
    public storage: StorageService,
    public usersService: UsersService
  ) {}

  get username() {
    return this.accountSettingsForm.get('username');
  }

  get email() {
    return this.accountSettingsForm.get('email');
  }

  get logoutTimer() {
    return this.userSettingsForm.get('logoutTimer');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  ngOnInit(): void {
    this.domain = this.customerService.company.Domain.replace('www.', '');
    this.domainTwo = this.customerService.company.DomainTwo
      ? this.customerService.company.DomainTwo.replace('www.', '')
      : '';

    this.customerService.systemSetting$.subscribe((settings: any) => {
      this.customerSettings = settings;
    });
    this.userSettings = this.storage.uvsCreds.userObject?.UserSettings
      ? JSON.parse(this.storage.uvsCreds.userObject.UserSettings)
      : {};
    this.useCases = USECASES.filter((use: UseCase) => {
      if (this.userSettings.useCases) {
        return this.userSettings.useCases.includes(use.value);
      }
      return false;
    });
    this.roleName = this.customerService.roleName;

    this.accountSettingsForm = this.fb.group({
      username: [
        this.storage.uvsCreds.userObject.UserName,
        [
          Validators.required,
          Validators.pattern(
            `([a-z0-9._%+-]+\@(${this.domain}))|([a-z0-9._%+-]+\@(${this.domainTwo}))`
          ),
        ],
      ],
      firstName: [
        this.storage.uvsCreds.userObject.FirstName,
        [Validators.required],
      ],
      lastName: [
        this.storage.uvsCreds.userObject.LastName,
        [Validators.required],
      ],
      phone: [
        this.storage.uvsCreds.userObject.Phone,
        [
          Validators.required,
          Validators.pattern(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
          ),
        ],
      ],
      email: [
        this.storage.uvsCreds.userObject.Email,
        [
          Validators.required,
          Validators.pattern(
            `^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$`
          ),
        ],
      ],
    });

    this.userSettingsForm = this.fb.group({
      defaultLanguage: [this.getUserSetting('defaultLanguage')],
      defaultUseCase: [this.getUserSetting('defaultUseCase')],
      defaultTab: [this.getUserSetting('defaultTab')],
      logoutTimer: [this.getUserSetting('logoutTimer'), [Validators.min(5)]],
      alertBell: [this.getUserSetting('alertBell')],
    });
    //console.log('userSettings', this.userSettings);
    //console.log('customerSettings', this.customerSettings);
  }

  getPasswordLength() {
    return this.customerSettings.MinPasswordLength
      ? this.customerSettings.MinPasswordLength
      : 8;
  }

  getPasswordRequirements() {
    const numCaps = this.customerSettings.PasswordNumCapitals
      ? this.customerSettings.PasswordNumCapitals
      : 1;
    const numNums = this.customerSettings.PasswordNumNumbers
      ? this.customerSettings.PasswordNumNumbers
      : 1;
    const numSymb = this.customerSettings.PasswordNumSymbols
      ? this.customerSettings.PasswordNumSymbols
      : 1;

    const regex = `(?=(.*[A-Z]){${numCaps},})(?=(.*[\\d]){${numNums},})(?=(.*[!@#$%^&*?]){${numSymb},})[A-Za-z\\d!@#$%^&*?]{1,}`;

    const requirements = {
      caps: numCaps,
      nums: numNums,
      symb: numSymb,
      regex: regex,
    };

    return requirements;
  }

  getUserSetting(setting: string) {
    switch (setting) {
      case 'defaultUseCase':
        return this.userSettings.defaultUseCase
          ? this.userSettings.defaultUseCase
          : null;
      case 'defaultLanguage':
        return this.userSettings.defaultLanguage
          ? this.userSettings.defaultLanguage
          : 'English';
      case 'defaultTab':
        return this.userSettings.defaultTab
          ? this.userSettings.defaultTab
          : 'home';
      case 'logoutTimer':
        return this.userSettings.logoutTimer
          ? this.userSettings.logoutTimer
          : 20;
      case 'alertBell':
        return !!this.userSettings.alertBell;
      default:
        return null;
    }
  }

  logout() {
    this.dialogRef.close();
    this.storage.removeStorage();
    this.socketService.disconnect();
    this.router.navigate(['/login'], { replaceUrl: true });
    this.notifier.open('Account settings updated successfully.', '', {
      panelClass: 'success',
    });
  }

  onSubmit() {
    const userId = this.storage.uvsCreds.userObject.Id;
    const accountDetails = this.accountSettingsForm.value;
    const accountSettings = this.changeSettings
      ? {
          useCases: this.userSettings.useCases,
          defaultLanguage: this.userSettingsForm.value.defaultLanguage,
          defaultUseCase: this.userSettingsForm.value.defaultUseCase,
          defaultTab: this.userSettingsForm.value.defaultTab,
          logoutTimer: this.userSettingsForm.value.logoutTimer,
          alertBell: this.userSettingsForm.value.alertBell,
        }
      : this.userSettings;

    this.loading = true;
    this.usersService
      .updateUser(accountDetails, accountSettings, userId)
      .subscribe(
        () => {
          this.loading = false;
          this.notifier.open('Successfully edited the account settings.', '', {
            panelClass: 'success',
          });
        },
        () => {
          this.loading = false;
          this.notifier.open(
            'There was a problem editing the account settings.',
            '',
            {
              panelClass: 'error',
            }
          );
        }
      );
  }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
