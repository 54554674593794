import { NgModule } from '@angular/core';
import { BoldBIService } from './services/boldbi.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AlertsModule } from '@app/alerts/alerts.module';
import { ApiPrefixInterceptor } from '@app/core/api-prefix-interceptor';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { BasicAuthInterceptor } from '@app/core/basic-auth-interceptor';
import { CustomElementsModule } from '@app/custom-elements/custom-elements.module';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { EnvServiceProvider } from '@app/services/env.service.provider';
import { ExpirationWarningComponent } from './expiration-warning/expiration-warning.component';
import { IframesModule } from '@app/iframes/iframes.module';
import { LoginModule } from '@app/login/login.module';
import { MaterialModule } from '@app/material.module';
import { NavModule } from '@app/nav/nav.module';
import { PageNotFoundComponent } from '@app/page-not-found/page-not-found.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DemoOnlyModule } from './demo-only/demo-only.module';
import { FilterSearchComponent } from './nav/filter-search/filter-search.component';
import { GlobalSearchAdvanceComponent } from './global-search-advance/global-search-advance.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ExpirationWarningComponent,
    FilterSearchComponent,
    GlobalSearchAdvanceComponent,
  ],
  imports: [
    AlertsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CustomElementsModule,
    DashboardModule,
    FormsModule,
    HttpClientModule,
    IframesModule,
    LoginModule,
    MaterialModule,
    NavModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedModule,
    DemoOnlyModule,
    TranslateModule.forRoot({
      defaultLanguage: sessionStorage.getItem('lang') || 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    BoldBIService,
    EnvServiceProvider,
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'right',
      },
    },
    { provide: Window, useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
