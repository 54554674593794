import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { CustomerService } from '@app/services/customer.service';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '@app/services/storage.service';
import { WebSocketService } from '@app/services/websocket.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;
  customerSettings: any = {};
  loading: boolean = false;

  constructor(
    public customerService: CustomerService,
    private fb: FormBuilder,
    private authService: AuthService,
    public storage: StorageService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private notifier: MatSnackBar,
    public router: Router,
    public socketService: WebSocketService
  ) {
    this.customerService.systemSetting$.subscribe((settings: any) => {
      this.customerSettings = settings;
    });
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(this.getPasswordRequirements().regex),
            Validators.minLength(this.getPasswordLength()),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: MustMatch('newPassword', 'confirmPassword') }
    );
  }

  getPasswordLength() {
    return this.customerSettings.MinPasswordLength
      ? this.customerSettings.MinPasswordLength
      : 8;
  }

  getPasswordRequirements() {
    const numCaps = this.customerSettings.PasswordNumCapitals
      ? this.customerSettings.PasswordNumCapitals
      : 1;
    const numNums = this.customerSettings.PasswordNumNumbers
      ? this.customerSettings.PasswordNumNumbers
      : 1;
    const numSymb = this.customerSettings.PasswordNumSymbols
      ? this.customerSettings.PasswordNumSymbols
      : 1;

    const regex = `(?=(.*[A-Z]){${numCaps},})(?=(.*[\\d]){${numNums},})(?=(.*[!@#$%^&*?]){${numSymb},})[A-Za-z\\d!@#$%^&*?]{1,}`;

    const requirements = {
      caps: numCaps,
      nums: numNums,
      symb: numSymb,
      regex: regex,
    };

    return requirements;
  }

  logout() {
    this.dialogRef.close();
    this.storage.removeStorage();
    this.socketService.disconnect();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  onSubmit() {
    const { oldPassword, newPassword } = this.changePasswordForm.value;
    const userId = this.storage.uvsCreds.userObject.Id;

    this.authService.changePassword(oldPassword, newPassword, userId).subscribe(
      () => {
        this.loading = false;
        this.notifier.open('Successfully updated the password.', '', {
          panelClass: 'success',
        });
        this.logout();
      },
      () => {
        this.loading = false;
        this.notifier.open('There was a problem changing the password.', '', {
          panelClass: 'error',
        });
      }
    );
  }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
