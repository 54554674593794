import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from '@app/services/storage.service';
import { EnvService } from '@app/services/env.service';
import { PermissionAction, PermissionResource } from '@app/core/constants';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  contractManagerPaths: string[] = [
    'dashboard',
    'devices',
    'debug-tool',
    'forgot-password',
    'infrastructures',
    'login',
    'select-customer',
    'set-password',
  ];

  constructor(
    private customerService: CustomerService,
    private env: EnvService,
    private router: Router,
    private storage: StorageService
  ) {}

  compareDates() {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //console.log(route);
    const uvsCredentials = this.storage.get('uvs-credentials');
    const currentEULADate = this.storage.get('eulaDate');

    const segments: string[] = route.url.map((seg: UrlSegment) => seg.path);
    if (segments.includes('DeviceManagement')) {
      return true;
    }
    if (segments.includes('XpertRestApi')) {
      console.log('trying to reach xra');
      return true;
    }

    if (uvsCredentials) {
      const lastEulaDate = new Date('February 21, 2023 03:24:00');
      const formattedDate = new Date(currentEULADate);

      // contract manager mode
      if (this.env.CMMode) {
        if (this.contractManagerPaths.includes(route.url[0].path)) return true;
        this.router.navigate(['/dashboard']);
        return false;
      }

      // super user mode no EULA
      if (uvsCredentials.userObject.IsSuperUser) {
        return true;
      }

      // eula not signed mode
      if (lastEulaDate > formattedDate) {
        this.router.navigate(['/eula']);
        return false;
      }

      if (route.data.subscription || route.data.permissionRequired) {
        //check if the CUSTOMER has the SUBSCRIPTION

        switch (route.data.subscription) {
          case 'AssetTracking':
            if (this.customerService.subscriptions?.AssetTracking) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'MELT':
            if (this.customerService.subscriptions?.MELT) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'PatientFlow':
            if (this.customerService.subscriptions?.PatientFlow) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'SDCT':
            if (this.customerService.subscriptions?.SDCT) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'StaffSafety':
            if (this.customerService.subscriptions?.StaffSafety) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'TemperatureMonitoring':
            if (this.customerService.subscriptions?.TemperatureMonitoring) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'HandHygiene':
            if (this.customerService.subscriptions?.HandHygiene) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'PeopleFlow':
            if (this.customerService.subscriptions?.PeopleFlow) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          case 'BedManagement':
            if (this.customerService.subscriptions?.BedManagement) {
              break;
            } else {
              this.router.navigate(['/dashboard']);
              return false;
            }
          default:
            //console.log('break, no sub');
            break;
        }

        //then check if USER has PERMISSION (to view)
        if (route.data.permissionRequired) {
          return this.customerService
            .getPermissionFromAPI(route.data.permissionRequired, 'view')
            .pipe(
              tap((allowed) => {
                if (!allowed) this.router.navigate(['/dashboard']);
              })
            );
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      console.log('Sending browser to /login');
      this.router.navigate(['/login']);
      return false;
    }
  }

  getPermission(
    resourceCheck: PermissionResource,
    permission: PermissionAction
  ): boolean {
    //console.log("called from auth guard...")
    //console.log("check for UseCase subscription on the customer object also")
    if (this.customerService.isSuperUser) return true;
    const pO = this.customerService.permissions.find(
      (resource: any) => resource.Name == resourceCheck
    );
    if (pO) {
      switch (permission) {
        case 'view':
          return pO.EnableView;
        case 'edit':
          return pO.EnableEdit;
        case 'add':
          return pO.EnableAdd;
        case 'delete':
          return pO.EnableDelete;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}
