import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventsService } from '@app/services/events.service';

@Component({
  selector: 'app-shared-event-manager',
  templateUrl: './shared-event-manager.component.html',
  styleUrls: ['./shared-event-manager.component.scss'],
})
export class SharedEventManagerComponent implements OnInit {
  @Input() public data: any;
  @Input() customerId: number = 0;

  acknowledgeForm: FormGroup;
  actionForm: FormGroup;
  actionTypes: any = [];
  customAction: boolean = false;
  ruleDef: any;
  userList: any = [];
  xpertMessage: any;

  loading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<SharedEventManagerComponent>,
    public eventsService: EventsService,
    public fb: FormBuilder,
    public notifier: MatSnackBar
  ) {
    this.acknowledgeForm = this.fb.group({
      comment: [''],
    });
    this.actionForm = this.fb.group({
      action: ['', [Validators.required]],
      comment: [''],
      user: [
        //this.eventsService.currentUser,
        '',
        [Validators.required],
      ],
    });
  }

  ngOnInit(): void {
    //ugly kludge but this.selected doesn't seem to contain data until about 1s
    //after the component is loaded.
    //const waiter = (val: any) => new Promise((resolve) => setTimeout(() => resolve(`${val}`), 1500));

    this.eventsService.initializeService();
    this.eventsService
      .getActionTypes()
      .subscribe((types) => (this.actionTypes = types));
    this.eventsService.getUsers().subscribe((users) => (this.userList = users));
    this.loading = false;
    //console.log(this.data)
  }

  acknowledgeEvent() {
    this.loading = true;
    const comment = this.acknowledgeForm.value.comment
      ? this.acknowledgeForm.value.comment
      : 0;
    this.eventsService.acknowledgeEvent(this.data.Id, comment).subscribe(
      () => {
        this.loading = false;
        this.notifier.open('Event acknowledged.', '', {
          panelClass: 'success',
        });
      },
      () => {
        this.loading = false;
        this.notifier.open('There was a problem acknowledging the event.', '', {
          panelClass: 'error',
        });
      }
    );
  }

  canCloseEvent(systemName: string): boolean {
    if (
      systemName === 'SAFETYSWITCH_ALERT' ||
      systemName === 'SAFETYSWITCHREPEAT_ALERT'
    ) {
      if (!this.ruleDef || !this.xpertMessage) return false;

      const allowIfOpen: boolean =
        this.ruleDef.conditions[0]?.attr[0]?.AllowEventClose;
      const switchOpen: boolean =
        this.xpertMessage.DeviceReports[0]?.Status?.Tamper2;

      if (!switchOpen) return true;
      if (switchOpen && allowIfOpen) return true;

      return false;
    }

    return true;
  }

  closeEvent() {
    this.loading = true;
    const form = this.actionForm.value;
    const eventId = this.data.Id;
    const userId = form.user.Id;
    const comment: any = form.comment ? form.comment : 0;

    this.eventsService.closeEvent(comment, userId, eventId).subscribe(
      () => {
        this.loading = false;
        this.dialogRef.close();
        this.data?.table?.selection.clear();
        this.notifier.open('Alert closed.', '', {
          panelClass: 'success',
        });
      },
      () => {
        this.loading = false;
        this.notifier.open(
          'There was a problem closing the event action.',
          '',
          { panelClass: 'error' }
        );
      }
    );
  }

  createEventAction() {
    this.loading = true;
    const form = this.actionForm.value;
    const comment: any = form.comment ? form.comment : 0;
    const actionId = this.customAction ? 0 : form.action.Id;
    const eventId = this.data.Id;
    const actionName = this.customAction ? form.action : form.action.Name;
    const userId = form.user.Id;

    this.eventsService
      .createEventAction(eventId, actionId, actionName, comment, userId)
      .subscribe(
        () => {
          this.loading = false;
          this.notifier.open('Event action applied.', '', {
            panelClass: 'success',
          });
        },
        () => {
          this.loading = false;
          this.notifier.open(
            'There was a problem applying the event action.',
            '',
            {
              panelClass: 'error',
            }
          );
        }
      );
  }

  isAlert() {
    const systemName = this.data.SystemName;
    return systemName.toLowerCase().includes('_alert');
  }

  toggleCustomAction() {
    this.customAction = !this.customAction;
    this.actionForm.get('action')?.setValue('');
  }
}
