import { Injectable } from '@angular/core';
import Icon from 'ol/style/Icon';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import { Text } from 'ol/style';
import Stroke from 'ol/style/Stroke';
import Polygon from 'ol/geom/Polygon';
import GeometryLayout from 'ol/geom/GeometryLayout';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import * as olProj from 'ol/proj';
import {
  AnimationFrame,
  Asset,
  Device,
  Infrastructure,
  Event,
} from '@app/core/interfaces';
import LineString from 'ol/geom/LineString';

@Injectable({
  providedIn: 'root',
})
export class MapRenderService {
  zoneSplitterRegEx: RegExp = new RegExp(',|;');
  constructor() {}

  //ICON STYLES
  invisibleIconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      src: './assets/images/invis.png',
    }),
  });

  zoneStyleNoLabels = new Style({
    stroke: new Stroke({
      color: 'rgb(0, 64, 255)',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(0, 64, 255, 0.2)',
    }),
  });

  //ZONES
  create2DZoneFeature(zone: any, withLabels: boolean = false): Feature | null {
    //don't draw obstructed zones
    if (zone.ZoneType == 'Obstructed') return null;

    //delete trailing semicolon
    if (zone.Polypoints[zone.Polypoints.length - 1] == ';') {
      zone.Polypoints = zone.Polypoints.slice(0, -1);
    }
    //split by regex
    const zPs = zone.Polypoints.split(this.zoneSplitterRegEx);

    //turn 1D array into 2D array
    var twoDPolypoints = [];
    if (zPs.length % 2 != 0) {
    } else {
      for (let i = 0; i < zPs.length; i += 2) {
        twoDPolypoints.push([zPs[i], -zPs[i + 1]]); //YNEG
      }
    }
    var tempZoneFeature = new Feature({
      geometry: new Polygon(Array(twoDPolypoints), GeometryLayout.XY),
      FeatureType: 'zone',
      Name: zone.Name,
      Type: zone.Type,
      Id: zone._id,
      Polypoints: twoDPolypoints,
    });

    if (withLabels) {
      tempZoneFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: 'rgb(0, 64, 255)',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(0, 64, 255, 0.2)',
          }),
          text: new Text({
            textAlign: 'center',
            textBaseline: 'hanging',
            font: '14px Arial',
            text: zone.Name,
            fill: new Fill({ color: 'black' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
            offsetX: 0,
            offsetY: 0,
            placement: 'POINT',
            overflow: true,
          }),
        })
      );
    } else {
      tempZoneFeature.setStyle(this.zoneStyleNoLabels);
    }

    return tempZoneFeature;
  }
  createGPSZoneFeature(zone: any, withLabels: boolean = false) {
    //delete trailing semicolon
    if (zone.Polypoints[zone.Polypoints.length - 1] == ';') {
      zone.Polypoints = zone.Polypoints.slice(0, -1);
    }

    //split by regex
    let zPs = zone.Polypoints.split(this.zoneSplitterRegEx);

    //CONVERT
    zPs = this.convertFromLatLon(zPs);

    //turn 1D array into 2D array
    var twoDPolypoints = [];
    if (zPs.length % 2 != 0) {
    } else {
      for (let i = 0; i < zPs.length; i += 2) {
        twoDPolypoints.push([zPs[i], zPs[i + 1]]);
      }
    }
    var tempZoneFeature = new Feature({
      geometry: new Polygon(Array(twoDPolypoints), GeometryLayout.XY),
      FeatureType: 'zone',
      point: Array(twoDPolypoints),
      Id: zone.Id,
      Name: zone.Name,
      ZoneType: zone.ZoneType,
    });

    if (withLabels) {
      tempZoneFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: 'rgb(0, 64, 255)',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(0, 64, 255, 0.2)',
          }),
          text: new Text({
            textAlign: 'center',
            textBaseline: 'hanging',
            font: '14px Arial',
            text: zone.Name,
            fill: new Fill({ color: 'black' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
            offsetX: 0,
            offsetY: 0,
            placement: 'POINT',
            overflow: true,
          }),
        })
      );
    } else {
      tempZoneFeature.setStyle(this.zoneStyleNoLabels);
    }
    return tempZoneFeature;
  }

  //Staff
  createIconBedFeature(bed: Asset, withLabels: boolean = true) {
    var tempFeature = new Feature({
      geometry: new Point([<number>bed.X, -(<number>bed.Y)]), //YNEG
      FeatureType: 'bed',
      Name: bed.Name,
      Id: bed.Id,
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/images/light_blue_.png',
        scale: 1,
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'hanging',
        font: '14px Arial',
        text: bed.Name,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        offsetX: 0,
        offsetY: 0,
        placement: 'POINT',
        overflow: true,
      }),
    });
    tempFeature.setStyle(tempStyle);
    //console.log(tempFeature);
    return tempFeature;
  }

  //Staff
  createIconStaffFeature(staff: Asset, withLabels: boolean = true) {
    var tempFeature = new Feature({
      geometry: new Point([staff.X, -staff.Y]), //YNEG
      FeatureType: 'staff',
      Name: staff.Name,
      Email: staff.Email,
      Id: staff.Id,
      GroupName: staff.GroupName,
      DepartmentName: staff.DepartmentName,
      DeviceName: staff.DeviceName,
      Portrait: '../../assets/portraits/' + staff.Portrait,
      Icon: staff.Icon ? staff.Icon : '../../assets/icons/light_blue_.png',
      ModelName: staff.ModelName,
      LocationUpdated: staff.LocationUpdated,
      DwellTime: { ZoneName: '', StartDateTime: '', EndDateTime: '' },
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: staff.Icon ? staff.Icon : '../../assets/icons/light_blue_.png',
        scale: 1,
      }),
    });
    if (withLabels) {
      tempStyle.setText(
        new Text({
          textAlign: 'center',
          textBaseline: 'hanging',
          font: '14px Arial',
          text: staff.Name,
          fill: new Fill({ color: 'black' }),
          stroke: new Stroke({ color: 'white', width: 2 }),
          offsetX: 0,
          offsetY: 0,
          placement: 'POINT',
          overflow: true,
        })
      );
    }
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }
  createIconStaffFeatureGPS(staff: Asset, withLabels: boolean = true) {
    var tempLL = olProj.fromLonLat([staff.Longitude, staff.Latitude]);
    var tempFeature = new Feature({
      geometry: new Point([tempLL[0], tempLL[1]]),
      FeatureType: 'staff',
      Name: staff.Name,
      Email: staff.Email,
      Id: staff.Id,
      GroupName: staff.GroupName,
      DepartmentName: staff.DepartmentName,
      DeviceName: staff.DeviceName,
      Portrait: '../../assets/portraits/' + staff.Portrait,
      Icon: staff.Icon ? staff.Icon : '../../assets/icons/light_blue_.png',
      ModelName: staff.ModelName,
      LocationUpdated: staff.LocationUpdated,
      DwellTime: { ZoneName: '', StartDateTime: '', EndDateTime: '' },
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: staff.Icon ? staff.Icon : '../../assets/icons/light_blue_.png',
        scale: 1,
      }),
    });
    if (withLabels) {
      tempStyle.setText(
        new Text({
          textAlign: 'center',
          textBaseline: 'hanging',
          font: '14px Arial',
          text: staff.Name,
          fill: new Fill({ color: 'black' }),
          stroke: new Stroke({ color: 'white', width: 2 }),
          offsetX: 0,
          offsetY: 0,
          placement: 'POINT',
          overflow: true,
        })
      );
    }
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }

  //Device
  createIconDeviceFeature(dev: Device) {
    var tempFeature = new Feature({
      geometry: new Point([dev.X, -dev.Y]), //YNEG
      FeatureType: 'dev',
      Name: dev.Name,
      Id: dev.Id,
      DeviceName: dev.DeviceName,
      ModelName: dev.ModelName,
      LocationUpdated: dev.LocationUpdated,
      DwellTime: { ZoneName: '', StartDateTime: '', EndDateTime: '' },
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/icons/light_blue_.png',
        scale: 1,
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'hanging',
        font: '14px Arial',
        text: dev.Name,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        offsetX: 0,
        offsetY: 0,
        placement: 'POINT',
        overflow: true,
      }),
    });
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }
  createIconDeviceFeatureGPS(dev: Device) {
    var tempLL = olProj.fromLonLat([dev.Longitude, dev.Latitude]);
    var tempFeature = new Feature({
      geometry: new Point([tempLL[0], tempLL[1]]),
      FeatureType: 'dev',
      Name: dev.Name,
      Id: dev.Id,
      DeviceName: dev.DeviceName,
      ModelName: dev.ModelName,
      LocationUpdated: dev.LocationUpdated,
      DwellTime: { ZoneName: '', StartDateTime: '', EndDateTime: '' },
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/icons/light_blue_.png',
        scale: 1,
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'hanging',
        font: '14px Arial',
        text: dev.Name,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        offsetX: 0,
        offsetY: 0,
        placement: 'POINT',
        overflow: true,
      }),
    });
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }

  //Events
  createIconEventFeature(event: any, withLabels: boolean = true) {
    var tempFeature = new Feature({
      geometry: new Point([event.X, -event.Y]), //YNEG
      FeatureType: 'event',
      Name: event.Name,
      Id: event.Id,
      Description: event.Description,
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/images/alert.gif',
        scale: 1,
      }),
    });
    if (withLabels) {
      tempStyle.setText(
        new Text({
          textAlign: 'center',
          textBaseline: 'hanging',
          font: '14px Arial',
          text: event.Name,
          fill: new Fill({ color: 'black' }),
          stroke: new Stroke({ color: 'white', width: 2 }),
          offsetX: 0,
          offsetY: 0,
          placement: 'POINT',
          overflow: true,
        })
      );
    }
    tempFeature.setStyle(tempStyle);
    //console.log(tempFeature);
    return tempFeature;
  }
  createIconEventFeatureGPS(event: any, withLabels: boolean = true) {
    var tempLL = olProj.fromLonLat([event.Longitude, event.Latitude]);
    var tempFeature = new Feature({
      geometry: new Point([tempLL[0], tempLL[1]]),
      FeatureType: 'event',
      Name: event.Name,
      Id: event.Id,
      Description: event.Description,
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/images/alert.gif',
        scale: 1,
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'hanging',
        font: '14px Arial',
        text: event.Name,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        offsetX: 0,
        offsetY: 0,
        placement: 'POINT',
        overflow: true,
      }),
    });
    if (withLabels) {
      tempStyle.setText(
        new Text({
          textAlign: 'center',
          textBaseline: 'hanging',
          font: '14px Arial',
          text: event.Name,
          fill: new Fill({ color: 'black' }),
          stroke: new Stroke({ color: 'white', width: 2 }),
          offsetX: 0,
          offsetY: 0,
          placement: 'POINT',
          overflow: true,
        })
      );
    }
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }

  //Infra
  createIconInfraFeature(infra: Infrastructure) {
    var tempFeature = new Feature({
      geometry: new Point([<number>infra.X, -(<number>infra.Y)]), //YNEG
      FeatureType: 'infra',
      Name: infra.Name,
      Id: infra.Id,
      UniqueId: infra.UniqueId, //mkl 8-10-2023
      DeviceType: infra.DeviceType,
      BatteryLevel: infra.BatteryLevel ? infra.BatteryLevel + '%' : '',
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/images/wifi.png',
        scale: 1,
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'hanging',
        font: '14px Arial',
        text: infra.Name,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        offsetX: 0,
        offsetY: 0,
        placement: 'POINT',
        overflow: true,
      }),
    });
    tempFeature.setStyle(tempStyle);
    //console.log(tempFeature);
    return tempFeature;
  }

  //Follow
  createFollowBracket(asset: Feature): Feature {
    var tempFeature = new Feature({
      geometry: asset.getGeometry(), //YNEG
      FeatureType: 'bracket',
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '/assets/images/bracket.png',
      }),
    });
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }

  createIndicatorArrow(asset: Feature): Feature {
    var tempFeature = new Feature({
      geometry: asset.getGeometry(), //YNEG
      FeatureType: 'bracket',
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0, 0],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '/assets/images/indicator.png',
      }),
    });
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }

  //copy of createFollowBracket 06/28/2023
  createBedStatusBracket(staff: any, asset: Feature) {
    var tempFeature = new Feature({
      geometry: asset.getGeometry(), //YNEG
      FeatureType: 'bracket',
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '/assets/images/' + staff.BedStatus + '.png',
        scale: 1,
      }),
    });

    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }

  //Animation Frame
  createAnimationFeature(frame: AnimationFrame, opacity: number = 1) {
    var tempFeature = new Feature({
      geometry: new Point([frame.X, -frame.Y]), //YNEG
      FeatureType: 'frame',
      Id: frame.Id,
      Description: frame.Description,
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/icons/light_blue_.png',
        scale: 1,
        opacity: opacity,
      }),
    });
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }
  createAnimationFeatureGPS(frame: AnimationFrame, opacity: number = 1) {
    var tempLL = olProj.fromLonLat([frame.Longitude, frame.Latitude]);
    var tempFeature = new Feature({
      geometry: new Point([tempLL[0], tempLL[1]]),
      FeatureType: 'frame',
      Id: frame.Id,
      Description: frame.Description,
    });
    var tempStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: '../../assets/icons/light_blue_.png',
        scale: 1,
        opacity: opacity,
      }),
    });
    tempFeature.setStyle(tempStyle);
    return tempFeature;
  }
  createAnimationLine(featOne: Feature, featTwo: Feature) {
    let extentOne = featOne.getGeometry()?.getExtent();
    let extentTwo = featTwo.getGeometry()?.getExtent();
    if (!extentOne || !extentTwo) {
      return new Feature();
    }
    let XYOne = [extentOne[0], extentOne[1]];
    let XYTwo = [extentTwo[0], extentTwo[1]];

    var featureLine = new Feature({
      //geometry: new LineString([[sx, -sy],[ex, -ey],]), //YNEG
      geometry: new LineString([
        [XYOne[0], XYOne[1]],
        [XYTwo[0], XYTwo[1]],
      ]), //YNEG
      FeatureType: 'line',
      Style: new Style({
        stroke: new Stroke({
          color: 'red',
          width: 4,
          lineDash: [0.1, 5],
        }),
      }),
    });
    return featureLine;
  }

  // GPS CONVERSION
  // takes a 1 dimensional array and returns a 1 dimensional array
  convertToLatLon(inputArray: number[]): any {
    var returnArray: any[] = [];
    for (let i = 0; i < inputArray.length; i += 2) {
      var newCoord = olProj.toLonLat([inputArray[i], inputArray[i + 1]]);
      returnArray.push(newCoord[0]);
      returnArray.push(newCoord[1]);
    }
    return returnArray;
  }
  // takes a 1 dimensional array and returns a 1 dimensional array
  convertFromLatLon(inputArray: number[]): any {
    var returnArray: any[] = [];
    //console.log(inputArray.length);
    for (let i = 0; i < inputArray.length; i += 2) {
      var newCoord = olProj.fromLonLat([inputArray[i], inputArray[i + 1]]);
      returnArray.push(newCoord[0]);
      returnArray.push(-newCoord[1]); //YNEG
      //console.log(newCoord[0], newCoord[1]);
    }
    //console.log(returnArray.length);
    return returnArray;
  }
}
