<div class="wrapper">
  <div class="alert-detail" *ngIf="!loading">
    <div class="headline">
      <span>{{ data.Name }}</span>
      <span *ngIf="data?.Status === 'Acknowledged'" class="acknowledged-event">
        {{ "EVENTS.MANAGE_EVENT.ACKNOWLEDGED" | translate }}
      </span>
      <span *ngIf="data?.Status === 'Closed'" class="closed-event">
        {{ "EVENTS.MANAGE_EVENT.CLOSED" | translate }}
      </span>
      <span *ngIf="data?.Status === 'Open'" class="new-event">
        {{ "EVENTS.MANAGE_EVENT.NEW" | translate }}
      </span>
    </div>
    <div class="alert-content">
      <img
        *ngIf="data.PersonPortraitURL"
        class="alert-content-portrait"
        [src]="'../../assets/portraits/' + data.PersonPortraitURL"
        onerror="this.src='../../assets/images/noMapImage.jpg'"
      />
    </div>
    <div class="alert-content">
      <span>{{ "EVENTS.MANAGE_EVENT.PERSON" | translate }}</span>
      <span class="alert-content-detail"> {{ data.PersonName }}</span>
    </div>
    <div class="alert-content">
      <span>{{ "EVENTS.MANAGE_EVENT.DATE" | translate }}</span>
      <span class="alert-content-detail">{{ data.EventTime }}</span>
    </div>
    <div class="alert-content">
      <span>{{ "EVENTS.MANAGE_EVENT.LOCATION" | translate }} </span>
      <span class="alert-content-detail"> {{ data.ModelName }}</span>
    </div>

    <mat-divider></mat-divider>

    <div class="alert-content">
      <span
        ><b
          ><i>{{ "EVENTS.MANAGE_EVENT.LOGS" | translate }}</i></b
        ></span
      >
    </div>
    <div
      class="alert-log"
      *ngIf="data.AcknowledgedDateTime !== '0001-01-01T00:00:00'"
    >
      <span>{{ "EVENTS.MANAGE_EVENT.ACKNOWLEDGED" | translate }}</span>
      <span class="alert-time">{{ data.AcknowledgedDateTime }}</span>
    </div>
    <div *ngFor="let action of data.EventActions">
      <div class="alert-log" *ngIf="action.ActionType !== 'undefined'">
        <span>{{ action.ActionType }} </span>
        <span class="alert-time">{{ action.ActionDateTime }}</span>
      </div>
    </div>

    <div class="manage-event" *ngIf="!isAlert() || data?.Status === 'Closed'">
      <span>{{
        "EVENTS.MANAGE_EVENT.THIS_ALERT_IS_NOT_MANAGEABLE" | translate
      }}</span>
    </div>

    <div *ngIf="isAlert() && data?.Status !== 'Closed'">
      <div class="manage-event">
        <span>{{ "EVENTS.MANAGE_EVENT.MANAGE_ALERT" | translate }}</span>
      </div>

      <form
        *ngIf="data?.Status === 'Open'"
        class="form-container"
        [formGroup]="acknowledgeForm"
        (ngSubmit)="acknowledgeEvent()"
      >
        <mat-form-field appearance="outline">
          <mat-label>{{
            "EVENTS.MANAGE_EVENT.OPTIONAL_COMMENT" | translate
          }}</mat-label>
          <textarea
            matInput
            placeholder="Leave a comment"
            formControlName="comment"
          ></textarea>
        </mat-form-field>
        <div class="form-buttons">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            [disabled]="acknowledgeForm.invalid"
          >
            {{ "EVENTS.MANAGE_EVENT.ACKNOWLEDGE_ALERT" | translate }}
          </button>
          <button
            (click)="closeEvent()"
            type="button"
            mat-flat-button
            color="primary"
            *ngIf="canCloseEvent(data.SystemName)"
          >
            {{ "FORMS.CLOSE" | translate }}
          </button>
          <button mat-stroked-button mat-dialog-close>
            {{ "FORMS.CANCEL" | translate }}
          </button>
        </div>
      </form>

      <form
        *ngIf="data?.Status === 'Acknowledged'"
        class="form-container"
        [formGroup]="actionForm"
        (ngSubmit)="createEventAction()"
      >
        <mat-checkbox color="primary" (change)="toggleCustomAction()"
          >{{ "EVENTS.MANAGE_EVENT.CUSTOM_ENTRY" | translate }}Custom
          Entry</mat-checkbox
        >

        <mat-form-field appearance="outline" *ngIf="customAction === false">
          <mat-label>{{
            "EVENTS.MANAGE_EVENT.SELECT_ENTRY" | translate
          }}</mat-label>
          <mat-select formControlName="action">
            <mat-option *ngFor="let action of actionTypes" [value]="action">
              {{ action.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="customAction === true">
          <mat-label>{{
            "EVENTS.MANAGE_EVENT.ADD_CUSTOM_ENTRY" | translate
          }}</mat-label>
          <input matInput formControlName="action" />
        </mat-form-field>

        <div class="user-select">
          <span>{{ "EVENTS.MANAGE_EVENT.SUBMITTED_BY" | translate }}</span>
          <mat-form-field appearance="outline">
            <mat-label>{{ "COLUMNS.USER" | translate }}</mat-label>
            <mat-select formControlName="user">
              <mat-option *ngFor="let user of userList" [value]="user">
                {{ user.FullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>{{
            "EVENTS.MANAGE_EVENT.OPTIONAL_COMMENT" | translate
          }}</mat-label>
          <textarea
            matInput
            placeholder="Leave a comment"
            formControlName="comment"
          ></textarea>
        </mat-form-field>
        <div class="form-buttons">
          <button
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="actionForm.invalid"
          >
            {{ "EVENTS.MANAGE_EVENT.APPLY_ACTION" | translate }}
          </button>
          <button
            (click)="closeEvent()"
            type="button"
            mat-flat-button
            color="primary"
            *ngIf="canCloseEvent(data.SystemName)"
          >
            {{ "FORMS.CLOSE" | translate }}
          </button>
          <button mat-stroked-button mat-dialog-close>
            {{ "FORMS.CANCEL" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
