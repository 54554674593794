import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flogo',
  template: `
    <div class="wrapperSub">
      <flogo-view *axLazyElement></flogo-view>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class FlogoComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {}
}
