import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscriptions } from '@app/core/interfaces';
import { CustomerService } from '@app/services/customer.service';
import { EnvService } from '@app/services/env.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss'],
})
export class DashboardViewComponent implements OnInit {
  useCaseSubscriptions!: Subscriptions;
  loading: boolean = true;

  constructor(
    public customerService: CustomerService,
    public env: EnvService,
    public dialog: MatDialog
  ) {
    this.customerService.initializeService();
  }

  ngOnInit(): void {
    this.customerService.getSubcriptions().subscribe((res: any) => {
      //console.log(res);
      this.useCaseSubscriptions = res;
      this.loading = false;
    });
  }
}
