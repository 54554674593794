import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rule-composer',
  template: `
    <div
      [ngClass]="{
        wrapper: subscription === 'rule-composer',
        wrapperSub: subscription !== 'rule-composer'
      }"
    >
      <rule-composer *axLazyElement></rule-composer>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class RuleComposerComponent implements OnInit {
  subscription: string = '';
  constructor() {
    this.subscription = window.location.pathname.split('/')[1];
  }
  ngOnInit(): void {}
}
