import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-logout-message',
  template: `
    <style>
      .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 1.5em;
      }
      .message {
        font-size: 1.15em;
        font-weight: 500;
        margin: 0.25em auto;
        text-align: center;
        width: 300px;
      }
    </style>
    <p class="message">You have been idle for too long.</p>
    <p class="message">
      You will be logged out in <b>{{ time }}</b
      >.
    </p>

    <div class="button-container">
      <button mat-flat-button mat-dialog-close color="primary">
        Continue Browsing
      </button>
      <button mat-flat-button color="warn" (click)="logout()">Logout</button>
    </div>
  `,
})
export class LogoutMessageComponent implements OnInit {
  countdown$ = interval(1000).subscribe(() => this.checkTimeRemaining());
  secondsRemaining: number = 60;
  time: string = '1:00';

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<LogoutMessageComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.countdown$.unsubscribe();
  }

  checkTimeRemaining() {
    this.secondsRemaining -= 1;
    if (this.secondsRemaining === 0) {
      this.countdown$.unsubscribe();
      this.logout();
    }

    const minutes = Math.floor(this.secondsRemaining / 60);
    const seconds = this.secondsRemaining % 60;
    this.time = `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`;
  }

  logout() {
    this.dialogRef.close();
    this.authService.logout().subscribe(
      () => {
        this.router.navigate(['/login'], { replaceUrl: true });
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
