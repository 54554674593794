import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/services/auth.service';
import { StorageService } from '@app/services/storage.service';
import { Router } from '@angular/router';
import { UseCase } from '@app/core/interfaces';
import { USECASES } from '@app/core/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss'],
})
export class CreateCustomerComponent implements OnInit {
  createCustomerForm: FormGroup;
  loading: boolean = false;
  useCases: UseCase[] = USECASES;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateCustomerComponent>,
    private notifier: MatSnackBar,
    private router: Router,
    private storage: StorageService,
    private translate: TranslateService
  ) {
    this.createCustomerForm = this.fb.group(
      {
        companyName: ['', [Validators.required]],
        name: ['', [Validators.required]],
        subscriptions: [[]],
        routeManagement: [[]],
        email: ['', [Validators.required, Validators.email]],
        phone: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
            ),
          ],
        ],
        domain: [
          '',
          [
            Validators.required,
            Validators.pattern(/(www.)+[a-z0-9.-]+\.[a-z]{2,3}$/),
          ],
        ],
        subDomain: [
          '',
          [Validators.pattern(/(www.)+[a-z0-9.-]+\.[a-z]{2,3}$/)],
        ],
      },
      { validator: MustMatch('email', 'domain', 'subDomain') }
    );
  }

  get email() {
    return this.createCustomerForm.get('email');
  }

  ngOnInit(): void {}

  clearRoutes() {
    this.createCustomerForm.get('routeManagement')?.setValue([]);
  }

  hasSelectedSub() {
    const selected = this.createCustomerForm.value.subscriptions;
    return this.useCases.filter((use: UseCase) => selected.includes(use.value));
  }

  onSubmit() {
    this.loading = true;
    const formData = this.createCustomerForm.value;
    const selectedSubs: any = this.setSubscriptions(
      formData.subscriptions,
      formData.routeManagement
    );
    this.authService.createCustomer(formData, selectedSubs).subscribe(
      (res: any) => {
        this.dialogRef.close();
        this.storage.setCustomerInfo({
          id: res.CustomerId,
          name: res.Name,
        });
        this.router.navigate(['/dashboard'], { replaceUrl: true });
        this.notifier.open(
          this.translate.instant('NOTIFIER.SUCCESS_CREATE'),
          '',
          { panelClass: 'success' }
        );
      },
      () => {
        this.loading = false;
        this.notifier.open(
          this.translate.instant('NOTIFIER.ERROR_CREATE'),
          '',
          { panelClass: 'error' }
        );
      }
    );
  }

  setSubscriptions(subs: string[], routes: string[]) {
    let subscriptions: any = {
      AssetTracking: false,
      HandHygiene: false,
      MELT: false,
      PatientFlow: false,
      PeopleFlow: false,
      SDCT: false,
      StaffSafety: false,
      TemperatureMonitoring: false,
    };

    let routeManagement: any = {
      AssetTracking: false,
      MELT: false,
      PatientFlow: false,
      PeopleFlow: false,
      SDCT: false,
      StaffSafety: false,
    };

    subs.forEach((sub: string) => (subscriptions[sub] = true));
    routes.forEach((route: string) => (routeManagement[route] = true));

    subscriptions.routeManagement = routeManagement;

    return subscriptions;
  }
}

// custom validator to check that email matches domains
export function MustMatch(s1: string, s2: string, s3: string) {
  return (formGroup: FormGroup) => {
    const email = formGroup.controls[s1];
    const domain = formGroup.controls[s2];
    const subDomain = formGroup.controls[s3];

    const formatDomain = domain.value
      .split('.')
      .filter((part: any) => part !== 'www')
      .join('.')
      .trim();
    const formatSubDomain =
      subDomain.value !== ''
        ? subDomain.value
            .split('.')
            .filter((part: any) => part !== 'www')
            .join('.')
            .trim()
        : formatDomain;

    const emailBack = email.value.split('@')[1];

    if (email.errors && !email.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (emailBack !== formatDomain && emailBack !== formatSubDomain) {
      //console.log(emailBack, formatDomain);
      email.setErrors({ mustMatch: true });
    } else {
      email.setErrors(null);
    }
  };
}
