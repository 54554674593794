<h2 *ngIf="data?.Name">{{ data.Name }}</h2>

<div class="wrapper">
  <img
    class="portrait"
    [src]="'../../assets/portraits/' + data.Portrait"
    onerror="this.src='../../assets/images/noMapImage.jpg'"
  />
  <br />

  <div class="info-line">
    <div class="label">{{ "COLUMNS.PHONE_NUMBER" | translate }}</div>
    <div *ngIf="data.PhoneNumber" class="detail">
      {{ data.PhoneNumber }}
    </div>
    <div *ngIf="!data.PhoneNumber" class="detail" style="color: grey">N/A</div>
  </div>

  <div class="info-line">
    <div class="label">{{ "COLUMNS.EMAIL_ADDRESS" | translate }}</div>
    <div *ngIf="data.EmailAddress" class="detail">
      {{ data.EmailAddress }}
    </div>
    <div *ngIf="!data.EmailAddress" class="detail" style="color: grey">N/A</div>
  </div>

  <div class="info-line">
    <div class="label">{{ "COLUMNS.GROUP" | translate }}</div>
    <div *ngIf="data.GroupName" class="detail">
      {{ data.GroupName }}
    </div>
    <div *ngIf="!data.GroupName" class="detail" style="color: grey">N/A</div>
  </div>

  <div class="info-line">
    <div class="label">{{ "COLUMNS.DEPARTMENT" | translate }}</div>
    <div *ngIf="data.DepartmentName" class="detail">
      {{ data.DepartmentName }}
    </div>
    <div *ngIf="!data.DepartmentName" class="detail" style="color: grey">
      N/A
    </div>
  </div>

  <div class="info-line">
    <div class="label">{{ "COLUMNS.ASSOC_DEVICES" | translate }}</div>
    <div *ngIf="data?.AssociatedDevices" class="detail">
      {{ data.AssociatedDevices.length }}
    </div>
  </div>
</div>

<br />

<div>
  <app-shared-map [data]="data" [inputType]="'asset'"></app-shared-map>
</div>
