<div class="wrapper">
  <div class="container">
    <div class="header">{{ "MAIN.FORGOT.HEADER" | translate }}</div>
    <p class="text">
      {{ "MAIN.FORGOT.TEXT" | translate }}
    </p>
    <form class="form" [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
      <input
        class="input"
        placeholder="{{ 'MAIN.FORGOT.FORM_PLACEHOLDER' | translate }}"
        type="text"
        formControlName="username"
      />
      <div class="form-buttons">
        <button [disabled]="!forgotPasswordForm.valid" class="button-primary">
          <mat-spinner
            class="spinner"
            color="accent"
            diameter="21"
            *ngIf="loading"
          ></mat-spinner>
          <span *ngIf="!loading">{{
            "MAIN.FORGOT.SEND_EMAIL" | translate
          }}</span>
        </button>
        <button class="button-cancel" (click)="redirectToLogin()">
          {{ "MAIN.FORGOT.CANCEL" | translate }}
        </button>
      </div>
    </form>
  </div>
  <img
    [ngClass]="env.international ? 'enabled-flag' : 'disabled-flag'"
    [src]="storage.countryFlagUrl"
    [matMenuTriggerFor]="langs"
  />
  <mat-menu #langs="matMenu">
    <button
      class="country"
      *ngFor="let lang of storage.languages"
      (click)="storage.setLang(lang.value)"
      mat-menu-item
    >
      <span>
        {{ "MAIN.LANGUAGES." + lang.value | translate }}
      </span>
      <img class="flag" [src]="getCountryFlag(lang.value)" />
    </button>
  </mat-menu>

  <div class="copyright">
    {{ "EULA.COPYRIGHT" | translate }}
    <br />
    Version 7.{{ version }}
  </div>
</div>
