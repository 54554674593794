<div class="drop-container">
  <div class="section">
    <div class="icon">
      <mat-icon [inline]="true">person_outline</mat-icon>
    </div>
    <div class="section-content">
      <span
        ><strong
          >{{ storage.uvsCreds?.userObject.FirstName }}
          {{ storage.uvsCreds?.userObject.LastName }}</strong
        ></span
      >
      <span>{{ storage.uvsCreds?.userObject.UserName }}</span>
      <span
        ><strong>{{ storage.uvsCreds?.userObject.RoleName }}</strong></span
      >
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="sub-section" (click)="editAccountSettingsPopup()">
    {{ "MAIN.NAV.MENU_COMPANY.SETTINGS" | translate }}
  </div>
  <div class="sub-section" (click)="editPasswordPopup()">
    {{ "MAIN.NAV.MENU_COMPANY.CHANGEPASSWORD" | translate }}
  </div>
  <mat-divider></mat-divider>

  <div *ngIf="customerService.getPermission('SUPER_USER_ONLY', 'view')">
    <div
      class="sub-section"
      *ngIf="customerService.getPermission('SUPER_USER_ONLY', 'view')"
      (click)="switchProfiles()"
    >
      {{ "MAIN.NAV.MENU_COMPANY.SWITCH" | translate }}
    </div>
    <div
      class="sub-section"
      *ngIf="customerService.getPermission('SUPER_USER_ONLY', 'view')"
      (click)="createNewProfile()"
    >
      {{ "MAIN.NAV.MENU_COMPANY.CREATE" | translate }}
    </div>
    <mat-divider></mat-divider>
  </div>

  <div class="logout" (click)="logout()">
    {{ "MAIN.NAV.MENU_COMPANY.LOGOUT" | translate }}
  </div>
</div>
