import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/auth.guard';

import { AssetsComponent } from '@app/custom-elements/assets/assets.component';
import { CompanyInfoComponent } from '@app/custom-elements/company-info/company-info.component';
import { DashboardViewComponent } from '@app/dashboard/dashboard-view/dashboard-view.component';
import { DashboardIframeComponent } from '@app/iframes/dashboard/dashboard-iframe.component';
import { DebugToolComponent } from '@app/custom-elements/debug-tool/debug-tool.component';
import { DevicesComponent } from '@app/custom-elements/devices/devices.component';
import { EmailSendComponent } from '@app/login/email-send/email-send.component';
import { EventsComponent } from '@app/custom-elements/events/events.component';
import { FirmwareComponent } from '@app/custom-elements/firmware/firmware.component';
import { ForgotPasswordComponent } from '@app/login/forgot-password/forgot-password.component';
import { HomeIframeComponent } from '@app/iframes/home/home-iframe.component';
import { InfrastructuresComponent } from '@app/custom-elements/infrastructures/infrastructures.component';
import { LoginViewComponent } from '@app/login/login-view/login-view.component';
import { MapEditorComponent } from '@app/custom-elements/map-editor/map-editor.component';
import { MapRealtimeComponent } from '@app/custom-elements/map-realtime/map-realtime.component';
import { PageNotFoundComponent } from '@app/page-not-found/page-not-found.component';
import { ReportIframeComponent } from '@app/iframes/report/report.component';
import { ReportsComponent } from '@app/custom-elements/reports/reports.component';
import { RoutesComponent } from '@app/custom-elements/routes/routes.component';
import { RuleComposerComponent } from '@app/custom-elements/rule-composer/rule-composer.component';
import { SelectCustomerViewComponent } from '@app/login/select-customer-view/select-customer-view.component';
import { ShellComponent } from '@app/nav/shell/shell.component';
import { SetPasswordComponent } from '@app/login/set-password/set-password.component';
import { ServerStatusComponent } from '@app/custom-elements/server-status/server-status.component';
import { SubSettingsComponent } from '@app/custom-elements/settings/sub-settings.component';
import { SystemSettingsComponent } from '@app/custom-elements/system-settings/system-settings.component';
import { UsersComponent } from '@app/custom-elements/users/users.component';
import { FlogoComponent } from '@app/custom-elements/flogo/flogo.component';
import { EulaViewComponent } from './login/eula-view/eula-view.component';
import { AnalyticsComponent } from './iframes/analytics/analytics.component';
import { ScreensaverComponent } from './demo-only/screensaver/screensaver.component';
import { GlobalSearchAdvanceComponent } from './global-search-advance/global-search-advance.component';

//these are 2nd level ones
const shellRoutes = [
  {
    path: 'assets',
    component: AssetsComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Staff',
    },
  },
  { path: 'dashboard', component: DashboardIframeComponent },
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Devices',
    },
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Events',
    },
  },
  { path: 'home', component: HomeIframeComponent },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Reports',
    },
  },
  { path: 'report-view', component: ReportIframeComponent },
  {
    path: 'route-manager',
    component: RoutesComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Routes',
    },
  },
  {
    path: 'settings',
    component: SubSettingsComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Settings',
    },
  },
  {
    path: 'sub-map',
    component: MapRealtimeComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Maps',
    },
  },
  {
    path: 'sub-rule',
    component: RuleComposerComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Rules',
    },
  },
  {
    path: 'sub-users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Users',
    },
  },
  { path: 'flow', component: FlogoComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

//these are the primary level URL extensions that the browser can accept, without pointing to a 404
const routes: Routes = [
  {
    path: 'asset-tracking',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'AssetTracking',
      permissionRequired: 'AssetTracking',
    },
    children: shellRoutes,
  },
  {
    path: 'company-information',
    component: CompanyInfoComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Settings',
    },
  },
  {
    path: 'dashboard',
    component: DashboardViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'debug-tool',
    component: DebugToolComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Settings',
    },
  },
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Devices',
    },
  },
  { path: 'email-sent', component: EmailSendComponent },
  {
    path: 'eula',
    component: EulaViewComponent,
  },

  {
    path: 'firmware',
    component: FirmwareComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Settings',
    },
  },
  {
    path: 'flow',
    component: FlogoComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Settings',
    },
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: 'hand-hygiene',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'HandHygiene',
      permissionRequired: 'HandHygiene',
    },
    children: shellRoutes,
  },
  {
    path: 'infrastructures',
    component: InfrastructuresComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Infrastructures',
    },
  },
  { path: 'login', component: LoginViewComponent },
  {
    path: 'map-editor',
    component: MapEditorComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Maps',
    },
  },
  {
    path: 'melt',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'MELT',
      permissionRequired: 'MELT',
    },
    children: shellRoutes,
  },
  {
    path: 'patient-flow',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'PatientFlow',
      permissionRequired: 'PatientFlow',
    },
    children: shellRoutes,
  },
  {
    path: 'people-flow',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'PeopleFlow',
      permissionRequired: 'PeopleFlow',
    },
    children: shellRoutes,
  },
  {
    path: 'bed-management',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'BedManagement',
      permissionRequired: 'BedManagement',
    },
    children: shellRoutes,
  },
  {
    path: 'sdct',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'SDCT',
      permissionRequired: 'SDCT',
    },
    children: shellRoutes,
  },
  {
    path: 'select-customer',
    component: SelectCustomerViewComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  //MKL 6-18-2024 Made this available without log in
  {
    path: 'server-status',
    component: ServerStatusComponent,
    // canActivate: [AuthGuard],
    data: {
      //permissionRequired: 'Settings',
    },
  },
  { path: 'set-password', component: SetPasswordComponent },
  {
    path: 'staff-safety',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'StaffSafety',
      permissionRequired: 'StaffSafety',
    },
    children: shellRoutes,
  },
  {
    path: 'system-settings',
    component: SystemSettingsComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Settings',
    },
  },
  {
    path: 'temperature-monitoring',
    component: ShellComponent,
    canActivate: [AuthGuard],
    data: {
      subscription: 'TemperatureMonitoring',
      permissionRequired: 'TemperatureMonitoring',
    },
    children: shellRoutes,
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Users',
    },
  },
  {
    path: 'rule-composer',
    component: RuleComposerComponent,
    canActivate: [AuthGuard],
    data: {
      permissionRequired: 'Rules',
    },
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'screensaver',
    component: ScreensaverComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'search',
    component: GlobalSearchAdvanceComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  //MKL not sure why I have to do this now
  // {
  //   path: '/XpertRestApi',
  //   redirectTo: 'http://52.45.17.177:802/XpertRestApi/swagger/ui/index',
  // },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
