import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-devices',
  template: `
    <div
      [ngClass]="{
        wrapper: subscription === 'devices',
        wrapperSub: subscription !== 'devices'
      }"
    >
      <devices-view *axLazyElement></devices-view>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class DevicesComponent implements OnInit {
  subscription: string = '';
  constructor() {
    this.subscription = window.location.pathname.split('/')[1];
  }

  ngOnInit(): void {}
}
