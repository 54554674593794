import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Asset, Event } from '@app/core/interfaces';
import { EventsService } from '@app/services/events.service';
import { WebSocketService } from '@app/services/websocket.service';

@Component({
  selector: 'app-screensaver',
  templateUrl: './screensaver.component.html',
  styleUrls: ['./screensaver.component.scss'],
})
export class ScreensaverComponent implements OnInit, OnDestroy {
  interloper: Asset | null = null;

  constructor(
    public socketService: WebSocketService,
    public eventService: EventsService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.interloper = null;

    this.socketService.getSocketWithoutLogin(); //MKL 12-5-2023 CoE Demo

    this.socketService.newEvent$.subscribe((event: Event | null) => {
      console.log('screensaver potential event: ', event);
      if (event != null) {
        if (event.SystemName.includes('AREA_ALERT') && event.ItemId) {
          //filtering it on a specific rule condition
          this.eventService
            .getStaffById(event.ItemId)
            .subscribe((staff: any) => {
              //makes sure that the event triggered is actually about a person
              this.interloper = staff;
              //MKL 12-11-2023 turn back off in 10 seconds
              setTimeout(() => {
                this.interloper = null;
              }, 10000);
            });
        } else {
          console.log('ALERT event without an itemID');
        }
      }
    });
  }

  ngOnDestroy() {
    this.interloper = null;
  }

  dismissBio() {
    this.interloper = null;
  }
}
