import { Injectable } from '@angular/core';
import { AES } from 'crypto-js';
import { BehaviorSubject } from 'rxjs';
import { EnvService } from './env.service';
import { Language, LoginForm } from '@app/core/interfaces';
import { LANGUAGES, SYN } from '@app/core/constants';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  countryFlagUrl: string = 'assets/country-flags/en.png';
  lang: string = '';
  eulaDate: string = '';
  languages: Language[] = LANGUAGES;
  Storage: Storage;
  company: any = null;
  //ucreds: string = '';
  uvsCreds: any = null;
  stopTimer$ = new BehaviorSubject(true);

  constructor(public env: EnvService) {
    this.Storage = window.sessionStorage;
    this.initialize();
  }

  get(key: string): any {
    if (key === 'lang') {
      // lang is stored as a simple string
      const storage: any = this.Storage.getItem(key);
      if (storage) {
        this.countryFlagUrl = `assets/country-flags/${storage}.png`;
      }
      return storage ? storage : '';
    } else if (key == 'eulaDate') {
      // eula date is a string
      const storage: any = this.Storage.getItem(key);
      return storage ? storage : 'January 1, 1970 01:00:00';
    }

    // else storage is a JSON object:
    const storage: any = this.Storage.getItem(key);
    return storage ? JSON.parse(storage) : null;
  }

  initialize() {
    if (!this.get('lang')) {
      this.setLang(this.env.defaultLang);
    }
    this.company = this.get('company');
    this.lang = this.get('lang');
    //this.ucreds = this.get('ucreds');
    this.uvsCreds = this.get('uvs-credentials');
  }

  hasUVSCreds(): boolean {
    return this.uvsCreds ? true : false;
  }

  removeStorage(): void {
    this.Storage.removeItem('company');
    this.Storage.removeItem('ucreds');
    this.Storage.removeItem('uvs-credentials');
    this.Storage.removeItem('permissions');
    this.company = undefined;
    //this.ucreds = '';
    this.uvsCreds = undefined;

    this.setLang(this.env.defaultLang);
    this.stopTimer$.next(true);
  }

  removeCustomerInfo(): void {
    this.Storage.removeItem('company');
    this.company = undefined;
    this.stopTimer$.next(true);
  }

  setCustomerInfo(value: any): void {
    this.Storage.setItem('company', JSON.stringify(value));
    this.company = value;
    this.stopTimer$.next(false);
  }

  setLang(value: string): void {
    this.Storage.setItem('lang', value);
    this.lang = value;
    this.countryFlagUrl = `assets/country-flags/${value}.png`;
    window.location.reload();
    this.stopTimer$.next(false);
  }

  setEULADate(value: string): void {
    this.Storage.setItem('eulaDate', value);
    this.eulaDate = value;
  }

  // setUcreds(logInForm: LoginForm): void {
  //   this.Storage.setItem(
  //     'ucreds',
  //     JSON.stringify(btoa(logInForm.username + ':' + logInForm.password))
  //   );
  //   this.ucreds = btoa(logInForm.username + ':' + logInForm.password);
  // }

  setUvsCreds(value: any, ldapReassign: boolean = false): void {
    let newStorage = value;

    if (!ldapReassign) {
      const hashPass = AES.encrypt(
        value.token,
        [SYN[0], SYN[2], SYN[4]].join('')
      ).toString();
      newStorage.token = hashPass;
    }

    this.Storage.setItem('uvs-credentials', JSON.stringify(newStorage));
    this.uvsCreds = value;
  }
}
