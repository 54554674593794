import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { NavbarComponent } from './navbar/navbar.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ShellComponent } from './shell/shell.component';
import { LogoutMessageComponent } from './logout-message/logout-message.component';
import { EditAccountSettingsComponent } from './edit-account-settings/edit-account-settings.component';
import { TilesMenuComponent } from './tiles-menu/tiles-menu.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  declarations: [
    NavbarComponent,
    NavMenuComponent,
    ShellComponent,
    LogoutMessageComponent,
    EditAccountSettingsComponent,
    TilesMenuComponent,
    ChangePasswordComponent,
  ],
  exports: [NavbarComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
})
export class NavModule {}
