import { Component, OnInit } from '@angular/core';
import { CustomerService } from '@app/services/customer.service';
import { EnvService } from '@app/services/env.service';

@Component({
  selector: 'app-tiles-menu',
  templateUrl: './tiles-menu.component.html',
  styleUrls: ['./tiles-menu.component.scss'],
})
export class TilesMenuComponent implements OnInit {
  constructor(
    public customerService: CustomerService,
    public env: EnvService
  ) {}

  ngOnInit(): void {}

  hasRuleComposer() {
    const subs = this.customerService.subscriptions;
    if (
      subs.AssetTracking ||
      subs.PatientFlow ||
      subs.StaffSafety ||
      subs.PeopleFlow
    )
      return true;

    const ruleManagement = subs.ruleManagement;
    for (const key in ruleManagement) {
      if (ruleManagement[key]) {
        return true;
      }
    }
    return false;
  }
}
