import {
  // AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from '@app/services/storage.service';
// import { BoldBIService } from '@app/services/boldbi.service';

@Component({
  selector: 'app-dashboard',
  template: `
    <iframe
      #dashboard
      frameborder="0"
      style="
    height: 100%;
    margin-bottom: -50px;
    width: 100%;
  "
    ></iframe>
  `,
})

// Add back in AfterViewInit after OnInit when ready
export class DashboardIframeComponent implements OnInit {
  @ViewChild('dashboard') iframe!: ElementRef;

  iframeLink: string = '';
  loginToken: string = '';
  timeZoneOffset: number = 0;
  userId: number = 0;
  // Remove these two when ready
  subscription: string = '';
  URLPrefix: string = '';

  // dashboardId: string = '';
  // siteIdentifier: string = '';
  // userEmail: string = '';
  // nonce: string = '';
  // embedSecretKey: string = ``;
  // signature: string = '';
  // companyId: number = 0;

  constructor(
    public customerService: CustomerService,
    public storageService: StorageService // public boldbiService: BoldBIService
  ) {
    // Remove all of this when ready
    this.subscription = window.location.pathname.split('/')[1];

    const d = new Date();
    this.timeZoneOffset = d.getTimezoneOffset();
  }

  ngOnInit() {
    let userData = this.storageService.get('uvs-credentials');

    if (userData) {
      this.loginToken = userData.userObject.LoginToken;
      this.userId = userData.userObject.Id;
    }

    this.setIframeUrl();
  }

  ngAfterViewInit() {
    this.iframe.nativeElement.setAttribute('src', this.iframeLink);
  }

  setIframeUrl() {
    switch (this.subscription) {
      case 'asset-tracking':
        this.URLPrefix = 'AssetTracking';
        break;
      case 'hand-hygiene':
        this.URLPrefix = 'HandHygiene';
        break;
      case 'melt':
        this.URLPrefix = 'MELT';
        break;
      case 'patient-flow':
        this.URLPrefix = 'PatientFlow';
        break;
      case 'people-flow':
        this.URLPrefix = 'PeopleFlow';
        break;
      case 'sdct':
        this.URLPrefix = 'SDCT';
        break;
      case 'staff-safety':
        this.URLPrefix = 'StaffSafety';
        break;
      case 'temperature-monitoring':
        this.URLPrefix = 'TemperatureMonitoring';
        break;
      case 'bed-management':
        this.URLPrefix = 'BedManagement';
        break;
      default:
        break;
    }

    this.iframeLink =
      `/Xpert${this.URLPrefix}Dashboard/?CustomerId=${this.customerService.customerId}` +
      `&uid=${this.userId}` +
      `&tkn=${this.loginToken}` +
      `&TimeZoneOffset=${this.timeZoneOffset}` +
      `&UseCase=${this.URLPrefix}`;
  }

  // Uncomment out when ngOnInit() and everything below is removed (above) and then uncomment out the currently commented out code
  // ngOnInit() {
  //   const userData = this.storageService.get('uvs-credentials');
  //   this.companyId = this.customerService.customerId || 0;
  //   this.loginToken = userData?.userObject.LoginToken || '';
  //   this.userId = userData?.userObject.Id || 0;

  //   this.nonce = this.boldbiService.generateNonce();
  //   this.userEmail = this.boldbiService.userEmail;
  //   this.embedSecretKey = this.boldbiService.embedSecret;

  //   const embedParameter = `embed_nonce=${this.nonce}&embed_user_email=${this.boldbiService.userEmail}`;
  //   this.signature = this.boldbiService.getSignatureUrl(
  //     embedParameter,
  //     this.embedSecretKey
  //   );

  //   const subscription = window.location.pathname.split('/')[1];

  //   switch (subscription) {
  //     case 'asset-tracking':
  //       this.siteIdentifier = 'Asset Tracking';
  //       this.dashboardId = '91b98a6d-57f2-49c4-933d-47b4a5bf7160';
  //       break;
  //     case 'hand-hygiene':
  //       this.siteIdentifier = 'Hand Hygiene';
  //       this.dashboardId = 'c0e1a4a3-3c60-421f-b254-d9eba42168cd';
  //       break;
  //     case 'melt':
  //       this.siteIdentifier = 'MELT';
  //       this.dashboardId = '';
  //       break;
  //     case 'patient-flow':
  //       this.siteIdentifier = 'Patient Flow';
  //       this.dashboardId = 'b2c17f06-8555-4a68-afcd-05583d7e0019';
  //       break;
  //     case 'people-flow':
  //       this.siteIdentifier = 'People Flow';
  //       this.dashboardId = '';
  //       break;
  //     case 'sdct':
  //       this.siteIdentifier = 'SDCT';
  //       this.dashboardId = '';
  //       break;
  //     case 'staff-safety':
  //       this.siteIdentifier = 'Staff Safety';
  //       this.dashboardId = '8fc2d06b-c819-4bbd-857b-d1c652599b2d';
  //       break;
  //     case 'temperature-monitoring':
  //       this.siteIdentifier = 'Temperature Monitoring';
  //       this.dashboardId = '';
  //       break;
  //     case 'bed-management':
  //       this.siteIdentifier = 'Bed Management';
  //       this.dashboardId = '006be8b1-2398-4de5-8721-10b71e9c7f59';
  //       break;
  //     default:
  //       break;
  //   }

  //   this.iframeLink = this.boldbiService.iframeURL(
  //     this.dashboardId,
  //     this.siteIdentifier,
  //     this.nonce,
  //     this.signature,
  //     this.companyId
  //   );
  //   console.log('iframelink', this.iframeLink);
  // }

  // ngAfterViewInit() {
  //   this.iframe.nativeElement.setAttribute('src', this.iframeLink);
  // }
}
