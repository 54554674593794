import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/material.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { ConfigDetailsComponent } from './config-details/config-details.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { SingleDeviceSettingsComponent } from './single-device-settings/single-device-settings.component';
import { SharedMapComponent } from './shared-map/shared-map.component';
import { SharedGpsMapComponent } from './shared-gps-map/shared-gps-map.component';
import { SharedSendTagMsgComponent } from './shared-send-tag-msg/shared-send-tag-msg.component';
import { SharedEventManagerComponent } from './shared-event-manager/shared-event-manager.component';
import { SharedAssetDetailsComponent } from './shared-asset-details/shared-asset-details.component';
import { SharedBreadcrumbFrameComponent } from './shared-breadcrumb-frame/shared-breadcrumb-frame.component';
import { BreadcrumbAssetComponent } from './shared-breadcrumb-frame/breadcrumb-asset/breadcrumb-asset.component';
import { BreadcrumbDeviceComponent } from './shared-breadcrumb-frame/breadcrumb-device/breadcrumb-device.component';
import { BreadcrumbGroupComponent } from './shared-breadcrumb-frame/breadcrumb-group/breadcrumb-group.component';
import { BreadcrumbDeptComponent } from './shared-breadcrumb-frame/breadcrumb-dept/breadcrumb-dept.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ConfigDetailsComponent,
    ConfirmPopupComponent,
    SingleDeviceSettingsComponent,
    SharedMapComponent,
    SharedGpsMapComponent,
    SharedSendTagMsgComponent,
    SharedEventManagerComponent,
    SharedAssetDetailsComponent,
    SharedBreadcrumbFrameComponent,
    BreadcrumbAssetComponent,
    BreadcrumbDeviceComponent,
    BreadcrumbGroupComponent,
    BreadcrumbDeptComponent,
  ],
  exports: [
    ConfigDetailsComponent,
    ConfirmPopupComponent,
    SingleDeviceSettingsComponent,
    SharedMapComponent,
    SharedGpsMapComponent,
    SharedSendTagMsgComponent,
    SharedEventManagerComponent,
    SharedAssetDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forRoot({
      defaultLanguage: sessionStorage.getItem('lang') || 'en', //MKL 7-22-2024
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
})
export class SharedModule {}
