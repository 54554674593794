import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Feature, Map as OpMap, View } from 'ol/';
import { Zoomify } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import { getCenter } from 'ol/extent';
import { ZoomSlider } from 'ol/control';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Projection from 'ol/proj/Projection';
import { MapRenderService } from '@app/services/map-render.service';
import { Asset, Device, Event } from '@app/core/interfaces';
import { EventsService } from '@app/services/events.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shared-map',
  template: `<div class="map" id="map">{{ errorMessage }}</div>`,
  styleUrls: ['./shared-map.component.scss'],
})
export class SharedMapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data!: Asset | Device | Event | any;
  @Input() inputType: 'asset' | 'infra' | 'device' | 'event' | 'error' =
    'error';

  errorMessage: string = '';
  mapId: number = 0;
  mapData: any;
  zones: any;
  zoneFeatures: Feature[] = [];
  pinFeatures: Feature[] = [];

  constructor(
    public mapService: EventsService,
    public renderService: MapRenderService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.mapService.initializeService();
    this.mapId = this.extractMapId(this.data);
    if (this.mapId != 0) {
      this.mapService.getMapById(this.mapId).subscribe(
        (res: any) => {
          this.drawMap(res);
        },
        () => {
          this.errorMessage = this.translate.instant('MAIN.ALERTS.NO_LOCATION');
        }
      );
    } else {
      this.errorMessage = this.translate.instant('MAIN.ALERTS.NO_LOCATION');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mapService.initializeService();
    this.mapId = this.extractMapId(this.data);
    if (this.mapId != 0) {
      this.mapService.getMapById(this.mapId).subscribe(
        (res: any) => {
          this.drawMap(res);
        },
        () => {
          this.errorMessage = this.translate.instant('MAIN.ALERTS.NO_LOCATION');
        }
      );
    } else {
      this.errorMessage = this.translate.instant('MAIN.ALERTS.NO_LOCATION');
    }
  }

  ngOnDestroy(): void {
    //console.log('destroying inset map component');
    this.mapData = {};
  }

  drawMap(res: any) {
    //Avoid opacity buildup bug
    this.zones = [];
    this.zoneFeatures = [];

    this.mapData = res;
    this.mapData.Zones.forEach((zone: any) => {
      const zz = this.renderService.create2DZoneFeature(zone);
      zz && this.zoneFeatures.push(zz);
    });

    this.pinFeatures.push(this.extractPinFeature(this.data, this.inputType));

    var extent: any = [0, 0, this.mapData.Width, -this.mapData.Height];
    var projection = new Projection({
      code: 'EPSG:4326',
      units: 'pixels',
      extent: [0, 0, this.mapData.Width, -this.mapData.Height],
    });
    var tileLayer = new TileLayer({
      source: new Zoomify({
        url: `../maps/${this.mapData.ModelId}/${this.mapData.ModelId}/`,
        size: [this.mapData.Width, this.mapData.Height],
      }),
    });
    var zoneLayer = new VectorLayer({
      source: new VectorSource({ features: this.zoneFeatures }),
    });

    if (this.pinFeatures.length > 0) {
      var vectorPinLayer = new VectorLayer({
        source: new VectorSource({ features: this.pinFeatures }),
      });
    } else {
      var vectorPinLayer = new VectorLayer({
        source: new VectorSource({ features: [] }),
      });
    }

    var oldmap = document.querySelector('#map');
    while (oldmap?.firstChild) {
      oldmap.removeChild(oldmap.firstChild);
    }

    var mapView = new View({
      projection: projection,
      center: getCenter(extent),
      zoom: 0,
      maxZoom: 5,
    });

    // var mousePositionControl = new MousePosition({
    //   coordinateFormat: createStringXY(1),
    //   projection: 'EPSG:4326',
    //   undefinedHTML: '&nbsp;',
    // });

    const mapElement = <HTMLElement>document.querySelector('#map');
    if (mapElement) {
      var map = new OpMap({
        layers: [tileLayer, zoneLayer, vectorPinLayer],
        target: mapElement,
        view: mapView,
        //controls: defaultControls().extend([mousePositionControl]),
      });

      var zoomslider = new ZoomSlider();
      map.addControl(zoomslider);

      // map.on('pointermove', (event: any) => {
      //   var layer = <any>map.getLayers().getArray()[1]
      //   var feats = layer.getSource().getFeatures();
      //   for (let i = 0; i < feats.length; i++) {
      //     feats[i].setStyle(this.mapService.returnCustomZoneStyle(false))
      //   }
      //   map.forEachFeatureAtPixel(event.pixel, (feature: any) => {
      //     if (feature &&feature?.values_?.FeatureType == 'zone') {
      //       feature.setStyle(this.mapService.returnCustomZoneStyle(false, feature?.values_?.Name))
      //     }
      //   });
      // });

      map.once('postrender', () => {
        var geo = this.pinFeatures[0].getGeometry();
        var ext = geo?.getExtent();
        //console.log(ext)
        ext && mapView.fit(ext, { maxZoom: 12 });
      });
    } else {
      console.log('map failed to draw.');
    }
  }

  extractMapId(anyData: any): number {
    if (anyData.MapId) {
      return anyData.MapId;
    } else if (anyData.CurrentMapId) {
      return anyData.CurrentMapId;
    } else return -1;
  }

  extractPinFeature(anyObject: any, featureType: string): Feature {
    let retFeat = new Feature();
    switch (featureType) {
      case 'asset':
        retFeat = this.renderService.createIconStaffFeature(anyObject);
        break;
      case 'infra':
        retFeat = this.renderService.createIconInfraFeature(anyObject);
        break;
      case 'device':
        retFeat = this.renderService.createIconDeviceFeature(anyObject);
        break;
      case 'event':
        retFeat = this.renderService.createIconEventFeature(anyObject);
        break;
      default:
        console.log('ERROR - feature type not recognized!');
        break;
    }
    //console.log('created feature', retFeat);
    return retFeat;
  }
}
