import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { StorageService } from '@app/services/storage.service';
import { CustomerService } from '@app/services/customer.service';
import { EnvService } from '@app/services/env.service';
import { WebSocketService } from '@app/services/websocket.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertsComponent } from '@app/alerts/alerts.component';
import { ExpirationWarningComponent } from '@app/expiration-warning/expiration-warning.component';
import { UsersService } from '@app/services/users.service';
import { combineLatest } from 'rxjs';
import { add, formatDistanceStrict } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FilterSearchComponent } from '@app/nav/filter-search/filter-search.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  licensesAboutToExpire: any = [];
  licenses: any = [];
  licenseGrace: number = 90;
  licenseWarning: number = 10;
  subscriptions: any;
  systemSetting: any;
  today: Date = new Date();

  filterOptions: string[] = [
    'Type',
    'Groups',
    'Properties',
    'Location',
    'Subscriptions',
  ];
  isTypeDropdownVisible: boolean = false;
  isGroupsDropdownVisible: boolean = false;

  alreadyWarned: boolean = false;
  licensesExpiring: boolean = false;
  licensesWarning: boolean = false;

  selectedEvent: any;

  @Input() customerId?: number;

  constructor(
    public authService: AuthService,
    public customerService: CustomerService,
    public dialog: MatDialog,
    public env: EnvService,
    public storage: StorageService,
    public socketService: WebSocketService,
    public translate: TranslateService,
    public usersService: UsersService,
    public router: Router,
    public notifier: MatSnackBar
  ) {
    combineLatest([
      this.customerService.licenses$,
      this.customerService.subscription$,
      this.customerService.systemSetting$,
    ]).subscribe(([ls, subs, sets]) => {
      this.systemSetting = sets;
      this.licenseGrace = this.systemSetting.LicenseGracePeriod
        ? this.systemSetting.LicenseGracePeriod
        : 90;
      this.licenseWarning = this.systemSetting.LicenseWarningPeriod
        ? this.systemSetting.LicenseWarningPeriod
        : 10;
      if (
        //ls !== [] &&
        subs !== 0
      ) {
        this.subscriptions = subs;
        this.licenses = ls;
        this.gracePeriodWarning();
      }
    });

    combineLatest([
      this.customerService.alreadyWarned$,
      this.customerService.licensesExpiring$,
      this.customerService.licensesWarning$,
    ]).subscribe(([already, expired, warned]) => {
      this.alreadyWarned = already;
      this.licensesExpiring = expired;
      this.licensesWarning = warned;
    });

    this.socketService.currentEvent$.subscribe((ev: any) => {
      this.selectedEvent = ev;
    });

    this.socketService.newEvent$.subscribe((event) => {
      if (
        event &&
        this.systemSetting.AutoPopupAlerts &&
        (!this.dialog.openDialogs || !this.dialog.openDialogs.length)
      ) {
        this.showAllAlerts();
      }
    });
  }

  ngOnInit() {
    if (this.storage.uvsCreds !== null) {
      this.socketService.getSocket();
    }
  }

  customerHasUseCase(useCaseName: string) {
    switch (useCaseName) {
      case 'Devices':
        return true;
      case 'Infrastructures':
        return true;
      case 'AssetTracking':
        return this.subscriptions?.AssetTracking;
      case 'HandHygiene':
        return this.subscriptions?.HandHygiene;
      case 'MELT':
        return this.subscriptions?.MELT;
      case 'PatientFlow':
        return this.subscriptions?.PatientFlow;
      case 'PeopleFlow':
        return this.subscriptions?.PeopleFlow;
      case 'SDCT':
        return this.subscriptions?.SDCT;
      case 'StaffSafety':
        return this.subscriptions?.StaffSafety;
      case 'TemperatureMonitoring':
        return this.subscriptions?.TemperatureMonitoring;
      default:
        return false;
    }
  }

  getCountryFlag(countryCode: string): string {
    const url: string = `assets/country-flags/${countryCode}.png`;
    return url;
  }

  //MKL more examples of translate instant
  getLicenseDaysRemaining(date: string) {
    const warningDate = add(new Date(date), { days: this.licenseGrace });
    if (warningDate <= this.today) {
      return this.translate.instant('MAIN.NAV.EXP.EXPIRED');
    }
    return `${formatDistanceStrict(
      warningDate,
      this.today
    )} ${this.translate.instant('MAIN.NAV.EXP.REMAINING')}`;
  }

  getLicenseDisplay(langKey: string) {
    return this.translate.instant(`MAIN.DASHBOARD.SUBS.${langKey}`);
  }

  gracePeriodWarning() {
    // check if any liceneses have reached the warning period
    const warningLicenses: any = this.licenses.filter((l: any) => {
      if (this.customerHasUseCase(l.name) && l.expiration) {
        const warningDate = add(new Date(l.expiration), {
          days: this.licenseGrace - this.licenseWarning,
        });
        return warningDate <= this.today;
      }
      return false;
    });

    // find any licenses in the grace period
    const expiringLicenses: any = this.licenses.filter(
      (l: any) =>
        this.customerHasUseCase(l.name) &&
        l.expiration &&
        new Date(l.expiration) <= this.today
    );

    if (expiringLicenses.length > 0) {
      this.customerService.licensesExpiring$.next(true);
      this.licensesAboutToExpire = expiringLicenses;
    }

    if (warningLicenses.length > 0) {
      this.customerService.licensesWarning$.next(true);

      if (
        !this.alreadyWarned &&
        (!this.dialog.openDialogs || !this.dialog.openDialogs.length)
      ) {
        this.customerService.alreadyWarned$.next(true);
        this.dialog.open(ExpirationWarningComponent, {
          data: {
            expiringLicenses: warningLicenses,
          },
        });
      }
    }
  }

  search(evt: any) {
    evt.preventDefault();
    if (evt.target['0'].value.length > 0) {
      //console.log('navigating to ' + evt.target['0'].value);
      this.router.navigate(['/search'], {
        queryParams: {
          name: evt.target['0'].value,
        },
      });
    } else {
      this.notifier.open(`Enter a search term.`, '', {
        panelClass: 'error',
      });
    }
  }

  openFilterSearch(searchVal: any) {
    this.dialog.open(FilterSearchComponent, {
      data: { keyword: searchVal },
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '75vw',
      width: this.customerService.getScreenSize() ? '100%' : '750px',
    });
  }

  openTilesMenu() {
    this.customerService.getSubcriptions().subscribe((subs) => {
      this.customerService.subscriptions = subs;
    });
  }

  routeToCompanyInfo() {}

  showAllAlerts() {
    if (this.env.CMMode) return;
    this.dialog.open(AlertsComponent, {
      height: 'calc(100% - 50px)',
      position: { right: '0px', top: '50px' },
      maxWidth: '100vw',
      width: '100%',
    });
  }

  updateLanguage(langKey: string) {
    if (!this.customerService.customerId) return;

    const userId = this.storage.uvsCreds.userObject.Id;
    let settings = this.customerService.userSettings;
    settings.defaultLanguage = langKey;
    this.usersService.updateUserSettings(userId, settings).subscribe();

    this.storage.setLang(langKey);
  }
}
