import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '@app/services/customer.service';
import { UseCase } from '@app/core/interfaces';
import { PermissionResource, USECASES } from '@app/core/constants';
import { WebSocketService } from '@app/services/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import { GET_NAME_OF_ASSET } from '@app/core/constants';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  collapsed: boolean = false;
  eventCount: number = 0;
  licensesExpiring: boolean = false;
  scrHeight: number = window.innerHeight;
  scrWidth: number = window.innerWidth;
  selectedSub: string = '';
  settings: any;
  useCases: UseCase[] = USECASES;

  //MKL 3-15-2024
  public GET_NAME_OF_ASSET = GET_NAME_OF_ASSET;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.collapsed = this.scrWidth < 600 ? true : false;
  }

  constructor(
    public customerService: CustomerService,
    public router: Router,
    public socketService: WebSocketService,
    public translate: TranslateService
  ) {
    this.getScreenSize();
    this.selectedSub = window.location.pathname.split('/')[1];
    this.customerService.systemSetting$.subscribe((sets) => {
      this.settings = sets;
    });
    this.customerService.licensesExpiring$.subscribe(
      (exp) => (this.licensesExpiring = exp)
    );
    this.socketService.eventPing$.subscribe(() => {
      this.getAlertCount();
    });
    //console.log('in shell.component . selected sub', this.selectedSub);
  }

  ngOnInit(): void {}

  getAlertCount() {
    const alerts = this.socketService.realEvents;
    const subAlerts = alerts.filter((i: any) => {
      const alertUseCase = USECASES.find(
        (use: UseCase) => use.enumId === i.UseCase
      );
      const currentUseCase = USECASES.find(
        (use: UseCase) => use.path === this.selectedSub
      );
      return alertUseCase?.value === currentUseCase?.value;
    });

    this.eventCount = subAlerts.length;
  }

  routeForReports() {
    if (this.selectedSub === 'melt' && this.settings.ShowMeltReportIframe) {
      return 'report-view';
    }
    return 'reports';
  }

  setRouteManagementKey(): PermissionResource {
    switch (this.selectedSub) {
      case 'asset-tracking':
        return 'AssetTracking';
      case 'hand-hygiene':
        return 'HandHygiene';
      case 'melt':
        return 'MELT';
      case 'patient-flow':
        return 'PatientFlow';
      case 'people-flow':
        return 'PeopleFlow';
      case 'sdct':
        return 'SDCT';
      case 'staff-safety':
        return 'StaffSafety';
      case 'temperature-monitoring':
        return 'TemperatureMonitoring';
      case 'bed-management':
        return 'BedManagement';
      default:
        return 'SUPER_USER_ONLY';
    }
  }

  showRules(): boolean {
    switch (this.selectedSub) {
      case 'melt':
        return (
          this.customerService.subscriptions.ruleManagement &&
          this.customerService.subscriptions.ruleManagement.MELT
        );
      case 'sdct':
        return (
          this.customerService.subscriptions.ruleManagement &&
          this.customerService.subscriptions.ruleManagement.SDCT
        );
      case 'temperature-monitoring':
        return false;
      case 'bed-management': // to do: what should this bool be?
        return false;
      default:
        return true;
    }
  }

  //MKL How it's been used
  getApplicationTitle(): string {
    const use = this.useCases.find(
      (useCase: UseCase) => useCase.path === this.selectedSub
    );
    return use
      ? this.translate.instant(`MAIN.DASHBOARD.SUBS.${use.langKey}`)
      : '';
  }

  toggleSideBar() {
    this.collapsed = !this.collapsed;
  }
}
