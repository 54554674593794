import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VERSION } from '@app/core/constants';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss'],
})
export class EmailSendComponent implements OnInit {
  version: string = VERSION;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirectToLogin() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  tryAgain() {
    this.router.navigate(['/forgot-password'], { replaceUrl: true });
  }
}
