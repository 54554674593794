<img class="screensaver" src="/assets/images/screensaver.jpg" />

<div *ngIf="interloper" class="nurse-entry">
  <img class="portrait" src="/assets/images/nurse_entry.jpg" />
  <div class="bio">
    <p>Name:</p>
    <p>{{ interloper.Name || "Nurse Name" }}</p>

    <p>Position:</p>
    <p>RN</p>

    <p>Certifications:</p>
    <p>RN-BC</p>

    <p>Education:</p>
    <p>BS Nursing, James Madison University</p>

    <p>Interests:</p>
    <p>Travel, Cooking, Pet Borzoi named Balto</p>

    <p>Group:</p>
    <p>{{ interloper.GroupName || "Nurse Group" }}</p>

    <p>Department:</p>
    <p>{{ interloper.DepartmentName || "Nurse Department" }}</p>
  </div>

  <button class="dismiss-button" (click)="dismissBio()">Close</button>
</div>
