import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-realtime',
  template: `
    <div class="wrapper">
      <map-realtime *axLazyElement></map-realtime>
    </div>
  `,
  styles: [``],
})
export class MapRealtimeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
