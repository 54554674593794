import { Language, Reason, Tab, UseCase } from '@app/core/interfaces';
import { format, getISOWeek } from 'date-fns';

export const SYN: string[] = ['@ir', 'mkl', '1sta', 'adtp', '3low!'];

export const ALERTS = [
  { value: 'sofia', display: 'Sofia' },
  { value: 'email', display: 'Email' },
  { value: 'tag', display: 'Tag Message' },
  { value: 'uri', display: 'External HTTP Call' },
];

// 0 is average of 4 trilaterations
// 1 is smallest max angle,
// 2 is largest area
// 3 is smallest area
// 4 is best 3 RSSI
// 5 is NLLS
// 6 is Smallest Max Angle then NLLS
export const LOCATION_ALGORITHMS = [
  { value: 5, display: 'NLLS (default)' },
  { value: 0, display: 'Average of Four Trilaterations' },
  { value: 1, display: 'Smallest Max Angle' },
  { value: 2, display: 'Largest Area' },
  { value: 3, display: 'Smallest Area' },
  { value: 4, display: 'Best Three RSSI' },
  { value: 6, display: 'Smallest Max Angle, then NLLS' },
];

export const CONDITIONS = [
  { value: 'area', display: 'Area' },
  { value: 'battery', display: 'Battery' },
  { value: 'button', display: 'Button' },
  { value: 'dwellAbsence', display: 'Dwell or Absence' },
  { value: 'motion', display: 'Motion' },
  { value: 'parLevel', display: 'Par Level' },
  { value: 'safetySwitch', display: 'Safety Switch' },
  { value: 'tagActive', display: 'Tag Active' },
  { value: 'tagInactive', display: 'Tag Inactive' },
  // { value: 'safetySwitchConfirm', display: 'Safety Switch + Confirm' },
  // { value: 'sensor', display: 'Sensor' },
  // { value: 'tagUnplugged', display: 'Tag Unplugged (A4+)' },
  // { value: 'tamper', display: 'Tamper(A5)' },
];

export const DEBUG_LOCATIONS: string[] = [
  'ALP',
  'ALP RECEIVER',
  'AMP',
  'ARC',
  'CMX',
  'DEVICE',
  'GENERIC RULE PROCESSOR',
  'HAND HYGIENE RULE PROCESSOR',
  'MELT RULE PROCESSOR',
  'MICRO WS',
  'REPORT PROCESSOR',
  'REPORT SCHEDULER',
  'ROUTE',
  'ROUTE CHECK',
  'ROUTE RULE PROCESSOR',
  'RULE',
  'RULE PROCESSOR',
  'RTLS RSSI LE',
  'SDCT RULE PROCESSOR',
  'SYSTEM',
  'TEMPERATURE RULE PROCESSOR',
  'WGU',
  'BGU',
  'B3.G',
  'G3.G',
  'TRILATERATION',
  'MERGE ENGINE',
  'ARUBA',
  'GATEWAY',
];

export const DEFAULT_DEVICE_CONFIG = {
  alt: { name: 'Altitude', value: -100, unit: 'Meters' },
  b4sm: { name: 'B4EnableSilent', value: 0, length: 1 },
  balg: { name: 'Algorithm', value: 2, length: 1 },
  bbf: { name: 'BeaconFrequency', value: 1000, length: 2 },
  bbt: { name: 'BeaconType', value: 0, length: 1 },
  bdrd: { name: 'DisableBLERadio', value: 0, length: 1 },
  bebd: { name: 'EnableBLEDisc', value: 0, length: 1 },
  bemp: { name: 'BLETxPowerOnReport', value: 0, length: 1 }, //MKL
  btxp: { name: 'BLETxPower', value: 4, length: 1 }, //MKL
  bpir: { name: 'ProxInRSSI', value: 70, length: 1 },
  bpmr: { name: 'ProxOutReportMissedBeacons', value: 0, length: 1 },
  bpom: { name: 'ProxOutMaxMissedBeacons', value: 3, length: 1 },
  bpor: { name: 'ProxOutRSSI', value: 78, length: 1 },
  brth: { name: 'RSSIThreshold', value: 88, length: 1 },
  //Zach codes the scanbitmask as "3" however long ago
  bsbm: { name: 'ScanBitmask', value: 3, length: 1 },
  bsc: { name: 'ScanCount', value: 2, length: 1 },
  bsd: { name: 'ScanDuration', value: 660, length: 2 },
  bsf: { name: 'ScanFrequency', value: 2000, length: 2 },
  bsor: { name: 'ScanOnReport', value: 1, length: 1 },
  bsq: { name: 'ScanQSize', value: 1, length: 1 },
  bta: { name: 'EnableBLEAdvertising', value: 1, length: 1 },
  dhlt: { name: 'DHCPLeaseTime', value: 1440, length: 2 }, //remove later MKL 5-10-2024
  dtda: { name: 'DisableTagDeactivation', value: 0, length: 1 },
  mcm: { name: 'ClearMessagesOnDetach', value: 0, length: 2 },
  mn: { name: 'MaintenanceInterval', value: 3600, length: 4, unit: 'Hours' },
  ms: { name: 'BuzzerMode', value: 0, length: 2 },
  oadp: { name: 'WifiOADPort', value: 8550, length: 2 },
  puma: { name: 'ConnectionAge', value: 3, length: 1 },
  pumr: { name: 'ConnectionRSSI', value: 75, length: 1 },
  sdri: { name: 'ReportImmediately', value: 1, length: 1 },
  se: { name: 'MotionSensitivity', value: 1, length: 2 },
  sm: { name: 'SensorMode', value: 0, length: 2 },
  ss: { name: 'StagnantInterval', value: 0, length: 2, unit: 'Minutes' },
  sd: { name: 'MotionStagnantTimeout', value: 0, length: 2, unit: 'Minutes' },
  wfrc: { name: 'WifiFailedRetryCount', value: 0, length: 1 },
  wr: { name: 'WifiRoaming', value: 0, length: 1 },
  wra: { name: 'ReportActions', value: 3, length: 1 },
  wrv: { name: 'ReportVersion', value: 3, length: 1 },
  wsai: { name: 'AlertInterval', value: 0, length: 1, unit: 'Seconds' }, //Safety alert time
  wscn: { name: 'WifiStayConn', value: 9, length: 2, unit: 'Seconds' },
  wsm: { name: 'ScanMode', value: 0, length: 2 },
  wsn: { name: 'ReportInterval', value: 120, length: 4, unit: 'Minutes' },
  alarm_exit: { name: 'AlarmExit', value: 0 },
  hightime1: { name: 'HighTime1', value: 0 },
  hightime2: { name: 'HighTime2', value: 0 },
  highvalue1: { name: 'HighValue1', value: 0, unit: 'Celcius' },
  highvalue2: { name: 'HighValue2', value: 0, unit: 'Celcius' },
  hysteresis: { name: 'Hysteresis', value: 0, unit: 'Celcius' },
  logperiod: { name: 'LogPeriod', value: 0, unit: 'Seconds' },
  lowtime1: { name: 'LowTime1', value: 0, unit: 'Seconds' },
  lowtime2: { name: 'LowTime2', value: 0, unit: 'Seconds' },
  lowvalue1: { name: 'LowValue2', value: 0, unit: 'Celcius' },
  lowvalue2: { name: 'LowValue2', value: 0, unit: 'Celcius' },
  transmitperiod: { name: 'TransmitPeriod', value: 240, unit: 'Seconds' },
  tries: { name: 'Tries', value: 1 },
};

export const DEFAULT_DEVICE_SETTINGS = {
  A1: 0,
  A3: 0,
  A4: 0,
  A5: 0,
  A6: 0,
  B3: 0,
  B4: 0,
  W4: 0,
};

export const DEFAULT_INFRA_CONFIG = {
  bbf: { name: 'BeaconFrequency', value: 1000, length: 2 },
  bibj: { name: 'iBMajor', value: 20570, length: 2 }, //Changed default from 16715 to 20570 MKL
  btxp: { name: 'BLETxPower', value: 4, length: 1 },
  hfo: { name: 'HighFrequencyOnly', value: 1, length: 1 },
  mn: { name: 'MaintenanceInterval', value: 3600, length: 4, unit: 'Minutes' }, //Default to minutes for unit
  mrsi: { name: 'MaximumRSSI', value: 70, length: 1 }, //was 20, then 15, now 70 MKL 5/30/2024
  mult: { name: 'Multiplier', value: 1, length: 1 },
  si: { name: 'Disabled', value: 0, length: 4 }, //MKL 3-18-2024
  oadp: { name: 'WifiOADPort', value: 8550, length: 2 },
  puma: { name: 'ConnectionAge', value: 3, length: 1 },
  pumr: { name: 'ConnectionRSSI', value: 75, length: 1 },
  wsn: { name: 'ReportInterval', value: 3600, length: 4, unit: 'Minutes' }, //Default to minutes for unit
  //MKL 4-17-2024
  bta: { name: 'EnableBLEAdvertising', value: 0, length: 1 },
  bebd: { name: 'EnableBLEDisc', value: 1, length: 1 }, //default to ON
  //BV 4-5-24
  bsbm: { name: 'ScanBitmask', value: 3, length: 1 }, //defaulted to "3" which bitshifts correctly, I guess? broken for sure, never worked
  bsc: { name: 'ScanCount', value: 1, length: 1 }, //mkl
  bsd: { name: 'ScanDuration', value: 1010, length: 2 }, //mkl
  bsf: { name: 'ScanFrequency', value: 2000, length: 2 },
  bsq: { name: 'ScanQSize', value: 1, length: 1 },
  balg: { name: 'Algorithm', value: 2, length: 1 },
  bpir: { name: 'ProxInRSSI', value: 70, length: 1 },
  bpmr: { name: 'ProxOutReportMissedBeacons', value: 0, length: 1 },
  bpom: { name: 'ProxOutMaxMissedBeacons', value: 3, length: 1 },
  bpor: { name: 'ProxOutRSSI', value: 78, length: 1 },
  brth: { name: 'RSSIThreshold', value: 88, length: 1 },
  bsor: { name: 'ScanOnReport', value: 1, length: 1 },
  //MKL 4-23-2024
  bebl: { name: 'BLEDiscoveryWhitelist', value: '', length: 1 },
  //MKL 4-25-2024
  prox: { name: 'Proximity', value: 1, length: 1 }, //Defaults to enabled.
};

export const DEFAULT_INFRA_SETTINGS = {
  AccessPoint: 0,
  B3G: 0,
  G3: 0,
  HDU: 0,
  L1: 0,
  L2: 0,
  WBU: 0,
  WGU: 0,
  AoA: 0,
  EnableEmailNotification: false,
  EnableLowBatteryLevelNotification: true,
  EnableOfflineTimer: true,
  OfflineMultiplier: 2,
  LowBatteryLevelValue: 30,
  EnableRepeatInterval: false,
  RepeatIntervalTimeValue: 90,
  RepeatIntervalTimeUnit: 'Seconds',
};

export const DEVICE_TYPES = [
  'A1',
  'A3',
  'A4',
  'A5',
  'A6',
  'B3',
  'B4',
  'W4',
  'W9',
  'AoA Tag',
  'TS1',
  'TS2',
  'HS1',
];
export const DEVICE_TYPES_2022 = ['A1', 'A3', 'A4', 'B3', 'B4n', 'W4n', 'W9'];

export const INCLUDE_IN_MESSAGE = [
  { value: 'map', display: 'Map' },
  { value: 'name', display: 'Name' },
  { value: 'zone', display: 'Zone' },
];

// export const INFRA_CONFIG_TYPES = [
//   'ACCESS POINT',
//   'B3G',
//   'G3',
//   'HDU',
//   'L1',
//   'L2',
//   'WBU',
//   'WGU',
//   'AoA',
//   'BGU',
// ];

export const LANGUAGES: Language[] = [
  { value: 'en', display: 'English' },
  { value: 'fr', display: 'French' },
  // { value: 'de', display: 'German' },
  // { value: 'es', display: 'Spanish' },
  // { value: 'it', display: 'Italian' },
  // { value: 'ja', display: 'Japanese' },
  // { value: 'ko', display: 'Korean' },
  // { value: 'ru', display: 'Russian' },
  // { value: 'zh', display: 'Chinese' },
];

export const LDAPSECURITYLEVELS: string[] = [
  'None',
  'Anonymous',
  'Delegation',
  'Encryption',
  'FastBind',
  'ReadonlyServer',
  'Sealing',
  'Secure',
  'SecureSocketsLayer',
  'ServerBind',
  'Signing',
];

export const MAP_LOCATIONS = [
  { value: 'buildings', display: 'Buildings' },
  { value: 'floors', display: 'Floors' },
  { value: 'sites', display: 'Sites' },
  { value: 'zones', display: 'Zones' },
  { value: 'zoneGroups', display: 'Zone Groups' },
  { value: 'geoZones', display: 'GeoZones' },
];

export const NOTIFICATION_SOUND = [
  { value: 'message', display: 'Message' },
  { value: 'siren', display: 'Siren' },
  { value: 'twinkle', display: 'Twinkle' },
  { value: 'stairs', display: 'Stairs' },
  { value: 'beep', display: 'Beep' },
];

export const NOTIFICATION_SOUND_MESSAGES = [
  { value: 'tone-one', display: 'Siren' },
  { value: 'tone-two', display: 'Twinkle' },
  { value: 'tone-three', display: 'Stairs' },
  { value: 'tone-four', display: 'Beep' },
  { value: 'silent', display: 'Silent' },
];

export const REPORT_REASONS: Reason[] = [
  { value: 1, name: 'Button' },
  { value: 2, name: 'Motion' },
  { value: 3, name: 'Periodic' },
  { value: 4, name: 'Calibrate' },
  { value: 5, name: 'After Motion' },
  { value: 6, name: 'Button 2' },
  { value: 7, name: 'Tamper Alarm' },
  { value: 8, name: 'Button 3' },
  { value: 9, name: 'Charger Status' },
  { value: 10, name: 'Safety Switch Status' },
  { value: 11, name: 'Low Battery' },
  { value: 12, name: 'Motion Stagnant' },
  { value: 13, name: 'Menu Selection' },
  { value: 14, name: 'Supplementary' },
  { value: 15, name: 'Location Beacon' },
  { value: 16, name: 'Priority Message Ack' },
  { value: 17, name: 'Battery Level Changed' },
  { value: 18, name: 'Btn Long Press' },
  { value: 19, name: 'Btn 2 Long Press' },
  { value: 21, name: 'Network' },
  { value: 22, name: 'Switch Changed' },
  { value: 23, name: 'iBeacon SDCT' },
  { value: 24, name: 'SDCT Proximity' },
  { value: 25, name: 'SDCT Out of Prox' },
  { value: 30, name: 'Temp Sensor Alert' },
  { value: 256, name: 'Route Check' },
  { value: 260, name: 'Hello Request' },
  { value: 261, name: 'Info Request' },
  { value: 263, name: 'Init Request' },
  { value: 265, name: 'Confirm Ack' },
];

export const ROUTE_TYPES = [
  { value: 'time', display: 'Time Based' },
  { value: 'order', display: 'Order Based' },
];

export const START_CONDITIONS = [
  {
    value: 'location',
    display: 'Location Only',
    tooltip:
      'If the tag enters the specific Zone or Zone Group set, then the route will begin.',
  },
  {
    value: 'location_button',
    display: 'Location and Button Push',
    tooltip:
      'If the tag enters the specific Zone or Zone Group, and then the designation Start Route button is pressed, then the route will begin.',
  },
  {
    value: 'infrastructure',
    display: 'Specific Infrastructure',
    tooltip:
      ' If the tag sees the specific Infrastructure set, then the route will begin.',
  },
];

export const TABS: Tab[] = [
  { path: 'home', display: 'Home' },
  { path: 'assets', display: 'Assets' },
  { path: 'events', display: 'Events' },
  { path: 'dashboard', display: 'Dashboard' },
  { path: 'reports', display: 'Reports' },
  { path: 'sub-map', display: 'Map' },
];

export const TAG_ALERT_FORMS = [
  { value: 'buildings', display: 'Buildings' },
  { value: 'departments', display: 'Departments' },
  { value: 'floors', display: 'Floors' },
  { value: 'groups', display: 'Groups' },
  { value: 'items', display: 'Items' },
  { value: 'zones', display: 'Zones' },
  { value: 'zoneGroups', display: 'Zone Groups' },
  { value: 'geoZones', display: 'geoZones' },
];

export const TAG_LOCATION = [
  { value: 'building', display: 'Building' },
  { value: 'map', display: 'Map' },
  { value: 'zone', display: 'Zone' },
  { value: 'zoneGroup', display: 'Zone Group' },
  { value: 'geoZone', display: 'Geo Zone' },
];

export const TAG_MESSAGE = [
  { value: 'nearEvent', display: 'Tags near event' },
  { value: 'selectedBuildings', display: 'Tags in selected buildings' },
  { value: 'selectedDepartments', display: 'Tags in selected departments' },
  { value: 'selectedFloors', display: 'Tags in selected floors' },
  { value: 'selectedItems', display: 'Tags in selected items' },
  { value: 'selectedZones', display: 'Tags in selected zones' },
  { value: 'selectedZoneGroups', display: 'Tags in selected zone groups' },
  { value: 'selectedGeoZones', display: 'Tags in selected geo zones' },
  { value: 'noTags', display: 'Send to no tags' },
];

export const USECASES: UseCase[] = [
  {
    path: 'asset-tracking',
    langKey: 'ASSET',
    value: 'AssetTracking',
    display: 'Asset Tracking',
    enumId: 1,
  },
  { path: 'melt', langKey: 'MELT', value: 'MELT', display: 'MELT', enumId: 2 },
  {
    path: 'patient-flow',
    langKey: 'PATIENT',
    value: 'PatientFlow',
    display: 'Patient Flow',
    enumId: 3,
  },
  { path: 'sdct', langKey: 'SDCT', value: 'SDCT', display: 'SDCT', enumId: 4 },
  {
    path: 'staff-safety',
    langKey: 'STAFF',
    value: 'StaffSafety',
    display: 'Staff Safety',
    enumId: 5,
  },
  {
    path: 'temperature-monitoring',
    langKey: 'TEMPERATURE',
    value: 'TemperatureMonitoring',
    display: 'Temperature Monitoring',
    enumId: 6,
  },
  {
    path: 'hand-hygiene',
    langKey: 'HYGIENE',
    value: 'HandHygiene',
    display: 'Hand Hygiene',
    enumId: 7,
  },
  {
    path: 'people-flow',
    langKey: 'PEOPLE',
    value: 'PeopleFlow',
    display: 'People Flow',
    enumId: 8,
  },
  {
    path: 'bed-management',
    langKey: 'MANAGEMENT',
    value: 'BedManagement',
    display: 'Bed Management',
    enumId: 9,
  },
];

// export const VERSION: string = `${format(new Date(), 'yy')}${(
//   '0' + getISOWeek(new Date())
// ).slice(-2)}`;

// SOFTWARE VERSION
export const VERSION: string = '2.6';

export const REGEX_MAC: RegExp = new RegExp(
  /^([A-Fa-f0-9:]{17}|[A-Fa-f0-9]{12})$/
);
export const REGEX_IMEI: RegExp = new RegExp(/^\d{15}(,\d{15})*$/);

export function GET_NAME_OF_ASSET(
  useCase: string | number,
  plural: boolean = false
): string {
  //console.log(useCase);
  if (typeof useCase === 'number') {
    //if object contains use case id but not the selected sub string, like in alerts, checks corresponding use case
    if (useCase === 1) useCase = 'asset-tracking';
    else if (useCase === 2) useCase = 'melt';
    else if (useCase === 3) useCase = 'patient-flow';
    else if (useCase === 4) useCase = 'sdct';
    else if (useCase === 5) useCase = 'staff-safety';
    else if (useCase === 6) useCase = 'temperature-monitoring';
    else if (useCase === 7) useCase = 'hand-hygiene';
    else if (useCase === 8) useCase = 'people-flow';
    else if (useCase === 9) useCase = 'bed-management';
  }

  switch (useCase) {
    case 'asset-tracking':
      return plural ? 'MODELS.ASSETS' : 'MODELS.ASSET';
    case 'melt':
      return plural ? 'MODELS.ASSETS' : 'MODELS.ASSET';
    case 'patient-flow':
      return plural ? 'MODELS.PATIENTS' : 'MODELS.PATIENT';
    case 'sdct':
      return plural ? 'MODELS.PEOPLE' : 'MODELS.PERSON';
    case 'staff-safety':
      return plural ? 'MODELS.PEOPLE' : 'MODELS.PERSON';
    case 'temperature-monitoring':
      return plural ? 'MODELS.MONITORED_ASSETS' : 'MODELS.MONITORED_ASSET';
    case 'hand-hygiene':
      return plural ? 'MODELS.PEOPLE' : 'MODELS.PERSON';
    case 'people-flow':
      return plural ? 'MODELS.PEOPLE' : 'MODELS.PERSON';
    case 'bed-management':
      return plural ? 'MODELS.BEDS' : 'MODELS.BED';
    default:
      return plural ? 'MODELS.ASSETS' : 'MODELS.ASSET';
  }
}

export function setUseCase(selectedSub: string): string {
  switch (selectedSub) {
    case 'asset-tracking':
      return 'AssetTracking';
    case 'hand-hygiene':
      return 'HandHygiene';
    case 'melt':
      return 'MELT';
    case 'patient-flow':
      return 'PatientFlow';
    case 'people-flow':
      return 'PeopleFlow';
    case 'sdct':
      return 'SDCT';
    case 'staff-safety':
      return 'StaffSafety';
    case 'temperature-monitoring':
      return 'TemperatureMonitoring';
    case 'bed-management':
      return 'BedManagement';
    default:
      return 'None';
  }
}

export function GET_NAME_OF_USECASE(useCase: string | number): string {
  if (typeof useCase === 'number') {
    //if object contains use case id but not the selected sub string, like in alerts, checks corresponding use case
    if (useCase === 1) useCase = 'asset-tracking';
    else if (useCase === 2) useCase = 'melt';
    else if (useCase === 3) useCase = 'patient-flow';
    else if (useCase === 4) useCase = 'sdct';
    else if (useCase === 5) useCase = 'staff-safety';
    else if (useCase === 6) useCase = 'temperature-monitoring';
    else if (useCase === 7) useCase = 'hand-hygiene';
    else if (useCase === 8) useCase = 'people-flow';
    else if (useCase === 9) useCase = 'bed-management';
  }

  const displayUseCase = (useCase: string) => {
    switch (useCase) {
      case 'asset-tracking':
        return 'Asset Tracking';
      case 'hand-hygiene':
        return 'Hand Hygiene';
      case 'melt':
        return 'MELT';
      case 'patient-flow':
        return 'Patient Flow';
      case 'people-flow':
        return 'People Flow';
      case 'sdct':
        return 'SDCT';
      case 'staff-safety':
        return 'Staff Safety';
      case 'temperature-monitoring':
        return 'Temperature Monitoring';
      case 'bed-management':
        return 'Bed Management';
      default:
        return 'None';
    }
  };

  return displayUseCase(String(useCase));
}

export type PermissionAction = 'view' | 'add' | 'edit' | 'delete';

export type PermissionResource =
  | 'Staff'
  | 'Events'
  | 'Devices'
  | 'Infrastructures'
  | 'Maps'
  | 'Rules'
  | 'Settings'
  | 'AssetTracking'
  | 'HandHygiene'
  | 'MELT'
  | 'PatientFlow'
  | 'SDCT'
  | 'StaffSafety'
  | 'TemperatureMonitoring'
  | 'Users'
  | 'UserRoles'
  | 'Routes'
  | 'Reports'
  | 'PeopleFlow'
  | 'LogoutTimers'
  | 'BedManagement'
  | 'DashboardDesigner'
  | 'SUPER_USER_ONLY';

export const ZoneTypes = ['Open', 'GPS', 'Obstructed'];

export type AssetAssociationType =
  | 'CLEANING'
  | 'DISCHARGING'
  | 'AVAILABLE'
  | 'ASSIGNED';

export function safeConsoleLog(msg: string) {
  //pull from ENV to see if testing local or client site //alternatively see if the URL is 177
  //console log only if local
  //console.log(msg)
}

export function formatTwelveMacToSeventeen(mac: string): string {
  mac = mac.replace(/..\B/g, '$&:');
  return mac;
}

export const MaxRecordReturn = 9999;

export function GetUseCaseIDFromSubscriptionName(subName: string): number {
  switch (subName) {
    case 'asset-tracking':
      return 1;
    case 'hand-hygiene':
      return 7;
    case 'melt':
      return 2;
    case 'patient-flow':
      return 3;
    case 'people-flow':
      return 8;
    case 'sdct':
      return 4;
    case 'staff-safety':
      return 5;
    case 'temperature-monitoring':
      return 6;
    case 'bed-management':
      return 9;
    default:
      return 0;
  }
}

export function splitOnCapitals(noSpaceName: string): string {
  return noSpaceName.replace(/([A-Z])/g, ' $1');
}

export const UDA_TYPES = ['YesNo', 'Int', 'Decimal', 'String', 'DateTime'];
