import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerService } from './customer.service'; //not used anymore? mkl 3/1/23
import { map } from 'rxjs/operators';
import { ActionType } from '@app/core/interfaces';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { USECASES, MaxRecordReturn } from '@app/core/constants';
import { Observable } from 'rxjs';
import { FULL_PERMISSIONS } from '@assets/PERMISSIONS';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  customerId!: number;
  subscription!: string;
  isSuperUser: boolean = false;
  permissions: any[] = [];

  constructor(
    private http: HttpClient,
    private customerService: CustomerService
  ) {}

  initializeService() {
    // ==> for production
    this.subscription = window.location.pathname.split('/')[1];
    let uvsC = JSON.parse(<string>sessionStorage.getItem('uvs-credentials'));
    let customerData = sessionStorage.getItem('company');
    this.customerId = customerData
      ? JSON.parse(customerData).id || JSON.parse(customerData).company
      : undefined;
    this.isSuperUser = uvsC?.userObject?.IsSuperUser
      ? uvsC.userObject.IsSuperUser
      : false;
    this.permissions = uvsC?.userObject?.Permissions
      ? uvsC.userObject.Permissions
      : [];

    // // // ==> For development
    // this.customerId = 1;
    // this.permissions = FULL_PERMISSIONS;
    // this.isSuperUser = true;
    // this.subscription = 'staff-safety';
  }

  acknowledgeEvent(eventId: number, comment: string) {
    return this.http.post(
      `/api/Events/AcknowledgeEvent?CustomerId=${this.customerService.customerId}` +
        `&EventId=${eventId}&AcknowledgeComment=${comment}`,
      {}
    );
  }

  closeEvent(comment: any, userId: any, eventId: any) {
    return this.http.post(
      '/api/Events/CloseEvent?CustomerId=' +
        this.customerService.customerId +
        '&EventId=' +
        eventId +
        '&EventCloseComment=' +
        comment +
        '&UserId=' +
        userId,
      {}
    );
  }

  createEventAction(
    eventId: any,
    actionId: any,
    actionType: any,
    comment: any,
    userId: any
  ) {
    return this.http.post(
      '/api/Events/ApplyAction?CustomerId=' +
        this.customerService.customerId +
        '&EventId=' +
        eventId +
        '&ActionTypeId=' +
        actionId +
        '&ActionType=' +
        actionType +
        '&ActionDetails=' +
        comment +
        '&UserId=' +
        userId,
      {}
    );
  }

  eventAction(
    eventId: any,
    actionId: any,
    actionType: any,
    comment: any,
    userId: any
  ) {
    return this.http.post(
      '/api/Events/ApplyAction?CustomerId=' +
        this.customerService.customerId +
        '&EventId=' +
        eventId +
        '&ActionTypeId=' +
        actionId +
        '&ActionType=' +
        actionType +
        '&ActionDetails=' +
        comment +
        '&UserId=' +
        userId,
      {}
    );
  }

  formatTime(date: string, raw: boolean = false): string {
    if (
      date === '0001-01-01T00:00:00' ||
      date === '1900-01-01T00:00:00' ||
      !date
    )
      return '---';

    if (raw) return date;

    const formatDate = format(parseISO(date + 'Z'), 'MMM do h:mm aaa');
    const toNow = formatDistanceToNow(parseISO(date + 'Z'));
    return `${formatDate} (${toNow} ago)`;
  }

  getActionTypes() {
    return this.http
      .get(
        `/api/MetaData/GetActionTypes?CustomerId=${this.customerService.customerId}`
      )
      .pipe(
        map((data: any) => {
          const actionTypes = data.List.map((type: any) => {
            const newType: ActionType = {
              Id: type.Id,
              Name: type.Name,
            };
            return newType;
          });
          return actionTypes;
        })
      );
  }

  getEventAction() {
    return this.http.get(
      `/api/MetaData/GetActionTypes?CustomerId=${this.customerService.customerId}`
    );
  }

  getSiteHierarchy(): Observable<any> {
    return this.http.get<any>(
      `/api/MetaData/GetLocationHierarchy?CustomerId=${this.customerId}`
    );
  }

  getMapById(id: number): Observable<any> {
    return this.http.get<any>(
      `/api/Maps/GetByMapId?MapId=${id}&CustomerId=${this.customerId}`
    );
  }

  getGPSMapByCustomerId(): Observable<any> {
    return this.http.get<any>(
      `/api/Maps/GetGPSMapByCustomerId?CustomerId=${this.customerId}`
    );
  }

  getStaffById(staffId: number) {
    return this.http.get(
      `/api/Staff/GetByStaffId?StaffId=${staffId}&CustomerId=${this.customerService.customerId}`
    );
  }

  getEventById(eventId: number) {
    // console.log(eventId)
    // console.log(this.customerService.customerId)
    return this.http.get(
      `/api/Events/GetByEventId?CustomerId=${this.customerService.customerId}&EventId=${eventId}`
    );
  }

  getTimeAgo(date: string): string {
    const toNow = formatDistanceToNow(parseISO(date + 'Z'));
    return `(${toNow} ago)`;
  }

  //BAD NUMBER OF RECORDS
  getUsers() {
    return this.http
      .get(
        '/api/Users/GetAll?CustomerId=' +
          this.customerService.customerId +
          '&NumberOfRecords=' +
          MaxRecordReturn
      )
      .pipe(
        map((res: any) => {
          return res.List;
        })
      );
  }

  sendMessageWithSound(ids: string, msg: string, options: string) {
    return this.http.post(
      `/api/Device/DisplayPriorityMessageOnTagNotif?StaffIdList=${ids}&Message=${msg}&MessageOptions=${options}&CustomerId=${this.customerService.customerId}`,
      {}
    );
  }

  getUseCaseNameFromID(useCaseID: number): string {
    return (
      USECASES.find((uC: any) => {
        return uC.enumId == useCaseID;
      })?.display || ''
    );
  }
}
