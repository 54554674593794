import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeIframeComponent } from '@app/iframes/home/home-iframe.component';
import { DashboardIframeComponent } from '@app/iframes/dashboard/dashboard-iframe.component';
import { ReportIframeComponent } from './report/report.component';
import { AnalyticsComponent } from './analytics/analytics.component';

@NgModule({
  declarations: [
    HomeIframeComponent,
    DashboardIframeComponent,
    ReportIframeComponent,
    AnalyticsComponent,
  ],
  imports: [CommonModule],
})
export class IframesModule {}
