import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { CreateCustomerComponent } from '@app/login/create-customer/create-customer.component';
import { EmailSendComponent } from '@app/login/email-send/email-send.component';
import { ForgotPasswordComponent } from '@app/login/forgot-password/forgot-password.component';
import { LoginViewComponent } from '@app/login/login-view/login-view.component';
import { SelectCustomerViewComponent } from '@app/login/select-customer-view/select-customer-view.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { EulaViewComponent } from './eula-view/eula-view.component';

@NgModule({
  declarations: [
    LoginViewComponent,
    SelectCustomerViewComponent,
    ForgotPasswordComponent,
    EmailSendComponent,
    CreateCustomerComponent,
    SetPasswordComponent,
    EulaViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
})
export class LoginModule {}
