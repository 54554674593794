<a [matTooltip]="tooltip" class="tile-link">
  <div
    [routerLink]="tileUseString !== 'disabled' ? path : null"
    [ngClass]="'tile ' + tileUseString"
  >
    <div class="tile-icon">
      <mat-icon [inline]="true">{{ icon }}</mat-icon>
    </div>
    <div class="tile-label">{{ label }}</div>
  </div>
</a>
