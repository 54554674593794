<h3 mat-dialog-title>
  {{ "DEVICES.SINGLE.TITLE" | translate }} - {{ data.selectedDevice?.UniqueId }}
</h3>
<div mat-dialog-content>
  <div class="wrapper">
    <mat-spinner
      class="spinner"
      diameter="350"
      strokeWidth="10"
      *ngIf="loading"
    ></mat-spinner>

    <div *ngIf="!loading">
      <div class="sub-headline">
        <div class="device-option">
          <mat-button-toggle-group [(ngModel)]="deviceSettings">
            <mat-button-toggle value="property">{{
              "DEVICES.SINGLE.PROPERTIES" | translate
            }}</mat-button-toggle>
            <mat-button-toggle value="history">{{
              "DEVICES.SINGLE.COMMAND_HISTORY" | translate
            }}</mat-button-toggle>
            <mat-button-toggle value="data">{{
              "DEVICES.SINGLE.RSSI_IN_LAST" | translate
            }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="refresh-button">
          <button mat-flat-button color="primary" (click)="refresh()">
            {{ "COLUMNS.REFRESH" | translate }}
          </button>
        </div>
      </div>

      <!-- Properties Tab -->
      <div [hidden]="deviceSettings === 'history' || deviceSettings === 'data'">
        <div class="properties">
          <h4 class="devicesettings-headline">
            {{ "COLUMNS.MAC_ADDRESS" | translate }}:
            <span>{{ selectedDevice.UniqueId }} </span>
          </h4>
          <h4 class="devicesettings-headline">
            {{ "COLUMNS.TYPE" | translate }}:
            <span
              >{{
                data.forInfra
                  ? selectedDevice.DeviceType
                  : selectedDevice.ModelName
              }}
            </span>
          </h4>
          <h4 class="devicesettings-headline">
            {{ "COLUMNS.FIRMWARE" | translate }}:
            <span>{{ selectedDevice.FirmwareVersion }}</span>
          </h4>
          <h4 class="devicesettings-headline">
            {{ "COLUMNS.BATTERY" | translate }}:
            <span>{{ selectedDevice.BatteryLevel }}% </span>
          </h4>
          <h4 class="devicesettings-headline">
            {{ "COLUMNS.ASSIGNED" | translate }}:
            <span>{{ selectedDevice.PersonAssociated }} </span>
          </h4>

          <h4 class="devicesettings-headline">
            {{ "COLUMNS.DATE_CREATED" | translate }}:
            <span>
              {{ formatDate(selectedDevice.DateCreated) }}
            </span>
          </h4>

          <h4 class="devicesettings-headline">
            {{ "COLUMNS.DATE_UPDATED" | translate }}:
            <span>{{ formatDate(selectedDevice.DateUpdated) }}</span>
          </h4>

          <h4 class="devicesettings-headline">
            {{ "COLUMNS.MAINTENANCE_UPDATED" | translate }}:
            <span>{{ formatDate(selectedDevice.MaintenanceUpdated) }}</span>
          </h4>

          <h4 class="devicesettings-headline">
            {{ "COLUMNS.CONFIGURATION" | translate }}:
            <a
              [ngClass]="{
                hyperlink: getConfigName(selectedDevice.ConfigId) !== 'None'
              }"
            >
              <span
                (click)="
                  getConfigName(selectedDevice.ConfigId) !== 'None' &&
                    selectConfig(selectedDevice.ConfigId)
                "
                >{{ getConfigName(selectedDevice.ConfigId) }}</span
              >
            </a>
          </h4>
        </div>
      </div>

      <!-- Command History Tab -->
      <div *ngIf="deviceSettings === 'history'">
        <h4 class="table-title properties">
          {{ "DEVICES.SINGLE.COMMAND_HISTORY" | translate }}
        </h4>
        <div class="table-container mat-elevation-z0">
          <table mat-table [dataSource]="currentSettings">
            <ng-container matColumnDef="CommandCode">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.CODE" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.CommandCode }}
              </td>
            </ng-container>
            <ng-container matColumnDef="CommandValue">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.VALUE" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.CommandValue }}
              </td>
            </ng-container>
            <ng-container matColumnDef="CommandDescription">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.DESCRIPTION" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.CommandDescription }}
              </td>
            </ng-container>
            <ng-container matColumnDef="DateUpdated">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.DATE_UPDATED" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ formatDate(element.DateUpdated) }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="currentColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: currentColumns"></tr>
          </table>
        </div>

        <h4 class="table-title properties">
          {{ "DEVICES.SINGLE.PENDING_SETTINGS" | translate }}
        </h4>
        <div class="table-container mat-elevation-z0">
          <table mat-table [dataSource]="pendingCommands">
            <ng-container matColumnDef="CommandCode">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.CODE" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.CommandCode }}
              </td>
            </ng-container>
            <ng-container matColumnDef="CommandValue">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.VALUE" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.CommandValue }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Description">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.DESCRIPTION" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Description }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="pendingColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: pendingColumns"></tr>
          </table>
        </div>
        <div class="delete-button">
          <button
            *ngIf="service.getPermission('Devices', 'delete')"
            [disabled]="pendingCommands.length === 0"
            mat-flat-button
            color="primary"
            (click)="confirmDelete()"
          >
            {{ "DEVICES.SINGLE.DELETE_PENDING_COMMANDS" | translate }}
          </button>
        </div>
      </div>

      <!-- Last RSSI Data Tab -->
      <div *ngIf="deviceSettings === 'data'">
        <div class="properties">
          <h4 class="devicesettings-headline">
            {{ "COLUMNS.TYPE" | translate }}:
            <span
              >{{
                data.forInfra
                  ? selectedDevice.DeviceType
                  : selectedDevice.ModelName
              }}
            </span>
          </h4>
          <!-- FIXME MKL bring it back when it can be plain english -->
          <!-- <h4 class="devicesettings-headline">
            Scan Reason:
            <span
              >{{
                this.statusMessage?.DeviceReports[0]?.Status?.DeviceReportReason
              }}
            </span>
          </h4> -->
          <h4 class="devicesettings-headline">
            {{ "COLUMNS.BATTERY" | translate }}:
            <span
              >{{
                this.statusMessage?.DeviceReports[0]?.Status?.BatteryLevel1
              }}%
            </span>
          </h4>
          <h4 class="devicesettings-headline">
            {{ "DEVICES.SINGLE.RSSI_DATA_RECEIVED" | translate }}:
            <span>{{
              formatDate(
                this.statusMessage?.DeviceReports[0]?.Status?.Timestamp
              )
            }}</span>
          </h4>
        </div>
        <div class="table-title">
          <section>
            <mat-slide-toggle
              [ngStyle]="{ color: showInfraDetected ? '#1565c0' : '#4a4a4a' }"
              class="show-all"
              [(ngModel)]="showInfraDetected"
              >{{
                "DEVICES.SINGLE.DEVICES_USED_FOR_LOCATIONS" | translate
              }}</mat-slide-toggle
            >
          </section>
        </div>

        <div *ngIf="showInfraDetected" class="table-container mat-elevation-z0">
          <table
            mat-table
            [dataSource]="statusMessage?.DeviceReports[0]?.RFID?.Readers"
          >
            <ng-container matColumnDef="AntennaDisplayName">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.MAC_ADDRESS" | translate }}
              </th>
              <td mat-cell *matCellDef="let elements">
                {{ elements.AntennaDisplayName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="ReaderName">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.READER_NAME" | translate }}
              </th>
              <td mat-cell *matCellDef="let elements">
                {{ elements.ReaderDisplayName }}
              </td>
            </ng-container>
            <!-- added a space after RSSI for better formatting, kludgey but works well -->
            <ng-container matColumnDef="RSSI">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.RSSI" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let elements"
                style="text-align: center"
              >
                -{{ elements.RSSI }}
              </td>
            </ng-container>
            <ng-container matColumnDef="MinRssi">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.MIN_RSSI" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let elements"
                style="text-align: center"
              >
                -{{ elements.MaxTransmitPower }}
              </td>
            </ng-container>
            <ng-container matColumnDef="MaxRssi">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.MAX_RSSI" | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let elements"
                style="text-align: center"
              >
                -{{ elements.MinReceiveSensitivity }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Map">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.LOCATION" | translate }}
              </th>
              <td mat-cell *matCellDef="let elements">
                {{ getDeviceMap(elements.ReaderID) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Zone">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.ZONE" | translate }}Zone
              </th>
              <td mat-cell *matCellDef="let elements">
                {{ getDeviceZone(elements.ReaderID) }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="detectedInfrasColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: detectedInfrasColumns"
            ></tr>
          </table>
        </div>

        <div class="table-title" *ngIf="service.selectedSub == 'sdct'">
          <section>
            <mat-slide-toggle
              [ngStyle]="{
                color: showProximityDevices ? '#1565c0' : '#4a4a4a'
              }"
              class="show-all"
              [(ngModel)]="showProximityDevices"
              >{{
                "DEVICES.SINGLE.PROXIMITY_DEVICES" | translate
              }}</mat-slide-toggle
            >
          </section>
        </div>

        <div
          *ngIf="showProximityDevices"
          class="table-container mat-elevation-z0"
        >
          <table mat-table [dataSource]="proximityMessage">
            <ng-container matColumnDef="MacAddress">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.MAC_ADDRESS" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.MacAddress }}
              </td>
            </ng-container>
            <ng-container matColumnDef="RSSI">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.RSSI" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">-{{ element.RSSI }}</td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="proximityReportColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: proximityReportColumns"
            ></tr>
          </table>
        </div>

        <div class="table-title">
          <section>
            <mat-slide-toggle
              [ngStyle]="{ color: showLocationRssi ? '#1565c0' : '#4a4a4a' }"
              [(ngModel)]="showLocationRssi"
              class="show-all"
              >{{
                "DEVICES.SINGLE.ALL_DETECTED_DEVICES" | translate
              }}</mat-slide-toggle
            >
          </section>
        </div>

        <div *ngIf="showLocationRssi" class="table-container mat-elevation-z0">
          <table mat-table [dataSource]="locMessage">
            <ng-container matColumnDef="MacAddress">
              <th mat-header-cell *matHeaderCellDef>
                {{ "COLUMNS.MAC_ADDRESS" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.MacAddress }}
              </td>
            </ng-container>
            <ng-container matColumnDef="RSSI">
              <th mat-header-cell *matHeaderCellDef>
                {{ "DEVICES.SINGLE.RSSI" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.RSSI }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="locationRssiColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: locationRssiColumns"></tr>
          </table>
        </div>
      </div>

      <hr />

      <!-- Maps -->
      <app-shared-map
        *ngIf="
          (!loading && data.selectedDevice && !data.selectedDevice.Latitude) ||
          (data.selectedDevice.Longitude == 0 &&
            data.selectedDevice.Latitude == 0)
        "
        [data]="data.selectedDevice"
        [inputType]="'device'"
      ></app-shared-map>

      <!-- <app-shared-gps-map
        *ngIf="
          deviceSettings != 'camera' &&
          data.selectedDevice.Latitude &&
          (data.selectedDevice.Longitude != 0 ||
            data.selectedDevice.Latitude != 0)
        "
        [data]="data.selectedDevice"
        [inputType]="'device'"
        [customerId]="service.customerId"
      ></app-shared-gps-map> -->
    </div>
  </div>
</div>
