<div *ngIf="!env.CMMode" class="drop-container">
  <a class="tile-link" routerLink="/dashboard">
    <div mat-menu-item class="section">
      <mat-icon>apps</mat-icon>
      <span>{{ "MAIN.NAV.HOME" | translate }}</span>
    </div>
  </a>

  <div mat-menu-item [matMenuTriggerFor]="subs" class="section">
    <mat-icon>subscriptions</mat-icon>
    <span>{{ "MAIN.NAV.SUBSCRIPTIONS" | translate }}</span>
  </div>

  <mat-menu #subs="matMenu">
    <a
      *ngIf="
        customerService.subscriptions?.AssetTracking &&
        customerService.getPermission('AssetTracking', 'view')
      "
      class="tile-link"
      routerLink="/asset-tracking"
    >
      <div mat-menu-item class="section">
        <mat-icon>share_location</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.ASSET" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.HandHygiene &&
        customerService.getPermission('HandHygiene', 'view')
      "
      class="tile-link"
      routerLink="/hand-hygiene"
    >
      <div mat-menu-item class="section">
        <mat-icon>clean_hands</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.HYGIENE" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.MELT &&
        customerService.getPermission('MELT', 'view')
      "
      class="tile-link"
      routerLink="/melt"
    >
      <div mat-menu-item class="section">
        <mat-icon>vibration</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.MELT" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.PatientFlow &&
        customerService.getPermission('PatientFlow', 'view')
      "
      class="tile-link"
      routerLink="/patient-flow"
    >
      <div mat-menu-item class="section">
        <mat-icon>transfer_within_a_station</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.PATIENT" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.PeopleFlow &&
        customerService.getPermission('PeopleFlow', 'view')
      "
      class="tile-link"
      routerLink="/people-flow"
    >
      <div mat-menu-item class="section">
        <mat-icon>settings_accessibility</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.PEOPLE" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.SDCT &&
        customerService.getPermission('SDCT', 'view')
      "
      class="tile-link"
      routerLink="/sdct"
    >
      <div mat-menu-item class="section">
        <mat-icon>6_ft_apart</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.SDCT" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.StaffSafety &&
        customerService.getPermission('StaffSafety', 'view')
      "
      class="tile-link"
      routerLink="/staff-safety"
    >
      <div mat-menu-item class="section">
        <mat-icon>engineering</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.STAFF" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.TemperatureMonitoring &&
        customerService.getPermission('TemperatureMonitoring', 'view')
      "
      class="tile-link"
      routerLink="/temperature-monitoring"
    >
      <div mat-menu-item class="section">
        <mat-icon>thermostat</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.TEMPERATURE" | translate }}</span>
      </div>
    </a>

    <a
      *ngIf="
        customerService.subscriptions?.TemperatureMonitoring &&
        customerService.getPermission('BedManagement', 'view')
      "
      class="tile-link"
      routerLink="/bed-management"
    >
      <div mat-menu-item class="section">
        <mat-icon>night_shelter</mat-icon>
        <span>{{ "MAIN.DASHBOARD.SUBS.MANAGEMENT" | translate }}</span>
      </div>
    </a>
  </mat-menu>

  <a
    *ngIf="customerService.getPermission('Devices', 'view')"
    class="tile-link"
    routerLink="/devices"
  >
    <div mat-menu-item class="section">
      <mat-icon>wifi</mat-icon>
      <span>{{ "MAIN.DASHBOARD.DEVICES" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('Infrastructures', 'view')"
    class="tile-link"
    routerLink="/infrastructures"
  >
    <div mat-menu-item class="section">
      <mat-icon>wifi_tethering</mat-icon>
      <span>{{ "MAIN.DASHBOARD.INFRASTRUCTURE" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('SUPER_USER_ONLY', 'view')"
    class="tile-link"
    routerLink="/firmware"
  >
    <div mat-menu-item class="section">
      <mat-icon>app_settings_alt</mat-icon>
      <span>{{ "MAIN.DASHBOARD.FIRMWARE" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('Maps', 'view')"
    class="tile-link"
    routerLink="/map-editor"
  >
    <div mat-menu-item class="section">
      <mat-icon>location_on</mat-icon>
      <span>{{ "MAIN.DASHBOARD.MAPS" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('Settings', 'view')"
    class="tile-link"
    routerLink="/users"
  >
    <div mat-menu-item class="section">
      <mat-icon>people</mat-icon>
      <span>{{ "MAIN.DASHBOARD.USERS" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('Rules', 'view')"
    class="tile-link"
    routerLink="/rule-composer"
  >
    <div mat-menu-item class="section">
      <mat-icon>rule</mat-icon>
      <span>{{ "MAIN.DASHBOARD.RULES" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('Settings', 'view')"
    class="tile-link"
    routerLink="/system-settings"
  >
    <div mat-menu-item class="section">
      <mat-icon>settings</mat-icon>
      <span>{{ "MAIN.DASHBOARD.SETTINGS" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('Settings', 'view')"
    class="tile-link"
    routerLink="/company-information"
  >
    <div mat-menu-item class="section">
      <mat-icon>info</mat-icon>
      <span>{{ "MAIN.DASHBOARD.COMPANY" | translate }}</span>
    </div>
  </a>

  <a
    *ngIf="customerService.getPermission('Settings', 'view'); true"
    class="tile-link"
    routerLink="/debug-tool"
  >
    <div mat-menu-item class="section">
      <mat-icon>handyman</mat-icon>
      <span>{{ "MAIN.DASHBOARD.DEBUG" | translate }}</span>
    </div>
  </a>
</div>

<div *ngIf="env.CMMode" class="drop-container">
  <a class="tile-link" routerLink="/dashboard">
    <div mat-menu-item class="section">
      <mat-icon>apps</mat-icon>
      <span>{{ "MAIN.NAV.HOME" | translate }}</span>
    </div>
  </a>
  <a class="tile-link" routerLink="/devices">
    <div mat-menu-item class="section">
      <mat-icon>wifi</mat-icon>
      <span>{{ "MAIN.DASHBOARD.DEVICES" | translate }}</span>
    </div>
  </a>
  <a class="tile-link" routerLink="/infrastructures">
    <div mat-menu-item class="section">
      <mat-icon>wifi_tethering</mat-icon>
      <span>{{ "MAIN.DASHBOARD.INFRASTRUCTURE" | translate }}</span>
    </div>
  </a>
  <a class="tile-link" routerLink="/debug-tool">
    <div mat-menu-item class="section">
      <mat-icon>handyman</mat-icon>
      <span>{{ "MAIN.DASHBOARD.DEBUG" | translate }}</span>
    </div>
  </a>
</div>
