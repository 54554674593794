import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '@app/services/customer.service';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-analytics',
  template: `
    <iframe
      #dashboard
      frameborder="0"
      style="
        height: 100%;
        margin-bottom: -50px;
        width: 100%;
      "
    ></iframe>
  `,
})
export class AnalyticsComponent implements OnInit {
  @ViewChild('dashboard') iframe!: ElementRef;

  iframeLink: string = '';
  loginToken: string = '';
  subscription: string = '';
  timeZoneOffset: number = 0;
  URLPrefix: string = '';
  userId: number = 0;

  constructor(
    public customerService: CustomerService,
    public storageService: StorageService
  ) {
    this.subscription = window.location.pathname.split('/')[1];

    const d = new Date();
    this.timeZoneOffset = d.getTimezoneOffset();
  }

  ngOnInit() {
    let userData = this.storageService.get('uvs-credentials');

    if (userData) {
      this.loginToken = userData.userObject.LoginToken;
      this.userId = userData.userObject.Id;
    }

    this.setIframeUrl();
  }

  ngAfterViewInit() {
    this.iframe.nativeElement.setAttribute('src', this.iframeLink);
  }

  setIframeUrl() {    
    //this.iframeLink = `/AssetTrackingHome/?CustomerId=1&uid=19&tkn=Xh7w3VARWeWrMPZUoxoc/ZcDzqnI/TOTIcZXeSn0MDNBJVEelYBe4zsB3j/gssEs5P1Drkh7AJ/6bBtXO7iD+w==&TimeZoneOffset=240&UseCase=AssetTracking`;
    this.iframeLink = `http://52.45.17.177:802/xpertbedanalytics/`;
  }
}
