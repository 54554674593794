import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Command, Config, Infrastructure } from '@app/core/interfaces';
import { format, parseISO } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import {
  PermissionAction,
  PermissionResource,
  MaxRecordReturn,
} from '@app/core/constants';

import Icon from 'ol/style/Icon';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { Text } from 'ol/style';
import Polygon from 'ol/geom/Polygon';
import GeometryLayout from 'ol/geom/GeometryLayout';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';

@Injectable({
  providedIn: 'root',
})
export class SingleDeviceSettingsService {
  selectedSub: string = '';
  configs$ = new BehaviorSubject([]);
  currentDeviceCommands$ = new BehaviorSubject([]);
  customerId: number = 0;
  infrastructure$ = new BehaviorSubject([]);
  pendingDeviceCommands$ = new BehaviorSubject([]);
  permissions: any[] = []; //4-26-2023

  constructor(private http: HttpClient) {
    this.selectedSub = window.location.pathname.split('/')[1];
  }

  initializeService(id: number, forInfra: boolean, permissions: any) {
    this.customerId = id;
    this.permissions = permissions;
    this.getAllConfigurations(forInfra).subscribe();
    this.getAllInfras().subscribe();
  }

  createIconTextFeature(infra: any) {
    var textStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: './assets/icons/square_orange_.png',
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'middle',
        font: '12px Arial',
        text: infra.Name,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 1 }),
        offsetX: 0,
        offsetY: 0,
        placement: 'POINT',
      }),
    });
    var tempFeature = new Feature({
      geometry: new Point([infra.X, infra.Y, infra.X, infra.Y]),
      Name: infra.Name,
      UniqueId: infra.UniqueId,
      Status: infra.Status,
    });
    tempFeature.setStyle(textStyle);
    return tempFeature;
  }

  createZoneFeature(zone: any, style: any) {
    var tempZoneFeature = new Feature({
      geometry: new Polygon(Array(zone.Polypoints), GeometryLayout.XY),
      Id: zone.Id,
      Name: zone.Name,
      point: Array(zone.Polypoints),
      type: zone.ZoneType,
    });
    tempZoneFeature.setStyle(style);
    return tempZoneFeature;
  }

  deleteDeviceCommandsById(command: Command) {
    return this.http.delete(
      `/api/Device/DeleteDeviceCommandById?DeviceId=${command.Id}&CustomerId=${this.customerId}`
    );
  }

  //BAD NUMBER OF RECORDS
  getAllConfigurations(forInfra: boolean = false) {
    return this.http
      .get(
        `/api/Device/GetAllConfigurations?PageNumber=1` +
          `&NumberOfRecordsPerPage=${MaxRecordReturn}` +
          `&ForInfra=${forInfra}` +
          `&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          const configs = data.List.map((el: any) => {
            const newConfig: Config = {
              Id: el.Id,
              Name: el.ConfigName,
              Description: el.ConfigDescription,
              Type: el.DeviceType,
              ConfigDef: el.ConfigDef,
              IsDefault: el.IsDefault,
              IntegrationId: el.IntegrationId,
            };
            return newConfig;
          });
          this.configs$.next(configs);
          return configs;
        })
      );
  }

  //BAD NUMBER OF RECORDS
  getAllInfras() {
    return this.http
      .get(
        `/api/Infrastructure/GetAll?CustomerId=${this.customerId}&NumberOfRecords=${MaxRecordReturn}`
      )
      .pipe(
        map((data: any) => {
          const infras = data.List.map((el: any) => {
            const infrastructure: Infrastructure = {
              Id: el.Id,
              DeviceTableId: el.DeviceTableId,
              UniqueId: el.UniqueId,
              BatteryLevel: el.BatteryLevel,
              ConfigId: el.ConfigId,
              PendingConfigId: el.PendingConfigId,
              Type: el.Type,
              GroupId: el.GroupId,
              FirmwareVersion: el.FirmwareVersion,
              DeviceType: el.DeviceType,
              IsEnabled: el.IsEnabled,
              IsMonitored: el.IsMonitored,
              Name: el.Name,
              DateUpdated: el.DateUpdated
                ? format(
                    parseISO(el.DateUpdated + 'Z'),
                    'MMM do, yyyy h:mm aaa'
                  )
                : '',
              Status: el.Status,
              SiteId: el.SiteId,
              BuildingId: el.BuildingId,
              FloorId: el.ModelId,
              MapId: el.MapId,
              X: el.X,
              Y: el.Y,
              SiteName: el.SiteName,
              BuildingName: el.BuildingName,
              MapName: el.MapName,
              ZoneName: el.ZoneName,
              ZoneId: el.ZoneID,
            };
            return infrastructure;
          });
          this.infrastructure$.next(infras);
          return infras;
        })
      );
  }

  getCurrentCommandsByMac(UniqueId: string) {
    return this.http
      .get(
        `/api/Device/GetDeviceSettings?MacAddress=${UniqueId}&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          this.currentDeviceCommands$.next(data.List);
          return data.List;
        })
      );
  }

  getDeviceById(Id: number) {
    return this.http.get(
      `/api/Device/GetByDeviceId?DeviceId=${Id}` +
        `&CustomerId=${this.customerId}`
    );
  }

  getInfra(id: number) {
    return this.http.get(
      `/api/Infrastructure/GetByInfrastructureByMac?MacAddress=${id}&CustomerId=${this.customerId}`
    );
  }

  getMapById(id: number) {
    return this.http.get<any>(
      `/api/Maps/GetByMapId?MapId=${id}&CustomerId=${this.customerId}`,
      {}
    );
  }

  getPendingCommandsByMac(UniqueId: string) {
    return this.http
      .get(
        `/api/Device/GetDeviceCommands?MacAddress=${UniqueId}&CustomerId=${this.customerId}`
      )
      .pipe(
        map((data: any) => {
          this.pendingDeviceCommands$.next(data.List);
          return data.List;
        })
      );
  }

  //MKL changed these to TIERS on 5-9-2024, no superuser, might fix later
  getPermission(
    permissionType: PermissionResource,
    permissionAction: PermissionAction
  ): boolean {
    const p = this.permissions.find(
      (permission: any) => permission.Name == permissionType
    );
    if (p) {
      switch (permissionAction) {
        case 'view': //4
          return p.EnableView || p.EnableAdd || p.EnableEdit || p.EnableDelete;
        case 'add': //3
          return p.EnableAdd || p.EnableEdit || p.EnableDelete;
        case 'edit': //2
          return p.EnableEdit || p.EnableDelete;
        case 'delete': //1
          return p.EnableDelete;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  returnCustomZoneStyle(zoneType: string) {
    var strokeColor = 'rgba(0, 157, 255)'; //default blue
    var fillColor = 'rgba(0, 157, 255, 0.25)'; //default blue
    var tempStyle = new Style({
      stroke: new Stroke({
        color: strokeColor,
        width: 1,
      }),
      fill: new Fill({
        color: fillColor,
      }),
      zIndex: 5,
    });
    return tempStyle;
  }
}
