import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerService } from './customer.service';
import Icon from 'ol/style/Icon';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { Text } from 'ol/style';
import Polygon from 'ol/geom/Polygon';
import GeometryLayout from 'ol/geom/GeometryLayout';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { add, format, parseISO } from 'date-fns';
import { Asset, Department, Group, Item } from '@app/core/interfaces';
import * as olProj from 'ol/proj';
import { MaxRecordReturn } from '@app/core/constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  asset$ = new BehaviorSubject([]);
  group$ = new BehaviorSubject([]);
  dept$ = new BehaviorSubject([]);
  subscriptions$ = new BehaviorSubject({});

  companyInfo: any = {};

  customerId: number = 0;
  permissions: any[] = []; //4-26-2023
  isSuperUser: boolean = false;

  //pagination
  loading: boolean = false;
  page = {
    count: 0,
    IsDescending: false,
    limit: 20,
    pageNumber: 1,
    SearchText: '',
    SortField: 'Name',
    StatusFilterValue: 'All',
  };

  constructor(private http: HttpClient, private notifier: MatSnackBar) {}

  initializeService() {
    // ==> for production
    let uvsC = JSON.parse(<string>sessionStorage.getItem('uvs-credentials'));
    let customerData = sessionStorage.getItem('company');
    this.customerId = customerData
      ? JSON.parse(customerData).id || JSON.parse(customerData).company
      : undefined;
    this.isSuperUser = uvsC?.userObject?.IsSuperUser
      ? uvsC.userObject.IsSuperUser
      : false;
    this.permissions = uvsC?.userObject?.Permissions
      ? uvsC.userObject.Permissions
      : [];

    this.page.pageNumber = 1;
    this.page.SortField = 'Name';
    this.page.IsDescending = false;

    //initial calls
    forkJoin([this.getCompanyInfo(), this.getDepartments()]).subscribe(
      ([companyData, depts]) => {
        //console.log(cameras); //MKL 11-10-2023
        this.companyInfo = companyData;
      },
      () => {
        this.notifier.open(
          'There was a problem retrieving the customer information.',
          '',
          { panelClass: 'error' }
        );
      }
    );
  }

  getAssetsAdvance(params: any) {
    this.loading = true;
    const url =
      `/api/Staff/GetAllAdvance?CustomerId=${this.customerId}` +
      `&PageNumber=${this.page.pageNumber}` +
      `&NumberOfRecords=${this.page.limit}` +
      (params.group ? `&SearchGroupID=${params.group}` : '') +
      (params.application ? `&UseCase=${params.application}` : '') +
      (params.name ? `&SearchName=${params.name}` : '') +
      (params.department ? `&SearchDeptID=${params.department}` : '') +
      (params.battery ? `&SearchBatteryMax=${params.battery}` : '');

    return this.http.get(url).pipe(
      map((data: any) => {
        this.page.count = data.TotalRecordsCount;
        const assets = data.List.map((asset: any) => {
          const newAsset: Asset = {
            Id: asset.Id,
            AlertStatus: asset.AlertStatus,
            AssociatedDevices: asset.AssociatedDevices,
            BedStatus: asset.BedStatus || '',
            StatusLabel: 'ok',
            Name: asset.Name,
            DisplayName: asset.DisplayName,
            Email: asset.Email,
            EnableAlerts: asset.EnableAlerts,
            EnableSDCT: asset.EnableSDCT,
            EnableHygiene: asset.EnableHygiene,
            DepartmentName: asset.DepartmentName,
            DepartmentID: asset.DepartmentID || 0,
            AssocItemName: asset.AssocItemName,
            AssocItemID: asset.AssocItemID,
            PendingDepartmentName: asset.PendingDepartmentName,
            PendingDepartmentId: asset.PendingDepartmentId,
            PendingDepartmentDateUpdated: asset.PendingDepartmentDateUpdated,
            DeviceID: asset.DeviceID,
            DeviceName: asset.DeviceName,
            Temperature: Number(asset.Temperature) || 'N/A',
            GroupName: asset.GroupName,
            GroupID: asset.GroupID,
            BuildingName: asset.CurrentBuildingName,
            SiteName: asset.CurrentSiteName,
            FloorName: asset.CurrentFloorName,
            MapId: asset.CurrentModelId,
            CurrentZones: asset.CurrentZones,
            Latitude: asset.Latitude || 0,
            Longitude: asset.Longitude || 0,
            Address: asset.Address,
            X: asset.CurrentX,
            Y: asset.CurrentY,
            LocationUpdated: asset.LocationUpdated
              ? format(
                  parseISO(asset.LocationUpdated + 'Z'),
                  'MM/dd/yy h:mm aaa'
                )
              : '',
            ModelName: asset.ModelName,
            PhoneNumber: asset.PhoneNumber,
            StaffID: asset.StaffID,
            Icon: asset.Icon,
            Portrait: asset.Portrait,
            AlarisStatus: asset.AlarisStatus,
            UseCases: asset.UseCases,
            UserDefinedAttributes: asset.UserDefinedAttributes,
            UserDefinedStatus: asset.UserDefinedStatus,
            UserDefinedStatusIcon: asset.UserDefinedStatusIcon,
            //UserDefinedStatuses: asset.UserDefinedStatus,
          };

          //change Current Zones
          try {
            if (newAsset.CurrentZones) {
              newAsset.CurrentZones = JSON.parse(newAsset.CurrentZones) || [];
            }
          } catch (err) {
            newAsset.CurrentZones = [];
          }

          return newAsset;
        });
        this.asset$.next(assets);
        this.loading = false;
        return assets;
      })
    );
  }

  getCompanyInfo() {
    return this.http
      .get('/api/Customer/GetByCustomerId?CustomerId=' + this.customerId)
      .pipe(
        map((data: any) => {
          var custApps: any = {};
          try {
            custApps = JSON.parse(data.CustomerApplications);
          } catch {
            console.log('error parsing custapps');
            custApps = {};
          }
          this.subscriptions$.next(custApps);
          return data;
        })
      );
  }

  getDepartments() {
    return this.http
      .get(`/api/MetaData/GetDepartments?` + `&CustomerId=${this.customerId}`)
      .pipe(
        map((data: any) => {
          const departments = data.List.map((dept: any) => {
            const newDept: Department = {
              Id: dept.Id,
              Name: dept.Name,
              Icon: dept.Icon,
              NumberOfMembers: dept.NumberOfMembers,
            };
            return newDept;
          });
          this.dept$.next(departments);
          return departments;
        })
      );
  }

  getGroupsByUseCase(useCaseString: string) {
    return this.http
      .get(
        `/api/MetaData/GetGroups?CustomerId=${this.customerId}` +
          `&UseCase=${useCaseString}`
      )
      .pipe(
        map((data: any) => {
          const groups = data.List.map((group: any) => {
            const newGroup: Group = {
              Id: group.Id,
              Name: group.Name,
              Icon: group.Icon,
              UserDefinedAttributes: group.UserDefinedAttributes,
              PrimaryKey: group.PrimaryKey,
              SecondaryKey: group.SecondaryKey,
              EnableDwell: group.EnableDwell,
              NumberOfMembers: group.NumberOfMembers,
            };
            return newGroup;
          });
          this.group$.next(groups);
          return groups;
        })
      );
  }
}
