<p>Department Name: {{ deptIdentifier.Name }}</p>

<div class="post-load" *ngIf="department != null">
  <!-- Grid -->
  <div class="wrapper">
    <div class="waitlist-grid">
      <div class="table-scroll" *ngIf="dataSource.length > 0">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="Order">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}.
            </td>
          </ng-container>
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "COLUMNS.NAME" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
          </ng-container>
          <ng-container matColumnDef="TimeWaiting">
            <th mat-header-cell *matHeaderCellDef>
              {{ "COLUMNS.TIME_WAITING" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.TimeSinceCreated }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="table-scroll" *ngIf="dataSource.length == 0">
        <p
          *ngIf="getUseCase() == 'bed-management'"
          style="color: grey; font-style: italic"
        >
          Waitlist Empty
        </p>
      </div>
    </div>
  </div>
</div>
