import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import OpMap from 'ol/Map';
import View from 'ol/View';
import { Feature, Overlay } from 'ol';
import { Extent, getCenter } from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import { ZoomSlider } from 'ol/control';
import VectorLayer from 'ol/layer/Vector';
import Projection from 'ol/proj/Projection';
import { OSM, Source, Vector as VectorSource, Zoomify } from 'ol/source';
import { EventsService } from '@app/services/events.service';
import { WebSocketService } from '@app/services/websocket.service';
import Layer from 'ol/layer/Layer';
import Point from 'ol/geom/Point';
import * as olProj from 'ol/proj';
import { MapRenderService } from '@app/services/map-render.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const STAFF_LAYER_NUMBER = 3;

@Component({
  selector: 'app-map-previewer',
  template: ` <div id="map"></div> `,
  styleUrls: ['./map-previewer.component.scss'],
})
export class MapPreviewerComponent implements OnInit, OnChanges {
  @Input() selectedEvent: any;
  @Input() selectedStaff: any;

  coordinates: Array<number> = [0, 0, 0];

  //THE MAP
  gpsMode: boolean = false;
  map: any;
  modelNumberForMap: number = 0;
  mapView: View = new View();

  //ANIMATION
  animationFrameIndex: number = 0;
  animating: boolean = true;
  popUpFeatureMap: Map<string, Feature> = new Map();

  //Zones, the EVENT, and any temporary socket event staff on the map.
  zones: Array<any> = [];
  zoneFeatures: Array<Feature> = [];
  vectorZoneLayer!: Layer;
  zoneSplitterRegEx: RegExp = new RegExp(',|;');

  events: Array<any> = [];
  eventFeature: Array<Feature> = [];
  vectorEventLayer!: Layer;

  staff: Array<any> = [];
  staffFeatures: Array<Feature> = [];
  vectorStaffLayer!: Layer;

  vectorFollowBracketLayer!: Layer;
  // unableToFollowEvent: boolean = false;

  // @Output() followEventStatusChanged = new EventEmitter<boolean>();

  constructor(
    private renderService: MapRenderService,
    private eventsService: EventsService,
    private socketService: WebSocketService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.eventsService.initializeService();
    this.socketService.connect(this.onMessage).then(() => {
      this.socketService.sendMessage(
        'SUBSCRIBE ' + JSON.stringify(this.socketService.subscribe)
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedEvent']) {
      if (changes['selectedEvent'].currentValue) {
        this.selectedStaff = null;
        this.selectedEvent = changes['selectedEvent'].currentValue;
        if (this.selectedEvent.MapId != 0) {
          this.loadMap(this.selectedEvent.MapId);
        } else if (this.selectedEvent.MapId == 0) {
          this.unloadMap();
          // PUTTING GPS BACK IN 8/9/2024
          if (
            this.selectedEvent?.Latitude != 0 ||
            this.selectedEvent?.Longitude != 0
          ) {
            console.log('load gps map');
            this.loadGPSMap();
          } else {
            console.log('map not found for event.');
            this.unloadMap();
          }
        } else {
          console.log('error');
        }
      }
    } else if (changes['selectedStaff']) {
      if (changes['selectedStaff'].currentValue) {
        this.selectedEvent = null;
        this.selectedStaff = changes['selectedStaff'].currentValue;
        this.selectedStaff.X = this.selectedStaff.CurrentX;
        this.selectedStaff.Y = this.selectedStaff.CurrentY;
        if (this.selectedStaff.CurrentModelId != 0) {
          //fix prop to allow follow
          this.loadMap(this.selectedStaff.CurrentModelId);
        } else if (this.selectedStaff.CurrentModelId == 0) {
          this.unloadMap();
          // PUTTING GPS BACK IN 8/9/2024
          if (
            this.selectedEvent?.Latitude != 0 ||
            this.selectedEvent?.Longitude != 0
          ) {
            console.log('load gps map');
            this.loadGPSMap();
          } else {
            console.log('map not found for event.');
            this.unloadMap();
          }
        } else {
          console.log('error');
        }
      }
    }
  }

  loadMap(mapNum: number) {
    this.gpsMode = false;
    this.modelNumberForMap = mapNum;
    this.eventsService.getMapById(mapNum).subscribe(
      (response: any) => {
        const mapElement = <HTMLElement>document.querySelector('#map');
        while (mapElement.firstChild) {
          mapElement.removeChild(mapElement.firstChild);
        }

        this.zones = response.Zones;

        this.zoneFeatures = [];
        this.eventFeature = [];
        this.staffFeatures = [];
        this.zones.forEach((zone: any) => {
          const zz = this.renderService.create2DZoneFeature(zone);
          zz && this.zoneFeatures.push();
        });

        if (this.selectedEvent) {
          this.eventFeature.push(
            this.renderService.createIconEventFeature(this.selectedEvent)
          ); //YNEG - PUTS IN ALIGNMENT WITH OTHER MAPS! MUST KEEP!
        }
        if (this.selectedStaff) {
          const ss = this.renderService.createIconStaffFeature(
            this.selectedStaff
          );
          this.staffFeatures.push(ss);
          this.vectorFollowBracketLayer = new VectorLayer({
            source: new VectorSource({ features: this.staffFeatures }),
            zIndex: 15,
          });
        } else {
          this.vectorFollowBracketLayer = new VectorLayer({
            source: new VectorSource({ features: [] }),
            zIndex: 15,
          });
        }

        //VIEW AND LAYERS
        this.mapView = new View({
          projection: new Projection({
            code: '???',
            units: 'pixels',
            extent: [0, 0, response.Width, -response.Height],
          }),
          center: getCenter([0, 0, response.Width, -response.Height]),
          zoom: 0,
          maxZoom: 3,
        });
        this.vectorZoneLayer = new VectorLayer({
          source: new VectorSource({
            features: this.zoneFeatures,
          }),
          zIndex: 10,
        });
        this.vectorEventLayer = new VectorLayer({
          source: new VectorSource({
            features: this.eventFeature,
          }),
          zIndex: 10,
        });
        this.vectorStaffLayer = new VectorLayer({
          source: new VectorSource({
            features: this.staffFeatures,
          }),
          zIndex: 10,
        });

        this.map = new OpMap({
          layers: [
            new TileLayer({
              source: new Zoomify({
                url: `../maps/${mapNum}/${mapNum}/`,
                size: [response.Width, response.Height],
              }),
              zIndex: 5,
            }),
            this.vectorZoneLayer,
            this.vectorEventLayer,
            this.vectorStaffLayer,
            this.vectorFollowBracketLayer,
          ],
          target: mapElement,
          view: this.mapView,
        });

        var zoomslider = new ZoomSlider();
        this.map.addControl(zoomslider);

        //Animation
        this.map.once('postrender', () => {
          setInterval(() => {
            this.animationFrameIndex++;
            if (this.animating) {
              var staffFeaturesInMap = this.map
                .getLayers()
                .array_[STAFF_LAYER_NUMBER].getSource()
                .getFeatures();
              staffFeaturesInMap.forEach((feat: any) => {
                if (feat.get('Moving')) {
                  var current = feat.getGeometry().getCoordinates().slice(0, 2);
                  var target = feat.get('Target');
                  var distanceBuffer = 12;
                  var increment = 6;
                  if (current[0] > target[0] + distanceBuffer) {
                    //X
                    feat.getGeometry().translate(-increment, 0);
                  }
                  if (current[0] < target[0] - distanceBuffer) {
                    //X
                    feat.getGeometry().translate(increment, 0);
                  }
                  if (current[1] > target[1] + distanceBuffer) {
                    //Y
                    feat.getGeometry().translate(0, -increment);
                  }
                  if (current[1] < target[1] - distanceBuffer) {
                    //Y
                    feat.getGeometry().translate(0, increment);
                  }
                }
              });
            }
          }, 60);
        });
        if (this.selectedEvent) {
          setTimeout(() => {
            this.mapView.fit(
              [
                this.selectedEvent.X,
                -this.selectedEvent.Y,
                this.selectedEvent.X,
                -this.selectedEvent.Y,
              ],
              {
                padding: [50, 50, 50, 50],
              }
            );
          }, 500);
        }
      },
      (error: any) => {
        this.snackBar.open(
          `Error: ${error.error.Message} Cannot follow this event.`,
          'Close',
          {
            duration: 7500,
          }
        );
        // this.unableToFollowEvent = true;
      }
    );
  }

  loadGPSMap() {
    this.gpsMode = true;
    this.eventsService.getGPSMapByCustomerId().subscribe(
      (response: any) => {
        this.modelNumberForMap = response.Id;
        const mapElement = <HTMLElement>document.querySelector('#map');
        while (mapElement.firstChild) {
          mapElement.removeChild(mapElement.firstChild);
        }

        this.zones = response.Zones;

        this.zoneFeatures = [];
        this.eventFeature = [];
        this.staffFeatures = [];

        this.zones.forEach((zone: any) => {
          this.zoneFeatures.push(this.renderService.createGPSZoneFeature(zone));
        });

        if (this.selectedEvent) {
          this.eventFeature.push(
            this.renderService.createIconEventFeatureGPS(this.selectedEvent)
          );
        }

        if (this.selectedStaff) {
          this.staffFeatures.push(
            this.renderService.createIconStaffFeatureGPS(this.selectedStaff)
          );
          this.vectorFollowBracketLayer = new VectorLayer({
            source: new VectorSource({ features: this.staffFeatures }),
            zIndex: 15,
          });
        } else {
          this.vectorFollowBracketLayer = new VectorLayer({
            source: new VectorSource({ features: [] }),
            zIndex: 15,
          });
        }

        //VIEW AND LAYERS
        this.mapView = new View({
          center: olProj.fromLonLat([0, 0]),
          zoom: 3,
        });
        this.vectorZoneLayer = new VectorLayer({
          source: new VectorSource({
            features: this.zoneFeatures,
          }),
          zIndex: 10,
        });
        this.vectorEventLayer = new VectorLayer({
          source: new VectorSource({
            features: this.eventFeature,
          }),
          zIndex: 10,
        });
        this.vectorStaffLayer = new VectorLayer({
          source: new VectorSource({
            features: this.staffFeatures,
          }),
          zIndex: 10,
        });

        this.map = new OpMap({
          layers: [
            new TileLayer({
              source: new OSM({}),
              zIndex: 5,
            }),
            this.vectorZoneLayer,
            this.vectorEventLayer,
            this.vectorStaffLayer,
          ],
          target: mapElement,
          view: this.mapView,
        });

        var zoomslider = new ZoomSlider();
        this.map.addControl(zoomslider);

        //Animation
        this.map.once('postrender', () => {
          if (this.eventFeature[0]) {
            var extent = this.eventFeature[0].getGeometry()?.getExtent();
            if (extent) {
              setTimeout(() => {
                this.mapView.fit(<Extent>extent, { maxZoom: 14 });
              }, 200);
            }
          }

          setInterval(() => {
            this.animationFrameIndex++;
            if (this.animating) {
              var staffFeaturesInMap = this.map
                .getLayers()
                .array_[STAFF_LAYER_NUMBER].getSource()
                .getFeatures();
              staffFeaturesInMap.forEach((feat: any) => {
                if (feat.get('Moving')) {
                  var current = feat.getGeometry().getCoordinates().slice(0, 2);
                  var target = feat.get('Target');
                  var buffer = 12;
                  var increment = 6;
                  if (current[0] > target[0] + buffer) {
                    feat.getGeometry().translate(-increment, 0);
                  }
                  if (current[0] < target[0] - buffer) {
                    feat.getGeometry().translate(increment, 0);
                  }
                  if (current[1] > target[1] + buffer) {
                    feat.getGeometry().translate(0, -increment);
                  }
                  if (current[1] < target[1] - buffer) {
                    feat.getGeometry().translate(0, increment);
                  }
                }
              });
            }
          }, 60);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onMessage = (ev: MessageEvent) => {
    try {
      let a: any = JSON.parse(ev.data);
      let msg: any = JSON.parse(JSON.stringify(a.data));
      if (
        msg?.SchemaName == 'XpertSchema.XpertMessage.XpertMessage' &&
        msg?.DeviceReports[0]?.Item?.ItemId
      ) {
        var msgItemId = msg.DeviceReports[0].Item.ItemId;

        var staffFeatureFound = false;
        for (let i = 0; i < this.staffFeatures.length; i++) {
          if (msgItemId == this.staffFeatures[i].get('Id')) {
            staffFeatureFound = true;
            if (
              msg.DeviceReports[0].RTLSModel2D.PosX &&
              msg.DeviceReports[0].RTLSModel2D.PosY
            ) {
              if (
                msg.DeviceReports[0].RTLSModel2D.PosModelID ==
                this.modelNumberForMap
              ) {
                var newPoint = <Point>this.staffFeatures[i].getGeometry();
                var deltaX =
                  msg.DeviceReports[0].RTLSModel2D.PosX -
                  newPoint.getCoordinates()[0];
                var deltaY =
                  -msg.DeviceReports[0].RTLSModel2D.PosY -
                  newPoint.getCoordinates()[1]; //YNEG
                if (deltaX > 1 || deltaY > 1 || deltaX < -1 || deltaY < -1) {
                  this.staffFeatures[i].set('Target', [
                    msg.DeviceReports[0].RTLSModel2D.PosX,
                    -msg.DeviceReports[0].RTLSModel2D.PosY,
                  ]);
                  this.staffFeatures[i].set('Moving', true);
                }

                //add follow bracket!
                this.vectorFollowBracketLayer.setSource(
                  new VectorSource({
                    features: [
                      this.renderService.createFollowBracket(
                        this.staffFeatures[i]
                      ),
                    ],
                  })
                );
              } else if (msgItemId == this.selectedStaff.Id) {
                console.log('follow to next map');
                this.loadMap(msg.DeviceReports[0].RTLSModel2D.PosModelID);
              } else {
                //this.staffFeatures[i].setStyle(this.mapService.invisibleIconStyle);
              }
            }
          }
        }
        if (
          !staffFeatureFound &&
          msg.DeviceReports[0].RTLSModel2D.PosModelID ==
            this.modelNumberForMap &&
          msg.DeviceReports[0].RTLSModel2D.PosX &&
          msg.DeviceReports[0].RTLSModel2D.PosY
        ) {
          this.eventsService.getStaffById(msgItemId).subscribe(
            (res: any) => {
              this.staffFeatures.push(
                this.renderService.createIconStaffFeature(res)
              );
              this.vectorStaffLayer.setSource(
                new VectorSource({ features: this.staffFeatures })
              );
            },
            (err: any) => {
              console.log(err);
            }
          );
        }
      } else {
        console.warn('socket message without mapID, X, or Y.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  unloadMap() {
    const mapElement = <HTMLElement>document.querySelector('#map');
    while (mapElement.firstChild) {
      mapElement.removeChild(mapElement.firstChild);
    }
  }
}
