import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvService } from '@app/services/env.service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private env: EnvService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // ingoring http calls from ngx-translate
    if (!request.url.includes('assets/i18n/')) {
      if (!/^(http|https):/i.test(request.url)) {
        if (request.url.includes('ssapi')) {
          request = request.clone({
            url: this.env.statusServerUrl + request.url,
          });
        } else {
          request = request.clone({ url: this.env.serverUrl + request.url });
        }
      }
    }
    return next.handle(request);
  }
}
