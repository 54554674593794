<h3 mat-dialog-title>
  {{ "DEVICES.EDIT_CONFIG.CONFIGURATION_DETAILS" | translate }}:
  {{ data.config.Name }}
</h3>
<div mat-dialog-content>
  <div class="wrapper">
    <mat-button-toggle-group [(ngModel)]="selectPanel">
      <mat-button-toggle value="settings">{{
        "DEVICES.EDIT_CONFIG.SETTINGS" | translate
      }}</mat-button-toggle>
      <mat-button-toggle value="commands">{{
        "DEVICES.EDIT_CONFIG.COMMANDS" | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-accordion
      *ngIf="selectPanel === 'settings' && data.forInfra === false"
    >
      <!-- AoA Tag Specific (CHH 1/16/2022 might beed to change ngIf statement-->
      <div *ngIf="displaySettings.alt.value >= 0">
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>{{
              "DEVICES.EDIT_CONFIG.AOA_TAG_ALTITUDE_SETTING" | translate
            }}</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="content">
            <span>{{ "DEVICES.EDIT_CONFIG.ALTITUDE" | translate }}</span>
            <span class="detail"
              >{{ displaySettings.alt.value }}
              {{ displaySettings.alt.unit }}
            </span>
          </div>
        </mat-expansion-panel>
      </div>

      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "DEVICES.EDIT_CONFIG.GENERIC_SETTINGS" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.REPORT_INTERVAL_TIME" | translate
          }}</span>
          <span class="detail"
            >{{ displaySettings.wsn.value }}
            {{ displaySettings.wsn.unit }}
          </span>
        </div>
        <div class="content" *ngIf="displaySettings?.workingMode">
          <span>{{ "DEVICES.EDIT_CONFIG.WORKING_MODE" | translate }}</span>
          <span class="detail">{{ displaySettings.workingMode }} </span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.MAINTENENCE_INTERVAL" | translate
          }}</span>
          <span class="detail"
            >{{ displaySettings.mn.value }} {{ displaySettings.mn.unit }}
          </span>
        </div>
        <div
          class="content"
          *ngIf="data.config?.Type === 'A1' || data.config?.Type === 'A2'"
        >
          <span>{{ "DEVICES.EDIT_CONFIG.CONNECTION_RSSI" | translate }}</span>
          <span class="detail">-{{ displaySettings.pumr.value }}</span>
        </div>
        <div
          class="content"
          *ngIf="data.config?.Type === 'A1' || data.config?.Type === 'A2'"
        >
          <span>{{ "DEVICES.EDIT_CONFIG.CONNECTION_AGE" | translate }}</span>
          <span class="detail">{{ displaySettings.puma.value }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.REPORT_VERSION" | translate }}</span>
          <span class="detail">{{ displaySettings.wrv.value }}</span>
        </div>
        <div class="content" *ngIf="data.config?.Type === 'B4'">
          <span>{{ "DEVICES.EDIT_CONFIG.BUZZER_MODE" | translate }}</span>
          <span class="detail">{{
            getBuzzerMode(displaySettings.ms.value)
          }}</span>
        </div>
        <div class="content" *ngIf="data.config?.Type === 'B4'">
          <span>{{
            "DEVICES.EDIT_CONFIG.CLEAR_MESSAGES_ON_DETACH" | translate
          }}</span>
          <span class="detail">{{
            displaySettings.mcm.value ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.REPORT_IMMEDIATELY" | translate
          }}</span>
          <span class="detail">{{
            displaySettings.sdri.value ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.DISABLE_TAG_DEACTIVATION" | translate
          }}</span>
          <span class="detail">{{
            displaySettings.dtda.value ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.REPORT_ACTIONS" | translate }}</span>
          <span class="detail">
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wra.value.Gateway"
            >
              {{ "DEVICES.EDIT_CONFIG.REPORT_VIA_GATEWAY" | translate }}
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wra.value.HFBle"
            >
              HF BLE
            </mat-checkbox>
            <mat-checkbox
              *ngIf="data.config?.Type !== 'A1' && data.config?.Type !== 'A2'"
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wra.value.Wifi"
            >
              Wi-Fi
            </mat-checkbox>
          </span>
        </div>
        <div
          class="content"
          *ngIf="data.config?.Type !== 'A1' && data.config?.Type !== 'A2'"
        >
          <span>{{ "DEVICES.EDIT_CONFIG.SCAN_MODE" | translate }}</span>
          <span class="detail">
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wsm.value.ALP"
            >
              ALP
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wsm.value.CCX"
            >
              CCX
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wsm.value.BLINK"
            >
              BLINK
            </mat-checkbox>
          </span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.SAFETY_ALERT_TIME" | translate }}</span>
          <span class="detail"
            >every {{ displaySettings.wsai.value }}
            {{ displaySettings.wsai.unit }}
          </span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.DHCP_LEASE_TIME" | translate }}</span>
          <span class="detail"> {{ displaySettings.dhlt.value }} Minutes </span>
        </div>
        <div class="content" *ngIf="data.config?.Type !== 'A1'">
          <span>{{ "DEVICES.EDIT_CONFIG.WIFI_STAY_CONN" | translate }}</span>
          <span class="detail"
            >every {{ displaySettings.wscn.value }}
            {{ displaySettings.wscn.unit }}
          </span>
        </div>
        <div class="content" *ngIf="data.config?.Type !== 'A1'">
          <span>{{ "DEVICES.EDIT_CONFIG.WIFI_ROAMING" | translate }}</span>
          <span class="detail">
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wr.value.FIP"
            >
              FIP
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.wr.value.FC"
            >
              FC
            </mat-checkbox>
          </span>
        </div>
        <div class="content" *ngIf="data.config?.Type !== 'A1'">
          <span>{{
            "DEVICES.EDIT_CONFIG.WIFI_FAILED_RETRY_COUNT" | translate
          }}</span>
          <span class="detail">{{ displaySettings.wfrc.value }}</span>
        </div>
        <div class="content" *ngIf="data.config?.Type !== 'A1'">
          <span>{{ "DEVICES.EDIT_CONFIG.WIFI_OAD_PORT" | translate }}</span>
          <span class="detail">{{ displaySettings.oadp.value }}</span>
        </div>
      </mat-expansion-panel>

      <!-- <!-1- Messaging Settings Section -1-> -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "DEVICES.EDIT_CONFIG.MESSAGING_SETTINGS" | translate
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content" *ngIf="data.config?.Type === 'B4'">
          <span>{{ "DEVICES.EDIT_CONFIG.ENABLE_SILENT" | translate }}</span>
          <span class="detail">
            <span *ngIf="displaySettings.b4sm.value">{{
              "FORMS.YES" | translate
            }}</span>
            <span *ngIf="!displaySettings.b4sm.value">{{
              "FORMS.NO" | translate
            }}</span>
          </span>
        </div>
        <div class="content" *ngIf="data.config?.Type === 'W9'">
          <span>{{ "DEVICES.EDIT_CONFIG.SOS_PHONE_NUMBERS" | translate }}</span>
          <span
            class="detail-phone-numbers"
            *ngIf="displaySettings?.phoneNumber1"
          >
            {{ displaySettings.phoneNumber1 || "" }},
            {{ displaySettings.phoneNumber2 || "" }},
            {{ displaySettings.phoneNumber3 || "" }}
          </span>
        </div>
      </mat-expansion-panel>

      <!-- <!-1- Motion Settings Section -1-> -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "DEVICES.EDIT_CONFIG.MOTION_SETTINGS" | translate
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.MOTION_SENSITIVITY" | translate
          }}</span>
          <span class="detail">{{
            getMotionSensitivity(displaySettings.se.value)
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.SENSOR_MODE" | translate }}</span>
          <span class="detail">{{
            getSensorMode(displaySettings.sm.value)
          }}</span>
        </div>
        <div class="content">
          <span *ngIf="displaySettings.sm.value === 0">{{
            "DEVICES.EDIT_CONFIG.LOCATION_UPDATE_INTERVAL_WHILE_MOVING"
              | translate
          }}</span>
          <span *ngIf="displaySettings.sm.value === 1">{{
            "DEVICES.EDIT_CONFIG.MOTION_STOP_TIMEOUT" | translate
          }}</span>
          <span *ngIf="displaySettings.sm.value === 2">{{
            "DEVICES.EDIT_CONFIG.MOTION_STOP_TIMEOUT" | translate
          }}</span>
          <span *ngIf="displaySettings.sm.value === 3">{{
            "DEVICES.EDIT_CONFIG.MAN_DOWN_DETECTION_TIME" | translate
          }}</span>
          <span class="detail"
            >every {{ displaySettings.sd.value }} {{ displaySettings.sd.unit }}
          </span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.STAGNANT_INTERVAL" | translate }}</span>
          <span class="detail"
            >every {{ displaySettings.ss.value }} {{ displaySettings.ss.unit }}
          </span>
        </div>
      </mat-expansion-panel>

      <!-- BLE Beacon Settings Section-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "DEVICES.EDIT_CONFIG.BLE_BEACON_SETTINGS" | translate
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.BLE_BEACONING" | translate }}</span>
          <span class="detail">{{
            displaySettings.bta.value ? "Enabled" : "Disabled"
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.BLE_RADIO" | translate }}</span>
          <span class="detail">{{
            displaySettings.bdrd.value ? "Disabled" : "Enabled"
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.BLE_ADVERTISING" | translate }}</span>
          <span class="detail">{{
            displaySettings.bta.value ? "Enabled" : "Disabled"
          }}</span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.REPORT_BLE_TX_POWER" | translate
          }}</span>
          <span class="detail">{{
            displaySettings.bemp.value ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.BLE_MAX_TX_POWER" | translate }}</span>
          <span class="detail">{{ displaySettings.btxp.value }}</span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.BEACON_FREQUENCY_MS" | translate
          }}</span>
          <span class="detail">{{ displaySettings.bbf.value }} ms</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.BEACON_TYPE" | translate }}</span>
          <span class="detail">{{
            getBeaconType(displaySettings.bbt.value)
          }}</span>
        </div>
      </mat-expansion-panel>

      <!-- BLE Scanning Settings Section-->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "DEVICES.EDIT_CONFIG.BLE_SCANNING_SETTINGS" | translate
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.ENABLE_BLE_DISC" | translate }}</span>
          <span class="detail">{{
            displaySettings.bebd.value ? "Enabled" : "Disabled"
          }}</span>
        </div>
        <div class="content">
          <span>Scan bitmask</span>
          <span class="detail">
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.bsbm.value.AF"
            >
              AF
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.bsbm.value.iBeacon"
            >
              iBeacon
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.bsbm.value.Eddystone"
            >
              Eddystone
            </mat-checkbox>
          </span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.AF_INFRASTRUCTURE_ONLY" | translate
          }}</span>
          <span class="detail">{{
            displaySettings.bsbm.value.AFInfraOnly ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.SCAN_DURATION_MS" | translate }}</span>
          <span class="detail">{{ displaySettings.bsd.value }} ms</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.SCAN_FREQUENCY_MS" | translate }}</span>
          <span class="detail">{{ displaySettings.bsf.value }} ms</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.SCAN_COUNT" | translate }}</span>
          <span class="detail">{{ displaySettings.bsc.value }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.SCAN_Q_SIZE" | translate }}</span>
          <span class="detail">{{ displaySettings.bsq.value }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.ALGORITHM_Q_SIZE" | translate }}</span>
          <span class="detail">{{
            getAlgorithm(displaySettings.balg.value)
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.RSSI_THRESHOLD" | translate }}</span>
          <span class="detail">{{ displaySettings.brth.value }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.PROX_OUT_RSSI" | translate }}</span>
          <span class="detail">{{ displaySettings.bpor.value }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.PROX_IN_RSSI" | translate }}</span>
          <span class="detail">{{ displaySettings.bpir.value }}</span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.PROX_OUT_MAX_MISSED_BEACONS" | translate
          }}</span>
          <span class="detail">{{ displaySettings.bpom.value }}</span>
        </div>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.REPORT_PROX_OUT_MAX_MISSED_BEACONS" | translate
          }}</span>
          <span class="detail">{{
            displaySettings.bpmr.value ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.SCAN_ON_REPORT" | translate }}</span>
          <span class="detail">{{
            displaySettings.bsor.value ? "Yes" : "No"
          }}</span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- for infra data.configs -->
    <mat-accordion *ngIf="selectPanel === 'settings' && data.forInfra === true">
      <!-- BLE General Settings Section -->
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>{{
            "DEVICES.EDIT_CONFIG.GENERIC_SETTINGS" | translate
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <span>{{
            "DEVICES.EDIT_CONFIG.REPORT_INTERVAL_TIME" | translate
          }}</span>
          <span class="detail"
            >every {{ displaySettings.wsn.value }}
            {{ displaySettings.wsn.unit }}
          </span>
        </div>
        <div
          class="content"
          *ngIf="data.config?.Type === 'B3G' || data.config?.Type === 'G3'"
        >
          <span>{{
            "DEVICES.EDIT_CONFIG.MAINTENANCE_INTERVAL_TIME" | translate
          }}</span>
          <span class="detail"
            >every {{ displaySettings.mn.value }} {{ displaySettings.mn.unit }}
          </span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.MIN_RSSI" | translate }}</span>
          <span class="detail"
            >-{{ displaySettings.bibj.value.MinimumRSSI }}db</span
          >
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.MAX_RSSI" | translate }}</span>
          <span class="detail">-{{ displaySettings.mrsi.value }}db</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.RSSI_OFFSET" | translate }}</span>
          <span class="detail">{{
            displaySettings.bibj.value.RSSIOffset
          }}</span>
        </div>
        <div
          class="content"
          *ngIf="data.config?.Type === 'L1' || data.config?.Type === 'L2'"
        >
          <span>{{ "DEVICES.EDIT_CONFIG.CONNECTION_RSSI" | translate }}</span>
          <span class="detail">-{{ displaySettings.pumr.value }}</span>
        </div>
        <div
          class="content"
          *ngIf="data.config?.Type === 'L1' || data.config?.Type === 'L2'"
        >
          <span>{{ "DEVICES.EDIT_CONFIG.CONNECTION_AGE_S" | translate }}</span>
          <span class="detail">{{ displaySettings.puma.value }}</span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.MULTIPLIER" | translate }}</span>
          <span class="detail">{{ displaySettings.mult.value }} </span>
        </div>
        <div class="content" *ngIf="data.config?.Type === 'WGU'">
          <span>{{ "DEVICES.EDIT_CONFIG.HIGH_FREQ_ONLY" | translate }}</span>
          <span class="detail">{{ displaySettings.hfo.value }} </span>
        </div>
        <div
          class="content"
          *ngIf="data.config?.Type === 'B3G' || data.config?.Type === 'G3'"
        >
          <span>{{ "DEVICES.EDIT_CONFIG.WIFI_OAD_PORT" | translate }}</span>
          <span class="detail">{{ displaySettings.oadp.value }}</span>
        </div>
        <div class="content" *ngIf="data.config?.Type === 'BGU'">
          <span>{{ "DEVICES.EDIT_CONFIG.BGU_MODE" | translate }}</span>
          <span class="detail">
            <span *ngIf="displaySettings?.bta?.value === 1">{{
              "DEVICES.EDIT_CONFIG.BEACONING" | translate
            }}</span>
            <span *ngIf="displaySettings?.bebd?.value === 1">{{
              "DEVICES.EDIT_CONFIG.SCANNING" | translate
            }}</span>
          </span>
        </div>
        <div class="content">
          <span>{{ "DEVICES.EDIT_CONFIG.PROXIMITY" | translate }}</span>
          <span class="detail">{{ displaySettings.prox.value }}</span>
        </div>
      </mat-expansion-panel>

      <!-- BLE Beacon Settings Section -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>BLE Beacon Settings</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <span>BLE Beaconing</span>
          <span class="detail">{{
            displaySettings.bta.value ? "Enabled" : "Disabled"
          }}</span>
        </div>
        <div class="content">
          <span>BLE max TX power</span>
          <span class="detail">{{ displaySettings.btxp.value }}</span>
        </div>
        <div class="content">
          <span>Beacon frequency</span>
          <span class="detail">{{ displaySettings.bbf.value }} ms</span>
        </div>
      </mat-expansion-panel>

      <!-- BLE Scanning Settings Section -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>BLE Scanning Settings</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
          <span>BLE Discovery</span>
          <span class="detail">{{
            displaySettings.bebd.value ? "Enabled" : "Disabled"
          }}</span>
        </div>
        <div class="content">
          <span>Scan bitmask</span>
          <span class="detail">
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.bsbm.value.AF"
            >
              AF
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.bsbm.value.iBeacon"
            >
              iBeacon
            </mat-checkbox>
            <mat-checkbox
              [disableRipple]="true"
              (click)="$event.preventDefault()"
              color="primary"
              [(ngModel)]="displaySettings.bsbm.value.Eddystone"
            >
              Eddystone
            </mat-checkbox>
          </span>
        </div>
        <div class="content">
          <span>OUI filter</span>
          <span class="detail">
            <span *ngFor="let oui of displaySettings.bebl.value">
              {{ oui }}
            </span>
          </span>
        </div>
        <div class="content">
          <span>AF infrastructure only</span>
          <span class="detail">{{
            displaySettings.bsbm.value.AFInfraOnly ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>Scan duration</span>
          <span class="detail">{{ displaySettings.bsd.value }} ms</span>
        </div>
        <div class="content">
          <span>Scan frequency</span>
          <span class="detail">{{ displaySettings.bsf.value }} ms</span>
        </div>
        <div class="content">
          <span>Scan count</span>
          <span class="detail">{{ displaySettings.bsc.value }}</span>
        </div>
        <div class="content">
          <span>Scan Q size</span>
          <span class="detail">{{ displaySettings.bsq.value }}</span>
        </div>
        <div class="content">
          <span>Algorithm Q size</span>
          <span class="detail">{{
            getAlgorithm(displaySettings.balg.value)
          }}</span>
        </div>
        <div class="content">
          <span>RSSI threshold</span>
          <span class="detail">{{ displaySettings.brth.value }}</span>
        </div>
        <div class="content">
          <span>Prox out RSSI</span>
          <span class="detail">{{ displaySettings.bpor.value }}</span>
        </div>
        <div class="content">
          <span>Prox in RSSI</span>
          <span class="detail">{{ displaySettings.bpir.value }}</span>
        </div>
        <div class="content">
          <span>Prox out max missed beacons</span>
          <span class="detail">{{ displaySettings.bpom.value }}</span>
        </div>
        <div class="content">
          <span>Report prox out missed beacons</span>
          <span class="detail">{{
            displaySettings.bpmr.value ? "Yes" : "No"
          }}</span>
        </div>
        <div class="content">
          <span>Scan on report</span>
          <span class="detail">{{
            displaySettings.bsor.value ? "Yes" : "No"
          }}</span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="commands" *ngIf="selectPanel === 'commands'">
      <div class="code" *ngFor="let code of setCommandsForType()">
        <span>{{ code }}</span>
        <span class="value">{{ currentValues[code].value }}</span>
      </div>
    </div>
  </div>
</div>
