<div class="wrapper">
  <div class="container">
    <div class="title">{{ "MAIN.PASSWORD.RESET" | translate }}</div>

    <div class="form" [formGroup]="changePassForm">
      <span class="label">{{ "MAIN.PASSWORD.TEMPORARY" | translate }}</span>
      <input
        class="login-input"
        type="password"
        formControlName="tempPassword"
      />
      <span
        class="wrong-password"
        *ngIf="tempPassword?.touched && tempPassword?.errors?.required"
        >{{ "MAIN.PASSWORD.TEMPORARY_REQUIRED" | translate }}</span
      >
      <span class="label">{{ "MAIN.PASSWORD.NEW" | translate }}</span>
      <input
        class="login-input"
        type="password"
        formControlName="newPassword"
      />
      <span
        class="wrong-password"
        *ngIf="newPassword?.touched && newPassword?.errors?.required"
        >{{ "MAIN.PASSWORD.NEW_REQUIRED" | translate }}</span
      >
      <span
        class="wrong-password"
        *ngIf="newPassword?.touched && newPassword?.errors?.pattern"
        >{{ getPasswordRequirements().caps }} capital(s),
        {{ getPasswordRequirements().nums }} number(s), and
        {{ getPasswordRequirements().symb }} symbol(s) required</span
      >
      <span
        class="wrong-password"
        *ngIf="newPassword?.touched && newPassword?.errors?.minlength"
        >Must be at least {{ getPasswordLength() }} characters long</span
      >
      <span class="label">{{ "MAIN.PASSWORD.CONFIRM" | translate }}</span>
      <input
        class="login-input"
        type="password"
        formControlName="confirmPassword"
      />
      <span
        class="wrong-password"
        *ngIf="confirmPassword?.touched && confirmPassword?.errors?.required"
        >{{ "MAIN.PASSWORD.CONFIRM_REQUIRED" | translate }}</span
      >
      <span
        class="wrong-password"
        *ngIf="confirmPassword?.touched && confirmPassword?.errors?.mustMatch"
        >{{ "MAIN.PASSWORD.CONFIRM_ERROR" | translate }}</span
      >
      <button
        [disabled]="!changePassForm.valid"
        class="button-primary"
        (click)="submit()"
      >
        <mat-spinner
          class="spinner"
          color="accent"
          diameter="21"
          *ngIf="loading"
        ></mat-spinner>
        <span *ngIf="!loading">{{ "FORMS.SUBMIT" | translate }}</span>
      </button>
      <button (click)="redirectToLogin()" class="button-cancel">
        {{ "FORMS.CANCEL" | translate }}
      </button>
    </div>
  </div>
  <div class="copyright">
    {{ "EULA.COPYRIGHT" | translate }}
    <br />
    Version 7.{{ version }}
  </div>
</div>
