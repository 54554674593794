import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Breadcrumb, BreadcrumbData, Asset } from '@app/core/interfaces';
import { AssetsService } from '@app/services/assets.service';

@Component({
  selector: 'app-breadcrumb-asset',
  templateUrl: './breadcrumb-asset.component.html',
  styleUrls: ['./breadcrumb-asset.component.scss'],
})
export class BreadcrumbAssetComponent implements OnChanges {
  @Output() public navigateEvent = new EventEmitter<Breadcrumb>();

  @Input() assetIdentifier!: BreadcrumbData;
  asset: Asset | null = null;

  constructor(public assetService: AssetsService) {}

  //ngOnChanges fires at the same time ngOnInit would at start
  ngOnChanges(changes: SimpleChanges): void {
    //console.log(this.getUseCase());
    this.assetService.initializeService();
    this.getStaffData();
  }

  getStaffData() {
    this.assetService
      .getStaffById(this.assetIdentifier.ID)
      .subscribe((res: any) => {
        //console.log(res);
        this.asset = res;
      });
  }

  navigateTo(bc: Breadcrumb) {
    this.navigateEvent.emit(bc);
  }

  getUseCase(): string {
    return window.location.pathname.split('/')[1];
  }
}
