import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firmware',
  template: `
    <div class="wrapper">
      <firmware-view *axLazyElement></firmware-view>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class FirmwareComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
