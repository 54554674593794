import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-settings',
  template: `
    <div class="wrapper-no-table">
      <system-settings *axLazyElement></system-settings>
    </div>
  `,
  styleUrls: ['../sub-app-styles.scss'],
})
export class SystemSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
